import React from "react";
import Button from "@prism/button";
import {formatDollarsAndCents} from "../../../../../formatters";

const HailDamageRow = (props) => {
    const {damage, index, deleteDamage} = props
    return (
        <tr>
            <td className="px-0">{index + 1}</td>
            <td className="text-left pl-4">{damage.size}</td>
            <td className="text-left pl-4">{damage.dents}</td>
            <td className="text-left pl-4">{formatDollarsAndCents(damage.cost)}</td>
            <td><Button outline
                        className='btn-icon-only'
                        value={damage.id}
                        onClick={deleteDamage}>
                <i className="icon prism-icon-cancel-circle"/>
            </Button>
            </td>
        </tr>
    )
}

export default HailDamageRow;
