import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabBar = ({ border, className: classNameFromProps, children, id }) => {
  const className = classNames('tab-bar', classNameFromProps, { 'has-border': border });

  return (
    <div className={className} id={id}>
      <ul className="nav nav-tabs" role="tablist">
        {children}
      </ul>
    </div>
  );
};

TabBar.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
TabBar.defaultProps = {
  border: false,
  className: null,
  id: null,
  children: null,
};

TabBar.displayName = 'TabBar';

export default TabBar;
