import {
    ADD_ANNOUNCEMENT_BY_TYPE,
    ADD_ANNOUNCEMENT_FOR_DAMAGE,
    DELETE_ANNOUNCEMENT_BY_CODE,
    SET_ANNOUNCEMENTS,
    WRAP_VEHICLE_COLOR_NOT_SELECTED
} from "./dispatchTypes";

export const setAnnouncements = (announcements) => async (dispatch, getState) => {
    dispatch({
        type: SET_ANNOUNCEMENTS,
        payload: {
            announcements: announcements,
        }
    })
}

export const addAnnouncement = (type, text) => dispatch => {
    dispatch({
        type: ADD_ANNOUNCEMENT_BY_TYPE,
        payload: {text: text, type: type}
    })
};

export const addAnnouncementByCode = (type, code, text) => (dispatch, getState) => {
    dispatch({
        type: ADD_ANNOUNCEMENT_BY_TYPE,
        payload: {type: type, code: code, text: text}
    })
};

export const addAnnouncementForDamage = (damage) => dispatch => {
    dispatch({
        type: ADD_ANNOUNCEMENT_FOR_DAMAGE,
        payload: damage
    })
};

export const deleteAnnouncementByCode = (type, code) => dispatch => {
    dispatch({
        type: DELETE_ANNOUNCEMENT_BY_CODE,
        payload: {type: type, code: code}
    })
};

export const wrapVehicleColorNotSelected = (selected) => (dispatch) => {
    dispatch({
        type: WRAP_VEHICLE_COLOR_NOT_SELECTED,
        payload: selected
    })
}