import React, {useState, useEffect, useRef} from 'react';
import Api from '../../../Api';
import Dropdown from "../../../common/Dropdown";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import isEqual from "lodash.isequal";
import {PLEASE_SELECT} from "../../../../utils/constants";

function SeverityDropdown(props) {

    const [options, setOptions] = useState([]);
    const prevProps = useRef({});

    useEffect(() => {

        function isMissingInputs(props) {
            if (!props) {
                return true
            }
            return !props.item.itemCode || !props.item.subItemCode || !props.damageCode;
        }

        async function getSeverities(signal, props) {
            return Api.getSeverities({
                ...props.item,
                damageCode: props.damageCode,
                groupCode: props.groupCode,
                category: props.categoryCode, signal: signal
            });
        }

        if (!isEqual(prevProps.current, props)) {

            if(isMissingInputs(props)) {
                setOptions([]);
                prevProps.current = props;
            } else if(prevProps.current.damageCode !== props.damageCode) {
                const abortController = new AbortController();
                const signal = abortController.signal;

                getSeverities(signal, props).then(response => {
                    if (response.items) {
                        if(!response.items.find(item => item.severity.code === props.value)) {
                            props.value && response.items.push({severity:{code: props.value, description: props.description}})
                        }
                        setOptions(response.items);
                        if(response.items.length === 1 && !props.value) {
                            const selectedOption = response.items[0].severity;
                            const selectedOptions = [{},{text: selectedOption.description}];
                            props.onChange({target: {options: selectedOptions, selectedIndex: 1, value: selectedOption.code}})
                        }
                    } else {
                        setOptions([]);
                        props.setStatusMessage("There was a problem fetching Severity list")
                    }
                });
                prevProps.current = props;
            }
        }

    }, [props]);

    return (
        <Dropdown disabled={(props.value === '' && options.length === 0) || props.disabled || props.addDisabled} onChange={props.onChange} value={props.value}
                  id={props.id || 'severity-dropdown-comp'}>
            {!props.value && <option value='' disabled hidden>{PLEASE_SELECT}</option>}
            {options.length === 0 && props.value && !props.addDisabled && <option value={props.value}>{props.description}</option>}
            {options.length && options.map(option => {
                return (<option key={option.severity.code}
                                value={option.severity.code}>{option.severity.description}</option>)
            })}
        </Dropdown>
    )

}

export default SeverityDropdown;
