import React from 'react';
import {connect} from "react-redux";
import Configure from "./Configure";

const ConfigurePanel = (props) => {

    const {designatedDescriptionResponse, designatedDescription} = props

    const displayConfigure = () => {
        return (!!designatedDescriptionResponse && !!designatedDescription)
            || designatedDescriptionResponse === null
    }

    return (
        <div id="configure-panel" className="bg-white">
            {displayConfigure() && <Configure/>}
        </div>
    )
};

function mapStateToProps({designatedDescriptionResponse, designatedDescription}) {
    return {designatedDescriptionResponse, designatedDescription};
}

export default connect(mapStateToProps, null)(ConfigurePanel);
