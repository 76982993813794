import Form from "@prism/form";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {HARDTOP, INSPECTOR_PROVIDED, ROOF_IDS, ROOF_TYPES} from "../../../utils/constants";
import React from "react";

const RoofType = (props) => {
    const {
        designatedDescription,
        onRoofTypeChange
    } = props


    const getInstalledRoofTypeCode = () => {
        if (!designatedDescription?.installedEquipment) {
            return null;
        }
        const installedEquipWithRoofGenerics = designatedDescription.installedEquipment.filter(e => e.generics?.some(g => ROOF_IDS.includes(g.id) || g.id === HARDTOP.id));
        const installedEquipRoofIds = installedEquipWithRoofGenerics.map(e => e.generics.filter(g => ROOF_IDS.includes(g.id) || g.id === HARDTOP.id ? g : null)).flat().map(g => g.id)
        const inspectorProvidedRoofId = installedEquipWithRoofGenerics.find(e => e.installedReason === INSPECTOR_PROVIDED)?.generics.find(g => ROOF_IDS.includes(g.id) || g.id === HARDTOP.id).id;
        if (!!inspectorProvidedRoofId) {
            return inspectorProvidedRoofId;
        }
        if (installedEquipRoofIds.length > 1) {
            switch (true) {
                case installedEquipRoofIds.includes(295):
                    return 295;
                case installedEquipRoofIds.includes(82):
                    return 82;
                case installedEquipRoofIds.includes(83):
                    return 83;
                case installedEquipRoofIds.includes(188):
                    return 188;
                case installedEquipRoofIds.includes(290):
                    return 290;
                case installedEquipRoofIds.includes(105):
                    return 105;
                case installedEquipRoofIds.includes(315):
                    return 315;
                default:
                    return null;
            }
        } else {
            return installedEquipWithRoofGenerics?.[0]?.generics?.find(g => ROOF_IDS.includes(g.id)).id;
        }

    }

    return (
        <Form.Group>
            <DataPair id="roof-type-dropdown" label="Roof Type">
                <Dropdown
                    disabled={!designatedDescription?.trim}
                    onChange={onRoofTypeChange}
                    value={getInstalledRoofTypeCode()}
                    id='configure-roof-type'
                >
                    <option key={HARDTOP.id} value={HARDTOP.id}>{HARDTOP.name}</option>
                    {ROOF_TYPES.map(option => {
                        return (<option key={option.id} value={option.id}>{option.name}</option>)
                    })}
                </Dropdown>
            </DataPair>
        </Form.Group>
    )

}

export default RoofType;