import React from "react";
import ReactDOM from "react-dom";
import Icon from "@prism/icon";
import Calendar from "react-calendar"
import "../../styles/calendar.css";

class DatePicker extends React.Component {

    state = {
        showCalendar: false,
        validExpirationDate: true,
        validationError: false,
        validText: true,
        value: ""
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        if(this.props.value) {
            this.setState ({
                value: this.props.value,
                parsedValue: new Date(Date.parse(this.props.value))
            })
        }
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickInput = () => {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    };

    handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(this);

        if ((!domNode || !domNode.contains(event.target)) && this.state.showCalendar) {
            this.handleClickInput()
        }
    };

    handleDateChange = (e) => {
        const dateString = `${e.getMonth() + 1}/${e.getDate()}/${e.getFullYear()}`;
        this.setState({
            parsedValue: e,
            value: dateString,
            showCalendar: false
        });
        this.props.onChange && this.props.onChange(dateString);
    };

    render() {

        const {id} = this.props;

        return(

            <div className=" input-group align-items-center mb-3">
                <div className="input-group-prepend">
                    <button className="input-group-text pr-2 pl-2 m-0 btn btn-primary"
                            disabled={this.props.disabled}
                            onClick={this.handleClickInput}>
                        <i className={'icon icon-primary-dark prism-icon-calendar'} />
                    </button>
                </div>
                <input type="text"
                       id={id}
                       placeholder={"ie. 02/20/2020"}
                       value={this.state.value}
                       className="form-control"
                       readOnly
                       onClick={this.handleClickInput}
                />
                <div className={`calendar-container ${this.state.showCalendar && "show"}`} >
                    <Calendar calendarType="US"
                              value={this.state.parsedValue}
                              onChange={this.handleDateChange}
                              nextLabel={<Icon glyph="arrow-right" />}
                              prevLabel={<Icon glyph="arrow-left" />}
                              next2Label={<><Icon glyph="arrow-right" /><Icon glyph="arrow-right" style={{marginLeft: "-8px"}}/></>}
                              prev2Label={<><Icon glyph="arrow-left" style={{marginRight: "-8px"}} /><Icon glyph="arrow-left" /></>}
                    />
                </div>
            </div>

        )
    }
}

export default DatePicker;