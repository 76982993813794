import React, {useState} from 'react';
import Modal from '@prism/modal';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import Row from "@prism/row";

function OdometerReadingModal(props) {

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const {showModal, toggle, originalOdometerReading, updatedOdometerReading} = props;

    const handleRadioButtonClick = (e) => {
        const value = e.currentTarget.value;
        const odometerReading = value === "" ? null : parseInt(value) || 0;
        setSelectedOption(e.currentTarget.name);
        setSelectedValue(odometerReading)
    };

    const confirm = () => {
        props.submitConfirm(selectedValue);
        props.toggle();
        setSelectedOption("")
    };

    return (
        <Modal backdrop={"static"}
               isOpen={showModal}
               toggle={toggle}
               className="image-overlay">
            <Modal.Header><div id="modal-header-content">Confirm Odometer Reading</div></Modal.Header>
            <Modal.Body>
                <div id="modal-body-content">You entered an odometer reading more than +/- 5 from a previous entry. Please confirm the correct value.</div>
                <Col className="pt-2">
                    <Row className="mr-3 mt-3">
                        <Col xs={8} sm={8}>
                            <RadioButton id="updated-odometer-reading" label="Entered Odometer" name="NEW"
                                         value={updatedOdometerReading} checked={selectedOption === "NEW"}
                                         onClick={handleRadioButtonClick}/>
                        </Col>
                        <Col className={"right-align"}>
                            <label className="mt-2">{updatedOdometerReading}</label>
                        </Col>
                    </Row>
                    <Row className="mr-3 mt-4">
                        <Col xs={8} sm={8}>
                            <RadioButton id="original-odometer-reading" label="Previous Odometer" name="ORIGINAL"
                                         value={originalOdometerReading} checked={selectedOption === "ORIGINAL"}
                                         onClick={handleRadioButtonClick}/>
                        </Col>
                        <Col className="right-align ">
                            <label className="mt-2">{originalOdometerReading}</label>
                        </Col>
                    </Row>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Col id="footer-content">
                <Modal.Okay id="confirmation-submit" text={"Confirm"} disabled={!selectedOption}
                            onClick={confirm}/>
                </Col>
            </Modal.Footer>
        </Modal>
    )
}

export default OdometerReadingModal;