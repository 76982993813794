import React from 'react';
import Row from "@prism/row";
import Col from "@prism/col";
import Checkbox from "@prism/checkbox";
import DataPair from "../../common/DataPair";
import Section from "../../common/Section";
import RadioButton from "@prism/radiobutton";
import {FormGroup} from "@prism/form";
import {updateDocumentation, deleteAutoDamage, addDamage} from "../../../actions/conditionActions";
import {toggleDamageModal} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";
import {AUTO_DAMAGES_BY_ITEM} from "../../../utils/constantsDamages";
import AddDamageModal from "../condition/AddDamageModal";

class Documentation extends React.Component {

    state = {};

    handleRadioButtonClick = (e) => {
        this.props.updateDocumentation(e.currentTarget.name, e.currentTarget.value === "true");
    };

    handleCheckboxClick = (e) => {
        this.props.updateDocumentation(e.currentTarget.name, e.currentTarget.checked)
    };

    handleItemChange = (e) => {
        if(this.props.condition[e.currentTarget.name] === (e.currentTarget.value === "true")) {return}
        const item = e.currentTarget.name;
        this.props.updateDocumentation(item, e.currentTarget.value === "true");
        if (e.currentTarget.value === "true") {
            const currentItemDamage = this.props.automatedDamages.find(damage => this.concatItemSubItemDamageCodes(damage) ===
                this.concatItemSubItemDamageCodes(AUTO_DAMAGES_BY_ITEM[item]) );
            if(currentItemDamage){
                this.props.deleteAutoDamage(currentItemDamage);
            }
        } else {
            this.setState({currentItem: item});
            this.props.toggleDamageModal(item)
        }
    };

    concatItemSubItemDamageCodes = (damage) => {
        return `${damage.itemCode}${damage.subItemCode}${damage.damageCode}`
    };

    handleCancelClick = (item) => {
        this.props.updateDocumentation(item, null);
        this.props.toggleDamageModal(item);
    };

    render() {

        if(!this.props.condition) {return null}

        const {navSdCard, maintenanceBook, ownerManual, warrantyBook} = this.props.condition;

        return (
            <Section heading="Documentation">
                {this.state.currentItem && <AddDamageModal showModal={this.props.globalDisplay[this.state.currentItem]}
                                onCancelClick={() => this.handleCancelClick(this.state.currentItem)}
                                toggle={() => this.props.toggleDamageModal(this.state.currentItem)}
                                damage={AUTO_DAMAGES_BY_ITEM[this.state.currentItem]}/>}
                <Row className="mx-0 mb-2">
                    <Col xs={5} className="mb-0 pr-0">

                            <FormGroup className="m-0" row>
                                <Col className="pl-0">
                                    <Checkbox label="Nav/SD Card"
                                              name="navSdCard"
                                              checked={navSdCard}
                                              onClick={this.handleCheckboxClick}
                                              className="ml-0"
                                              id="navSdCard"
                                    />
                                </Col>
                            </FormGroup>

                    </Col>

                    <Col xs={7} className="mb-0 pl-0 text-right">

                            <FormGroup className="m-0" row>
                                <Col className="pl-0">
                                    <Checkbox label="Maintenance Books"
                                              name="maintenanceBook"
                                              checked={maintenanceBook}
                                              onClick={this.handleCheckboxClick}
                                              id="maintenanceBook"
                                    />
                                </Col>
                            </FormGroup>

                    </Col>
                </Row>
                <hr className="mx-3 border-smoke"/>
                <Row className="mx-0 mt-2">
                    <Col xl={3} lg={3} md={4} sm={6} xs={6} className="mb-0">
                        <DataPair label="Owner's Manual" required>
                            <FormGroup className="m-0" row>
                                <RadioButton label="Yes"
                                             value={true}
                                             name="ownerManual"
                                             checked={ownerManual === true}
                                             onClick={this.handleItemChange}
                                />
                                <RadioButton label="No"
                                             value={false}
                                             name="ownerManual"
                                             checked={ownerManual === false}
                                             onClick={this.handleItemChange}
                                             className="ml-3"
                                />
                            </FormGroup>
                        </DataPair>
                    </Col>

                    <Col xl={3} lg={3} md={4} sm={6} xs={6} className="mb-0">
                        <DataPair label="Warranty Book" required>
                            <FormGroup className="m-0" row>
                                <RadioButton label="Yes"
                                             value={true}
                                             name="warrantyBook"
                                             checked={warrantyBook === true}
                                             onClick={this.handleItemChange}
                                />
                                <RadioButton label="No"
                                             value={false}
                                             name="warrantyBook"
                                             checked={warrantyBook === false}
                                             onClick={this.handleItemChange}
                                             className="ml-3"
                                />
                            </FormGroup>
                        </DataPair>
                    </Col>
                </Row>
            </Section>
        )
    }
}

const matchDispatchToProps = {
    addDamage,
    updateDocumentation,
    deleteAutoDamage,
    toggleDamageModal
};


function mapStateToProps({condition, automatedDamages, globalDisplay}) {
    return {condition, automatedDamages, globalDisplay};
}

export default connect(mapStateToProps, matchDispatchToProps)(Documentation);