import {HIDE_ALL_PUSH_PANELS, DISABLE_ALL_BUTTONS, SHOW_PUSH_PANEL} from "../actions/dispatchTypes";
import {calculateButtonStates} from "../buttonStates";

export default function buttonStatesReducer(state = {}, action) {
    if(!state.scan) {
        state = getButtonStates('scan');
    }

    switch(action.type) {
        case SHOW_PUSH_PANEL:
            return getButtonStates(action.panelName);
        case HIDE_ALL_PUSH_PANELS:
            return getButtonStates('condition');
        case DISABLE_ALL_BUTTONS:
            return disableAllButtons();
        default:
            return state;
    }
}

function getButtonStates(activePanel) {
    const buttonStates = calculateButtonStates({activePanel});
    return {...buttonStates};
}

function disableAllButtons() {
    const buttonStates = {
        scan: 'disabled',
        photos: 'disabled',
        workOrderList: 'disabled',
        cr: 'disabled',
        grade: 'disabled',
        profile: 'disabled',
        notes: 'disabled'
    };

    return {...buttonStates};
}
