import {LOAD_WORK_ORDER_SUCCESS, UNLOAD_WORK_ORDER} from "../actions/dispatchTypes";
import {BMW_GROUP_CODES, MBC_GROUP_CODES} from "../utils/constants";

export default function accountReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            if (action.payload.account == null) {
                return null
            }
            return getGroupCodeFlags(action.payload.account)
        case UNLOAD_WORK_ORDER:
            return null;
        default:
            return state;
    }
}

function getGroupCodeFlags(account) {
    const isBmw = BMW_GROUP_CODES.includes(account.groupCode);
    const isMercedes = MBC_GROUP_CODES.includes(account.groupCode);
    return {...account, isBmw, isMercedes}
}
