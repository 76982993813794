import React from "react";
import Checkbox from "@prism/checkbox";
import Col from "@prism/col";
import Row from "@prism/row";
import Dropdown from "../../common/Dropdown";
import {
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    wrapVehicleColorNotSelected
} from "../../../actions/announcementsActions";
import {connect} from "react-redux";
import {CONDITION_ANNOUNCEMENT} from "../../../utils/constants";

class AnnouncementList extends React.Component {

    getWrapAnnouncementCodes = () => {
        const options = this.props.dataLists.announcementsDictionary && this.props.dataLists.announcementsDictionary.filter(ann => ann.text.toUpperCase().startsWith("WRAPPED VEHICLE:"));
        return options.map(opt => opt.code)
    };

    state = {
        isWrapped: false,
        wrapAnnouncementCodes: this.getWrapAnnouncementCodes()
    };

    componentDidMount() {
        this.checkWrapAnnouncement();
    }

    announcementExists = (code) => {
        if(!this.props.announcements.items) {return false}
        const conditionAnnouncements = this.props.announcements.items.find(announcement => announcement.source === CONDITION_ANNOUNCEMENT);
        if(!conditionAnnouncements) {return false}
        return conditionAnnouncements.announcements.filter(announcement => announcement.referenceCode === code).length > 0
    };

    announcementListNoWraps = () => {
        return this.props.dataLists.announcementsDictionary && this.props.dataLists.announcementsDictionary.filter(ann => !ann.text.toUpperCase().startsWith("WRAPPED VEHICLE:")).sort((a, b) =>
        {
            var str1 = (a.altText1) ? a.altText1.toUpperCase() : a.text.toUpperCase();
            var str2 = (b.altText1) ? b.altText1.toUpperCase() : b.text.toUpperCase();
            return (str1 > str2) ? 1 : -1;
        })
    };

    announcementTextForCode = (code) => {
        const ann = this.props.dataLists.announcementsDictionary.find(announcement => announcement.code === code);
        return ann? (ann.altText1 ? ann.altText1 : ann.text): "";
    };

    announcementWrapColors = () => {
        const options = this.props.dataLists.announcementsDictionary && this.props.dataLists.announcementsDictionary.filter(ann => ann.text.toUpperCase().startsWith("WRAPPED VEHICLE:"));
        return options.map(opt => {
            return {
                ...opt,
                color: opt.text.substring(17)
            }
        })
    };

    toggleWrapped = (e) => {
        this.setState({isWrapped: !this.state.isWrapped});
        this.props.wrapVehicleColorNotSelected(false)

        if(!e.currentTarget.checked && this.state.wrapCode) {
            this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, this.state.wrapCode);
        } else if(e.currentTarget.checked) {
            if(this.state.wrapCode) {
                this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, this.state.wrapCode, this.announcementTextForCode(this.state.wrapCode));
            }else{
                this.props.wrapVehicleColorNotSelected(true)
            }
        }
    };

    handleCheckboxChange = (e) => {
        console.log(e);
        if(e.currentTarget.checked) {
            this.props.addAnnouncementByCode(e.currentTarget.name, e.currentTarget.value, this.announcementTextForCode(e.currentTarget.value));
        } else {
            this.props.deleteAnnouncementByCode(e.currentTarget.name, e.currentTarget.value);
        }
    };

    handleWrapColorChange = async (e) => {
        if(this.state.wrapCode && (this.state.wrapCode !== e.value)) { this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, this.state.wrapCode);}
        this.setState({
            wrapColor: e.currentTarget.name,
            wrapCode: e.currentTarget.value
        });
        this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, e.currentTarget.value, this.announcementTextForCode(e.currentTarget.value));

        if(e.currentTarget.value !== "") {
            this.props.wrapVehicleColorNotSelected(false)
        }else{
            this.props.wrapVehicleColorNotSelected(true)
        }
    };

    checkWrapAnnouncement = () => {
        if(this.props.announcements.wrapVehicleColorIsMissing === true){
            this.setState({
                isWrapped: true
            })
            return
        }

        if(!!this.state.wrapCode) {return}
        if(!this.props.announcements.items) {return false}
        const conditionAnnouncements = this.props.announcements.items.find(announcement => announcement.source === CONDITION_ANNOUNCEMENT);
        if(!conditionAnnouncements || !conditionAnnouncements.announcements.length) {return}
        const wrapAnnouncement = conditionAnnouncements.announcements.filter(announcement => this.state.wrapAnnouncementCodes.includes(announcement.referenceCode));
        if(wrapAnnouncement.length) {
            this.setState({
                isWrapped: true,
                wrapCode: wrapAnnouncement[0].referenceCode,
                wrapColor: wrapAnnouncement[0].text.substring(17)
            })
        }
    };

    render() {
        return(
            <Col className="announcement-list-container px-0">

                {this.announcementListNoWraps().map(ann => {
                    const checked = this.announcementExists(ann.code);
                    return (<Col  key={`${ann.code}${checked.toString()}`}>
                                <Checkbox name={CONDITION_ANNOUNCEMENT} label={ann.altText1 ? ann.altText1 : ann.text} id={ann.code} onChange={this.handleCheckboxChange} value={ann.code} checked={checked}/>
                            </Col>)
                })}
                <Col  key={`wrapAnn${this.state.wrapColor}${this.state.isWrapped}`}>
                    <Checkbox id="color-wrap-checkbox" label="Wrapped Vehicle" className="mb-2" checked={this.state.isWrapped} onChange={this.toggleWrapped}/>
                </Col>

                <Col xs={7}>
                    {this.state.isWrapped && <Row>
                    <Col xs={1}><span className="col-form-label font-weight-bolder text-danger pr-1">*</span></Col>
                    <Col className="pl-0">
                        <Dropdown id="color-wrap-list" onChange={this.handleWrapColorChange} value={this.state.wrapCode}>
                            <option value={""}>Select One...</option>
                            {this.announcementWrapColors().map(color => {
                                return <option name={color.color} value={color.code} key={color.code}>{color.color}</option>
                            })}
                        </Dropdown>
                    </Col>
                    </Row>}
                </Col>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    wrapVehicleColorNotSelected
};

function mapStateToProps({announcements, dataLists}) {
    return {announcements, dataLists};
}

export default connect(mapStateToProps, matchDispatchToProps)(AnnouncementList);