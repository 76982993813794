import React from "react";
import Message from "@prism/message";
import {connect} from "react-redux";
import {hideDamageSuccessMessage} from "../../actions/globalDisplayActions"

class DamageSuccessMessage extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.globalDisplay.showDamageSuccessMessage && this.props.globalDisplay.showDamageSuccessMessage) {
      setTimeout(this.props.hideDamageSuccessMessage, 5000)
    }
  }

  render() {
    return (
      <Message
        id="damage-success-message"
        isOpen={this.props.globalDisplay.showDamageSuccessMessage}
        className="floating-message"
        type="success">
        Damage Added
      </Message>
    )
  }
}

const mapStateToProps = ({globalDisplay}) => {
  return {globalDisplay}
}

const mapDispatchToProps = {
  hideDamageSuccessMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(DamageSuccessMessage)