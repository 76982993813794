import React from 'react';
import Tires from "./Tires";


const TiresPanel = (props) => {

    return (
        <div id="tires-panel" className="bg-white">
            <Tires/>
        </div>
    )
};

export default TiresPanel