import React from "react";
import Col from "@prism/col";
import {connect} from "react-redux";

const Seller = (props) => {
    return(
        <Col>
            <div className="seller-decoration">
                <div className="seller-overlay-box" />
                <div className="font-weight-lighter seller">SELLER</div>
            </div>
            <div className="mt-2">
                <div className="font-weight-bolder seller-name">{props.account ? props.account.accountDescription : '--'}</div>
                <div className="font-weight-lighter groupcode-number">{props.account ? `${props.account.groupCode}/${props.account.accountNumber}` : '--'}</div>
            </div>
            <br/>
        </Col>
    )
};

function mapStateToProps({account}) {
    return {account};
}
export default connect(mapStateToProps)(Seller);