import React from "react";
import Row from "@prism/row";
import LastServiceDate from "./LastServiceDate";
import ServiceRequired from "./ServiceRequired";
import ServiceNotes from "./ServiceNotes";
import LastServiceOdo from "./LastServiceOdo";
import ServiceExceeded from "./ServiceExceeded";
import ServiceDue from "./ServiceDue";
import EngineOilAndBrakeFluid from "./EngineOilAndBrakeFluid";
import Section from "../../common/Section";

const MaintenanceAndService = () => {
    return (
        <Section heading="Maintenance & Service">
            <Row className="mx-0">
                <ServiceRequired/>
                <EngineOilAndBrakeFluid/>
                <LastServiceDate/>
                <LastServiceOdo/>
                <ServiceDue/>
                <ServiceExceeded/>
                <ServiceNotes/>
            </Row>
        </Section>
    )
};

export default MaintenanceAndService;