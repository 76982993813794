import {
    ADD_AUTOMATED_DAMAGE,
    ADD_DAMAGE,
    DELETE_AUTOMATED_DAMAGE,
    DELETE_DAMAGE,
    DELETE_DAMAGE_IMAGE,
    HANDLE_KEEP_PHOTO_RESPONSE,
    LOAD_WORK_ORDER_SUCCESS,
    SET_CONDITION,
    SET_CONDITION_ODOMETER_FROM_UNIT,
    UNLOAD_WORK_ORDER,
    UPDATE_CHARGE_PERCENTAGE,
    UPDATE_CHARGING_CABLE,
    UPDATE_CONDITION,
    UPDATE_CURRENT_RANGE,
    UPDATE_DAMAGE,
    UPDATE_DAMAGE_IMAGE,
    UPDATE_DOCUMENTATION,
    UPDATE_DRIVABLE,
    UPDATE_EMISSIONS,
    UPDATE_ENGINE_STARTS,
    UPDATE_FUEL_LEVEL,
    UPDATE_HEADSET_COUNT,
    UPDATE_INTERIOR_ODOR,
    UPDATE_KEY_VALUE,
    UPDATE_LAST_SERVICE_DATE,
    UPDATE_LAST_SERVICE_ODO,
    UPDATE_ODOMETER_READING,
    UPDATE_ODOMETER_UNITS,
    UPDATE_PAINT_TYPE,
    UPDATE_REMOTE_COUNT,
    UPDATE_SERVICE_DUE,
    UPDATE_SERVICE_EXCEEDED,
    UPDATE_SERVICE_NOTES,
    UPDATE_SERVICE_REQUIRED,
    UPDATE_TIRE
} from "../actions/dispatchTypes";
import update from "immutability-helper";
import {
    COMPACT_SPARE,
    CRSO,
    KILOMETER_STATIC_VALUE,
    KILOMETERS_TITLE_CASE,
    MILES_TITLE_CASE,
    SPARE,
    TIRE_LOCATIONS
} from "../utils/constants";
import {isPricingEmptyWithValidAction} from "../utils/utils";

export default function conditionReducer(state = defaultState(), action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return loadInitialWorkOrder(state, action.payload);
        case SET_CONDITION:
            return loadConditionData(state, action.payload);
        case SET_CONDITION_ODOMETER_FROM_UNIT:
            return setConditionOdometer(state, action.payload.odometer);
        case UNLOAD_WORK_ORDER:
            return defaultState();
        case UPDATE_CONDITION:
            return action.payload;
        case UPDATE_DOCUMENTATION:
            return updateDocumentation(state, action.payload);
        case UPDATE_KEY_VALUE:
            return updateKeyValue(state, action.payload);
        case UPDATE_SERVICE_REQUIRED:
            return updateServiceRequired(state, action.payload);
        case UPDATE_FUEL_LEVEL:
            return updateFuelLevel(state, action.payload);
        case UPDATE_SERVICE_NOTES:
            return updateServiceNotes(state, action.payload);
        case UPDATE_HEADSET_COUNT:
            return updateHeadsetCount(state, action.payload);
        case UPDATE_REMOTE_COUNT:
            return updateRemoteCount(state, action.payload);
        case UPDATE_PAINT_TYPE:
            return updatePaintType(state, action.payload);
        case UPDATE_CHARGING_CABLE:
            return updateChargingCable(state, action.payload);
        case UPDATE_TIRE:
            return updateTire(state, action.payload);
        case DELETE_DAMAGE:
        case DELETE_AUTOMATED_DAMAGE:
            return deleteDamage(state, action.payload);
        case ADD_DAMAGE:
        case ADD_AUTOMATED_DAMAGE:
            return addDamage(state, action.payload);
        case UPDATE_DAMAGE:
            return updateDamage(state, action.payload);
        case UPDATE_INTERIOR_ODOR:
            return updateInteriorOdor(state, action.payload)
        case UPDATE_LAST_SERVICE_DATE:
            return updateLastServiceDate(state, action.payload)
        case UPDATE_LAST_SERVICE_ODO:
            return updateLastServiceOdo(state, action.payload)
        case UPDATE_EMISSIONS:
            return updateEmissions(state, action.payload)
        case UPDATE_SERVICE_DUE:
            return updateServiceDue(state, action.payload)
        case UPDATE_DRIVABLE:
            return updateDrivable(state, action.payload)
        case UPDATE_ENGINE_STARTS:
            return updateEngineStarts(state, action.payload)
        case UPDATE_SERVICE_EXCEEDED:
            return updateServiceExceeded(state, action.payload);
        case DELETE_DAMAGE_IMAGE:
            return setHasImage(state, action.payload.key, false);
        case UPDATE_DAMAGE_IMAGE:
            return setHasImage(state, action.payload.key, true);
        case HANDLE_KEEP_PHOTO_RESPONSE:
            const hasImage = !action.payload.deletedImageAfterKeeping
            return setHasImage(state, action.payload.key, hasImage);
        case UPDATE_ODOMETER_READING:
            return updateOdometerReading(state, action.payload);
        case UPDATE_ODOMETER_UNITS:
            return updateOdometerUnits(state, action.payload);
        case UPDATE_CHARGE_PERCENTAGE:
            return updateChargePercentage(state, action.payload);
        case UPDATE_CURRENT_RANGE:
            return updateCurrentRange(state, action.payload);
        default:
            return state;
    }
}

function defaultState() {
    return {keys: []}
}

function setConditionOdometer(state, odometer) {
    const odometerUnits = odometer.units.toUpperCase().startsWith('K') ? KILOMETERS_TITLE_CASE : MILES_TITLE_CASE
    return update(state, {
        $merge: {
            odometer: {
                reading: odometer.reading,
                units: odometerUnits
            }
        }
    });
}

function loadInitialWorkOrder(state, payload) {
    let condition = payload.condition;
    let unit = payload.unit;
    let newState = {};
    if (!condition) {
        newState = update(defaultState(), {
            $merge: {
                isNewCondition: true,
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            }
        });
    } else if (!condition.href) {
        newState = update(condition, {
            $merge: {
                isNewCondition: true,
                keys: [],
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            }
        });
    } else if (condition.damages) {
        const initDamages = condition.damages.map(damage => {
            damage.imageHref ? damage.hasImage = true : damage.hasImage = false
            damage.damageKeyAtWorkOrderLoad = damage.damageKey
            return damage
        })
        const missingPricing = anyDamageMissingPricing(condition, condition.damages);

        newState = update(condition, {
            $merge: {
                missingPricing: missingPricing,
                damages: initDamages,
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            },
        });
    } else {
        newState = update(condition, {
            $merge: {
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            },
        });
    }

    // check if tires exists and not empty
    if (newState.tires && newState.tires.length) {
        newState.tires = newState.tires.map(tire => {
            if (tire.location === SPARE && tire.spareType === COMPACT_SPARE) {
                if (tire.construction == null || tire.manufacturer == null) {
                    return {...tire, construction: 'R', manufacturer: 'Other'};
                }
            }
            return tire;
        });
    }

    // if tires undefined or null or empty array
    if (newState.tires && !newState.tires.length) {
        TIRE_LOCATIONS.forEach(location => {
            newState.tires.push({location: location})
        })
        console.log(" populated tires with default tire locations ")
    }

    return newState;
}

function loadConditionData(state, payload) {
    let condition = payload.condition;
    let unit = payload.unit;
    let newState = {};

    if (condition.damages) {
        const initDamages = condition.damages.map(damage => {
            damage.imageHref ? damage.hasImage = true : damage.hasImage = false
            damage.damageKeyAtWorkOrderLoad = damage.damageKey
            return damage
        })
        const missingPricing = anyDamageMissingPricing(condition, condition.damages);

        newState = update(condition, {
            $merge: {
                missingPricing: missingPricing,
                damages: initDamages,
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            },
        });
    } else {
        newState = update(condition, {
            $merge: {
                odometer: {
                    reading: unit.odometer.reading,
                    units: getUnitsForCondition(unit.odometer.units)
                }
            },
        });
    }

    return newState;
}

function getUnitsForCondition(units) {
    if (units) {
        return units.toUpperCase().startsWith('K') ? KILOMETERS_TITLE_CASE : MILES_TITLE_CASE
    }
    return MILES_TITLE_CASE;
}

function updateKeyValue(state, key) {
    let keyIndex = state.keys && state.keys.map(conditionKey => {
        return conditionKey.type
    }).indexOf(key.type);
    if (key.quantity === "N/A") {
        if (keyIndex >= 0) {
            return update(state, {keys: {$splice: [[keyIndex, 1]]}});
        }
    } else {
        key.quantity = parseInt(key.quantity);
        if (keyIndex === -1) {
            return update(state, {
                keys: {
                    $push: [key]
                }
            })
        } else {
            return update(state, {
                keys: {
                    [keyIndex]: {
                        $set: key
                    }
                }
            })
        }
    }
}

function updateHeadsetCount(state, newCount) {
    return update(state, {
        $merge: {
            entertainmentSystemHeadset: newCount
        }
    })
}

function updateRemoteCount(state, newCount) {
    return update(state, {
        $merge: {
            entertainmentSystemRemote: newCount
        }
    })
}

function updateDocumentation(state, updatedDocumentation) {
    return update(state, {
        $merge: {
            ...updatedDocumentation
        }
    })
}

function updatePaintType(state, updatedPaintType) {
    return update(state, {
        $merge: {
            paintType: updatedPaintType
        }
    })
}

function updateChargingCable(state, chargingCablePresent) {
    return update(state, {
        $merge: {
            chargingCable: chargingCablePresent
        }
    })
}

function updateOdometerReading(state, mileage) {
    return update(state, {
        $merge: {
            odometer: {
                ...state.odometer,
                reading: mileage
            }
        }
    })
}

function updateOdometerUnits(state, units) {
    return update(state, {
        $merge: {
            odometer: {
                ...state.odometer,
                units: (getOdometerUnits(units))
            }
        }
    })
}

function getOdometerUnits(units) {
    return units === KILOMETER_STATIC_VALUE ? KILOMETERS_TITLE_CASE : MILES_TITLE_CASE
}

function updateTire(state, tire) {
    const tireIndex = state.tires ? state.tires.map(conditionTire => {
        return conditionTire.location
    }).indexOf(tire.location) : -1;
    if (!state.tires) {
        return update(state, {
            $merge: {
                tires: [tire]
            }
        })
    } else if (tireIndex === -1) {
        return update(state, {
            tires: {
                $push: [tire]
            }
        })
    } else {
        return update(state, {
            tires: {
                [tireIndex]: {
                    $set: tire
                }
            }
        })
    }
}

function deleteDamage(state, deletedDamage) {
    const damages = state.damages.filter(damage => damage.damageKey !== deletedDamage.damageKey);
    const missingPricing = anyDamageMissingPricing(state, damages);
    return update(state, {
        $merge: {
            missingPricing: missingPricing,
            damages: damages
        }
    })
}

function addDamage(state, newDamage) {
    const damage = {...newDamage, damageKey: calculateDamageKey(newDamage)};
    if (state?.damages?.find(dmg => dmg.damageKey === damage.damageKey)) {
        return state
    } else {
        if (!state.damages) {
            return update(state, {
                $merge: {
                    damages: [damage]
                }
            })
        } else {
            return update(state, {
                damages: {
                    $push: [damage]
                }
            })
        }
    }
}

function updateDamage(state, updatedDamage) {
    const damages = state.damages.map(damage => damage.damageKey === updatedDamage.damageKey ? Object.assign(updatedDamage, {damageKey: calculateDamageKey(updatedDamage)}) : damage);
    const missingPricing = anyDamageMissingPricing(state, damages);
    return update(state, {
        $merge: {
            missingPricing: missingPricing,
            damages: damages
        }
    })
}

function updateInteriorOdor(state, newOdor) {
    return update(state, {
        $merge: {
            interiorOdor: newOdor
        }
    })
}

function updateServiceRequired(state, serviceRequired) {
    return update(state, {
        $merge: {
            serviceRequired: serviceRequired
        }
    })

}

function updateFuelLevel(state, fuelLevel) {
    return update(state, {
        $merge: {
            fuelLevel: fuelLevel
        }
    })

}

function updateLastServiceDate(state, lastServiceDate) {
    return update(state, {
        $merge: {
            lastServiceDate: lastServiceDate
        }
    })
}

function updateServiceNotes(state, serviceNotes) {
    return update(state, {
        $merge: {
            serviceNotes: serviceNotes
        }
    })
}

function updateLastServiceOdo(state, lastServiceOdo) {
    return update(state, {
        $merge: {
            lastServiceOdometer: lastServiceOdo
        }
    })
}

function updateDrivable(state, drivablePresent) {
    return update(state, {
        $merge: {
            drivable: drivablePresent
        }
    })
}

function updateEngineStarts(state, engineStarts) {
    return update(state, {
        $merge: {
            engineStarts: engineStarts
        }
    })
}

function updateEmissions(state, newEmission) {
    return update(state, {
        $merge: {
            emissions: newEmission
        }
    })
}

function updateServiceDue(state, serviceDue) {
    return update(state, {
        $merge: {
            serviceDueInMiles: serviceDue
        }
    })
}

function updateServiceExceeded(state, serviceExceeded) {
    return update(state, {
        $merge: {
            serviceExceededByMiles: serviceExceeded
        }
    })
}

function setHasImage(state, damageKey, hasImage) {
    if (!state.damages) {
        return state
    }
    const damageIndex = state.damages
        .map((damage) => damage.damageKey === damageKey)
        .indexOf(true);
    if (damageIndex === -1) {
        return state;
    }
    return update(state, {
        damages: {
            [damageIndex]: {
                $merge: {hasImage: hasImage},
            },
        },
    });
}

function updateChargePercentage(state, chargePercentage) {
    return update(state, {
        $merge: {
            chargePercentage: chargePercentage
        }
    })
}

function updateCurrentRange(state, currentRange) {
    return update(state, {
        $merge: {
            currentRange: currentRange
        }
    })
}

export function calculateDamageKey(damage) {
    return `L1-${damage.itemCode}-${damage.subItemCode}-${damage.damageCode}`;
}

function anyDamageMissingPricing(condition, damages) {
    if (condition.conditionType !== CRSO || !damages) {
        return false;
    }
    return damages.some((damage) => {
        return isPricingEmptyWithValidAction(damage)
    });
}