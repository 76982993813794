import React, {useEffect, useRef, useState} from 'react';
import Api from '../../../Api';
import Dropdown from "../../../common/Dropdown";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import {CHARGEABLE_OPTIONS, PLEASE_SELECT} from "../../../../utils/constants";
import isEqual from "lodash.isequal";


function ChargeableDropdown(props) {

    const [options, setOptions] = useState([]);
    const prevProps = useRef({});

    useEffect(() => {

        function isMissingInputs(props) {
            if (!props) {
                return true
            }
            return !props.severityCode || !props.item.itemCode;
        }

        async function getChargeables(signal, props) {
            return Api.getChargeableOptions({
                ...props.item,
                damageCode: props.damageCode,
                severityCode: props.severityCode,
                groupCode: props.groupCode,
                category: props.categoryCode,
                listOptions: true,
                signal: signal
            });
        }

        function inChargeableOptions(props) {
            return CHARGEABLE_OPTIONS.find(option => option.code === props.value)
        }

        function valueMatchesDefaultChargeable(response) {
            return !!props.value && !!response.code && (props.value === response.code) && !!response.description;
        }

        if (!isEqual(prevProps.current, props)) {

            if (isMissingInputs(props)) {
                setOptions([]);
                prevProps.current = props;
            } else if (prevProps.current.severityCode !== props.severityCode || prevProps.current.value !== props.value) {
                const abortController = new AbortController();
                const signal = abortController.signal;
                let options = [...CHARGEABLE_OPTIONS];

                getChargeables(signal, props).then(response => {
                    if (response.description) {

                        if (props.value && !inChargeableOptions(props)) {
                            const chargeableOption = response.options.find(option => option.code === props.value);
                            if (!chargeableOption) {
                                if (valueMatchesDefaultChargeable(response)) {
                                    options.push({code: props.value, description: response.description})
                                }
                            } else {
                                options.push({code: props.value, description: chargeableOption.description})
                            }
                        }

                        if (!options.find(item => item.code === response.code)) {
                            options.push({code: response.code, description: response.description})
                        }

                        setOptions(options);

                        if (!props.value) {
                            props.onChange({
                                target: {
                                    value: response.code,
                                    options: [{text: response.description}],
                                    selectedIndex: 0
                                }
                            })
                        }

                    } else {
                        setOptions(options);
                    }
                });
                prevProps.current = props;
            }

        }


    }, [props]);

    return (
        <Dropdown disabled={(!props.value && options.length === 0) || props.disabled} onChange={props.onChange}
                  value={props.value}
                  id='chargeable-dropdown-comp'>
            {!props.value && <option value='' disabled>{PLEASE_SELECT}</option>}
            {options.map(option => {
                return (<option key={option.code}
                                value={option.code}>{option.description}</option>)
            })}
        </Dropdown>
    )

}

export default ChargeableDropdown;
