import {SET_APPLICATION_FLAGS} from "./dispatchTypes";
import {APP_VERSION} from "../config/env";

export const setApplicationFlags = (version) => dispatch => {
    const appVersion = getAppVersion();

    dispatch({
        type: SET_APPLICATION_FLAGS,
        payload:{
            appVersion: appVersion,
            webVersion: APP_VERSION,
            reduxStateVersion: evaluateReduxStateVersion(appVersion),
            flags: {
                deviceStorage: evaluateDeviceStorageFlag(appVersion)
            }
        }
    })

};

export const getAppVersion = () => {
    try {
        return window.NativeApp.getAppVersion();
    }
    catch {
        return "0.0"
    }
};

export const evaluateDeviceStorageFlag = (version) => {
    return parseInt(version.replace(/\./g,"")) > 10024
}

export const evaluateReduxStateVersion = (version) => {
    return parseInt(version.replace(/\./g,"")) < 10036 ? 1 : 2;
}