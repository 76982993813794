import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import "../../../styles/review-panel.css";
import {setCurrentConditionTabIndex} from "../../../actions/naviationActions";
import {
    MISSING_MANUFACTURER,
    MISSING_SIZE,
    MISSING_DEPTH, MISSING_SPARE, MISSING_CONSTRUCTION
} from "../../../utils/messages";
import {TIRE_LOCATIONS} from "../../../utils/constants";

class TiresReview extends React.Component {

    state = {
        isValid: false
    };

    validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    }

    validationFailureIcon = () => {
        return <Icon glyph="notification-circle" color="danger" className="status-icon"/>
    }

    navigateToTiresTab = () => {
        this.props.setCurrentConditionTabIndex(2);
    }

    getValidationMessages =() => {

        const validationMessages = [];

        if(!this.props.condition.tires) {
            TIRE_LOCATIONS.forEach(tireLocation => {
                if(tireLocation !== 'Spare') {
                    validationMessages.push(tireLocation+':'+MISSING_MANUFACTURER)
                    validationMessages.push(tireLocation+':'+MISSING_SIZE)
                    validationMessages.push(tireLocation+':'+MISSING_DEPTH)
                } else {
                    validationMessages.push(MISSING_SPARE)
                }
            });

        } else {
         for(var tire of this.props.condition.tires) {

             if(tire.location === 'Spare' && !tire.spareType ) {
                 validationMessages.push(MISSING_SPARE)
             }

             if(tire.location === 'Spare' && tire.spareType !== 'full-spare') {
                continue;
             }

            if(tire.location === 'Spare' && tire.spareType === 'full-spare' && this.props.condition.damages!=null &&
                this.props.condition.damages.find(damage => (damage.item === 'Spare Tire' && damage.damageCode === 'MS'))!= null) {
               continue;
            }

             if(!tire.manufacturer){
                validationMessages.push(tire.location+':'+MISSING_MANUFACTURER)
             }
             if(!tire.size){
                validationMessages.push(tire.location+':'+MISSING_SIZE)
             }
             if(!tire.depth){
                validationMessages.push(tire.location+':'+MISSING_DEPTH)
             }
             if(!tire.construction){
                 validationMessages.push(tire.location+':'+MISSING_CONSTRUCTION)
             }
          }
         }
          return validationMessages.join(", ")
    }

    componentDidMount() {
        this.checkValidation();
    }

    checkValidation = () => {
        var isValid = true;
        if(!this.props.condition.tires) {
            isValid = false;
        } else {
         for(var tire of this.props.condition.tires) {
             if(tire.location === 'Spare' && !tire.spareType ) {
                 isValid = false;
             }

             if (tire.location === 'Spare' && tire.spareType !== 'full-spare') {
                 continue;
             }
             if (tire.location === 'Spare' && tire.spareType === 'full-spare' && this.props.condition.damages != null &&
                 this.props.condition.damages.find(damage => (damage.item === 'Spare Tire' && damage.damageCode === 'MS'))) {
                 continue;
             }

             if (!tire.manufacturer || !tire.size || !tire.depth || !tire.construction) {
                 isValid = false;
                 break;
             }
         }
        }
        this.setState({isValid});
        this.props.tiresPanelIsValid(isValid);
    }

    render() {
        const {isValid} = this.state;
        return (
            <Row className={`px-6 py-4 mx-0 review-row ${!isValid && " show-is-clickable"}`} onClick={isValid ? undefined : this.navigateToTiresTab}>
                <Col className="flex-grow-1">
                    <Row className="h2 review-row-title"><Col>Tires</Col></Row>
                    {!isValid && <Row className="review-error-message"><Col>{this.getValidationMessages()}</Col></Row>}
                </Col>
                <Col className="flex-grow-0">
                    {isValid ? this.validationSuccessIcon() : this.validationFailureIcon()}
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({condition}) {
    return {condition};
}

const matchDispatchToProps = {
    setCurrentConditionTabIndex
}

export default connect(mapStateToProps, matchDispatchToProps)(TiresReview);
