import React from "react";

import Button from '@prism/button';
import Chip from '@prism/chip';
import Col from '@prism/col';
import Row from '@prism/row';

import SlidePanel from "../../SlidePanel";
import {
    STANDARD_TIRE_WIDTHS,
    RATIO_TIRE_WIDTHS,
    OTHER_TIRE_WIDTHS,
    STANDARD_ASPECT_RATIOS,
    ALTERNATE_ASPECT_RATIOS,
    WHEEL_DIAMETERS
} from "../../../utils/constants";

class TireSizeSelector extends React.Component {

    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tire.size && (this.props.isOpen !== prevProps.isOpen)) {
            const {size} = this.props.tire;
            const separator = size.indexOf("X") === -1 ? "/" : "X";
            const rimDiameter = size.split("-")[1];
            const width = separator === "X" ? size.slice(0, (size.indexOf(separator)+1)) : size.slice(0, size.indexOf(separator));
            const aspectRatio = size.slice((size.indexOf(separator) + 1), (size.indexOf("-")));

            const updatedState = {
                size: this.props.tire.size,
                aspectRatio,
                rimDiameter,
                width
            };
            this.setState(updatedState);
        }
    }

    handleWidthClick = (w) => {
        this.setState({width: w});
    };

    handleAspectRatioClick = (r) => {
        this.setState({aspectRatio: r});
    };

    handleRimDiameterClick = (d) => {
        this.setState({rimDiameter: d});
    };

    handleCancelClick = () => {
        this.setState({
            width: this.props.tire.width,
            aspectRatio: this.props.tire.aspectRatio,
            rimDiameter: this.props.tire.rimDiameter,
            size: this.props.tire.size
        });
        this.props.handleToggleSizeSelector();
    };

    handleSaveClick = () => {
        if(!this.requiredFieldsFound()) { return; }
        this.props.handleSizeUpdate({...this.state});
        this.props.handleToggleSizeSelector();
    };

    requiredFieldsFound = () => {
        const {width, aspectRatio, rimDiameter} = this.state;
        return width && aspectRatio && rimDiameter
    }

    render() {
        const {width, aspectRatio, rimDiameter} = this.state;

        return (
            <SlidePanel width={"100vw"} id="scan-panel"
                        isOpen={this.props.isOpen}
                        from='left'
                        // offset={true}
                        className="bg-white"
            >
                <Col id="edit-tire-size">
                    <Row className="bg-ash flex-row-reverse mb-1 navbar">
                        <Col className="flex-grow-0">
                            <i style={{fontSize: '30px'}} className="icon prism-icon-cross cursor-pointer"
                               id='close-tire-size-selector' onClick={this.handleCancelClick}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1 className={'prism-h1'}>Edit Tire Size</h1>
                        </Col>
                    </Row>
                    <div className="tire-width">
                        <Row className="my-2">
                            <div className="col">
                                <label className="prism-h5 item">Tire Width</label>
                            </div>
                        </Row>
                        <Row className="mx-0">
                            {STANDARD_TIRE_WIDTHS.map(width_option => (
                                <Col xs={2} className="my-1 p-0" key={`std-width-${width_option}`}>
                                    <Chip type="select" label={width_option}
                                          selected={width === width_option}
                                          onClick={() => this.handleWidthClick(width_option)}/>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mx-0">
                            {RATIO_TIRE_WIDTHS.map(width_option => (
                                <Col xs={2} className="my-1 p-0" key={`ratio-width-${width_option}`}>
                                    <Chip type="select" label={width_option}
                                          selected={width === width_option}
                                          onClick={() => this.handleWidthClick(width_option)}/>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mx-0">
                            {OTHER_TIRE_WIDTHS.map(width_option => (
                                <Col xs={2} className="my-1 p-0" key={`other-width-${width_option}`}>
                                    <Chip type="select" label={width_option}
                                          selected={width === width_option}
                                          onClick={() => this.handleWidthClick(width_option)}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="aspect-ratio">
                        <Row className="my-2">
                            <div className="col">
                                <label className="prism-h5 item">Aspect Ratio</label>
                            </div>
                        </Row>
                        <Row className="mx-0">
                            {STANDARD_ASPECT_RATIOS.map(aspect => (
                                <Col xs={2} className="my-1 p-0" key={`std-aspect-${aspect}`}>
                                    <Chip type="select" label={aspect} selected={aspectRatio === aspect}
                                          onClick={() => this.handleAspectRatioClick(aspect)}/>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mx-0">
                            {ALTERNATE_ASPECT_RATIOS.map(aspect => (
                                <Col xs={2} className="my-1 p-0" key={`alt-aspect-${aspect}`}>
                                    <Chip type="select" label={aspect} selected={aspectRatio === aspect}
                                          onClick={() => this.handleAspectRatioClick(aspect)}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="wheel-diameter">
                        <Row className="my-2">
                            <div className="col">
                                <label className="prism-h5 item">Wheel Diameter</label>
                            </div>
                        </Row>
                        <Row className="mx-0">
                            {WHEEL_DIAMETERS.map(diameter => (
                                <Col xs={2} className="my-1 p-0" key={`diameter-${diameter}`}>
                                    <Chip type="select" label={diameter} selected={rimDiameter === diameter}
                                          onClick={() => this.handleRimDiameterClick(diameter)}/>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <Row className="mx-0">
                        <Col xs={12} className="p-0 mt-3 mb-1">
                            <Button disabled={!this.requiredFieldsFound()} color="primary" className="btn-full-width"
                                    onClick={this.handleSaveClick}>SAVE</Button>
                        </Col>
                        <Col xs={12} className="p-0 mt-1 mb-3">
                            <Button outline className="btn-full-width" onClick={this.handleCancelClick}>CANCEL</Button>
                        </Col>
                    </Row>
                </Col>
            </SlidePanel>
        )

    }
}

export default TireSizeSelector;