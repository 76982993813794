import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import {updateServiceExceeded} from "../../../actions/conditionActions";
import {connect} from "react-redux";

class ServiceExceeded extends React.Component {

    handleChange = (e) => {
        const value = e.target.value;
        const serviceExceeded = value === "" ? null : parseInt(value) || 0;
        this.props.updateServiceExceeded(serviceExceeded);
    };

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        if(this.props.condition && (this.props.condition.serviceRequired === undefined || this.props.condition.serviceRequired === null)) {
            return <></>
        }

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3} className='mb-2'>
                <DataPair label="Service Exceeded By (m)">
                    <input
                        type="text"
                        id="service-exceeded"
                        className="form-control"
                        value={this.props.condition.serviceExceededByMiles}
                        maxLength={6}
                        onChange={this.handleChange}
                    />
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateServiceExceeded
};

function mapStateToProps({condition}) {
    return {condition};
}
export default connect(mapStateToProps, matchDispatchToProps)(ServiceExceeded);