import React from "react";
import DataPair from "../../common/DataPair";
import {ELECTRIC_FUEL_CATEGORIES} from "../../../utils/constants";
import {connect} from "react-redux";
import Input from "@prism/input";
import Form from "@prism/form";
import {updateChargePercentage, updateCurrentRange} from "../../../actions/conditionActions";

class Battery extends React.Component {

    extract = (str, pattern) => {
        return (str.match(pattern) || []).pop() || '';
    };

    handleBatteryChargeChange = (e) => {
        let chargePercentage = this.extract(e.target.value, "[0-9]+").substring(0,3);
        if(chargePercentage.length > 2 && chargePercentage !== '100') {
            chargePercentage = chargePercentage.substring(0,2);
        }
        this.props.updateChargePercentage(chargePercentage);
    };

    handleBatteryRangeChange = (e) => {
        const value = e.target.value.substring(0,4);
        const currentRange = value === "" ? null : parseInt(value) || 0;
        this.props.updateCurrentRange(currentRange);
    };

    render() {
        const {designatedDescription} = this.props;
        const engine = designatedDescription?.powertrain?.engine;
        const isElectric = engine ? ELECTRIC_FUEL_CATEGORIES.includes(engine.fuelCategory) : false;
        const batteryChargeRequired = engine ? engine.fuelCategory === "Electric" : false;
        const hasOdometer = this.props.unit && this.props.unit.odometer;
        const odometerUnits = hasOdometer && this.props.unit.odometer.units;

        if(this.props.condition === null || !isElectric || !engine) {
            return <></>
        }

        return (
            <>
                <Form.Group>
                    <DataPair label="Battery Charge (%)" required={batteryChargeRequired}>
                        <Input
                            type="text"
                            id="battery-charge-percentage"
                            value={this.props.condition.chargePercentage}
                            onChange={this.handleBatteryChargeChange}
                        />
                    </DataPair>
                </Form.Group>
                <Form.Group>
                    <DataPair label={`Battery Range (${odometerUnits?.toUpperCase().startsWith('K') ? 'km' : 'mi'})`}>
                        <Input
                            type="text"
                            id="battery-range"
                            value={this.props.condition.currentRange}
                            onChange={this.handleBatteryRangeChange}

                        />
                    </DataPair>
                </Form.Group>
            </>

        )
    }
}

const matchDispatchToProps = {
    updateChargePercentage,
    updateCurrentRange
};

function mapStateToProps({condition, description, globalDisplay, designatedDescription, unit}) {
    return {condition, description, globalDisplay, designatedDescription, unit};
}
export default connect(mapStateToProps, matchDispatchToProps)(Battery);
