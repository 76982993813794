import React from 'react';
import Row from "@prism/row";
import Tire from './Tire';
import {updateTire} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import update from "immutability-helper";
import {TIRE_LOCATIONS} from "../../../utils/constants";
import Accordion from 'react-bootstrap/Accordion';
import snakeCase from "lodash.snakecase";

class Tires extends React.Component {
    state = {
        validTires: []
    };

    componentDidMount() {
        (this.props.condition && this.props.condition.tires) && this.props.condition.tires.forEach(tire => {
            this.updateValidTireList(tire);
        })
        window.scrollTo(0,0);
    }

    getTireByLocation = (location) => {
        if (!this.props.condition.tires) {
            this.updateTire({location: location});
            return {location: location};
        }
        const conditionTire = this.props.condition.tires.find(tire => {
            return tire.location === location;
        });
        if (conditionTire) {
            if (this.isValid(conditionTire) && !this.validTireExists(conditionTire)) {
                this.updateValidTireList(conditionTire)
            }
        }
        return conditionTire
    };

    updateTire = (tire) => {
        this.updateValidTireList(tire);
        this.props.updateTire(tire);
    };

    updateValidTireList = (tire) => {

        if (this.isValid(tire) && !this.validTireExists(tire)) {
            const updatedValidTires = update(this.state, {
                validTires: {
                    $push: [tire]
                }
            });
            this.setState(updatedValidTires);

        } else if (this.isValid(tire) && this.validTireExists(tire)) {

            const tireIndex = this.state.validTires.map(validTire => {
                return validTire.location
            }).indexOf(tire.location);

            const updatedValidTires = update(this.state, {
                validTires: {
                    [tireIndex]: {
                        $set: tire
                    }

                }
            });
            this.setState(updatedValidTires);

        } else if (!this.isValid(tire) && this.validTireExists(tire)) {

            const tireIndex = this.state.validTires.map(validTire => {
                return validTire.location
            }).indexOf(tire.location);

            const updatedValidTires = update(this.state, {
                validTires: {
                    $splice: [[tireIndex, 1]]
                }
            });
            this.setState(updatedValidTires);
        }
    };

    validTireExists = (tire) => {
        const tireIndex = this.state.validTires.map(validTire => {
            return validTire.location
        }).indexOf(tire.location);
        return tireIndex !== -1;
    };

    isValid = (tire) => {
        const {manufacturer, size, construction} = tire;
        return (!!manufacturer && !!size && !!construction)
    };

    render() {

        TIRE_LOCATIONS.forEach(tireLocation => {
            this.updateValidTireList(tireLocation)
        });

        return (<>
                <Row className="mx-0 mb-3 pt-3">

                    <Accordion defaultActiveKey={[0,1,2,3,4]} alwaysOpen flush>
                    {TIRE_LOCATIONS.map((tireLocation, index) => {
                        return <Accordion.Item eventKey={index} key={`${tireLocation}-accitem`}> <Accordion.Header className={`${snakeCase(tireLocation)}`}> {tireLocation}</Accordion.Header>
                        <Accordion.Body> <Tire tire={this.getTireByLocation(tireLocation)}
                                     updateTire={this.updateTire}
                                     validTires={this.state.validTires}
                                     key={tireLocation}
                        /> </Accordion.Body> </Accordion.Item>

                    })}
                    </Accordion>

                </Row>
            </>
        )
    }
}


const matchDispatchToProps = {
    updateTire
};

function mapStateToProps({condition}) {
    return {condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(Tires);