import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updateEmissions} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {PLEASE_SELECT} from "../../../utils/constants";


class Emissions extends React.Component {

    handleChange = (e) => {
        const newEmission = e.currentTarget.value;
        this.props.updateEmissions(newEmission);
    };

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        const value = this.props.condition.emissions;
        const defaultSelection = value === null || value === undefined ? undefined : [value];

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                <DataPair label={"Emissions"} required>
                    <Dropdown id={"emissions"} key={`emissions${value}`} value={defaultSelection} onChange={this.handleChange}>
                        <option value={""} disabled>{PLEASE_SELECT}</option>
                        {this.props.staticOptionValues && this.props.staticOptionValues.emissions.map(option => {
                            return (
                                <option key={option.description} value={option.description}>{option.description}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateEmissions
};

function mapStateToProps({condition, staticOptionValues}) {
    return {condition, staticOptionValues};
}
export default connect(mapStateToProps, matchDispatchToProps)(Emissions);