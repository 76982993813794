import React, {useState} from 'react';
import {connect} from "react-redux";
import ErrorBoundary from '../common/ErrorBoundary';
import {isNativeApp} from "../../utils/utils";
import Thumbnail from "./Thumbnail";
import Col from '@prism/col';
import Row from '@prism/row';
import MiscImageModal from "./MiscImageModal";

const MiscImages = (props) => {


    const [modalOpen, setModalOpen] = useState(false);
    const [displayImage, setDisplayImage] = useState({});

    const showImage = (image) => {
        setModalOpen(true);
        setDisplayImage(image);
    };

    const toggleModal = () => {
        setModalOpen((prevState) => !prevState);
    };

    const nextMiscImageTag = () => {
        console.log("nextMiscImageTage props", props)
        if(!props.miscellaneousImages.length) { return "MISCELLANEOUS1" }
        const imageKeys = props.miscellaneousImages.map(image => parseInt(image.angle.match(/\d+/)));
        return `MISCELLANEOUS${Math.max(...imageKeys) + 1}`;
    };

    const capturePhoto = () => {
        console.log("capturePhoto clicxked")
        const tag = nextMiscImageTag();
        const category = "MISCELLANEOUS";
        const { vin } = props.designatedDescription;
        const { auctionCode, workOrderNumber } = props;

        if (isNativeApp()) {
            console.log(
                "capture photo with",
                auctionCode,
                workOrderNumber,
                category,
                tag
            );
            window.NativeApp.capturePhoto(
                workOrderNumber,
                vin,
                tag, // damageKey -> key in native -- should be auto generated misc#
                category,
                tag
            );
        } else {
            console.log(
                "camera not available in web view.",
                auctionCode,
                workOrderNumber
            );
        }
    };

    if(props.miscellaneousImages === undefined) {
        console.log("misc images set undefined, short circuiting", props)
        return <></>;
    }

    const miscImages = props.miscellaneousImages.filter(miscImage => miscImage.status !== 'DELETED').map(
        image => {
            return {
                category: "MISC",
                src: {thumbnail: image.url, lowRes: image.url},
                description: image.angle,
                imageTag: image.angle
            }
    });

    return (
        <Col id='misc-images-container'>
            <ErrorBoundary>
                <MiscImageModal
                    showModal={modalOpen}
                    toggle={toggleModal}
                    imageTitle={"1"}
                    image={displayImage}
                />
                <Row>
                  <Col xs={6} className="p-1">
                    <Thumbnail id="add-misc-images-button"
                               onClick={capturePhoto}
                               key="MISCELLANEOUS"
                    />
                  </Col>
                { miscImages.map(image => {
                    return <Col xs={6} className="p-1"><Thumbnail image={image} key={image.key} onClick={() => showImage(image)}/></Col>;
                })}
              </Row>
            </ErrorBoundary>
        </Col>
    )
}

function mapStateToProps({miscellaneousImages, workOrderInfo, designatedDescription}) {
    const { auctionCode, workOrderNumber } = workOrderInfo;
    return { miscellaneousImages, workOrderNumber, auctionCode, designatedDescription };
}

export default connect(mapStateToProps)(MiscImages);
