import React from "react";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import {connect} from "react-redux";
import {hideAllPushPanels, togglePushPanel} from "../../actions/pushPanelActions";
import ButtonTrayPanel from "../buttonTray/ButtonTrayPanel";
import HeroContent from "../heroContent/HeroContent";

class VehicleInformationHeader extends React.Component {

    handleHeroButtonClick = () => {
        this.props.togglePushPanel("hero");
    };

    handleContinueClick = () => {
        this.props.hideAllPushPanels();
    };

    getModel = (designatedDescriptionResponse) => {
        return !designatedDescriptionResponse.model ? '' : designatedDescriptionResponse.model;
    }

    render() {
        let ymmString = "";
        let designatedDescriptionResponse = this.props.designatedDescriptionResponse
        if (designatedDescriptionResponse) {
            ymmString = truncate(`${designatedDescriptionResponse.year} ${designatedDescriptionResponse.make} ${this.getModel(designatedDescriptionResponse)}`, 20);
        }

        return (
            <Row className="veh-info-header mx-0 sticky-top">
                <Col xs={12} onClick={this.handleHeroButtonClick}>
            <span className="work-order">
              <Icon glyph="triangle-right" className="veh-info-header-triangle"/>
              <span className="font-weight-lighter text-white">WO #</span>
              <span className="font-weight-bolder text-white">{this.props.workOrderInfo.workOrderNumber}</span>
            </span>
                    <span
                        className="ymmt">{ymmString}</span>
                </Col>
                <ButtonTrayPanel name="hero" width={'100vw'} showClose={false} overlayClassName="hero-slider">
                    <HeroContent/>
                    <div className="hero-slider-footer">
                        <button type="button" id="btn-continue" className="btn btn-primary btn-full-width"
                                onClick={this.handleContinueClick}>CONTINUE
                        </button>
                    </div>
                </ButtonTrayPanel>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    togglePushPanel,
    hideAllPushPanels
};

function mapStateToProps({workOrderInfo, designatedDescriptionResponse}) {
    return {workOrderInfo, designatedDescriptionResponse};
}

//used to truncate year make model string on smaller screens
function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + "..." : str;
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInformationHeader);
