import {HIDE_ALL_PUSH_PANELS, LOAD_WORK_ORDER_SUCCESS, SHOW_PUSH_PANEL} from "../actions/dispatchTypes";

export const SCAN_PANEL = 'scan';

const defaultState = {
    activePanel: SCAN_PANEL
};

export default function pushPanelsReducer(state = defaultState, action) {
    switch(action.type) {
        case HIDE_ALL_PUSH_PANELS:
            return {activePanel: ''};
        case SHOW_PUSH_PANEL:
            return {activePanel: action.panelName};
        case LOAD_WORK_ORDER_SUCCESS:
            return {activePanel: 'hero'};
        default:
            return state;
    }
}
