import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import '../styles/slide-panel.css';

const CLOSE_TIMEOUT = 500;

const SlidePanel = ({ isOpen,
                      onRequestClose,
                      onAfterOpen,
                      children,
                      className,
                      overlayClassName,
                      contentClassName,
                      from,
                      width,
                      zIndex,
                      offset,
                      id }) => {
    const directionClass = `slide-pane_from_${from}`;

    return <Modal
        className={`slide-pane ${directionClass} ${className || ''}`}
        style={ zIndex ? {
            content: {width: width || 'unset'},
            overlay: {zIndex: zIndex || 'auto'}
        } : {
            content: {width: width || 'unset'}
        }
        }
        overlayClassName={`slide-pane__overlay ${overlayClassName || ''} ${offset && 'offset-left'}`}
        closeTimeoutMS={CLOSE_TIMEOUT}
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        from={from}
        appElement={document.querySelector('#application-container')}>
        <div id={id} className={`slide-pane__content ${contentClassName || ''}`}>
            {children}
        </div>
    </Modal>;
};

SlidePanel.propTypes = {
    from: PropTypes.string
};

SlidePanel.defaultProps = {
    from: "left"
};

export default SlidePanel;

