import React from 'react';
import Modal, {ModalHeader} from '@prism/modal';
import DamageModalDetails from "./DamageModalDetails";

class AddDamageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "",
        }
    }

    render() {
        const {
            showModal,
            toggle,
            onCancelClick,
            onSaveClick,
            damage,
            isStructural
        } = this.props;

        return (
            <Modal backdrop={"static"}
                   isOpen={showModal}
                   toggle={toggle}
                   className="damage-modal automated-damage-modal"
            >
                <ModalHeader className="damage-modal-header">{isStructural ? 'Add Structural Damage' : damage?.item}</ModalHeader>
                {/*<Modal.Body>*/}
                <DamageModalDetails onCancelClick={onCancelClick}
                                    onSaveClick={onSaveClick}
                                    toggle={toggle}
                                    damage={damage}
                                    isStructural={isStructural}/>

                {/*</Modal.Body>*/}

            </Modal>
        )
    }

}

export default AddDamageModal;
