import React from 'react';

import DamageListView from './DamageListView';
import {connect} from "react-redux";
import DamageEditWrapper from "./DamageEditWrapper";
const DamagePanel = (props) => {

    return (
        {
            damageListView: <DamageListView/>,
            damageEditView: <DamageEditWrapper/>,
        }[props.globalDisplay.damagePanelView]
    )
};

function mapStateToProps({globalDisplay}) {
    return {globalDisplay};
}

export default connect(mapStateToProps)(DamagePanel);