import {
    RE_DECODE_VIN,
    SET_DESIGNATED_DESCRIPTION,
    SET_DESIGNATED_DESCRIPTION_FROM_VCR,
    UPDATE_BLOCK_TYPE,
    UPDATE_BODY_STYLE,
    UPDATE_COUNTRY,
    UPDATE_CYLINDER_COUNT,
    UPDATE_DISPLACEMENT,
    UPDATE_DRIVETRAIN,
    UPDATE_FUEL_TYPE,
    UPDATE_INDUCTION,
    UPDATE_INSTALLED_COLOR,
    UPDATE_MAKE,
    UPDATE_MODEL,
    UPDATE_SEAT_TRIM,
    UPDATE_SUB_SERIES,
    UPDATE_TRANSMISSION,
    UPDATE_TRIM,
    UPDATE_WHEELS,
    UPDATE_ROOF_TYPE,
    UPDATE_YEAR
} from "./dispatchTypes";
import {INSPECTOR_PROVIDED, INTERIOR} from "../utils/constants";
import cloneDeep from "lodash.clonedeep";
import Api from "../components/Api";

export const setDesignatedDescription = (catalogId) => async (dispatch, getState) => {
    let state = getState().designatedDescriptionResponse;
    let designatedDescription;
    if (!state.fromTrustedSource) {
        let vcrResponse = await Api.getCatalogVehicleForSelectedTrim({
            vin: state.vin,
            sessionId: state.vcrResponse.sessionId,
            userSelectedTrimId: catalogId,
            reDecodeVin: getState().globalDisplay.isRedecode
        })
        dispatch({
            type: SET_DESIGNATED_DESCRIPTION_FROM_VCR,
            payload: vcrResponse.status === undefined ? vcrResponse : null
        })
    } else {
        designatedDescription = state.catalogVehicles.find((dd) => dd.catalogId === catalogId)
        dispatch({
            type: SET_DESIGNATED_DESCRIPTION,
            payload: {
                vin: state.vin,
                designatedDescription: designatedDescription
            }
        })
    }
};

export const redecodeVin = (designatedDescriptionResponse) => (dispatch) => {
    dispatch({
        type: RE_DECODE_VIN,
        payload: {designatedDescriptionResponse}
    })
}

export const updateSeatTrim = (interiorColor, seatTrim) => (dispatch) => {
    dispatch({
        type: UPDATE_SEAT_TRIM,
        payload: {interiorColor, seatTrim}
    })
}

export const updateInstalledColor = (colorCode, type, catalogId) => (dispatch, getState) => {
    const response = getState().designatedDescriptionResponse;
    const taxonomyData = getState().taxonomyData;
    const catalogVehicle = response?.catalogVehicles.find((vehicle) => vehicle.catalogId === catalogId)
    let color = type === INTERIOR ? getInteriorOemColor(catalogVehicle, colorCode) : getExteriorOemColor(catalogVehicle, colorCode)
    if (!color) {
        let genericColor = type === INTERIOR ? getInteriorGenericColor(taxonomyData, colorCode) : getExteriorGenericColor(taxonomyData, colorCode)
        color = cloneDeep(genericColor)
        delete color.id;
        delete color.name;
        color.normalizedName = colorCode;
    } else {
        color = cloneDeep(color)
    }
    color.isDesignated = true;
    color.installed = true;
    color.installedReason = INSPECTOR_PROVIDED;
    dispatch({
        type: UPDATE_INSTALLED_COLOR,
        payload: {color: color, colorType: type}
    })
};

const getInteriorOemColor = (catalogVehicle, colorCode) => {
    if (catalogVehicle) {
        return catalogVehicle.colors?.interior?.find(color => color.oemOptionCode ? color.oemOptionCode === colorCode : color.oemName === colorCode)
    }
    return null;
}

const getInteriorGenericColor = (taxonomyData, colorCode) => {
    return taxonomyData.interiorColors.find(color => color.name === colorCode)
}

const getExteriorOemColor = (catalogVehicle, colorCode) => {
    if (catalogVehicle) {
        return catalogVehicle.colors?.exterior?.find(color => color.oemOptionCode ? (color.oemOptionCode === colorCode && color.isPrimary !== false) : color.oemName === colorCode)
    }
    return null;
}

const getExteriorGenericColor = (taxonomyData, colorCode) => {
    return taxonomyData.exteriorColors.find(color => color.name === colorCode)
}

export const updateWheels = (wheelCode) => dispatch => {
    dispatch({
        type: UPDATE_WHEELS,
        payload: wheelCode
    })
}

export const updateRoofType = (roofId) => dispatch => {
    dispatch({
        type: UPDATE_ROOF_TYPE,
        payload: roofId
    })
}

export const updateYear = (year) => dispatch => {
    dispatch({
        type: UPDATE_YEAR,
        payload: year
    })
}

export const updateBodyStyle = (payload) => dispatch => {
    dispatch({
        type: UPDATE_BODY_STYLE,
        payload
    })
}

export const updateCountry = (payload) => dispatch => {
    dispatch({
        type: UPDATE_COUNTRY,
        payload
    })
}

export const updateMake = (payload) => dispatch => {
    dispatch({
        type: UPDATE_MAKE,
        payload
    })
}

export const updateModel = (payload) => dispatch => {
    dispatch({
        type: UPDATE_MODEL,
        payload
    })
}

export const updateTrim = (trim) => dispatch => {
    dispatch({
        type: UPDATE_TRIM,
        payload: trim
    })
}

export const updateFuelType = (fuelType) => dispatch => {
    dispatch({
        type: UPDATE_FUEL_TYPE,
        payload: fuelType
    })
}

export const updateCylinderCount = (payload) => dispatch => {
    dispatch({
        type: UPDATE_CYLINDER_COUNT,
        payload
    })
}

export const updateBlockType = (payload) => dispatch => {
    dispatch({
        type: UPDATE_BLOCK_TYPE,
        payload
    })
}

export const updateDisplacement = (payload) => dispatch => {
    dispatch({
        type: UPDATE_DISPLACEMENT,
        payload
    })
}

export const updateInduction = (induction) => dispatch => {
    dispatch({
        type: UPDATE_INDUCTION,
        payload: induction
    })
}

export const updateTransmission = (transmission) => dispatch => {
    dispatch({
        type: UPDATE_TRANSMISSION,
        payload: transmission
    })
}

export const updateDrivetrain = (drivetrain) => dispatch => {
    dispatch({
        type: UPDATE_DRIVETRAIN,
        payload: drivetrain
    })
}

export const updateSubSeries = (subSeries, catalogId) => (dispatch, getState) => {
    const response = getState().designatedDescriptionResponse;
    const catalogVehicle = response?.catalogVehicles.find(vehicle => vehicle.catalogId === catalogId)
    let payload = catalogVehicle?.thirdPartyIdentifiers?.manheim?.find(id => id.ssubse === subSeries);
    dispatch({
        type: UPDATE_SUB_SERIES,
        payload
    })
}
