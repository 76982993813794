import {GET_ITEMS_FOR_PANEL_SUCCESS} from "../actions/dispatchTypes";

export default function itemListReducer(state = {}, action) {
    switch (action.type) {
        case GET_ITEMS_FOR_PANEL_SUCCESS:
            return setItemList(state, action.items);
        default:
            return state;
    }
}

function setItemList(state, items) {
    const sortedItems = items.sort((a, b) => a.sequence - b.sequence)
    return {...state, items: sortedItems}
}
