import React from 'react';
import {connect} from "react-redux";

import Button from '@prism/button';
import Col from '@prism/col';
import Row from '@prism/row';

import TextCounter from "../common/TextCounter";
import {addF5Note, deleteF5Note} from "../../actions/f5NotesActions";
import {addRepNote, deleteRepNote} from "../../actions/clientSpecificsActions";
import {addAnnouncement} from "../../actions/announcementsActions";
import {getUserDetails} from "../../actions/userDetailsActions";
import {getCurrentDate} from "../../utils/utils";
import {VEHICLE_NOTE} from "../../utils/constants";


class AddEditNote extends React.Component {

    state = {
        notesText: ''
    };

    componentDidMount() {
        if (!this.props.userDetails.userId) {
            this.props.getUserDetails();
        }
    }

    handleEditRemarksChange = (e) => {
        this.setState({notesText: e.value});
    };

    handleSave = () => {
        if (this.props.noteType === 'F5Note') {
            this.handleSaveF5Note();
        } else if (this.props.noteType === 'RepNote') {
            this.handleSaveRepNote();
        }
        this.props.cancelAddNote();
    };

    handleSaveF5Note = () => {
        if (this.props.note) {
            this.props.deleteF5Note(this.props.note);
        }
        if (this.state.notesText.slice(0, 2) === "**") {
            this.props.addAnnouncement(VEHICLE_NOTE, this.state.notesText.slice(2))
        } else if (this.state.notesText !== '') {
            this.props.addF5Note({
                notesText: this.state.notesText,
                addedBy: this.props.userDetails.userId.substring(0, 10),
                addedDate: getCurrentDate()
            });
        }
    };

    handleSaveRepNote = () => {
        if (this.props.note) {
            this.props.deleteRepNote(this.props.note);
        }
        this.props.addRepNote({
            notesText: this.state.notesText,
            addedBy: this.props.userDetails.userId.substring(0, 10),
            addedDate: getCurrentDate(),
            workOrder: this.props.consignment.workOrderNumber,
            auctionId: this.props.workOrderSelection.auctionCode
        });
    };

    userDetailsLoaded = () => {
        return this.props.userDetails.userId;
    };

    render() {
        return (
            <Col style={{marginTop: '-16px'}}>
                <Row className="mb-3">
                    <Col>
                        <TextCounter
                            id='remarks-field'
                            onChange={this.handleEditRemarksChange}
                            placeholder="Enter Note"
                            maxLength={60}
                            rows="2"
                            value={this.props.note ? this.props.note.notesText.trimEnd() : ''}
                        />
                    </Col>
                </Row>
                <Row className="mb-4 flex-row-reverse">
                    <Col className="flex-grow-0">
                        <Button color="primary" className="m-0" onClick={this.handleSave}
                                disabled={!this.userDetailsLoaded()}>SAVE</Button>
                    </Col>
                    <Col className="flex-grow-0 pr-0">
                        <Button outline color="dark" className="m-0" onClick={this.props.cancelAddNote}>CANCEL</Button>
                    </Col>
                </Row>
            </Col>
        )
    }
}


const matchDispatchToProps = {
    addF5Note, addRepNote, deleteF5Note, deleteRepNote, getUserDetails, addAnnouncement
};

function mapStateToProps({userDetails, consignment, workOrderSelection}) {
    return {userDetails, consignment, workOrderSelection};
}

export default connect(mapStateToProps, matchDispatchToProps)(AddEditNote);