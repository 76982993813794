import React, {useState} from 'react';
import {connect} from "react-redux";
import ErrorBoundary from '../../common/ErrorBoundary';
import Col from "@prism/col";
import Thumbnail from "../../photoPanelContent/Thumbnail";
import Row from "@prism/row";
import DamageImageModal from "./DamageImageModal";
import {getActiveImage} from "../../../actions/damageImageActions";

const DamageImages = (props) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [displayImage, setDisplayImage] = useState({});

    const showImage = (image) => {
        setModalOpen(true);
        setDisplayImage(image);
    };

    const toggleModal = () => {
        setModalOpen((prevState) => !prevState);
    };

    const compileDamageImages = () => {
        const {damages, damageImages} = props;
        let images = [];
        damages?.forEach(damage => {
            let damageImage = damageImages.find(damageImage => (damageImage.status !== 'DELETED') && (damageImage.key === damage.damageKey));

            damageImage && images.push({
                category: "DMG",
                src: {
                    thumbnail: damageImage.url,
                    lowRes: damageImage.url
                },
                description: damage.item,
                damageKey: damage.damageKey,
                imageTag: damageImage.angle,
                damage: damage
            })
        });
        return images;
    };

    let damageImagesWithDamageKey = compileDamageImages()
    if (!damageImagesWithDamageKey?.length) {
        return <>
            <div id='damage-images-container' className="container">
                <Row>
                    <div style={{height: '200px'}} className="col-md-6 offset-md-3 text-center">
                        <div className={`imageviewer-photo-not-available`}>
                            <div className={'icon-wrapper'}><i className={'icon prism-icon-image'}/></div>
                            <div><span>Media Not Available</span></div>
                        </div>
                    </div>
                </Row>
            </div>
        </>;
    } else {
        return (
            <div id='damage-images-container' className={"container"}>
                <ErrorBoundary>
                    <DamageImageModal
                        showModal={modalOpen}
                        toggle={toggleModal}
                        imageTitle={displayImage.description}
                        damageKey={displayImage.damageKey}
                        damage={displayImage.damage || {}}
                        getActiveImage={props.getActiveImage}
                    />

                    <Row className="mb-3">
                        {damageImagesWithDamageKey.map(image => {
                            return <Col xs={6} className="p-1">
                                <Thumbnail
                                    image={image}
                                    imageKey={image.description}
                                    onClick={() => showImage(image)}
                                />
                            </Col>;
                        })}
                    </Row>
                </ErrorBoundary>
            </div>
        )
    }
}

const matchDispatchToProps = {
    getActiveImage
};

function mapStateToProps({condition, damageImages, userDetails}) {
    const {damages} = condition;
    return {damageImages, damages, userDetails};
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageImages);