import React from 'react';
import '../../styles/work-list-item-status.css'

class WorkListItemStatus extends React.Component {

    iconName = () => {
        switch(this.statusName()) {
            case 'failed':
                return 'error-triangle';
            case 'invalid':
                return 'error-triangle';
            case 'success':
                return 'checkmark-circle';
            default:
                return 'cloud-upload';
        }
    };

    statusName = () => {
        if(!this.props.status) {
            return 'unknown';
        }
        return (this.props.status.overAllStatus || '').toLowerCase();
    };

    render() {

        const iconName = this.iconName();
        const statusName = this.statusName();
        const className = `work-list-item-status status-${statusName} icon prism-icon-${iconName}`;

        return <span className={className} />;
    }
}

export default WorkListItemStatus;