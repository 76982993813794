import React from 'react';

const Dropdown = (props) => (
    <>
        <select
            className="form-control prism-select"
            onChange={props.onChange}
            value={props.value === null || props.value === undefined ? '' : props.value}
            disabled={props.disabled}
            name={props.name}
            id={props.id}
            style={props.style}>
            {props.placeholder && <option value=''>{props.placeholder}</option>}
            {props.children}
        </select>
        {props.invalid && <span className="text-danger font-size-sm"><i className="icon prism-icon-notification-circle text-danger" /> {props.validationMessage}</span>}
    </>
);

export default Dropdown;