import React from "react";
import AccessoriesPanel from "./accessories/AccessoriesPanel";
import TiresPanel from "./tires/TiresPanel";
import ReviewPanel from "./review/ReviewPanel";
import DamagePanel from "./damage/DamagePanel";
import ConditionPanel from "./condition/ConditionPanel";
import ConfigurePanel from "./configure/ConfigurePanel";
import {connect} from "react-redux";
import {setCurrentConditionTabIndex} from "../../actions/naviationActions"
import Tabs from "../common/prism/Tabs/components/Tabs";
import TabPane from  "../common/prism/Tabs/components/TabItem";

const ConditionTabControls = (props) => {
    const {selectedConditionTabIndex} = props.navigation
    const disabled = !props.designatedDescription?.trim?.normalizedName;
    const tabs = {
        0:<ConfigurePanel/>,
        1:<AccessoriesPanel/>,
        2:<TiresPanel/>,
        3:<DamagePanel/>,
        4:<ConditionPanel/>,
        5:<ReviewPanel/>,   
    };
    return(
    <>
        <Tabs activeIndex={selectedConditionTabIndex} className="sticky-top condition-tab-bar">
            <TabPane className={"configure-tab"} label="CONFIGURE" onClick={() => props.setCurrentConditionTabIndex(0)}></TabPane>
            <TabPane className={"accessories-tab"} label="ACCESSORIES" onClick={() => props.setCurrentConditionTabIndex(1)} disabled={disabled}></TabPane>
            <TabPane className={"tires-tab"} label="TIRES" onClick={() => props.setCurrentConditionTabIndex(2)} disabled={disabled}></TabPane>
            <TabPane className={"damage-tab"} label="DAMAGE" onClick={() => props.setCurrentConditionTabIndex(3)} disabled={disabled}></TabPane>
            <TabPane className={"condition-tab"} label="CONDITION" onClick={() => props.setCurrentConditionTabIndex(4)} disabled={disabled}></TabPane>
            <TabPane className={"review-tab"} label="REVIEW" onClick={() => props.setCurrentConditionTabIndex(5)} disabled={disabled}></TabPane>
        </Tabs>
        {tabs[selectedConditionTabIndex]}
    </>
    )
};

const matchDispatchToProps = {
    setCurrentConditionTabIndex
}

function mapStateToProps({navigation, designatedDescription}) {
    return {navigation, designatedDescription};
}

export default connect(mapStateToProps, matchDispatchToProps)(ConditionTabControls);
