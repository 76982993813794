import React from "react";

import Row from "@prism/row";
import Col from "@prism/col";
import {connect} from "react-redux";
import {hideAuditExistsModal, showAuditExistsModal, showLogoutModal} from "../../actions/globalDisplayActions";
import Switch from "@prism/switch";
import {isAuditMode, isNativeApp} from "../../utils/utils";
import {AUDIT_EXISTS, AUDIT_EXISTS_CONFIRMATION_MESSAGE, OK} from "../../utils/constants";
import ConfirmationOrDecisionModal from "../ConfirmationOrDecisionModal";

class Profile extends React.Component {

    handleAuditModeChange = async (e) => {
        if (isNativeApp()) window.NativeApp.saveAuditMode(!e)
        if (isAuditMode() && this.props.globalDisplay.hasAudit) {
            this.props.showAuditExistsModal();
        }
    };

    handleOk = () => {
        this.props.hideAuditExistsModal();
    };

    render() {
        const {isAuditor} = this.props.userDetails.role.scopes;
        return (
            <>
                <ConfirmationOrDecisionModal
                    shouldShowModal={this.props.globalDisplay.shouldShowAuditExistsModal && !!this.props.navigation.selectedConditionTabIndex}
                    modalHeader={AUDIT_EXISTS}
                    modalBody={AUDIT_EXISTS_CONFIRMATION_MESSAGE}
                    modalType="audit-exists"
                    confirmationText={OK}
                    handleClose={this.handleOk}
                />
                <Col id='profile-container' className="px-0">
                    <LogoutHeader showLogoutModal={this.props.showLogoutModal}/>
                    <ProfileHeader headerText="User Profile"/>
                    <ProfileSection>
                        <div id="user-profile-section">
                            <Row>
                                <Col>
                                    <h3 className="profile-section-heading">Username</h3>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <div id="username"
                                         className="profile-section-text">{this.props.userDetails.userId}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className="profile-section-heading">Email Address</h3>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <div id="email-id"
                                         className="profile-section-text">{this.props.userDetails.emailId}</div>
                                </Col>
                            </Row>
                        </div>
                        <div id="user-setting">
                            {isAuditor &&
                                <div>
                                    <div className="user-setting-header p-2 mb-3">Settings</div>
                                    <Row className="mb-3">
                                        <Col className="profile-section-heading">
                                            <Switch
                                                id="audit-mode"
                                                label="Audit Mode"
                                                labelAlignment="left"
                                                statusText="OnOff"
                                                status={isNativeApp() && window.NativeApp.getAuditMode()}
                                                onChange={this.handleAuditModeChange}
                                                disabled={this.props.globalDisplay.workOrderOpen ? !this.props.globalDisplay.inspectionCompleted : false}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    </ProfileSection>
                </Col>
            </>
        );
    };
}

const LogoutHeader = (props) => {
    return (<Row id="profile-panel-main-header" className="flex-row-reverse">
        <Col className="text-right mr-3">
            <div id="logout" onClick={props.showLogoutModal} className="logout-button-icon"> Logout</div>
        </Col>
    </Row>)
};

const ProfileHeader = (props) => {
    return (<Row className="user-profile-heading-row">
        <Col>
            <h3 className="user-profile-heading py-2">{props.headerText}</h3>
        </Col>
    </Row>)
};

const ProfileSection = ({children}) => {
    return (
        <div className="username-container-group mt-3 mb-2">
            {children}
        </div>
    )
};

function mapStateToProps({userDetails, globalDisplay, navigation}) {
    return {userDetails, globalDisplay, navigation};
}

const matchDispatchToProps = {
    showLogoutModal,
    showAuditExistsModal,
    hideAuditExistsModal
};

export default connect(mapStateToProps, matchDispatchToProps)(Profile);
