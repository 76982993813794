import {useSelector} from "react-redux";
import React from "react";

const FlatCarUtil = () => {

    const condition = useSelector((state) => state.condition);
    const suggestedDamages = useSelector((state) => state.suggestedDamages);
    const selectedFlatCarPanel = useSelector((state) => state.globalDisplay.selectedFlatCarPanel);
    function handlePanelStriping(panelId) {
        const panelSuggestedDamages = (!!suggestedDamages) ?
            suggestedDamages?.damages?.find(damage => activeDamageOnPanel(damage, panelId) && !isDuplicateSuggestedDamage(damage)) : undefined;

        if (selectedFlatCarPanel === panelId) {
            if (panelSuggestedDamages !== undefined) {
                return ' active-suggested'
            }
            return ' active'
        } else if (panelSuggestedDamages !== undefined) {
            return ' inactive-suggested'
        }
        return ''
    }

    function activeDamageOnPanel(damage, panelId) {
        return damage.artCodes.panelId === panelId && !damage.action;
    }

    function isSuggestedDamagesPresent() {
        const remainingSuggestedDamages = suggestedDamages?.damages?.filter(damage => !damage.action && !isDuplicateSuggestedDamage(damage));
        return remainingSuggestedDamages?.length > 0;
    }

    const isDuplicateSuggestedDamage = (suggestedDamage) => {
        return !!condition.damages?.find(damage => damage.damageKey === suggestedDamage.artCodes.damageKey);
    }

    const panelSuggestedDamages = () => {
        return (!!suggestedDamages && selectedFlatCarPanel != null) ?
            suggestedDamages?.damages.find(damage => (activeDamageOnPanel(damage, selectedFlatCarPanel) && !isDuplicateSuggestedDamage(damage))) : null;
    }

    const StripedPattern = () => (
        <pattern id="inactive-suggested" patternTransform="rotate(45)" width="100%" height="8" x="0" y="0" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="100%" height="4" fill="red"></rect>
            <rect x="0" y="4" width="100%" height="4" fill="var(--charcoal)"></rect>
        </pattern>
    );
    const StripedPatternLegend = () => (
        <pattern id="suggested-legend" patternTransform="rotate(-45)" width="100%" height="8" x="0" y="0" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="100%" height="4" fill="red"></rect>
            <rect x="0" y="4" width="100%" height="4" fill="var(--charcoal)"></rect>
        </pattern>
    );
    const SelectedStripedPattern = () => (
        <pattern id="active-suggested" patternTransform="rotate(45)" width="100%" height="8" x="0" y="0" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="100%" height="4" fill="red"></rect>
            <rect x="0" y="4" width="100%" height="4" fill="var(--gold)"></rect>
        </pattern>
    );

    return {handlePanelStriping, SelectedStripedPattern, StripedPatternLegend, StripedPattern, isSuggestedDamagesPresent, panelSuggestedDamages};
}
export default FlatCarUtil;