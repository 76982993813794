import React from "react";

const DamageHeader = () => {
    return (
        <thead>
        <tr>
            <th>Dent</th>
            <th className="pl-4">Length</th>
            <th className="pl-4">Width</th>
            <th className="pl-4">Hours</th>
            <th/>
        </tr>
        </thead>
    )
}

export default DamageHeader;
