import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import Modal from "@prism/modal";
import {clearVehicleDataFromState} from "../actions/globalDisplayActions";
import Col from "@prism/col";
import {isAuditMode, isNativeApp} from "../utils/utils";
import {setCurrentConditionTabIndex} from "../actions/naviationActions";
import {toggleScanPushPanel} from "../actions/pushPanelActions";

function AuditModeErrorModal(props) {

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        let shouldShow = isNativeApp() && props.workOrderOpen && !props.inspectionCompleted && isAuditMode();
        console.debug("AuditModeErrorModal->shouldShowModal: ",
            JSON.stringify({
                shouldShow: shouldShow, isNativeApp: isNativeApp(),
                workOrderOpen: props.workOrderOpen,
                inspectionCompleted: props.inspectionCompleted,
                isAuditMode: isAuditMode()}));
        setOpenModal(shouldShow);
    }, [props]);


    const handleContinue = async () => {

        // disable audit mode
        console.debug("AuditModeErrorModal->handleContinue: ", JSON.stringify({isNativeApp: isNativeApp()}))
        if (isNativeApp()) window.NativeApp.saveAuditMode(false);
        setOpenModal(false);
        await props.submitScan(props.scanAuctionCode, props.workOrderNumber);

    }

    const handleCancel = () => {
        // hide this modal
        // show scan page
        console.debug("AuditModeErrorModal->handleCancel");
        props.toggleScanPushPanel()
        props.setCurrentConditionTabIndex(1);
        props.clearVehicleDataFromState();
    }


    return (
        <Modal isOpen={openModal} id="audit-mode-error-modal" className="audit-mode-error-modal-content">
            <Modal.Header id={"audit-mode-error-modal-header"}>
                <div id="modal-header-content">{props.vehicleErrorType}</div>
            </Modal.Header>
            <Modal.Body>
                <div id="modal-body-content">
                    An inspection has not been completed on this vehicle. Would you like to switch to inspection mode?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Col id="footer-content">
                    <Modal.Cancel id="error-modal-cancel-btn" text="Cancel" onClick={handleCancel}/>
                    {isNativeApp() &&
                        <Modal.Okay id="error-modal-continue-btn" text="Inspection Mode" onClick={handleContinue}/>}
                </Col>
            </Modal.Footer>
        </Modal>
    )
}


function mapStateToProps({globalDisplay, workOrderInfo}) {
    const {scanAuctionCode, workOrderNumber} = workOrderInfo;
    const { inspectionCompleted, workOrderOpen } = globalDisplay;
    return { workOrderNumber, scanAuctionCode, inspectionCompleted, workOrderOpen };
}

const mapDispatchToProps = {
    clearVehicleDataFromState, setCurrentConditionTabIndex, toggleScanPushPanel
};


export default connect(mapStateToProps, mapDispatchToProps)(AuditModeErrorModal);

