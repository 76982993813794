import React from 'react';
import {connect} from "react-redux";

import Button from '@prism/button';
import Col from '@prism/col';
import Row from '@prism/row';

import ErrorBoundary from "../common/ErrorBoundary";
import ViewF5Notes from "./ViewF5Notes";
import EditSellerNotes from "./EditSellerNotes";

import '../../styles/notes.css';


class NotesContainer extends React.Component {

    state = {
        notesViewState: 'view'
    };

    showEditSellerNotesPanel = () => {
        this.setState({
            notesViewState: 'editSellerNotes'
        });
    };

    hideEditPanel = () => {
        this.setState({
            notesViewState: 'view'
        });
    };

    showF5NotesPanel = () => {
        this.setState({
            notesViewState: 'viewF5Notes'
        });
    };

    render() {
        return (
            {
                view: <Col id='notes-container'>
                    <Header/>
                    <Section subheader='Seller Notes'>
                        <SectionContent
                            onClick={this.showEditSellerNotesPanel}
                            icon='edit'
                            buttonText='EDIT'
                            note={this.props.condition.notesToSeller}
                            showNote
                        />
                    </Section>
                    <Section subheader='F5 Notes'>
                        <SectionContent
                            onClick={this.showF5NotesPanel}
                            icon='plus'
                            buttonText='Add/View Notes'
                        />
                    </Section>

                </Col>,
                viewF5Notes: <ErrorBoundary><ViewF5Notes hideF5NotesPanel={this.hideEditPanel}/></ErrorBoundary>,
                editSellerNotes: <ErrorBoundary><EditSellerNotes hideEditPanel={this.hideEditPanel}/></ErrorBoundary>

            }[this.state.notesViewState]
        )
    }
}

const Header = () => {
    return (<Row className="mt-3">
        <Col>
            <h1 className={'prism-h1 ml-2'}>COMMENTS</h1>
        </Col>
    </Row>)
};

const Section = (props) => {
    const {children, subheader} = props;

    return (

        <div className="notes-container-group mx-4 mt-3 mb-2">
            <Row>
                <Col>
                    <h2 className="text-secondary-dark notes-subheader">{subheader}</h2>
                </Col>
            </Row>
            {children}
        </div>
    )
};

const SectionContent = (props) => {
    const noCommentsMsg = "No comments entered.";
    return (
        <>
            {props.showNote &&
                <Row>
                    <Col>
                        <div>
                            <div className="text-black vehicle-note-text">
                                {props.note || noCommentsMsg}
                            </div>
                        </div>
                    </Col>
                </Row>}
            <Row mb={8}>
                <div className="flex-grow-1 mt-3 mb-2">
                    <Button className="my-2 ml-1" color="secondary" onClick={props.onClick}>
                        <i className={`icon btn-icon__left prism-icon-${props.icon}`}/>{props.buttonText}
                    </Button>
                </div>
            </Row>
        </>
    )
}

function mapStateToProps({consignment, condition}) {
    return {consignment, condition};
}

export default connect(mapStateToProps, null)(NotesContainer);
