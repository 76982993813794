import React from 'react';
import DataPair from "../../common/DataPair";
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import {updateDrivable} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import Row from "@prism/row";

class Drivable extends React.Component {

    handleDrivable = (e) => {
        this.props.updateDrivable(e.currentTarget.value === "true")
    };

    render() {

        if (!this.props.condition) {
            return null
        }

        const {drivable} = this.props.condition;

            return (
                <Col xs={6}>
                    <DataPair label="Drivable" id="drivable" required>
                        <Row>
                            <Col className="pl-0" xs={5}>
                                <RadioButton label="Yes"
                                             name="drivable"
                                             value={true}
                                             checked={drivable === true}
                                             onClick={this.handleDrivable}
                                />
                            </Col>
                            <Col xs={7}>
                                <RadioButton label="No"
                                             name="drivable"
                                             value={false}
                                             checked={drivable === false}
                                             onClick={this.handleDrivable}
                                />
                            </Col>
                        </Row>


                    </DataPair>
                </Col>
            )

    }
}

const matchDispatchToProps = {
    updateDrivable
};


function mapStateToProps({condition, description}) {
    return {condition, description};
}

export default connect(mapStateToProps, matchDispatchToProps)(Drivable);