import React from 'react';
import '../../../../styles/unified-panel.css';

export function UnifiedPanel({className, id, children}) {

    return(
        <div className={"panel " + className} id={id}>
            {children}
        </div>
    )

}

export function UnifiedPanelHead({className, children}) {
    return (
        <div className={"panel-head " + className}>
            {children}
        </div>
    )
}

export default UnifiedPanel;