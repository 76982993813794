import React, {useEffect} from 'react';
import Summary from "./Summary";
import MaintenanceAndService from "./MaintenanceAndService";
import Announcements from "./Announcements";
import DrivableEngineStarts from "./DrivableEngineStarts";

const ConditionPanel = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div id="condition-panel" className="m-3">
            <Summary/>
            <MaintenanceAndService/>
            <DrivableEngineStarts/>
            <Announcements/>
        </div>
    )
};

export default ConditionPanel