import React from "react";
import {EXTERIOR, INTERIOR, NOT_SPECIFIED, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import {isOemColor} from "../../../utils/utils";

const ColorDropdown = (props) => {

    const {colorType, designatedDescription, designatedDescriptionResponse, taxonomyData} = props;

    const getOemDisplayName = (option) => {
        if (!!option.materialType?.length && !option.materialType?.includes(NOT_SPECIFIED)) {
            if (option.oemOptionCode) {
                return `${option.oemName} (${option.oemOptionCode}) - ${option.materialType.join('/')}`;
            } else {
                return `${option.oemName} - ${option.materialType.join('/')}`;
            }
        } else {
            if (option.oemOptionCode) {
                return `${option.oemName} (${option.oemOptionCode})`;
            } else {
                return option.oemName;
            }
        }
    }

    const designationHasNoColors = (type) => {
        return !designatedDescription || !designatedDescription.colors || !designatedDescription.colors[type];
    }

    const getOemColors = (type) => {
        if (designationHasNoColors(type)) {
            return [];
        }
        const vehicle = designatedDescriptionResponse?.catalogVehicles.find(vehicle => vehicle.catalogId === designatedDescription.catalogId)
        if (!vehicle?.colors || !vehicle.colors[type]) {
            return [];
        }
        let interiorColors = vehicle?.colors?.interior;
        let exteriorColors = vehicle?.colors?.exterior;
        if (type === INTERIOR) {
            return interiorColors?.filter(color => isOemColor(color))
        }
        return exteriorColors?.filter(color => isOemColor(color) && color.isPrimary !== false && !!color.normalizedName);
    }

    const getInstalledColorCode = (type) => {
        if (designationHasNoColors(type)) {
            return null;
        }
        let colors = type === INTERIOR ? designatedDescription.colors.interior : designatedDescription.colors.exterior
        let color;
        if (type === EXTERIOR) {
            color = colors?.find(c => !!c.isDesignated && c.isPrimary !== false);
        } else {
            color = colors?.find(c => !!c.isDesignated);
        }
        if (!color) {
            return null;
        } else if (!color.oemOptionCode) {
            return !color.oemName ? color.normalizedName : color.oemName;
        }
        return color.oemOptionCode;
    }

    const getGenericColors = (type) => {
        let designatedColor = designationHasNoColors(type) ? null : (type === INTERIOR ? designatedDescription.colors.interior[0] : designatedDescription.colors.exterior[0])
        let genericColors = type === INTERIOR ? taxonomyData.interiorColors : taxonomyData.exteriorColors;
        // if we received a designated generic color that's not in our list, add it
        if (designatedColor && !isOemColor(designatedColor) && !genericColors.find(color => color.name === designatedColor.normalizedName)) {
            genericColors.push({name: designatedColor.normalizedName})
        }
        return genericColors
    }

    return (
        <Dropdown
            value={getInstalledColorCode(colorType)}
            key={getInstalledColorCode(colorType)}
            id={props.id}
            onChange={props.onChange}
            disabled={!designatedDescription?.trim}
        >
            {!getInstalledColorCode(colorType) && <option value='' disabled>{PLEASE_SELECT}</option>}
            {colorType === INTERIOR && getOemColors(colorType)?.map(
                option => {
                    return (<option
                        value={option.oemOptionCode ? option.oemOptionCode : option.oemName}
                        key={`oem-${option.oemOptionCode ? option.oemOptionCode : option.oemName}`}
                    >{getOemDisplayName(option)}</option>)
                }
            )}
            {colorType === EXTERIOR && getOemColors(colorType)?.map(
                option => {
                    return (<option
                        value={option.oemOptionCode ? option.oemOptionCode : option.oemName}
                        key={`oem-${option.oemOptionCode ? option.oemOptionCode : option.oemName}`}
                    >{getOemDisplayName(option)}</option>)
                }
            )}
            {colorType === INTERIOR && getGenericColors(colorType).map(
                option => {
                    return (<option
                        value={option.name}
                        key={option.id}
                    >{option.name}</option>)
                }
            )}
            {colorType === EXTERIOR && getGenericColors(colorType).map(
                option => {
                    return (<option
                        value={option.name}
                        key={option.id}
                    >{option.name}</option>)
                }
            )}
        </Dropdown>
    )
};

export default ColorDropdown;