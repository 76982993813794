import React from 'react';
import Button from "@prism/button";

const ViewPhotoButton = ({id, disabled, onClick}) => (

    <Button id={id}
            className={"mx-2 my-0"}
            disabled={disabled}
            onClick={onClick}>
        <i className="icon prism-icon-images large-button-icon text-secondary" />
    </Button>

);

export {ViewPhotoButton};