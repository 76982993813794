import React from 'react';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import Row from "@prism/row";
import {connect} from "react-redux";
import {CONDITION_ANNOUNCEMENT, STRUCTURAL_ALTERATION} from "../../../utils/constants";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {setStructuralAlterationSelected} from "../../../actions/globalDisplayActions";
import DataPair from "../../common/DataPair";

class StructuralDamage extends React.Component {

    handleStructureAlteration = (e) => {
        this.props.setStructuralAlterationSelected();

        if (e.currentTarget.value=== "true") {
            if (!this.announcementExists("AN0036")){
                this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, "AN0036", STRUCTURAL_ALTERATION);

            }

        } else {
            this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, "AN0036");
        }
    };

    announcementExists = (code) => {
        if (this.props.condition.isNewCondition && !this.props.globalDisplay.structuralAlterationSelected)
        {
            return null
        }

        if (!this.props.announcements.items) {
            return false
        }
        const conditionAnnouncements = this.props.announcements.items.find(announcement => announcement.source === CONDITION_ANNOUNCEMENT);
        if (!conditionAnnouncements) {
            return false
        }
        return conditionAnnouncements.announcements.filter(announcement => announcement.referenceCode === code).length > 0
    };

    render() {
        return (
            <Col xs={7}>
                <DataPair label="Structural Alteration" id="structure-alteration" required>
                    <Row>
                        <Col className="pl-0" xs={6}>
                            <RadioButton label="Yes"
                                         name="structureAlteration"
                                         value={true}
                                         checked={this.announcementExists("AN0036") === true}
                                         onClick={this.handleStructureAlteration}
                            />
                        </Col>
                        <Col className="pl-0" xs={6}>
                            <RadioButton label="No"
                                         name="structureAlteration"
                                         value={false}
                                         checked={this.announcementExists("AN0036") === false}
                                         onClick={this.handleStructureAlteration}
                            />
                        </Col>
                    </Row>

                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    setStructuralAlterationSelected

};


function mapStateToProps({condition, description, announcements, globalDisplay}) {
    return {condition, description, announcements, globalDisplay};
}
export default connect(mapStateToProps, matchDispatchToProps)(StructuralDamage);