import React from "react";
import Button from "@prism/button";
import Confirm from "../common/Confirm";
import Icon from "@prism/icon";
import { connect } from "react-redux";
import { noop } from "lodash";
import {deleteMiscellaneousImage} from "../../actions/miscellaneousImageActions";
import {isNativeApp} from "../../utils/utils";

const DeleteMiscImageButton = (props) => {
    const handleConfirm = props.onConfirm || noop;

    const handleDeletePhotoClick = () => {
        if(isNativeApp()) {
            window.NativeApp.deletePhoto(props.workOrderInfo.workOrderNumber, props.tag);
        }

        props.deleteMiscellaneousImage(props.tag);
    };

  return (
    <>
      <Confirm
        title="Delete Image"
        description="This will permanently delete the image. Are you sure you want to delete it?"
        submitDescription="Delete"
        cancelDescription="Cancel"
      >
        {(confirm) => (
          <Button
            id={"delete-image-" + props.tag}
            disabled={props.disabled}
            onClick={confirm(() => {
              handleDeletePhotoClick();
              handleConfirm();
            })}
            className={`delete-photo-button ${
              props.className && " " + props.className
            }`}
            style={props.style}
          >
            <Icon glyph="trash" className="large-button-icon mr-2" />
            Delete Photo
          </Button>
        )}
      </Confirm>
    </>
  );
};

function mapStateToProps({ workOrderInfo }) {
    return { workOrderInfo };
}

const matchDispatchToProps = {
    deleteMiscellaneousImage,
};

export default connect(mapStateToProps, matchDispatchToProps)(DeleteMiscImageButton);
