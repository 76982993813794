import Form from "@prism/form";
import React from "react";
import {EXTERIOR} from "../../../utils/constants";
import Input from "@prism/input";
import ColorDropdown from "./ColorDropdown";

const ExteriorColor = (props) => {
    const {designatedDescription, designatedDescriptionResponse, onExteriorColorChange, taxonomyData} = props

    return (
        <Form.Group>
            <Input.Label>
                <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>Exterior Color
            </Input.Label>
            <ColorDropdown
                id={"configure-exterior-color"}
                onChange={onExteriorColorChange}
                colorType={EXTERIOR}
                taxonomyData={taxonomyData}
                designatedDescription={designatedDescription}
                designatedDescriptionResponse={designatedDescriptionResponse}
            />
        </Form.Group>
    )

}

export default ExteriorColor;