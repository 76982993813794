import React from "react";
import SedanExterior from "./SedanExterior";
import SedanInterior from "./SedanInterior";
import Mechanical from "../Mechanical";
import SedanStructural from "./SedanStructural";
import "../../../../../styles/flat-car.css"
import {connect} from "react-redux";

const Sedan = (props) => {
    switch (props.flatCarView) {
        case "interior":
            return <SedanInterior style={{width: '500px'}} clearSelectedItem={props.clearSelectedItem}/>;
        case "exterior":
            return <SedanExterior clearSelectedItem={props.clearSelectedItem}/>;
        case "structural":
            return <SedanStructural clearSelectedItem={props.clearSelectedItem}/>;
        case "mechanical":
            return <Mechanical  clearSelectedItem={props.clearSelectedItem}/>;
        default:
            return <SedanExterior clearSelectedItem={props.clearSelectedItem}/>;
    }
};

function mapStateToProps({globalDisplay}) {
    const {flatCarView} = globalDisplay;
    return {flatCarView};
}

export default connect(mapStateToProps)(Sedan);