import React from 'react';
import Modal from "@prism/modal";
import Col from "@prism/col";
import Row from "@prism/row";
import {CONFIRM, CONTINUE, DECODE, YES} from "../utils/constants";
import {logInfo} from "../actions/loggingActions";
import {connect} from "react-redux";

function ConfirmationOrDecisionModal(props) {

    const {
        shouldShowModal,
        modalType,
        modalHeader,
        modalBody,
        confirmationText,
        cancelText
    } = props

    const handleClose = () => {
        props.handleClose();
    };

    const handleContinue = () => {
        props.handleContinue();
    };

    const displayContinueButton = () => {
        return confirmationText === CONTINUE
            || confirmationText === YES
            || confirmationText === CONFIRM
            || confirmationText === DECODE
            || modalType === 'logout'
            || modalType === 'session-timeout';
    }

    const showStyledCancelButton = () => {
        return modalType === 'redecode' || modalType === 'logout' || modalType === 'close-inspection';
    }

    if (shouldShowModal) {
        props.logInfo("ConfirmationOrDecisionModal", `Displaying Modal: ${modalHeader}`)
    }

    return (

        <Modal isOpen={shouldShowModal} id={`${modalType}-modal`} style={{zIndex: 9600}}>
            <Modal.Header>
                <div
                    id={`${modalType}-modal-header-content`}>
                    {modalHeader}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div id={`${modalType}-modal-body-content`}>
                    {modalBody}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Row id='modal-footer-row'>
                    {/* it's a 2-button modal if cancelText is present */}
                    {!!cancelText && showStyledCancelButton() ? <Col id={`${modalType}-footer-content`}>
                        <Modal.Cancel
                            id={`${modalType}-cancel-btn`}
                            text={cancelText}
                            onClick={handleClose}
                        />
                    </Col> : null}
                    {!!cancelText && !showStyledCancelButton() ? <Col id={`${modalType}-footer-content`}>
                        <Modal.Okay
                            id={`${modalType}-cancel-btn`}
                            text={cancelText}
                            onClick={handleClose}
                        />
                    </Col> : null}
                    <Col>
                        {/* display one button or the other, depending upon the needed functionality */}
                        {displayContinueButton() ?
                            <Modal.Okay
                                id={`${modalType}-continue-btn`}
                                text={confirmationText}
                                onClick={handleContinue}
                            />
                            :
                            <Modal.Okay
                                id={`${modalType}-ok-btn`}
                                text={confirmationText}
                                onClick={handleClose}
                            />
                        }
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapDispatchToProps = {
    logInfo
}

export default connect(null, mapDispatchToProps)(ConfirmationOrDecisionModal);
