import React from 'react';
import Modal from "@prism/modal";
import {connect} from "react-redux";
import {hideDeleteIncompleteInspectionModal} from "../actions/globalDisplayActions";


function DeleteIncompleteInspectionModal(props) {

    const handleDelete = () => {
        window.NativeApp.removeVehicleState(props.currentWorkOrder, props.reduxStateVersion)
        props.getVehiclesWithState()
        props.hideDeleteIncompleteInspectionModal()
    }

    return (
        //Set up is open, on clicks and WO
        <Modal isOpen={props.showDeleteIncompleteInspectionModal} id={"delete-incomplete-inspection-modal"}>
            <Modal.Header>DELETE INCOMPLETE INSPECTION</Modal.Header>
            <Modal.Body>
                {`Are you sure you would like to delete the progress made on WO#${props.currentWorkOrder}?`}
            </Modal.Body>
            <Modal.Footer>
                <Modal.Cancel id="delete_cancel" text="Cancel" onClick={props.hideDeleteIncompleteInspectionModal}/>
                <Modal.Okay id="delete_ok" text="Yes" onClick={handleDelete}/>
            </Modal.Footer>
        </Modal>
    )

}

function mapStateToProps({globalDisplay, appFlags}) {
    const {showDeleteIncompleteInspectionModal, currentWorkOrder} = globalDisplay;
    const {reduxStateVersion} = appFlags;
    return {showDeleteIncompleteInspectionModal, currentWorkOrder, reduxStateVersion}
}

const mapDispatchToProps = {
    hideDeleteIncompleteInspectionModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteIncompleteInspectionModal);