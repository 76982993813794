import {SET_AUCTION_CODE, SET_SCAN_AUCTION_CODE, SET_WORK_ORDER_INFO} from "./dispatchTypes";

export const submitScan = (scanSubmitter, timerId, params) => async (dispatch, getState) => {
    const {auctionCode, workOrderNumber} = params;
    scanSubmitter.auctionCode = auctionCode;
    scanSubmitter.workOrderNumber = workOrderNumber;
    scanSubmitter.dispatch = dispatch;
    scanSubmitter.getState = getState;
    await scanSubmitter.submit(timerId);
};

// To be used when an A1 record exists and user is explicitly requesting to open the L1
export const resubmitForL1 = (scanSubmitter, timerId) => async (dispatch, getState) => {
    scanSubmitter.dispatch = dispatch;
    scanSubmitter.getState = getState;
    await scanSubmitter.submitForL1(timerId);
};

export const setAuctionCode = (auctionCode) => dispatch => {
    dispatch({
        type: SET_AUCTION_CODE,
        auctionCode
    });
};

export  const setScanAuctionCode = (scanAuctionCode) => dispatch => {
    dispatch({
        type: SET_SCAN_AUCTION_CODE,
        scanAuctionCode
    });
};

export const setWorkOrderInfo = (workOrderInfo) => dispatch => {
    dispatch({
        type: SET_WORK_ORDER_INFO,
        payload: workOrderInfo
    });
};
