import React from "react";
import Icon from "@prism/icon";

const RequiredPhotoMessage = (props) => {
  return props.hasPhoto ? (
    <div className="required-photo required-photo-provided w-100">
      <Icon
        glyph="checkmark-circle"
        color="success"
        className="required-photo-icon"
      />
      Photo Required
    </div>
  ) : (
    <div className="required-photo required-photo-needed w-100">
      <Icon
        glyph="error-triangle"
        color="danger"
        className="required-photo-icon"
      />
      Photo Required
    </div>
  );
};

export default RequiredPhotoMessage;
