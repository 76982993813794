/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabPane = (props) => {
  const {
    className: classNameFromProps,
    children,
    disabled,
    id,
    isAnimated,
    active,
    labelledby,
  } = props;
  const isActive = active && !disabled;
  const className = classNames(classNameFromProps, 'tab-pane', {
    fade: isAnimated,
    show: isActive,
    active: isActive,
  });

  return (
    <div
      id={id}
      className={className}
      role="tabpanel"
      aria-labelledby={labelledby}
    >
      {children}
    </div>
  );
};

TabPane.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isAnimated: PropTypes.bool,
  labelledby: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TabPane.defaultProps = {
  active: false,
  className: '',
  disabled: false,
  isAnimated: true,
  labelledby: '',
  children: null,
};

TabPane.displayName = 'TabPane';

export default TabPane;
