import {deepParseJson} from "deep-parse-json";
import {
    exteriorColorFromDesignatedDescription,
    interiorColorFromDesignatedDescription
} from "../utils/vehicleDescriptors";
import {getSubSeries, isNativeApp} from "../utils/utils";

export const deviceStorage = {
    getItem: async (key) => {},
    setItem: async (key, item) => {
        if(isNativeApp()) {
            let data = JSON.parse(item);
            let appFlags = deepParseJson(data.appFlags);
            let workOrderInfo = deepParseJson(data.workOrderInfo);
            let deviceStorageController = deepParseJson(data.deviceStorageController);
            if (appFlags.flags.deviceStorage && workOrderInfo.workOrderNumber && deviceStorageController.saveState) {
                let vehicleState = buildVehicleState(data, workOrderInfo.workOrderNumber, item, appFlags);
                window.NativeApp.saveVehicleState(vehicleState);
            }
        }
    },
    removeItem: async (key) => {}
}

export const buildVehicleState = (data, workOrder, item, appFlags)  => {
    let designatedDescription = deepParseJson(data.designatedDescription);
    let unit = deepParseJson(data.unit);

    return JSON.stringify({
        state: item,
        version: appFlags.reduxStateVersion,
        vehicleUiElement: {
            workOrder: workOrder,
            vin: unit.vin,
            uiElement1: designatedDescription ? (designatedDescription.year + ' ' +
                    designatedDescription.make + ' ' +
                    (designatedDescription.model ? (designatedDescription.model.normalizedName ? designatedDescription.model.normalizedName :'') : '') + ' ' +
                    getSubSeries(designatedDescription)) : ' ',
            uiElement2: (exteriorColorFromDesignatedDescription(designatedDescription) || '') + ',' +
                    (interiorColorFromDesignatedDescription(designatedDescription) || ''),
            uiElement3: (designatedDescription && designatedDescription.title) ?
                    designatedDescription.title : ''
        }
    });
}