import {LOAD_WORK_ORDER_SUCCESS} from "../actions/dispatchTypes";

export default function taxonomyDataReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload.taxonomyData || state;
        default:
            return state;
    }
}
