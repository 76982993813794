import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import "../../../styles/review-panel.css";
import {setCurrentConditionTabIndex} from "../../../actions/naviationActions";
import {
    MISSING_DRIVABLE_MESSAGE,
    MISSING_EMISSIONS_MESSAGE,
    MISSING_ENGINE_STARTS_MESSAGE,
    MISSING_FLOOD_DAMAGE_MESSAGE,
    MISSING_INTERIOR_ODOR_MESSAGE,
    MISSING_SERVICE_REQUIRED_MESSAGE,
    MISSING_SERVICE_REQUIRED_SERVICES_MESSAGE,
    MISSING_STRUCTURAL_ALTERATION_MESSAGE,
    MISSING_STRUCTURAL_DAMAGE_MESSAGE,
    MISSING_WRAP_VEHICLE_COLOR
} from "../../../utils/messages";

class ConditionReview extends React.Component {

    state = {
        interiorOdorIsValid: false,
        emissionsBookIsValid: false,
        engineStartsIsValid: false,
        serviceRequiredIsValid: false,
        serviceRequiredServicesAreValid: false,
        drivableIsValid: false,
        floodDamageIsValid: false,
        structuralDamageIsValid: false,
        structuralAlterationIsValid: false,
        wrapVehicleColorIsValid: false,
        isValid: false
    };

    componentDidMount() {
        console.log("validating....");
        this.checkValidation();
    }

    validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    };

    validationFailureIcon = () => {
        return <Icon glyph="notification-circle" color="danger" className="status-icon"/>
    };

    serviceRequiredIsValid = () => {
        if (!this.props.account.isBmw && !this.props.account.isMercedes) {
            return true;
        }
        return this.props.globalDisplay.serviceRequiredSelected;
    }

    serviceSelectionsAreValid = () => {
        if (!this.props.account.isBmw || !this.props.condition.serviceRequired) {
            return true;
        }
        return this.props.account.isBmw && (this.props.globalDisplay.engineOilServiceSelected || this.props.globalDisplay.brakeFluidServiceSelected);
    }

    checkValidation = () => {
        const interiorOdorIsValid = !!this.props.condition.interiorOdor;
        const emissionsIsValid = !!this.props.condition.emissions;
        const engineStartsIsValid = this.props.condition.engineStarts === false || this.props.condition.engineStarts === true;
        const serviceRequiredIsValid = this.serviceRequiredIsValid();
        const serviceRequiredServicesAreValid = this.serviceSelectionsAreValid();
        const drivableIsValid = this.props.condition.drivable === false || this.props.condition.drivable === true;
        const floodDamageIsValid = this.props.globalDisplay.floodDamageSelected;
        const structuralDamageIsValid = this.props.globalDisplay.structuralDamageSelected;
        const structuralAlterationIsValid = this.props.globalDisplay.structuralAlterationSelected;
        const wrapVehicleColorIsValid = this.props.announcements.wrapVehicleColorIsMissing !== true;
        const isValid = interiorOdorIsValid
            && emissionsIsValid
            && engineStartsIsValid
            && serviceRequiredIsValid
            && serviceRequiredServicesAreValid
            && drivableIsValid
            && floodDamageIsValid
            && structuralDamageIsValid
            && structuralAlterationIsValid
            && wrapVehicleColorIsValid;
        this.setState({
            interiorOdorIsValid,
            emissionsIsValid,
            engineStartsIsValid,
            serviceRequiredIsValid,
            serviceRequiredServicesAreValid,
            drivableIsValid,
            floodDamageIsValid,
            structuralDamageIsValid,
            structuralAlterationIsValid,
            wrapVehicleColorIsValid,
            isValid
        });
        this.props.conditionPanelIsValid(isValid);
    };

    navigateToConditionTab = () => {
        this.props.setCurrentConditionTabIndex(4);
    };

    getValidationMessages = () => {
        const validationMessages = [];
        if (!this.state.interiorOdorIsValid) {
            validationMessages.push(MISSING_INTERIOR_ODOR_MESSAGE)
        }
        if (!this.state.emissionsIsValid) {
            validationMessages.push(MISSING_EMISSIONS_MESSAGE)
        }
        if (!this.state.engineStartsIsValid) {
            validationMessages.push(MISSING_ENGINE_STARTS_MESSAGE)
        }
        if (!this.state.serviceRequiredIsValid) {
            validationMessages.push(MISSING_SERVICE_REQUIRED_MESSAGE)
        }
        if (!this.state.serviceRequiredServicesAreValid) {
            validationMessages.push(MISSING_SERVICE_REQUIRED_SERVICES_MESSAGE)
        }
        if (!this.state.drivableIsValid) {
            validationMessages.push(MISSING_DRIVABLE_MESSAGE)
        }
        if (!this.state.floodDamageIsValid) {
            validationMessages.push(MISSING_FLOOD_DAMAGE_MESSAGE)
        }
        if (!this.state.structuralDamageIsValid) {
            validationMessages.push(MISSING_STRUCTURAL_DAMAGE_MESSAGE)
        }
        if (!this.state.structuralAlterationIsValid) {
            validationMessages.push(MISSING_STRUCTURAL_ALTERATION_MESSAGE)
        }
        if (!this.state.wrapVehicleColorIsValid) {
            validationMessages.push(MISSING_WRAP_VEHICLE_COLOR)
        }
        return validationMessages.join(", ")
    };

    render() {
        const {isValid} = this.state;
        return (
            <Row className={`px-6 py-4 mx-0 review-row ${!isValid && " show-is-clickable"}`}
                 onClick={isValid ? undefined : this.navigateToConditionTab}>
                <Col className="flex-grow-1">
                    <Row className="h2 review-row-title"><Col>Condition</Col></Row>
                    {!isValid && <Row className="review-error-message"><Col>{this.getValidationMessages()}</Col></Row>}
                </Col>
                <Col className="flex-grow-0">
                    {isValid ? this.validationSuccessIcon() : this.validationFailureIcon()}
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({condition, globalDisplay, announcements, consignment, account, description, unit, designatedDescription}) {
    return {condition, globalDisplay, announcements, consignment, account, description, unit, designatedDescription};
}

const matchDispatchToProps = {
    setCurrentConditionTabIndex
}

export default connect(mapStateToProps, matchDispatchToProps)(ConditionReview);