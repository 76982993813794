import React from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";

const TrimDropdown = (props) => {

    const {designatedDescription, designatedDescriptionResponse} = props;

    return (
        <Dropdown
            value={designatedDescription ? designatedDescription.catalogId : ''}
            id="configure-trim-dropdown"
            onChange={props.onChange}
        >
            {!designatedDescription.trim && <option value='' disabled>{PLEASE_SELECT}</option>}
            {designatedDescriptionResponse.fromTrustedSource &&
                designatedDescriptionResponse.catalogVehicles.map(
                    trimOption => {
                        return (<option
                                value={trimOption.catalogId}
                                key={trimOption.catalogId}
                            >{trimOption.trim.normalizedName} - {trimOption.powertrain.transmission.type}</option>
                        )
                    }
                )} :
            {!designatedDescriptionResponse.fromTrustedSource && designatedDescriptionResponse.vcrResponse &&
                designatedDescriptionResponse.vcrResponse.vehicleTrims.map(
                trimOption => {
                    return(<option
                        value={trimOption.id}
                        key={trimOption.id}
                    >{trimOption.name}</option>)
                }
            )}
        </Dropdown>
    )
};

export default TrimDropdown;