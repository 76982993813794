import {ADD_SUGGESTED_DAMAGE, DELETE_SUGGESTED_DAMAGE, MANUALLY_ADD_SUGGESTED_DAMAGE} from "./dispatchTypes";

export const addSuggestedDamage = (damage) => dispatch => {
    dispatch({
        type: ADD_SUGGESTED_DAMAGE,
        payload: damage
    })
};

export const deleteSuggestedDamage = (damage) => dispatch => {
    dispatch({
        type: DELETE_SUGGESTED_DAMAGE,
        payload: damage
    })

};

export const manuallyAddSuggestedDamage = (manuallyAddedDamageKey) => dispatch => {
    dispatch({
        type: MANUALLY_ADD_SUGGESTED_DAMAGE,
        payload: manuallyAddedDamageKey
    })
};
