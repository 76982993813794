import {CLEAR_ALL_MESSAGES, LOAD_WORK_ORDER_FAIL} from "../actions/dispatchTypes";
import update from "immutability-helper";
import {uuid} from "../utils/utils";

const initialState = [];

export default function statusMessagesReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_FAIL:
            return errorMessage(state, action.message.error)
        case CLEAR_ALL_MESSAGES:
            return clearMessages();

        default:
            return state;
    }
}

function errorMessage(state, messageText) {
    if (isNewMessage(state, messageText) && messageText) {
        return update(state, {$unshift: [{text: messageText, type: 'danger', id: uuid()}]});
    } else {
        return state;
    }
}

function isNewMessage(state, messageText) {
    if (state.length < 1) {
        return true;
    }
    const messageIndex = state.map(message => {
        return message.text
    }).indexOf(messageText);
    return messageIndex === -1;
}

function clearMessages() {
    return [];
}
