import {
    LOAD_WORK_ORDER_SUCCESS,
    UPDATE_ODOMETER_READING,
    UPDATE_ODOMETER_UNITS
} from "../actions/dispatchTypes";
import update from "immutability-helper";
import {KILOMETER_STATIC_VALUE, KILOMETERS_ALL_CAPS, MILES_ALL_CAPS} from "../utils/constants";

export default function unitReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload.unit || state;
        case UPDATE_ODOMETER_READING:
            return updateOdometerReading(state, action.payload);
        case UPDATE_ODOMETER_UNITS:
            return updateOdometerUnits(state, action.payload);
        default:
            return state;
    }
}

function updateOdometerReading(state, reading) {
    return update(state, {
        $merge: {
            odometer: {
                ...state.odometer,
                reading: reading
            }
        }
    })
}

function updateOdometerUnits(state, units) {
    return update(state, {
        $merge: {
            odometer: {
                ...state.odometer,
                units: (getOdometerUnits(units))
            }
        }
    })
}

function getOdometerUnits(units) {
    return units === KILOMETER_STATIC_VALUE ? KILOMETERS_ALL_CAPS : MILES_ALL_CAPS
}
