import React from 'react';
import DataPair from "../../common/DataPair";
import {connect} from 'react-redux';
import Dropdown from "../../common/Dropdown";

import {calculateDamageKey, deleteAutoDamage, updateKeyValue} from "../../../actions/conditionActions";
import AddDamageModal from "../condition/AddDamageModal";
import {toggleDamageModal} from "../../../actions/globalDisplayActions";
import {
    MISSING_COMBO_KEY_DAMAGE,
    MISSING_FOB_KEY_DAMAGE,
    MISSING_MASTER_KEY_DAMAGE,
    MISSING_SMART_KEY_DAMAGE,
    MISSING_SPARE_KEY_DAMAGE
} from "../../../utils/constantsDamages";

class KeyDisplay extends React.Component {
    state = {}

    handleUpdateKey = (e) => {
        const quantity = e.target.value;
        const key = {type: this.props.keyType.type, quantity: quantity, keyRuleType: this.props.keyType.keyRuleType};
        this.props.updateKeyValue(key);
        this.isAutoDamageForKeySelectionRequired(key);
        //also keep that damage in reducer for deletion purpose
    };

    setStateAndPromise = (toSetFunc) => {
        return new Promise((a, r) => {
            this.setState(toSetFunc, () => {
                a()
            });
        });
    };

    async isAutoDamageForKeySelectionRequired(key) {
        console.log(this.props.damageRules)

        function getKeyDamage(key) {
            switch (key.type) {
                case 'Master':
                    return MISSING_MASTER_KEY_DAMAGE;
                case 'Spare':
                    return MISSING_SPARE_KEY_DAMAGE;
                case 'Remote-Fob':
                    return MISSING_FOB_KEY_DAMAGE;
                case 'Smart':
                    return MISSING_SMART_KEY_DAMAGE;
                case 'Combo':
                    return MISSING_COMBO_KEY_DAMAGE;
                default:
                    return null;
            }
        }

        function getClientRule(damageRules) {
            return damageRules.rules?.keys?.find(keyRule => (keyRule.type === key.keyRuleType && !keyRule.optional))
        }

        const keyRule = getClientRule(this.props.damageRules);
        const keyDamage = getKeyDamage.call(this, key)

        if (keyDamage) {
            let existingKeyDamage = null
            if (this.props.condition.damages) {
                existingKeyDamage = this.props.condition.damages.find(existingDamage => calculateDamageKey(existingDamage) === keyDamage.damageKey)
            }

            //Only add damage if it does not exist already...
            if ((this.failsKeyRule(key, keyRule) || key.quantity === 0) && !existingKeyDamage) {
                this.props.toggleDamageModal(key.type)

                await this.setStateAndPromise(() => {
                    return {damageKeyType: key.type, keyDamage}
                });
            } else if ((key.quantity === "N/A" || this.passesKeyRule(key, keyRule)) && existingKeyDamage) {
                this.props.deleteAutoDamage(existingKeyDamage)
            }
        }
    }

    passesKeyRule(key, keyRule) {
        return !!keyRule ? key.quantity >= keyRule.quantity : key.quantity > 0;
    }

    failsKeyRule(key, keyRule) {
        return !!keyRule && key.quantity < keyRule.quantity;
    }

    handleCancelClick = () => {
        const key = {type: this.state.damageKeyType, quantity: "N/A"};
        this.props.updateKeyValue(key);
        this.props.toggleDamageModal(this.state.damageKeyType)
    }

    getKeyDisplayOptions = () => {
        const range = (start, end) => Array.from(Array(end - start + 1).keys()).map(x => x + start);
        return range(-1, 7).map(option => {
            if (option === -1) return <option id="N/A" name="N/A" value="N/A">N/A</option>;
            return <option value={option.toString()}
                           id={`key_${this.props.keyType.type}_opt_${option.toString()}`}>{option.toString()}</option>;
        });
    };
    getSelectionValue = () => {
        const valueSet = !!this.props.displayKey;
        const quantity = valueSet ? this.props.displayKey.quantity : null;
        if (quantity === null)
            return 'N/A';
        else
            return quantity.toString();
    };

    render() {
        const valueSet = !!this.props.displayKey;
        const label = this.props.keyType.text;
        const type = valueSet ? this.props.displayKey.type : null;
        return (
            <div key={type}>
                {this.state.damageKeyType &&
                    <AddDamageModal showModal={this.props.globalDisplay[this.state.damageKeyType]}
                                    onCancelClick={this.handleCancelClick}
                                    toggle={() => this.props.toggleDamageModal(this.state.damageKeyType)}
                                    damage={this.state.keyDamage}/>}
                <DataPair label={label} id={`key_${this.props.keyType.type}_value`}>
                    <Dropdown
                        type="text"
                        id={this.props.keyType.type}
                        value={this.getSelectionValue()}
                        onChange={this.handleUpdateKey}>
                        {this.getKeyDisplayOptions()}
                    </Dropdown>
                </DataPair>
            </div>
        )
    }
}

const matchDispatchToProps = {
    updateKeyValue,
    toggleDamageModal,
    deleteAutoDamage
};

function mapStateToProps({damageRules, globalDisplay, condition}) {
    return {damageRules, globalDisplay, condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(KeyDisplay);




