import {
    LOAD_WORK_ORDER_SUCCESS,
    ADD_MISCELLANEOUS_IMAGE,
    DELETE_MISCELLANEOUS_IMAGE
} from "../actions/dispatchTypes";

export default function miscellaneousImagesReducer(state = [], action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return getMiscellaneousImages(state, action);
        case ADD_MISCELLANEOUS_IMAGE:
            return addMiscellaneousImages(state, action);
        case DELETE_MISCELLANEOUS_IMAGE:
            return deleteMiscellaneousImage(state, action);
        default:
            return state;
    }
}

function getMiscellaneousImages(state, action) {
    if(action.payload.miscellaneousImage && action.payload.miscellaneousImage.images) {
        return action.payload.miscellaneousImage.images.map(image => Object.assign({key: image.angle}, image));
    } else{
        return [];
    }
}

function addMiscellaneousImages(state, action) {
    return state.concat([{
        key: action.payload.key,
        angle: action.payload.key,
        status: 'UPDATED',
        url: action.payload.url
    }])
}

function deleteMiscellaneousImage(state, action) {
    return Object.assign([], state.map(miscImage => {
        // if the image was added and deleted in the same session, don't include it
        if (miscImage.angle === action.payload.key && miscImage.status === 'UPDATED') {
            miscImage.angle = null;
        } else if (miscImage.angle === action.payload.key) {
            miscImage.status = 'DELETED';
        }
        return miscImage;
    }).filter(image => image.angle !== null));
}