import React from 'react';

import DamageEditView from './DamageEditView';
import Row from "@prism/row";
import Col from "@prism/col";
import ButtonGroup from "@prism/buttongroup";
import Button from "@prism/button";

import {
    clearItemsList,
    clearSelectedFlatCarPanel,
    handlePanelClick
} from "../../../actions/flatCarActions";
import {
    hideFlatCarPanel,
    showExteriorFlatCarView,
    showInteriorFlatCarView, showMechanicalFlatCarView, showStructuralFlatCarView
} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";

class DamageEditWrapper extends React.Component {

    damageEditViewRef = React.createRef();

    handleViewButtonClick = (e) => {
        this.props.clearItemsList();
        this.props.clearSelectedFlatCarPanel();
        this.damageEditViewRef && this.damageEditViewRef.current.resetTransform();
        if (e.currentTarget.name === 'exterior') {
            this.props.showExteriorFlatCarView();
        } else if (e.currentTarget.name === 'interior') {
            this.props.showInteriorFlatCarView();
        } else if (e.currentTarget.name === 'structural') {
            this.props.showStructuralFlatCarView();
            this.props.handlePanelClick(69)
        } else if (e.currentTarget.name === 'mechanical') {
            this.props.showMechanicalFlatCarView();
            this.props.handlePanelClick(70)
        }
    };

    render() {
        return (
            <div id="damage-wrapper">
                <Row>
                    <Col xs={12}>
                        <ButtonGroup className='mx-0 px-0' style={{width: '100%'}}>
                            <Button outline active={this.props.flatCarView === 'exterior'} className="button-white"
                                    onClick={this.handleViewButtonClick} name="exterior" style={{width: '22%', fontSize: '1.4rem'}}>Exterior</Button>
                            <Button outline active={this.props.flatCarView === 'interior'} className="button-white"
                                    onClick={this.handleViewButtonClick} name="interior" style={{width: '22%', fontSize: '1.4rem'}}>Interior</Button>
                            <Button outline active={this.props.flatCarView === 'structural'} className="button-white"
                                    onClick={this.handleViewButtonClick}
                                    name="structural" style={{width: '28%', fontSize: '1.4rem'}}>Structural</Button>
                            <Button outline active={this.props.flatCarView === 'mechanical'} className="button-white"
                                    onClick={this.handleViewButtonClick}
                                    name="mechanical" style={{width: '28%', fontSize: '1.4rem'}}>Mechanical</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <DamageEditView ref={this.damageEditViewRef}/>
            </div>
        )
    }
}

const matchDispatchToProps = {
    clearItemsList,
    clearSelectedFlatCarPanel,
    hideFlatCarPanel,
    showExteriorFlatCarView,
    showInteriorFlatCarView,
    showMechanicalFlatCarView,
    showStructuralFlatCarView,
    handlePanelClick
};

function mapStateToProps({globalDisplay, itemList}) {
    const {flatCarView} = globalDisplay;
    return {flatCarView, itemList};
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageEditWrapper);