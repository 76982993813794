import React from "react";
import PaintType from "./PaintType";
import ChargingCable from "./ChargingCable";
import Section from "../../common/Section";
import Row from "@prism/row";

const AdditionalAccessories = () => {

    return(
        <Section heading="Other" containerClass="pb-0">
          <Row className="mx-0">
            <PaintType/>
          </Row>
          <Row className="mx-0">
            <ChargingCable/>
          </Row>
        </Section>
    )

}

export default AdditionalAccessories;