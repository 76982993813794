import {
    LOAD_WORK_ORDER_SUCCESS,
    SET_AUCTION_CODE,
    SET_SCAN_AUCTION_CODE,
    SET_WORK_ORDER_INFO,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";

export default function conditionReducer(state = emptySelection(), action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            console.log("wo info", action);
            return {workOrderNumber: parseInt(action.workOrderNumber), auctionCode: action.auctionCode, scanAuctionCode: action.auctionCode};
        case SET_AUCTION_CODE:
            return {...state, auctionCode: action.auctionCode};
        case SET_SCAN_AUCTION_CODE:
            console.log("scan location :", action.scanAuctionCode);
            return {...state, scanAuctionCode: action.scanAuctionCode};
        case SET_WORK_ORDER_INFO:
            return {...state, auctionCode: action.payload.auctionCode, workOrderNumber: action.payload.workOrderNumber};
        case UNLOAD_WORK_ORDER:
            return unloadWorkOrder(state);
        default:
            return state;
    }
}

function emptySelection() {
    return {
        auctionCode: '',
        scanAuctionCode: '',
        disableSubmit: false,
        workOrderNumber: '',
        disableReview: false
    };
}

function unloadWorkOrder(state) {
    return {...state,
        disableSubmit: false,
        workOrderNumber: '',
        disableReview: false
    };
}
