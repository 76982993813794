import React from "react";
import Button from "@prism/button";
import Confirm from "../../common/Confirm";

const DeleteSuggestedDamageButton = (props) => {
    const {suggestedDamage, onClick} = props;

    const handleClick = () => {
        onClick(suggestedDamage);
    }

    return (
        <>
            <Confirm title={suggestedDamage.item}
                     description="This will delete the line item. Are you sure you want to delete it?"
                     submitDescription="Delete"
                     cancelDescription="Cancel">
                {confirm => (
                    <Button id={'delete-suggested-' + suggestedDamage.artCodes.damageKey}
                            onClick={confirm(handleClick)}
                            className={"mx-2 my-0"}
                            color="secondary">
                        <i className="icon prism-icon-trash large-button-icon text-white"/>
                    </Button>
                )}
            </Confirm>
        </>
    )
};


export default DeleteSuggestedDamageButton;
