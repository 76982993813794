import Api from '../components/Api';
import {GET_USER_DETAILS} from "./dispatchTypes";

export const getUserDetails = (params) => async dispatch => {
    dispatch({
        type: GET_USER_DETAILS,
        payload: await Api.getUserDetails(params)
    });
};

export const setUserDetails = (details) => dispatch => {
    dispatch({
        type: GET_USER_DETAILS,
        payload: details
    })
};
