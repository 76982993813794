import {combineReducers} from 'redux';
import accountReducer from "./accountReducer";
import automatedDamagesReducer from "./automatedDamagesReducer";
import buttonStatesReducer from "./buttonStatesReducer";
import conditionReducer from "./conditionReducer";
import damageImageReducer from "./damageImageReducer";
import locationsReducer from "./locationsReducer";
import navigationReducer from "./navigationReducer";
import progressReducer from "./progressReducer";
import pushPanelsReducer from "./pushPanelsReducer";
import staticOptionValuesReducer from "./staticOptionValuesReducer";
import userDetailsReducers from "./userDetailsReducer";
import workOrderInfoReducer from "./workOrderInfoReducer";
import globalDisplayReducer from "./globalDisplayReducer";
import consignmentReducer from "./consignmentReducer";
import itemListReducer from "./itemListReducer";
import announcementsReducer from "./announcementsReducer";
import dataListsReducer from "./dataListsReducer";
import statusMessagesReducer from "./statusMessagesReducer";
import locationReducer from "./locationReducer";
import appFlagsReducer from "./appFlagsReducer";
import clientDamageRulesReducer from "./clientDamageRulesReducer";
import f5NotesReducer from "./f5NotesReducer";
import originalWorkOrderReducer from "./originalWorkOrderReducer";
import workListReducer from "./workListReducer";
import miscellaneousImagesReducer from "./miscellaneousImagesReducer";
import heartbeatReducer from "./heartbeatReducer";
import designatedDescriptionResponseReducer from "./designatedDescriptionResponseReducer";
import designatedDescriptionReducer from "./designatedDescriptionReducer";
import unitReducer from "./unitReducer";
import originalUnitReducer from "./originalUnitReducer";
import taxonomyDataReducer from "./taxonomyDataReducer";
import vehicleCatalogResolverReducer from "./vehicleCatalogResolverReducer";
import deviceStorageControllerReducer from "./deviceStorageControllerReducer";
import suggestedDamageReducer from "./suggestedDamageReducer";
import repurposeReducer from "./repurposeReducer";
import repurposeAnnouncementsReducer from "./repurposeAnnouncementsReducer.js";

const rootReducer = combineReducers({
    account: accountReducer,
    announcements: announcementsReducer,
    appFlags: appFlagsReducer,
    automatedDamages: automatedDamagesReducer,
    buttonStates: buttonStatesReducer,
    condition: conditionReducer,
    consignment: consignmentReducer,
    damageImages: damageImageReducer,
    damageRules: clientDamageRulesReducer,
    dataLists: dataListsReducer,
    designatedDescriptionResponse: designatedDescriptionResponseReducer,
    designatedDescription: designatedDescriptionReducer,
    f5Notes: f5NotesReducer,
    globalDisplay: globalDisplayReducer,
    heartbeat: heartbeatReducer,
    itemList: itemListReducer,
    locations: locationsReducer,
    location: locationReducer,
    miscellaneousImages: miscellaneousImagesReducer,
    navigation: navigationReducer,
    originalWorkOrder: originalWorkOrderReducer,
    progress: progressReducer,
    pushPanels: pushPanelsReducer,
    staticOptionValues: staticOptionValuesReducer,
    statusMessages: statusMessagesReducer,
    taxonomyData: taxonomyDataReducer,
    unit: unitReducer,
    userDetails: userDetailsReducers,
    workList: workListReducer,
    originalUnit: originalUnitReducer,
    workOrderInfo: workOrderInfoReducer,
    vehicleCatalogResolver: vehicleCatalogResolverReducer,
    deviceStorageController: deviceStorageControllerReducer,
    suggestedDamages: suggestedDamageReducer,
    repurpose: repurposeReducer,
    repurposeAnnouncements: repurposeAnnouncementsReducer
});

export default rootReducer;
