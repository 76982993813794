import Api from "../components/Api";
import {ADD_F5_NOTE, DELETE_F5_NOTE, POPULATE_F5_NOTES} from "./dispatchTypes";

export const addF5Note = (newNote) => dispatch => {
    dispatch({
        type: ADD_F5_NOTE,
        payload: newNote
    })
};

export const deleteF5Note = (note) => dispatch => {
    dispatch({
        type: DELETE_F5_NOTE,
        payload: note
    })
};

export const getVehicleNotes = (workOrderNumber, locationCode, signal) => async (dispatch, getState) => {
    const response = await Api.getVehicleNotes({workOrderNumber: workOrderNumber, locationCode: locationCode, signal: signal});
    dispatch({
        type: POPULATE_F5_NOTES,
        payload: response
    })
};