import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";

const FuelType = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onFuelTypeChange
    } = props

    const [options, setOptions] = useState([]);
    const fuelType = designatedDescription?.powertrain?.engine?.fuelCategory;

    useEffect(() => {
        async function fetchData() {
            return await Api.getFuelTypes();
        }

        if (!hasDDSResponse) {
            fetchData().then(r => setOptions(r.items?.length ? r.items : []));
        }
    }, [hasDDSResponse]);

    return (
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-fuel-type" label="Fuel Type">
                    {fuelType}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-fuel-type" label="Fuel Type" required={true}>
                    <Dropdown disabled={!options?.length}
                              onChange={onFuelTypeChange}
                              value={fuelType}
                              id='fuel-type-dropdown'>
                        {!fuelType && <option value=''>{PLEASE_SELECT}</option>}
                        {options?.map(option => {
                            return (
                                <option
                                    value={option.name}
                                    key={option.name}>
                                    {option.name}
                                </option>
                            )
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>
    )
}

export default FuelType;