import React from "react";
import {connect} from "react-redux";
import {handlePanelClick} from "../../../../../actions/flatCarActions";
import FlatCarUtil from "../FlatCarUtil";

const SedanExterior = (props) => {
    const {handlePanelStriping, StripedPattern, SelectedStripedPattern, StripedPatternLegend, isSuggestedDamagesPresent} = FlatCarUtil();
    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }


    function getTrunkLidPanelNumber() {
        return "hatchback" === props.designatedDescription?.bodyDescription?.vehicleType?.toLowerCase() ? 25 : 24;
    }

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -60 423.7 430">
                <StripedPattern/>
                <SelectedStripedPattern/>
                <StripedPatternLegend/>
                <g id="Layer_2" data-name="Layer 2" className="flat-car rotate">
                    <g id="sedan-exterior">
                        <path id="Body" className="panel-dark"
                              d="M310.46,214.28h37s32.38,1.41,47.63-14.56,15.25-80.27,0-96.22S347.5,89,347.5,89h-37a1,1,0,0,1-.05-.23h-6.24a2,2,0,0,0,.07.23l-73,0s-102,.3-103.16-.08-36.37,6.66-37.78,8.3S78.11,109.6,78.11,151.61s10.8,52.81,12.21,54.45,36.61,8.67,37.78,8.32,103.16-.09,103.16-.09h73l0,.08h6.22S310.45,214.31,310.46,214.28Z"/>
                        <path id="Spare_Tire" data-name="Spare Tire" className={`panel-dark${handlePanelStriping(30)}`}
                              onClick={() => handlePanelClick(30)}
                              d="M53.48,151.62a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,53.48,151.62ZM36.81,165.44a17,17,0,0,1-7.42,3.06c-.07-.2-.66-2-1-3.15a6.17,6.17,0,0,1-.35-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26C35.89,163.58,36.72,165.26,36.81,165.44Zm.78-5.26a6.19,6.19,0,0,1-2.25-1.76c-.85-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.19.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C40.37,161.59,38.7,160.76,37.59,160.18Zm-9.39-5H25.28l-2.07-2.06v-2.92l2.06-2.07H28.2l2.06,2.06v2.93ZM40.47,150a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.26-1.76c1.1-.59,2.79-1.42,3-1.51A17,17,0,0,1,43.63,149C43.43,149,41.66,149.63,40.47,150Zm-5.17-9.23A6,6,0,0,1,33.54,143c-.89.84-4,3.53-5.11,4.52-.1-1.5-.39-5.58-.42-6.81a6,6,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.42,3.07C36.72,138,35.89,139.66,35.3,140.76Zm-10.19-2.87a6,6,0,0,1,.36,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6,6,0,0,1-1.76-2.26c-.58-1.11-1.42-2.79-1.5-3a16.91,16.91,0,0,1,7.41-3.07C24.15,134.92,24.75,136.69,25.11,137.89Zm-9.23,5.17a6.14,6.14,0,0,1,2.27,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.38-6.81.42A6.17,6.17,0,0,1,13,150c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.41C13.1,141.64,14.78,142.47,15.88,143.06ZM13,153.24a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.31.31,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6.09,6.09,0,0,1-2.25,1.76c-1.11.58-2.78,1.41-3,1.5a17,17,0,0,1-3.07-7.41C10.06,154.2,11.82,153.61,13,153.24Zm5.17,9.23a6,6,0,0,1,1.76-2.25c.89-.85,4-3.54,5.11-4.52.11,1.5.38,5.58.42,6.81a6.19,6.19,0,0,1-.35,2.84c-.37,1.19-1,3-1,3.15a17,17,0,0,1-7.42-3.06C16.77,165.24,17.6,163.57,18.18,162.47Z"/>
                        <polygon id="Left_Front_Door" data-name="Left Front Door"
                                 className={`panel-dark${handlePanelStriping(10)}`} onClick={() => handlePanelClick(10)}
                                 points="303.56 27.55 235 27.55 235 71.42 314.95 71.69 303.56 27.55"/>
                        <path id="Left_Rear_Door" data-name="Left Rear Door"
                              className={`panel-dark${handlePanelStriping(14)}`} onClick={() => handlePanelClick(14)}
                              d="M169.16,53.83c11.89-5.75,16.66-18.77,17-26.28h48.05V71.42l-70.45-.23Z"/>
                        <path id="Left_Front_Fender" data-name="Left Front Fender"
                              className={`panel-dark${handlePanelStriping(7)}`} onClick={() => handlePanelClick(7)}
                              d="M311.85,27.55c.41,17.24,13.54,29.74,31.29,29.74,19,0,29.26-15.6,29.35-30.34h8.25c25.1,9.73,26,31.16,26.05,33.11-2.11.83-30.68,11.58-91,11.63L304.42,27.55Z"/>
                        <path id="Left_Rear_Qtr_Panel" data-name="Left Rear Qtr Panel"
                              className={`panel-dark${handlePanelStriping(17)}`} onClick={() => handlePanelClick(17)}
                              d="M87.7,48.81c0-12.63,6.3-20.7,37.59-21.86.11,23.62,19.62,29.87,30,29.87A32.34,32.34,0,0,0,168,54.38l-5.22,16.8-73.38-.24a113.93,113.93,0,0,0-.89-12.09,92.32,92.32,0,0,1-.77-10Z"/>
                        <path id="Left_Rocker" data-name="Left Rocker"
                              className={`panel-dark${handlePanelStriping(13)}`}
                              onClick={() => handlePanelClick(13)}
                              d="M186,26.76H312s-1.94-8.9-11.17-8.9-97.15-.8-102.83-.8c-9.42,0-12,9.7-12,9.7Z"/>
                        <path id="Left_Front_Wheel" data-name="Left Front Wheel"
                              className={`panel-dark${handlePanelStriping(8)}`} onClick={() => handlePanelClick(8)}
                              d="M368.87,26.74a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,368.87,26.74ZM352.2,40.56a17,17,0,0,1-7.42,3.07c-.06-.2-.66-2-1-3.16a6,6,0,0,1-.36-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.12,4.52a6.11,6.11,0,0,1,1.75,2.26C351.28,38.7,352.11,40.38,352.2,40.56ZM353,35.3a6.11,6.11,0,0,1-2.26-1.76c-.84-.89-3.53-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.19.36,3,1,3.16,1A17,17,0,0,1,356,36.81C355.76,36.71,354.09,35.88,353,35.3Zm-9.4-5h-2.92L338.6,28.2V25.28l2.06-2.06h2.92l2.06,2.06V28.2Zm12.27-5.15a6.17,6.17,0,0,1-2.84.35c-1.23,0-5.31-.31-6.81-.42,1-1.13,3.68-4.22,4.52-5.11A6,6,0,0,1,353,18.19c1.1-.6,2.79-1.43,3-1.51a16.92,16.92,0,0,1,3.07,7.4C358.82,24.15,357.05,24.75,355.86,25.12Zm-5.17-9.24a6,6,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.1-1.49-.38-5.58-.42-6.81a6.05,6.05,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C352.11,13.1,351.28,14.78,350.69,15.88ZM340.51,13a6.19,6.19,0,0,1,.35,2.84c0,1.23-.31,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6.11,6.11,0,0,1-1.75-2.26c-.59-1.1-1.43-2.79-1.51-3a17,17,0,0,1,7.41-3.07C339.54,10,340.14,11.82,340.51,13Zm-9.24,5.17a6.06,6.06,0,0,1,2.27,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.39-6.81.42a6.19,6.19,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C328.49,16.76,330.17,17.6,331.27,18.18ZM328.4,28.36a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.32.32,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6,6,0,0,1-2.25,1.76c-1.11.58-2.78,1.41-3,1.51a17.11,17.11,0,0,1-3.07-7.42C325.45,29.32,327.21,28.73,328.4,28.36Zm5.17,9.24a6.14,6.14,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.11,1.5.39,5.58.42,6.81a6.17,6.17,0,0,1-.35,2.84c-.37,1.19-1,3-1,3.16a17,17,0,0,1-7.42-3.07C332.16,40.37,333,38.7,333.57,37.6Z"/>
                        <path id="Left_Rear_Wheel" data-name="Left Rear Wheel"
                              className={`panel-dark${handlePanelStriping(19)}`} onClick={() => handlePanelClick(19)}
                              d="M182.21,26.74a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,182.21,26.74ZM165.54,40.56a17,17,0,0,1-7.42,3.07c-.06-.2-.66-2-1-3.16a6,6,0,0,1-.36-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.12,4.52A6.11,6.11,0,0,1,164,37.6C164.62,38.7,165.45,40.38,165.54,40.56Zm.79-5.26a6.11,6.11,0,0,1-2.26-1.76c-.84-.89-3.53-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.19.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C169.1,36.71,167.43,35.88,166.33,35.3Zm-9.4-5H154l-2.07-2.06V25.28L154,23.22h2.92L159,25.28V28.2Zm12.27-5.15a6.17,6.17,0,0,1-2.84.35c-1.23,0-5.31-.31-6.81-.42,1-1.13,3.68-4.22,4.52-5.11a6,6,0,0,1,2.26-1.75c1.11-.6,2.79-1.43,3-1.51a16.92,16.92,0,0,1,3.07,7.4C172.16,24.15,170.39,24.75,169.2,25.12ZM164,15.88a6,6,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.1-1.49-.38-5.58-.42-6.81A6.05,6.05,0,0,1,157.1,13c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C165.45,13.1,164.62,14.78,164,15.88ZM153.85,13a6.19,6.19,0,0,1,.35,2.84c0,1.23-.31,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6.11,6.11,0,0,1-1.75-2.26c-.59-1.1-1.43-2.79-1.51-3a17,17,0,0,1,7.41-3.07C152.88,10,153.48,11.82,153.85,13Zm-9.23,5.17a6.11,6.11,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.82.42a6.19,6.19,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17.11,17.11,0,0,1,3.07-7.42C141.83,16.76,143.51,17.6,144.62,18.18Zm-2.88,10.18a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.32.32,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6,6,0,0,1-2.25,1.76c-1.11.58-2.78,1.41-3,1.51a17.11,17.11,0,0,1-3.07-7.42C138.79,29.32,140.55,28.73,141.74,28.36Zm5.17,9.24a6.14,6.14,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.11,1.5.39,5.58.42,6.81a6.17,6.17,0,0,1-.35,2.84c-.37,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C145.5,40.37,146.33,38.7,146.91,37.6Z"/>
                        <path id="Right_Front_Fender" data-name="Right Front Fender"
                              className={`panel-dark${handlePanelStriping(31)}`} onClick={() => handlePanelClick(31)}
                              d="M315.81,230.72c60.3.05,88.87,10.79,91,11.62,0,2.09-1,23.41-26.12,33.13h-8.18c-.09-14.74-10.37-30.34-29.35-30.34-17.75,0-30.88,12.5-31.29,29.74h-7.43Z"/>
                        <polygon id="Right_Front_Door" data-name="Right Front Door"
                                 className={`panel-dark${handlePanelStriping(34)}`} onClick={() => handlePanelClick(34)}
                                 points="235.21 230.98 314.79 230.72 303.4 274.86 235.21 274.86 235.21 230.98"/>
                        <path id="Right_Rear_Door" data-name="Right Rear Door"
                              className={`panel-dark${handlePanelStriping(38)}`} onClick={() => handlePanelClick(38)}
                              d="M234.22,231v43.87H186.17c-.35-7.51-5.12-20.54-17-26.28l-5.39-17.36Z"/>
                        <path id="Right_Rear_Qtr_Panel" data-name="Right Rear Qtr Panel"
                              className={`panel-dark${handlePanelStriping(41)}`} onClick={() => handlePanelClick(41)}
                              d="M87.7,253.6a92.13,92.13,0,0,1,.77-10,114.12,114.12,0,0,0,.89-12.09l73.38-.25L168,248a32.34,32.34,0,0,0-12.72-2.44c-10.33,0-29.84,6.25-29.95,29.86C94,274.29,87.7,266.23,87.7,253.6Z"/>
                        <path id="Right_Rocker" data-name="Right Rocker"
                              className={`panel-dark${handlePanelStriping(37)}`}
                              onClick={() => handlePanelClick(37)}
                              d="M311.78,275.85c-.37,1.3-2.74,8.51-10.91,8.51-5.18,0-35,.25-61.33.47-20.93.17-39,.32-41.51.32-8.34,0-11.3-7.83-11.78-9.3Z"/>
                        <path id="Right_Front_Wheel" data-name="Right Front Wheel"
                              className={`panel-dark${handlePanelStriping(32)}`} onClick={() => handlePanelClick(32)}
                              d="M368.87,275.67a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,368.87,275.67ZM352.2,289.49a17,17,0,0,1-7.41,3.06c-.07-.19-.67-2-1-3.16a6,6,0,0,1-.36-2.83c0-1.23.32-5.32.42-6.81,1.14,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26C351.28,287.63,352.11,289.31,352.2,289.49Zm.79-5.27a6.11,6.11,0,0,1-2.26-1.75c-.84-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6.17,6.17,0,0,1,2.84.35c1.19.37,3,1,3.16,1a17,17,0,0,1-3.07,7.41C355.78,285.65,354.09,284.81,353,284.22Zm-9.4-5h-2.92l-2.06-2.07v-2.91l2.07-2.07h2.92l2.07,2.06v2.93ZM355.86,274a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.14,3.68-4.22,4.52-5.11a6.11,6.11,0,0,1,2.26-1.76c1.1-.58,2.77-1.42,3-1.51A17,17,0,0,1,359,273C358.83,273.07,357.05,273.68,355.86,274Zm-5.17-9.23a6.11,6.11,0,0,1-1.75,2.26c-.9.84-4,3.53-5.12,4.52-.1-1.5-.38-5.59-.42-6.81a6,6,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.42,3.07C352.11,262,351.28,263.71,350.69,264.81Zm-10.18-2.87a6.17,6.17,0,0,1,.35,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6.22,6.22,0,0,1-1.76-2.26c-.58-1.1-1.41-2.77-1.51-3a17,17,0,0,1,7.42-3.07C339.55,259,340.14,260.75,340.51,261.94Zm-9.23,5.17a6.08,6.08,0,0,1,2.25,1.75c.85.9,3.54,4,4.52,5.12-1.49.1-5.58.38-6.81.42a6,6,0,0,1-2.84-.36c-1.19-.36-2.95-1-3.16-1a17.12,17.12,0,0,1,3.07-7.41C328.5,265.69,330.17,266.52,331.28,267.11Zm-2.88,10.18a6.19,6.19,0,0,1,2.84-.35c1.23,0,5.32.31,6.81.42-1,1.13-3.67,4.22-4.51,5.11a6.28,6.28,0,0,1-2.27,1.76c-1.1.58-2.78,1.41-3,1.5a17,17,0,0,1-3.07-7.41C325.44,278.25,327.21,277.66,328.4,277.29Zm5.18,9.23a6.19,6.19,0,0,1,1.75-2.26c.89-.84,4-3.53,5.11-4.51.11,1.49.39,5.58.42,6.8a6.21,6.21,0,0,1-.35,2.85c-.37,1.19-1,3-1,3.15a16.94,16.94,0,0,1-7.41-3.06C332.15,289.32,333,287.63,333.58,286.52Z"/>
                        <path id="Right_Rear_Wheel" data-name="Right Rear Wheel"
                              className={`panel-dark${handlePanelStriping(43)}`} onClick={() => handlePanelClick(43)}
                              d="M182.21,275.67a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,182.21,275.67Zm-16.67,13.82a17,17,0,0,1-7.41,3.06c-.07-.19-.67-2-1-3.16a6,6,0,0,1-.36-2.83c0-1.23.32-5.32.42-6.81,1.14,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26C164.62,287.63,165.45,289.31,165.54,289.49Zm.79-5.27a6.11,6.11,0,0,1-2.26-1.75c-.84-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6.17,6.17,0,0,1,2.84.35c1.19.37,3,1,3.16,1a17,17,0,0,1-3.07,7.41C169.12,285.65,167.44,284.81,166.33,284.22Zm-9.4-5H154L152,277.12v-2.91l2.07-2.07h2.92L159,274.2v2.93ZM169.2,274a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.14,3.68-4.22,4.52-5.11a6.11,6.11,0,0,1,2.26-1.76c1.1-.58,2.77-1.42,3-1.51a17,17,0,0,1,3.07,7.41C172.17,273.07,170.39,273.68,169.2,274ZM164,264.81a6.11,6.11,0,0,1-1.75,2.26c-.9.84-4,3.53-5.12,4.52-.1-1.5-.38-5.59-.42-6.81a6,6,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.42,3.07C165.45,262,164.62,263.71,164,264.81Zm-10.18-2.87a6.17,6.17,0,0,1,.35,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6.22,6.22,0,0,1-1.76-2.26c-.58-1.1-1.41-2.77-1.5-3a17,17,0,0,1,7.41-3.07C152.89,259,153.48,260.75,153.85,261.94Zm-9.23,5.17a6.08,6.08,0,0,1,2.25,1.75c.85.9,3.54,4,4.52,5.12-1.49.1-5.58.38-6.81.42a6,6,0,0,1-2.84-.36c-1.19-.36-3-1-3.16-1a17.12,17.12,0,0,1,3.07-7.41C141.84,265.69,143.51,266.52,144.62,267.11Zm-2.88,10.18a6.19,6.19,0,0,1,2.84-.35c1.23,0,5.32.31,6.82.42-1,1.13-3.68,4.22-4.52,5.11a6.33,6.33,0,0,1-2.26,1.76c-1.11.58-2.79,1.41-3,1.5a17.12,17.12,0,0,1-3.07-7.41C138.78,278.25,140.55,277.66,141.74,277.29Zm5.18,9.23a6.19,6.19,0,0,1,1.75-2.26c.89-.84,4-3.53,5.11-4.51.11,1.49.39,5.58.42,6.8a6.21,6.21,0,0,1-.35,2.85c-.37,1.19-1,3-1,3.15a16.94,16.94,0,0,1-7.41-3.06C145.49,289.32,146.33,287.63,146.92,286.52Z"/>
                        <path id="Front_Bumper" data-name="Front Bumper"
                              className={`panel-dark${handlePanelStriping(2)}`}
                              onClick={() => handlePanelClick(2)}
                              d="M390,95s17.62,2.52,22.08,8,11.64,21.32,11.64,48.8-6.55,41.75-11.4,48.4-23.19,8.45-23.19,8.45a30.08,30.08,0,0,0,15.23-17.91l5.65-5a101.92,101.92,0,0,0,5.79-33.94A113.74,113.74,0,0,0,410,116.64l-5.39-4.15A30.79,30.79,0,0,0,390,95Z"/>
                        <path id="Rear_Bumper" data-name="Rear Bumper"
                              className={`panel-dark${handlePanelStriping(29)}`}
                              onClick={() => handlePanelClick(29)}
                              d="M85.34,208.65s-10.27-.52-14.74-6S61,179.29,61,151.81s4.55-42.75,9.4-49.4S85.57,95,85.57,95c-5.83,4.9-9.23,17.9-9.23,17.9H73.68s-4.79,19.67-4.79,38.94S73.72,191,73.72,191l3,.15S80.11,201.87,85.34,208.65Z"/>
                        <path id="Hood" className={`panel-dark${handlePanelStriping(5)}`}
                              onClick={() => handlePanelClick(5)}
                              d="M395.87,174c5.16-22.45,5.16-22.45,0-44.62-6.31-27.11-62-27.83-62-27.83s10.53,23.66,10.53,50.16-10.53,50.17-10.53,50.17S389.65,201.07,395.87,174Z"/>
                        <path id="Trunk_Lid" data-name="Trunk Lid"
                              className={`panel-dark${handlePanelStriping(getTrunkLidPanelNumber())}`}
                              onClick={() => handlePanelClick(getTrunkLidPanelNumber())}
                              d="M85.24,131.59c-1.95,15.64-2.08,23,0,39.86,0,0,9.89-.23,10.05,1.66,2.36,28.67,14.92,23.47,14.92,23.47s-5.8-22-5.8-45.28a195.75,195.75,0,0,1,5.8-44.86S98.73,101,94.93,129.73C94.66,131.73,85.24,131.59,85.24,131.59Z"/>
                        <path id="Left_Rear_Lights" data-name="Left Rear Lights"
                              className={`panel-white${handlePanelStriping(22)}`} onClick={() => handlePanelClick(22)}
                              d="M105,99.66c-10.51,2.77-11.15,28.82-11.15,28.82l-7.8.85S89,97.57,105,99.66Z"/>
                        <path id="Right_Rear_Lights" data-name="Right Rear Lights"
                              className={`panel-white${handlePanelStriping(21)}`} onClick={() => handlePanelClick(21)}
                              d="M86,173.14l7.8.85s.64,26,11.15,28.82C89,204.9,86,173.14,86,173.14Z"/>
                        <path id="Rear_Glass" data-name="Rear Glass" className={`panel-white${handlePanelStriping(28)}`}
                              onClick={() => handlePanelClick(28)}
                              d="M108.72,151.61c0-5.17.27-10.31.57-15.44A131.59,131.59,0,0,1,111.12,120c.26-1.45,2.57-13.93,4.43-13.88,0,0,33.31.94,50.69,9.15,0,0-2.35,11.74-2.59,36.38.24,24.65,2.59,36.38,2.59,36.38-17.38,8.21-50.69,9.15-50.69,9.15-1.86.05-4.17-12.41-4.43-13.88a131.43,131.43,0,0,1-1.83-16.2C109,161.93,108.76,156.78,108.72,151.61Z"/>
                        <path id="Left_Rear_Glass" data-name="Left Rear Glass"
                              className={`panel-white${handlePanelStriping(68)}`} onClick={() => handlePanelClick(68)}
                              d="M226.46,95.74A41.27,41.27,0,0,0,220.88,110c-6.39.18-12,.15-16.53-.11a160.15,160.15,0,0,1-29.27-4.62,46.56,46.56,0,0,1,4.83-8.56C193.13,96.14,209.6,95.85,226.46,95.74Z"/>
                        <path id="Left_Rear_Glass-2" data-name="Left Rear Glass"
                              className={`panel-white${handlePanelStriping(18)}`} onClick={() => handlePanelClick(18)}
                              d="M174.76,96.87a43.58,43.58,0,0,0-3.44,7.35,132.16,132.16,0,0,1-16.47-5.65A181.6,181.6,0,0,1,174.76,96.87Z"/>
                        <path id="Right_Rear_Glass-2" data-name="Right Rear Glass"
                              className={`panel-white${handlePanelStriping(42)}`} onClick={() => handlePanelClick(42)}
                              d="M154.85,204.65A132.16,132.16,0,0,1,171.32,199a44.52,44.52,0,0,0,3.42,7.35A181.12,181.12,0,0,1,154.85,204.65Z"/>
                        <path id="Left_Front_Glass" data-name="Left Front Glass"
                              className={`panel-white${handlePanelStriping(65)}`} onClick={() => handlePanelClick(65)}
                              d="M315.58,96.46c-6.79,5.73-51.56,11.45-84.86,13.12,2.59-7.15,5.79-11.42,8.38-13.89C278.37,95.59,315.88,96.21,315.58,96.46Z"/>
                        <path id="Right_Rear_Glass" data-name="Right Rear Glass"
                              className={`panel-white${handlePanelStriping(67)}`} onClick={() => handlePanelClick(67)}
                              d="M179.91,206.58a46.16,46.16,0,0,1-4.83-8.58,160.15,160.15,0,0,1,29.27-4.62c4.48-.26,10.14-.29,16.53-.11a41.51,41.51,0,0,0,5.58,14.22C209.6,207.37,193.14,207.1,179.91,206.58Z"/>
                        <path id="Right_Front_Glass" data-name="Right Front Glass"
                              className={`panel-white${handlePanelStriping(66)}`} onClick={() => handlePanelClick(66)}
                              d="M239.09,207.55c-2.59-2.51-5.79-6.77-8.37-13.91,33.29,1.69,78.06,7.39,84.86,13.12C315.88,207,278.37,207.65,239.09,207.55Z"/>
                        <path id="Windshield" className={`panel-white${handlePanelStriping(1)}`}
                              onClick={() => handlePanelClick(1)}
                              d="M325.68,201.83c-11.75,2.82-54.68-11-54.68-11,8-17.6,7.51-39.2,7.51-39.2S279,130,271,112.41c0,0,42.93-13.84,54.68-11,0,0,10.78,11.28,12,50.22C336.46,190.55,325.68,201.83,325.68,201.83Z"/>
                        <path id="Left_Headlight" data-name="Left Headlight"
                              className={`panel-white${handlePanelStriping(3)}`} onClick={() => handlePanelClick(3)}
                              d="M398.24,122.33c-6.91-18.42-32.1-18.07-32.1-18.07l-2.85-9.42S398.24,100.81,398.24,122.33Z"/>
                        <path id="Right_Headlight" data-name="Right Headlight"
                              className={`panel-white${handlePanelStriping(4)}`} onClick={() => handlePanelClick(4)}
                              d="M366.14,198.46s25.19.37,32.1-18.05c0,21.52-34.95,27.49-34.95,27.49Z"/>
                        <path id="Left_Front_Mirror" data-name="Left Front Mirror"
                              className={`panel-dark${handlePanelStriping(11)}`} onClick={() => handlePanelClick(11)}
                              d="M311.63,84.06c-2.19-5.4-4.17-10.39-11-9.62,0,0,1.72,8.73,3.56,14.29h6.24C310.11,87.17,312.24,85.6,311.63,84.06Z"/>
                        <path id="Right_Front_Mirror" data-name="Right Front Mirror"
                              className={`panel-dark${handlePanelStriping(35)}`} onClick={() => handlePanelClick(35)}
                              d="M311.63,219.18c.63-1.59-1.65-3.2-1.19-4.82h-6.22c-1.85,5.55-3.6,14.43-3.6,14.43C307.46,229.57,309.44,224.57,311.63,219.18Z"/>
                        <path id="Roof" className={`panel-dark${handlePanelStriping(6)}`}
                              onClick={() => handlePanelClick(6)}
                              d="M169.29,151.73c0-21.9,4.35-34.26,5.31-35.22a5.32,5.32,0,0,1,3.78-1.57s23.16,2.6,41.16,2.6,41.16-2.6,41.16-2.6a5.32,5.32,0,0,1,3.78,1.57c1,1,6.52,13.32,6.52,35.22S265.44,186,264.48,187a5.31,5.31,0,0,1-3.78,1.56s-25.41-2.59-41.16-2.59-41.16,2.59-41.16,2.59A5.31,5.31,0,0,1,174.6,187C173.64,186,169.29,173.64,169.29,151.73Z"/>

                    </g>
                </g>
                {isSuggestedDamagesPresent() &&
                    <>
                        <rect id="exterior-legend" x="0" y="350" width="5%" height="5%" fill="url(#suggested-legend)"></rect>
                        <text id="exterior-legend-text" x="28" y="366" fill="var(--charcoal)">Suggested Damage</text>
                    </>
                }
            </svg>
        </>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay, designatedDescription, suggestedDamages}) {
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel, designatedDescription, suggestedDamages};
}

export default connect(mapStateToProps, matchDispatchToProps)(SedanExterior)