import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Api from "../../Api";
import {Typeahead} from "react-bootstrap-typeahead";

const Make = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onMakeChange,
        globalDisplay
    } = props

    const {
        vehicleTypeId,
        vehicleSubTypeId
    } = globalDisplay

    const [options, setOptions] = useState([]);
    const make = designatedDescription?.make;

    const handleChange = (options) => {
        onMakeChange(options[0])
    }


    useEffect( () => {
        if(vehicleTypeId) {
            async function fetchData() {
                return await Api.getMakes({vehicleTypeId, vehicleSubTypeId});
            }
            fetchData().then(r => setOptions(r.items));
        }

    }, [vehicleTypeId, vehicleSubTypeId]);

    return(
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-make" label="Make">
                    {make}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-make" label="Make" required={true}>
                    <Typeahead
                        disabled={!vehicleTypeId || props.disabled}
                        id="make-dropdown"
                        placeholder={PLEASE_SELECT}
                        onChange={handleChange}
                        options={options? options : []}
                        labelKey="name"
                        clearButton
                        selected={options?.filter(item => item.name === make)}
                        emptyLabel={'Loading...'}
                    />
                </DataPair>
            </Form.Group>

    )

}

export default Make;