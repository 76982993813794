import Col from "@prism/col";
import Row from "@prism/row";
import React from "react";
import Section from "../../../common/Section";

const TrimFinderTrimList = (props) => {
    const {
        vehicleTrims,
        onTrimChange
    } = props

    return(
        <Section heading="Trims:" containerClass={"mx-2"} containerHeadingClass={"text-secondary-dark"}>
            <Col id={'trim-finder-list'}>
                {vehicleTrims?.map(trim => (
                    !trim.filtered &&
                    <Row className={"trim-finder-trim-row"} onClick={() => onTrimChange({target: {value: trim.id}})}>
                        <span>{trim.name}</span>
                    </Row>
                ))}
            </Col>
        </Section>
    )
}

export default TrimFinderTrimList;