import React from "react";
import {connect} from "react-redux";
import MessageWrapper from "./MessageWrapper";

const MessageCenter = (props) => {
        const {statusMessages} = props;
        const displayMessages = props.messages || statusMessages;

        return (<div id='message-center'>
                {displayMessages.map(message => {
                    return <MessageWrapper key={message.text} {...message}/>
                })}
            </div>
        )
};

function mapStateToProps({ statusMessages }) {
    return { statusMessages };
}

export default connect(mapStateToProps, null)(MessageCenter);
