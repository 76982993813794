import React from "react";
import Button from "@prism/button";
import Icon from "@prism/icon";
import { connect } from "react-redux";
import { isNativeApp } from "../../../../utils/utils";

const capturePhoto = (props) => {
  const { damageKey, auctionCode, category, title, workOrderNumber, vin } = props;

  if (isNativeApp()) {
    console.log(
      "capture photo with",
      auctionCode,
      workOrderNumber,
      damageKey,
      category
    );
    window.NativeApp.capturePhoto(
      workOrderNumber,
      vin,
      damageKey,
      category,
      title
    );
  } else {
    console.log(
      "camera not available in web view",
      auctionCode,
      workOrderNumber,
      damageKey
    );
  }
};

const TakePhotoButton = (props) => {
  const { auctionCode, workOrderNumber } = props.workOrderInfo;
  const {vin} = props.designatedDescription

  return props.hasPhoto ? (
      <Button
          className="w-100 retake-photo"
          color="secondary"
          disabled={props.disabled}
          id={"retake-photo-" + props.damageKey}
          onClick={() =>
              capturePhoto({
                  auctionCode: auctionCode,
                  workOrderNumber: workOrderNumber,
                  damageKey: props.damageKey,
                  vin: vin,
                  category: "DAMAGE",
                  title: props.title
              })
          }
      >
          <Icon glyph="camera" className="large-button-icon mr-2" />
          RETAKE PHOTO
      </Button>
  ) : (
    <Button
      className="w-100 take-photo"
      color="secondary"
      disabled={props.disabled}
      onClick={() =>
        capturePhoto({
          auctionCode: auctionCode,
          workOrderNumber: workOrderNumber,
          damageKey: props.damageKey,
          vin: vin,
          category: "DAMAGE",
          title: props.title
        })
      }
    >
      <Icon glyph="camera" className="large-button-icon" />
    </Button>
  );
};

function mapStateToProps({ workOrderInfo, designatedDescription }) {
  return { workOrderInfo, designatedDescription };
}

export default connect(mapStateToProps)(TakePhotoButton);
