import Col from "@prism/col";
import DataPair from "../../../../common/DataPair";
import Dropdown from "../../../../common/Dropdown";
import React from "react";
import {PLEASE_SELECT} from "../../../../../utils/constants";

const SelectQuestion = (props) => {
    let labelText = props.question.question.slice(0, props.question.question.indexOf('*') === -1 ? props.question.question.length : props.question.question.indexOf('*'));
    let requiredFlag = props.required ? '*' : null

    return (
        <Col className="my-2" id={props.question.id}>
            <DataPair
                label={<div>{labelText}{requiredFlag && <span className="text-danger">{requiredFlag}</span>}</div>}
                id={props.question.question}>
                <Dropdown
                    name={props.question.question}
                    value={props.question.answer}
                    onChange={props.onChange}
                    validationMessage="Required"
                    invalid={props.question.invalid}
                    required={props.required}>
                    {(!props.question.answer && props.required) ?
                        <option value='' disabled>{PLEASE_SELECT}</option>
                        :
                        (!props.required) && <option value=''>{PLEASE_SELECT}</option>
                    }
                    {props.question.answers.map(answer => {
                        return <option key={answer}>{answer}</option>
                    })}
                </Dropdown>
            </DataPair>
        </Col>
    )
}

export default SelectQuestion;
