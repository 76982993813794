import React from "react";
import {connect} from "react-redux";
import {VEHICLE_SUB_TYPE_FLAT_CAR_MAPPING} from "../../../../utils/constants";

import Minivan from "./Minivan";
import Sedan from "./Sedan";
import SUV from "./SUV";
import TruckKingCab from "./TruckKingCab";

const FlatCar = (props) => {

    const flatCarTypeId = () => {
        let flatCarTypeId = props.flatCarTypeId ? props.flatCarTypeId : VEHICLE_SUB_TYPE_FLAT_CAR_MAPPING[props.designatedDescription.bodyDescription?.vehicleType];
        return !flatCarTypeId ? 1 : flatCarTypeId;
    }

    switch (flatCarTypeId()) {
        case 1:
            return <Sedan clearSelectedItem={props.clearSelectedItem}/>;
        case 2:
            return <TruckKingCab clearSelectedItem={props.clearSelectedItem}/>;
        case 3:
            return <Minivan clearSelectedItem={props.clearSelectedItem}/>;
        case 4:
            return <SUV clearSelectedItem={props.clearSelectedItem}/>;
        default:
            return <Sedan clearSelectedItem={props.clearSelectedItem}/>;
    }

};

function mapStateToProps({condition, designatedDescription}) {
    const {flatCarTypeId} = condition;
    return {flatCarTypeId, designatedDescription};
}

export default connect(mapStateToProps)(FlatCar);