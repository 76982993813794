import Form from "@prism/form";
import React from "react";
import {INTERIOR} from "../../../utils/constants";
import Input from "@prism/input";
import ColorDropdown from "./ColorDropdown";

const InteriorColor = (props) => {
    const {designatedDescription, designatedDescriptionResponse, onInteriorColorChange, taxonomyData} = props

    return (
        <Form.Group>
            <Input.Label>
                <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>Interior Color
            </Input.Label>
            <ColorDropdown
                id={"configure-interior-color"}
                onChange={onInteriorColorChange}
                colorType={INTERIOR}
                taxonomyData={taxonomyData}
                designatedDescription={designatedDescription}
                designatedDescriptionResponse={designatedDescriptionResponse}
            />
        </Form.Group>
    )

}

export default InteriorColor;