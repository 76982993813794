import React, {useEffect, useState} from 'react';
import FinishButton from "./FinishButton";
import AccessoriesReview from "./AccessoriesReview";
import TiresReview from "./TiresReview";
import DamageReview from "./DamageReview";
import ConditionReview from "./ConditionReview";
import AnnouncementsReview from "./AnnouncementsReview";
import AnnouncementsHeader from "./AnnouncementsHeader";
import ConfigureReview from "./ConfigureReview";
import {connect} from "react-redux";
import SpinnerOverlay from "../../Spinner/SpinnerOverlay";

const ReviewPanel = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    })

    const [configurePanelIsValid, setConfigurePanelIsValid] = useState(false);
    const [accessoriesPanelIsValid, setAccessoriesPanelIsValid] = useState(false);
    const [tiresPanelIsValid, setTiresPanelIsValid] = useState(true);
    const [conditionPanelIsValid, setConditionPanelIsValid] = useState(false);
    const [damagePanelIsValid, setDamagePanelIsValid] = useState(false);

    const updateConfigureIsValid = (isValid) => {
        setConfigurePanelIsValid(isValid);
    };

    const updateAccessoriesIsValid = (isValid) => {
        setAccessoriesPanelIsValid(isValid);
    };

    const updateConditionIsValid = (isValid) => {
        setConditionPanelIsValid(isValid)
    }

    const updateTiresIsValid = (isValid) => {
        setTiresPanelIsValid(isValid);
    };
    const updateDamageIsValid = (isValid) => {
        setDamagePanelIsValid(isValid)
    }

    const allSectionsValid = () => {
        return configurePanelIsValid && accessoriesPanelIsValid && tiresPanelIsValid && conditionPanelIsValid && damagePanelIsValid
    };

    return (
        <div id="review-panel" className="bg-white px-0">
            {props.progress.submitInProgress ?
                <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/> : null}
            <ConfigureReview configurePanelIsValid={updateConfigureIsValid}></ConfigureReview>
            <AccessoriesReview accessoriesPanelIsValid={updateAccessoriesIsValid}/>
            <TiresReview tiresPanelIsValid={updateTiresIsValid}/>
            <DamageReview damagePanelIsValid={updateDamageIsValid}/>
            <ConditionReview conditionPanelIsValid={updateConditionIsValid}/>
            <AnnouncementsHeader/>
            <AnnouncementsReview/>
            <FinishButton disabled={!allSectionsValid()}/>
        </div>
    )
};

function mapStateToProps(
    {
        progress
    }
) {
    return {progress};
}

export default connect(mapStateToProps)(ReviewPanel);