import React from 'react';
import {connect} from "react-redux";

import Col from '@prism/col';
import Container from '@prism/container';
import Row from '@prism/row';
import Button from '@prism/button';

import {getUserDetails} from "../../actions/userDetailsActions";
import {getWorkList} from "../../actions/workListActions";
import WorkListItem from "./WorkListItem";
import Spinner from "../common/Spinner/Spinner";
import '../../styles/work-list.css';

class WorkList extends React.Component {

    componentDidMount() {
        if (!this.props.userDetails.userId) {
            console.debug("work list mounted, getting user details");
            this.props.getUserDetails();
        } else {
            this.updateWorkList();
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.userDetails.userId && !prevProps.userDetails.userId) {
            console.debug("found username, getting work list", this.props.userDetails.userId );
            this.updateWorkList();
        }
    }

    updateWorkList = () => {
        console.debug("updating work list for", this.props.userDetails.userId);
        this.props.getWorkList({ userId: this.props.userDetails.userId });
    };

    incompleteWorkListRender = () => {
        if(!this.props.incompleteWorkList) {
            console.info("No incomplete work list data to render");
            return null;
        }
        console.debug("Found incomplete work list data:", typeof(this.props.incompleteWorkList), this.props.incompleteWorkList);
        return this.props.incompleteWorkList.map((item) => {
            return (<WorkListItem key={item.workOrder} incompleteWorkItem={item} submitScan={this.props.submitScan}/>);
        });
    }

    handleSubmitScan = async (locationCode, workOrderNumber) => {
        await this.props.checkAndHandleExistingWorkOrder(workOrderNumber);
        this.props.submitScan(locationCode, workOrderNumber);
     };

    workListRender = () => {
        if(!this.props.workList.href) {
            console.info("No work list data to render");
            return null;
        }
        console.debug("Found work list data:", typeof(this.props.workList), this.props.workList);
        console.log(this.props.workList);
        let obj = {};
        let count = 0;
        return this.props.workList.items
            .filter((item) => {
                if(!(item.vehicle.workOrderNumber in obj) && count < 25){
                   obj[item.vehicle.workOrderNumber] = 1;
                   count++;
                   return item;
                } else {
                    return null;
                }
            }).sort((a, b) => {
               return (this.status(a) - this.status(b));
            })
            .map((item) => {
            return (<WorkListItem key={item.vehicle.workOrderNumber} stagedInspection={item} submitScan={this.handleSubmitScan}/>);
        });
    };

    status = (workOrder) => {
        switch(this.statusName(workOrder)) {
            case 'failed':
                return -5;
            case 'invalid':
                return -5;
            case 'success':
                return 5;
            default:
                return 0;
        }
    };

    statusName = (workOrder) => {
        if(!workOrder.status) {
            return 'unknown';
        }
        return (workOrder.status.overAllStatus || '').toLowerCase();
    };

    render() {
        console.log("LIST PROPS", this.props);
        return (<Container id="work-list">
            {this.props.incompleteWorkList?.length > 0 &&
                <>
                <Row className="work-list-item-header mb-2 pb-3">
                    <Col className="mb-1 prism-h2 text-smoke section-name flex-grow-1 pl-0">
                        Incomplete
                    </Col>
                </Row>
                <div id='work-list-items'>{this.incompleteWorkListRender()}</div>
                </>
            }
            <Row className="work-list-item-header mb-2 pb-3">
                <Col className="mb-1 prism-h2 text-smoke section-name flex-grow-1 pl-0">
                    Recent
                </Col>
                <Col className="flex-grow-0 pr-0">
                    <Button className="input-group-text pr-4 pl-4 m-0"
                            color="secondary"
                            size="sm"
                            onClick={this.updateWorkList}>REFRESH
                        {this.props.workList.isFetching ?
                            <div style={{width: '20px', height: '20px'}} className="pl-2" id="work-list-spinner">
                                <Spinner size={20}/>
                            </div>
                            : null}
                    </Button>
                </Col>
            </Row>
            <div id='work-list-items'>{this.workListRender()}</div>
        </Container>)
    }
}

const matchDispatchToProps = {
    getUserDetails,
    getWorkList
};

function mapStateToProps({ userDetails, workList }) {
    return { userDetails, workList };
}

export default connect(mapStateToProps, matchDispatchToProps, null, {forwardRef: true})(WorkList);