import React from "react";
import Button from '@prism/button';
import {showAddDamageView, showAddEditDamage} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";
import {ENABLE_ADD_DAMAGES} from "../../../config/env";

const AddDamageButton = (props) => {
    return (
        <Button color="secondary"
                id="add-new-damage"
                // this is a deprecated way of defining the variant are we running old prism?
                // https://ghe.coxautoinc.com/Prism/prism/blob/14ccbde5441a309bcf4b25469ca0b09315689f71/src/components/Button/Button.jsx#L29
                floatingAction
                className="m-2"
                disabled={props.disabled}
                onClick={() => ENABLE_ADD_DAMAGES === 'true' ? props.showAddDamageView() : props.showAddEditDamage()}
        >
            <i className="icon prism-icon-plus"/>
        </Button>
    )
};

const matchDispatchToProps = {
    showAddDamageView,
    showAddEditDamage
};

export default connect(null, matchDispatchToProps)(AddDamageButton);