import {LOADING_WORK_ORDER, LOADING_WORK_ORDER_COMPLETE} from "./dispatchTypes";

export const loadingWorkOrder = () => dispatch => {
    dispatch({
        type: LOADING_WORK_ORDER
    })
};

export const loadingWorkOrderComplete = () => dispatch => {
    dispatch({
        type: LOADING_WORK_ORDER_COMPLETE
    })
};
