import {
    ADD_MISCELLANEOUS_IMAGE, DELETE_MISCELLANEOUS_IMAGE
} from './dispatchTypes';

export const addMiscellaneousImage = (imageTag, imageHref) => dispatch => {
    dispatch({
        type: ADD_MISCELLANEOUS_IMAGE,
        payload: {key: imageTag, url: imageHref}
    })
};

export const deleteMiscellaneousImage = (imageTag) => dispatch => {
    dispatch({
        type: DELETE_MISCELLANEOUS_IMAGE,
        payload: {key: imageTag}
    })
};