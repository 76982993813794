import React from 'react';

import '../../styles/outline-panel.css';

export function OutlinePanel({className, id, children}) {

    return(
        <div className={"panel--outline " + className} id={id}>
            {children}
        </div>
    )

}

export function PanelHead({className, id, children}, props) {
    return (
        <div className={"panel-head " + className}  id={id} {...props}>
            {children}
        </div>
    )
}

export default OutlinePanel;