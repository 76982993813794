import React from "react";
import {connect} from "react-redux";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updateRemoteCount} from "../../../actions/conditionActions";
import Col from "@prism/col";


class Remote extends React.Component {

    handleChange = (e) => {
        const quantity = e.currentTarget.value;
        this.props.updateRemoteCount(quantity);
    }

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        const remoteOptions = Array.from(Array(3).keys());
        const quantity = this.props.condition && this.props.condition.entertainmentSystemRemote !== undefined && this.props.condition.entertainmentSystemRemote !== null ? this.props.condition.entertainmentSystemRemote : null;

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                <DataPair label="Remotes">
                    <Dropdown id="remotes" key={`remote${quantity}`} value={[quantity === null ? "N/A" : quantity.toString()]} onChange={this.handleChange}>
                        <option value={""}>N/A</option>
                        {remoteOptions.map(option => <option id={`remote_opt_${option.toString()}`} key={`remote_opt_${option.toString()}`} value={option.toString()}>{option.toString()}</option>)}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateRemoteCount
};


function mapStateToProps({condition}) {
    return {condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(Remote);