import {
    ADD_AUTOMATED_DAMAGE,
    ADD_DAMAGE,
    DELETE_ANNOUNCEMENT_BY_CODE,
    DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE,
    DELETE_AUTOMATED_DAMAGE,
    DELETE_DAMAGE,
    SET_CONDITION,
    SET_CONDITION_ODOMETER_FROM_UNIT,
    UPDATE_CHARGE_PERCENTAGE,
    UPDATE_CHARGING_CABLE,
    UPDATE_CONDITION,
    UPDATE_CURRENT_RANGE,
    UPDATE_DAMAGE,
    UPDATE_DOCUMENTATION,
    UPDATE_DRIVABLE,
    UPDATE_EMISSIONS,
    UPDATE_ENGINE_STARTS,
    UPDATE_FUEL_LEVEL,
    UPDATE_FUEL_LEVEL_DAMAGE,
    UPDATE_HEADSET_COUNT,
    UPDATE_INTERIOR_ODOR,
    UPDATE_KEY_VALUE,
    UPDATE_LAST_SERVICE_DATE,
    UPDATE_LAST_SERVICE_ODO,
    UPDATE_ODOMETER_OPERABLE,
    UPDATE_PAINT_TYPE,
    UPDATE_REMOTE_COUNT,
    UPDATE_SERVICE_DUE,
    UPDATE_SERVICE_EXCEEDED,
    UPDATE_SERVICE_NOTES,
    UPDATE_SERVICE_REQUIRED,
    UPDATE_TIRE
} from "./dispatchTypes";
import {STRUCTURAL_DAMAGE_ITEMS} from "../utils/structuralDamageItems";
import {CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE} from "../utils/constants";
import {ODOMETER_CHECK} from "../utils/constantsDamages";
import {logObject} from "./loggingActions";

export const setConditionOdometerFromUnit = () => async (dispatch, getState) => {
    let unit = getState().unit;
    dispatch({
        type: SET_CONDITION_ODOMETER_FROM_UNIT,
        payload: {
            odometer: unit.odometer
        }
    })
}

export const setCondition = (condition) => async (dispatch, getState) => {
    let unit = getState().unit;
    dispatch({
        type: SET_CONDITION,
        payload: {
            unit: unit,
            condition: condition,
        }
    })
}

export const updateCondition = (condition) => (dispatch) => {
    dispatch({
        type: UPDATE_CONDITION,
        payload: condition
    });
};

export const updateKeyValue = (key) => (dispatch) => {

    dispatch({
        type: UPDATE_KEY_VALUE,
        payload: key
    })
};

export const updateHeadsetCount = (newCount) => (dispatch) => {
    dispatch({
        type: UPDATE_HEADSET_COUNT,
        payload: newCount
    })
};

export const updateServiceRequired = (isServiceRequired) => (dispatch) => {
    dispatch({
        type: UPDATE_SERVICE_REQUIRED,
        payload: isServiceRequired
    })
};

export const updateFuelLevel = (fuelLevel) => (dispatch) => {
    dispatch({
        type: UPDATE_FUEL_LEVEL,
        payload: fuelLevel
    })
};

export const updateFuelLevelDamage = (fuelLevelDamage) => (dispatch) => {
    dispatch({
        type: UPDATE_FUEL_LEVEL_DAMAGE,
        payload: fuelLevelDamage
    })
}

export const updateRemoteCount = (newCount) => (dispatch) => {
    dispatch({
        type: UPDATE_REMOTE_COUNT,
        payload: newCount
    })
};

export const updateDocumentation = (docType, value) => (dispatch) => {
    dispatch({
        type: UPDATE_DOCUMENTATION,
        payload: {[docType]: value}
    })
};

export const updatePaintType = (value) => (dispatch) => {
    dispatch({
        type: UPDATE_PAINT_TYPE,
        payload: value
    })
};

export const updateChargingCable = (value) => (dispatch) => {
    dispatch({
        type: UPDATE_CHARGING_CABLE,
        payload: value
    })
};

export const updateTire = (tire) => (dispatch) => {
    dispatch({
        type: UPDATE_TIRE,
        payload: tire
    })
};

function numberOfStructuralDamages(damages) {
    let structuralDamageItems = damages?.filter(dmg => {
        const longDamageKey = `${dmg.itemCode}-${dmg.subItemCode}-${dmg.damageCode}-${dmg.severityCode}`;
        return STRUCTURAL_DAMAGE_ITEMS.includes(
            longDamageKey
        )
    });
    return structuralDamageItems?.length;
}

function hasStructuralDamage(damages) {
    return numberOfStructuralDamages(damages) > 0;
}

function updatedDamageUsedToBeStructural(damage, damages, originalDamageKey) {
    // find the damage on the Condition that matches the damage being edited and see if it used to be structural damage before it was edited
    if (!!damage.damageKeyAtWorkOrderLoad) {
        const conditionDamage = damages?.find(d => d.damageKeyAtWorkOrderLoad === damage.damageKeyAtWorkOrderLoad);
        if (conditionDamage) {
            const damageKey = `${conditionDamage.itemCode}-${conditionDamage.subItemCode}-${conditionDamage.damageCode}-${conditionDamage.severityCode}`;
            return STRUCTURAL_DAMAGE_ITEMS.includes(damageKey);
        }
    } else {
        const originalDamage = damages?.find(d => d.damageKey === originalDamageKey);
        let formattedKey;
        if (!!originalDamage) {
            formattedKey = `${originalDamageKey.slice(3)}-${originalDamage.severityCode}`
        }
        return STRUCTURAL_DAMAGE_ITEMS.includes(formattedKey);
    }
    return false;
}

function isStructural(damage) {
    const damageKey = `${damage.itemCode}-${damage.subItemCode}-${damage.damageCode}-${damage.severityCode}`;
    return STRUCTURAL_DAMAGE_ITEMS.includes(damageKey);
}

export const deleteDamage = (damage, condition) => (dispatch) => {
    dispatch({
        type: DELETE_DAMAGE,
        payload: damage
    });

    if (condition) {
        const damages = condition?.damages?.filter(d => d.damageKey !== damage.damageKey);
        // Is the damage to be deleted structural? If not, don't even do the delete announcement logic
        if (isStructural(damage)) {
            //If the last structural damage has been deleted, we delete the announcement
            if (!hasStructuralDamage(damages)) {
                logObject('conditionActions', 'deleteDamage', 'damage', damage)
                logObject('conditionActions', 'deleteDamage', 'condition', condition)
                dispatch({
                    type: DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE,
                    payload: damage
                });
            }
        }
        // Odometer Check adds an announcement when manually created, so we need to delete that here
        let hasOdometerDamageItem = (damages?.filter(damage => {
            return (damage.itemCode === ODOMETER_CHECK.itemCode && damage.subItemCode === ODOMETER_CHECK.subItemCode);
        }));

        if (!hasOdometerDamageItem) {
            dispatch({
                type: DELETE_ANNOUNCEMENT_BY_CODE,
                payload: {type: CONDITION_ANNOUNCEMENT, code: ODOMETER_INOP_ANNOUNCEMENT_CODE}
            })
        }
    }
};

export const deleteAutoDamage = (damage) => (dispatch) => {
    dispatch({
        type: DELETE_AUTOMATED_DAMAGE,
        payload: damage
    });
}

export const addDamage = (damage) => (dispatch) => {
    dispatch({
        type: ADD_DAMAGE,
        payload: damage
    })
};

export const addAutoDamage = (damage) => (dispatch) => {
    dispatch({
        type: ADD_AUTOMATED_DAMAGE,
        payload: damage
    })
}

export const updateDamage = (damage, condition, originalDamageKey) => (dispatch) => {
    dispatch({
        type: UPDATE_DAMAGE,
        payload: damage
    })
    // if the updated damage is not structural, determine if there is a structural damage announcement that needs to be deleted
    if (!isStructural(damage)) {
        const damages = condition?.damages
        if (updatedDamageUsedToBeStructural(damage, damages, originalDamageKey) && numberOfStructuralDamages(damages) === 1) {
            dispatch({
                type: DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE,
                payload: damage
            });
        }
    }
};

export const updateInteriorOdor = (newOdor) => (dispatch) => {
    dispatch({
        type: UPDATE_INTERIOR_ODOR,
        payload: newOdor
    })
};

export const updateEmissions = (newEmissions) => (dispatch) => {
    dispatch({
        type: UPDATE_EMISSIONS,
        payload: newEmissions
    })
};


export const updateLastServiceDate = (lastServiceDate) => (dispatch) => {
    dispatch({
        type: UPDATE_LAST_SERVICE_DATE,
        payload: lastServiceDate
    })
}

export const updateLastServiceOdo = (lastServiceOdo) => (dispatch) => {
    dispatch({
        type: UPDATE_LAST_SERVICE_ODO,
        payload: lastServiceOdo
    })
}
export const updateServiceDue = (serviceDue) => (dispatch) => {
    dispatch({
        type: UPDATE_SERVICE_DUE,
        payload: serviceDue
    })
}

export const updateDrivable = (drivable) => (dispatch) => {
    dispatch({
        type: UPDATE_DRIVABLE,
        payload: drivable
    })
}
export const updateEngineStarts = (engineStarts) => (dispatch) => {
    dispatch({
        type: UPDATE_ENGINE_STARTS,
        payload: engineStarts
    })
}

export const updateServiceNotes = (serviceNotes) => (dispatch) => {
    dispatch({
        type: UPDATE_SERVICE_NOTES,
        payload: serviceNotes
    })
}

export const updateServiceExceeded = (serviceExceeded) => (dispatch) => {
    dispatch({
        type: UPDATE_SERVICE_EXCEEDED,
        payload: serviceExceeded
    })

}

export const updateOdometerOperable = (isOperable) => (dispatch) => {
    dispatch({
        type: UPDATE_ODOMETER_OPERABLE,
        payload: isOperable
    })
};

export const updateChargePercentage = (chargePercentage) => (dispatch) => {
    dispatch({
        type: UPDATE_CHARGE_PERCENTAGE,
        payload: chargePercentage
    })

}

export const updateCurrentRange = (currentRange) => (dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_RANGE,
        payload: currentRange
    })

}

export function calculateDamageKey(damage) {
    return `L1-${damage.itemCode}-${damage.subItemCode}-${damage.damageCode}`;
}

