export function formatDollarsAndCents(number) {
    number = number || 0;
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatDollars(number) {
    number = number || 0;
    return number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

export function zeroPad(num, numZeros) {
    var an = Math.abs (num);
    var digitCount = 1 + Math.floor (Math.log (an) / Math.LN10);
    if (digitCount >= numZeros) {
        return num;
    }
    var zeroString = Math.pow (10, numZeros - digitCount).toString ().substr (1);
    return num < 0 ? '-' + zeroString + an : zeroString + an;
}

export function formatDateToUTC(currentDate) {
    const date = `${currentDate.getUTCFullYear()}-${("0"+(currentDate.getUTCMonth()+1)).slice(-2)}-${("0"+currentDate.getUTCDate()).slice(-2)}`;
    const time = `${("0"+currentDate.getUTCHours()).slice(-2)}:${("0"+currentDate.getUTCMinutes()).slice(-2)}:${("0"+currentDate.getUTCSeconds()).slice(-2)}.${currentDate.getUTCMilliseconds()}`;
    return `${date}T${time}Z`;
}

export function formatNumber(number) {
    number = number || 0;
    return number.toLocaleString('en-US');
}

export function userFriendlyFormat(date) {
    const dateFormat = date.substring(5,7) + '/'+ date.substring(8,10) + '/' + date.substring(2,4)
    const hours = parseInt(date.substring(11,13))

    if (hours > 12)
        return dateFormat + ' — ' + (hours - 12) + ":" + date.substring(14,16) + " pm"

    return dateFormat + ' — ' + hours + ":" + date.substring(14,16) + " am"
}