import {FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS, FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR} from "./dispatchTypes";
import Api from "../components/Api";

export const fetchAnnouncementsDictionary = () => async dispatch => {
    const announcementsDictionaryResponse = await Api.getAnnouncementsDictionary();
    if(!announcementsDictionaryResponse) {
        dispatch({
            type: FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR
        })
    } else {
        dispatch({
            type: FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS,
            payload: announcementsDictionaryResponse.items
        })
    }
}