export function getCSRFHeaders() {
    let csrf_token_tag = document.head.querySelector("[name~=_csrf][content]");
    let csrf_header_tag = document.head.querySelector("[name~=_csrf_header][content]");

    if(csrf_token_tag == null || csrf_header_tag == null) {
        console.warn("No CSRF tags found, not adding header");
        return {};
    }

    let csrf_token = csrf_token_tag.content;
    let csrf_header = csrf_header_tag.content;

    let headers = {};
    headers[csrf_header] = csrf_token;


    return headers;
}

const csrf = {
    getCSRFHeaders
};

export default csrf;