import React from "react";
import damageCalculatorLib from "../damageCalculatorLib";

const HailDamageFooter = (props) => {
    return (
        <tr>
            <td className="px-0">Totals</td>
            <td className="pl-5">--</td>
            <td className="pl-5">--</td>
            <td className="pl-5">{damageCalculatorLib.totalDamageCost(props.damages)}</td>
            <td/>
        </tr>
    )
}

export default HailDamageFooter;
