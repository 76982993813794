import React from "react";
import damageCalculatorLib from "../damageCalculatorLib";

const DamageFooter = (props) => {
    return (
        <tr>
            <td className="px-0">Totals</td>
            <td className="pl-4">--</td>
            <td className="pl-4">--</td>
            <td className="pl-4">{damageCalculatorLib.totalDamageHours(props.damages)}hr</td>
            <td/>
        </tr>
    )
}

export default DamageFooter;
