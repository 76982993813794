import React from 'react';
import {connect} from "react-redux";
import Modal from "@prism/modal";
import Col from "@prism/col";
import Row from "@prism/row";

function ContinueIncompleteWorkOrderModal(props) {
    const handleContinueClick = () => {
        if (props.resolve) {
            props.resolve(false);
        }
    };

    const handleStartOverClick = () => {
        if (props.resolve) {
            props.resolve(true);
        }
    };

    return (
        <Modal
            isOpen={props.showContinueIncompleteWorkOrderModal}
            id="continue-incomplete-inspection-modal"
        >
            <Modal.Header>Continue Incomplete Inspection?</Modal.Header>
            <Modal.Body>
                There is an incomplete inspection do you want to continue with the incomplete inspection or start over?
            </Modal.Body>
            <Modal.Footer>
                <Row id="footer-content">
                    <Col>
                        <Modal.Okay id="continue-inspection-button" text="Continue" onClick={handleContinueClick}/>
                    </Col>
                    <Col>
                        <Modal.Cancel id="start-over-inspection" text="Start Over" onClick={handleStartOverClick}/>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )

}

function mapStateToProps({globalDisplay}) {
    const {showContinueIncompleteWorkOrderModal} = globalDisplay;
    return {showContinueIncompleteWorkOrderModal}
}

export default connect(mapStateToProps)(ContinueIncompleteWorkOrderModal);