import Col from "@prism/col";
import Row from "@prism/row";
import React from "react";
import Button from "@prism/button";
import "../../../../styles/trim_finder.css"

const TrimFinderQuestion = (props) => {
    const {
        question,
        onClick
    } = props

    return(
        <Col className={"my-3"}>
        <Row>
            <Col>
                <div className="h3 text-black" id={'trim-finder-question'}>{question?.name}</div>
            </Col>
        </Row>
            <Row>
                <Col xs={3}>
                    <Button outline className={"w-100"} onClick={() => onClick("yes")} id={'trim-finder-yes'}>Yes</Button>
                </Col>
                <Col xs={3}>
                    <Button outline className={"w-100"} onClick={() => onClick("no")} id={'trim-finder-no'}>No</Button>
                </Col>
                <Col xs={6} className={"text-right"}>
                    <Button textonly onClick={() => onClick("skip")} id={'trim-finder-skip'}>Skip</Button>
                </Col>
            </Row>
        </Col>
    )
}

export default TrimFinderQuestion;