/* eslint-disable default-case */
import {
    ADD_ANNOUNCEMENT_BY_TYPE,
    ADD_ANNOUNCEMENT_FOR_DAMAGE,
    DELETE_ANNOUNCEMENT_BY_CODE,
    DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE,
    LOAD_WORK_ORDER_SUCCESS,
    SET_ANNOUNCEMENTS,
    UNLOAD_WORK_ORDER,
    UPDATE_ANNOUNCEMENTS,
    WRAP_VEHICLE_COLOR_NOT_SELECTED
} from "../actions/dispatchTypes";
import {formatDateToUTC} from "../formatters";
import update from "immutability-helper";
import {CONDITION_ANNOUNCEMENT, STRUCTURAL_DAMAGE, STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE} from "../utils/constants";
import {STRUCTURAL_DAMAGE_ITEMS} from "../utils/structuralDamageItems";
import {logObject} from "../actions/loggingActions";

export default function announcementsReducer(state = {}, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
        case SET_ANNOUNCEMENTS:
            return filterDuplicateConditionAnnouncements(action.payload.announcements) || state;
        case UNLOAD_WORK_ORDER:
            return {};
        case UPDATE_ANNOUNCEMENTS:
            return action.payload;
        case ADD_ANNOUNCEMENT_BY_TYPE:
            return addAnnouncement(state, action.payload);
        case DELETE_ANNOUNCEMENT_BY_CODE:
            return deleteAnnouncement(state, action.payload);
        case ADD_ANNOUNCEMENT_FOR_DAMAGE:
            return addAnnouncementForDamage(state, action.payload);
        case DELETE_ANNOUNCEMENT_FOR_STRUCTURAL_DAMAGE:
            return deleteAnnouncementForStructuralDamage(state, action.payload);
        case WRAP_VEHICLE_COLOR_NOT_SELECTED:
            return updateWrapVehicleColorSelection(state, action.payload);
        default:
            return state;
    }
}

function addAnnouncement(state, payload) {
    logObject('announcementsReducer', 'addAnnouncement', 'state', state)
    logObject('announcementsReducer', 'addAnnouncement', 'payload', payload)
    let newState;
    let sourceIndex = -2;
    if (state.items) {
        sourceIndex = state.items.map(item => {
            return item.source
        }).indexOf(payload.type);
    }
    const formattedDate = formatDateToUTC(new Date());
    const announcement = {
        createdTimestamp: formattedDate,
        username: null,
        text: payload.text,
        referenceCode: payload.code
    };
    if (sourceIndex === -1) {
        newState = update(state, {
            items: {
                $push: [{
                    source: payload.type,
                    announcements: [announcement]
                }]
            },
            $merge: {count: state.items.length + 1}
        });
    } else if (sourceIndex === -2) {
        newState = update(state, {
            items: {
                $set: [{
                    source: payload.type,
                    announcements: [announcement]
                }]
            },
            $merge: {count: 1}
        });
    } else {
        newState = update(state, {
            items: {
                [sourceIndex]: {
                    announcements: {
                        $push: [announcement]
                    }
                }
            },
            $merge: {count: state.items.length}
        });
    }

    return update(newState, {
        $set: filterDuplicateConditionAnnouncements(newState)
    });
}

function addAnnouncementForDamage(state, payload) {
    const longDamageKey = `${payload.itemCode}-${payload.subItemCode}-${payload.damageCode}-${payload.severityCode}`;
    let isStructuralDamage;
    if (STRUCTURAL_DAMAGE_ITEMS.includes(longDamageKey)) {
        isStructuralDamage = true;
    }
    if (isStructuralDamage) {
        const announcement = {
            type: CONDITION_ANNOUNCEMENT,
            text: STRUCTURAL_DAMAGE,
            code: STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE
        };

        return addAnnouncement(state, announcement);
    } else {
        return state;
    }
}

function deleteAnnouncement(state, payload) {
    logObject('announcementsReducer', 'deleteAnnouncement', 'state', state)
    logObject('announcementsReducer', 'deleteAnnouncement', 'payload', payload)
    let sourceIndex = state.items && state.items.map(item => {
        return item.source
    }).indexOf(payload.type);
    const updatedAnnouncements = state.items && state.items[sourceIndex]?.announcements?.filter(item => {
        if (!item.referenceCode) {
            return item.text !== payload.code
        } else {
            return item.referenceCode !== payload.code
        }

    });

    if (updatedAnnouncements) {
        return update(state, {
            items: {
                [sourceIndex]: {
                    announcements: {
                        $set: updatedAnnouncements
                    }
                }
            },
            $merge: {count: (state.items ? state.items.length : 0)}
        });
    } else {
        return state
    }
}

function deleteAnnouncementForStructuralDamage(state = {keys: []}, deletedDamage) {
    return deleteAnnouncement(state, {type: CONDITION_ANNOUNCEMENT, code: STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE})
}

function updateWrapVehicleColorSelection(state, colorSelected) {
    return update(state, {
        $merge: {
            wrapVehicleColorIsMissing: colorSelected
        }
    })
}

function filterDuplicateConditionAnnouncements(announcements) {
    let filteredAnnouncements = {...announcements}
    let conditionIndex = announcements?.items?.findIndex(item => item.source === "CONDITION" || item.type === "CONDITION")
    if (conditionIndex >= 0) {
        let conditionItems = announcements.items[conditionIndex];
        filteredAnnouncements.items[conditionIndex].announcements = conditionItems.announcements.filter((annc, i, a) => a.findIndex(o => (o.referenceCode === annc.referenceCode)) === i);
    }
    return filteredAnnouncements;
}