import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import TabItem from './TabItem';
import TabBar from './TabBar';
import TabPane from './TabPane';
import { useTabs } from './useTabs';

const TabColors = ['light', 'dark'];
const TabSizes = ['sm', 'md'];
const Tabs = (props) => {


  const {
    childArray, childrenProps, className, id, border, activeIndex, tabs, updateHandleClick, externallySetActiveIndex
  } = useTabs(props);


  const handleTabClick = (index) => updateHandleClick(index);
    if(props.activeIndex!== activeIndex) {
        externallySetActiveIndex(props.activeIndex)
    }
  return (
    <div className={className} id={id}>
      <TabBar border={border}>
        {childrenProps.map((tabProps, index) => {
          const keyId = `${id}-tab-${index}`;
          return (
            <li key={keyId} className="nav-item">
              <TabItem
                {...tabProps}
                active={props.activeIndex === index}
                onClick={handleTabClick(index)}
              />
            </li>
          );
        })}
      </TabBar>
      <div className="tab-content">
        {childArray.map((child, index) => {
          const keyId = `${id}-pane-${index}`;
          return (
            <TabPane
              {...child.props}
              active={props.activeIndex === index}
              key={keyId}
              panelIndex={index}
              labelledby={tabs[index] ? tabs[index].id : null}
            />
          );
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  activeIndex: PropTypes.number,
  border: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(TabColors),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      activeIndex: PropTypes.number,
      className: PropTypes.string,
      content: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      id: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
        }),
      ]),
      tabClassName: PropTypes.string,
      tabId: PropTypes.string,
    })
  ),
  iconOnly: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(TabSizes),
  textOnly: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Tabs.defaultProps = {
  activeIndex: 0,
  border: true,
  color: TabColors[0],
  id: uuid(),
  className: '',
  data: null,
  iconOnly: false,
  onChange: () => {},
  size: TabSizes[1],
  textOnly: false,
  children: null,
};

Tabs.displayName = 'Tabs';

export default Tabs;
