import React from 'react';
import { FormFeedback } from '@prism/form';
import {connect} from "react-redux";

class NumberInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validationError: false
        }
    }

    validate = (e,value) => {
        let currentValue = parseFloat(e.target.value);
        if (isNaN(currentValue)) {
            e.target.value = '';
        }

        if (currentValue > value) {
           e.target.focus();
            return this.setState({
                validationError: true
            }, this.props.callbackFromParent({
                validationFailed: true
            }))
        }
        this.props.onBlur && this.props.onBlur(e,value);
        this.props.callbackFromParent({ validationFailed: false });
        return false;
    };

    resetValidation = (e) => {
        this.setState({
            validationError: false
        });
        this.props.onChange(e);
    };

    handleFocus = (e) => e.target.select();

    render() {

        const inputName = this.props.name ? this.props.name : '';
        const validateValue = this.props.validateValue;
        const disabled = this.props.disabled;
        return(
            <React.Fragment>
                  <div className="input-group-prepend">
                           <button className="input-group-text pr-1 pl-1 m-0 btn btn-outline btn-edit-bug"
                                   disabled={true}>
                               <i className={`icon icon-primary-dark prism-icon-${this.props.icon}`} />
                           </button>
                      </div>
                    <input type="number"
                           name={inputName}
                           value={this.props.value}
                           id={this.props.id}
                           className={`form-control ${this.state.validationError && "is-invalid"}`}
                           invalid={this.state.validationError.toString()}
                           onBlur = {event => this.validate(event,validateValue)}
                           onChange = {this.resetValidation}
                           onFocus = {this.handleFocus}
                           disabled = {disabled}
                    />
                    <FormFeedback className="mt-8">
                                <span id={'validation-'+ this.props.id} >
                                    <i className="icon prism-icon-notification-circle form-feedback__icon text-danger" />
                                    {this.props.validationErrorText}
                                </span>
                    </FormFeedback>
            </React.Fragment>
        )
    }
}

function mapStateToProps({validationFailed}) {
    return {validationFailed};
}

export default connect(mapStateToProps)(NumberInput);