import {ADD_F5_NOTE, DELETE_F5_NOTE, POPULATE_F5_NOTES, UNLOAD_WORK_ORDER} from "../actions/dispatchTypes";
import update from 'immutability-helper';

export default function f5NotesReducer(state = {}, action) {
    switch (action.type) {
        case UNLOAD_WORK_ORDER:
            return {};
        case POPULATE_F5_NOTES:
            return populateNotes(state, action.payload.notes);
        case ADD_F5_NOTE:
            return addF5Note(state, action.payload);
        case DELETE_F5_NOTE:
            return deleteF5Note(state, action.payload);
        default:
            return state
    }
}

function populateNotes(state, notes) {
    if(!notes || notes.length === 0) {
        return state;
    }
    notes.map(note => {
        note.notesText = note.notesText.replace(/\0/g, '');
        return note;
    });
    return { existingNotes: notes.sort((a, b) => a.addedDate < b.addedDate ? 1 : -1) };
}

function addF5Note(state, newNote) {
    newNote.notesText = newNote.notesText.replace(/\0/g, '');
    state = state || {};
    state.existingNotes = state.existingNotes || [];
    return update(state, {
        isUpdated: {$set: true},
        existingNotes: {
            $unshift: [newNote]
        }
    })
}

function deleteF5Note(state, note) {
    const noteIndex = state.existingNotes.indexOf(note);

    if(noteIndex > -1) {
        return update(state, {
            isUpdated: {$set: true},
            existingNotes:{
                $splice: [[noteIndex, 1]]
            }
        });
    } else {
        return state;
    }

}
