import {
    ADD_SUGGESTED_DAMAGE,
    DELETE_SUGGESTED_DAMAGE,
    LOAD_WORK_ORDER_SUCCESS,
    MANUALLY_ADD_SUGGESTED_DAMAGE,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";
import update from "immutability-helper";
import {calculateDamageKey} from "./conditionReducer";
import {ADDED, DELETED, EDITED, MANUAL} from "../utils/constants";

export default function suggestedDamageReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return initialSuggestedDamages(action.payload) || state;
        case ADD_SUGGESTED_DAMAGE:
            return addDamage(state, action.payload)
        case DELETE_SUGGESTED_DAMAGE:
            return deleteDamage(state, action.payload)
        case MANUALLY_ADD_SUGGESTED_DAMAGE:
            return manuallyAddDamage(state, action.payload)
        case UNLOAD_WORK_ORDER:
            return null;
        default:
            return state;
    }
}


function initialSuggestedDamages(payload) {
    let suggestedDamages = payload.suggestedDamages;
    if (suggestedDamages && suggestedDamages.damages && suggestedDamages.damages.length) {
        suggestedDamages.damages.map(damage => {
            damage.artCodes.damageKey = calculateDamageKey(damage.artCodes);
            return damage;
        });
    }
    return suggestedDamages;
}

function addDamage(state, addedDamage) {
    const damages = state.damages.map(damage => damage.artCodes.damageKey === addedDamage.artCodes.damageKey ? Object.assign(damage, {action: addedDamage.isEdited ? EDITED : ADDED}) : damage);
    return update(state, {
        $merge: {
            damages: damages
        }
    })
}

function deleteDamage(state, deletedDamage) {
    const damages = state.damages.map(damage => damage.artCodes.damageKey === deletedDamage.artCodes.damageKey ? Object.assign(damage, {action: DELETED}) : damage);
    return update(state, {
        $merge: {
            damages: damages
        }
    })
}

function manuallyAddDamage(state, manuallyAddedDamage) {
    const damages = state.damages.map(damage => damage.artCodes.damageKey === manuallyAddedDamage ? Object.assign(damage, {action: MANUAL}) : damage);
    return update(state, {
        $merge: {
            damages: damages
        }
    })
}