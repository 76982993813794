import {
    UPDATE_ODOMETER_READING,
    UPDATE_ODOMETER_UNITS
} from "./dispatchTypes";

export const updateOdometerReading = (reading) => (dispatch) => {
    dispatch({
        type: UPDATE_ODOMETER_READING,
        payload: reading
    })
}

export const updateOdometerUnits = (units) => (dispatch) => {
    dispatch({
        type: UPDATE_ODOMETER_UNITS,
        payload: units
    })
}
