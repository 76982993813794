export const MISSING_CHARGING_CABLE_DAMAGE = {
    itemCode: "0520",
    subItemCode: "39",
    item: "Drive Battery Charging Cable",
    damageCode: "MS",
    damage: "Missing",
    severityCode: "RQ",
    severity: "Replacement Required",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0520-39-MS"
};

export const MISSING_WARRANTY_BOOK_DAMAGE = {
    item: "Warranty Books",
    itemCode: "0480",
    subItemCode: "03",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0480-03-MS"
};
export const ODOMETER_CHECK = {
    description: "Odometer Check",
    item: "Odometer Check",
    itemCode: "0531",
    subItemCode: "01",
    damage: "Inspection",
    damageCode: "IS",
    severity: "Odo Check Required",
    severityCode: "OD",
    locationCode: "ME",
    action: "Warranty",
    actionCode: "WA",
    damageKey: "L1-0531-01-IS"
};

export const SERVICE_REQUIRED_DAMAGE = {
    item: "Warning - Maintenance",
    itemCode: "0404",
    subItemCode: "16",
    damage: "On",
    damageCode: "ON",
    severity: "Unacceptable",
    severityCode: "UA",
    action: "Mech Ck",
    actionCode: "MC",
    damageKey: "L1-0404-16-ON"
};

export const MISSING_OWNER_MANUAL_DAMAGE = {
    item: "Owners manuals",
    itemCode: "0480",
    subItemCode: "05",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0480-05-MS"
};

export const MISSING_SPARE_TIRE_DAMAGE = {
    item: "Spare Tire",
    itemCode: "0260",
    subItemCode: "00",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    locationCode: "ER",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0260-00-MS"
};

export const FUEL_LEVEL_DAMAGE = {
    item: "Gas",
    itemCode: "0532",
    subItemCode: "00",
    damage: "Missing",
    damageCode: "MS",
    severity: "Unacceptable",
    severityCode: "UA",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0532-00-MS"
};

export const MISSING_COMBO_KEY_DAMAGE = {
    item: "Combo Key",
    itemCode: "0420",
    subItemCode: "08",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0420-08-MS"
};

export const MISSING_MASTER_KEY_DAMAGE = {
    item: "Master/Door Key",
    itemCode: "0420",
    subItemCode: "04",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0420-04-MS"
};

export const MISSING_SMART_KEY_DAMAGE = {
    item: "Smart Key",
    itemCode: "0420",
    subItemCode: "11",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0420-11-MS"
};

export const MISSING_SPARE_KEY_DAMAGE = {
    item: "Spare Key",
    itemCode: "0420",
    subItemCode: "07",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0420-07-MS"
};

export const MISSING_FOB_KEY_DAMAGE = {
    item: "Remote/Fob Key",
    itemCode: "0428",
    subItemCode: "00",
    damage: "Missing",
    damageCode: "MS",
    severity: "Replacement Required",
    severityCode: "RQ",
    action: "Replace",
    actionCode: "RP",
    damageKey: "L1-0428-00-MS"
};

export const AUTO_DAMAGES_BY_ITEM = {
    warrantyBook: MISSING_WARRANTY_BOOK_DAMAGE,
    ownerManual: MISSING_OWNER_MANUAL_DAMAGE,
    chargingCable: MISSING_CHARGING_CABLE_DAMAGE,
    odometerCheck: ODOMETER_CHECK
};

export const AUTOMATICALLY_CREATED_DAMAGES = [
    MISSING_CHARGING_CABLE_DAMAGE,
    MISSING_WARRANTY_BOOK_DAMAGE,
    MISSING_OWNER_MANUAL_DAMAGE,
    ODOMETER_CHECK,
    MISSING_SPARE_TIRE_DAMAGE
];

export const DAMAGE_AREAS = [{code: 'EF', description: 'Front'},
    {code: 'EL', description: 'Left'},
    {code: 'ER', description: 'Rear'},
    {code: 'ET', description: 'Right'},
    {code: 'IN', description: 'Interior'},
    {code: 'ME', description: 'Mechanical'},
    {code: 'FR', description: 'Frame'},
    {code: 'RC', description: 'Recalls'},
    {code: undefined, description: undefined}]; // for cases when a damage locationCode is undefined
