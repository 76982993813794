import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updateHeadsetCount} from "../../../actions/conditionActions";
import {connect} from "react-redux";


class Headset extends React.Component {

    handleHeadsetsChange = (e) => {
        const quantity = e.currentTarget.value;
        this.props.updateHeadsetCount(quantity);
    };

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        const headsetOptions = Array.from(Array(5).keys());
        const quantity = this.props.condition && this.props.condition.entertainmentSystemHeadset !== undefined && this.props.condition.entertainmentSystemHeadset !== null ? this.props.condition.entertainmentSystemHeadset : null;

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                <DataPair label={"Headsets"} >
                    <Dropdown id={"headsets"} key={`headset${quantity}`} value={[quantity === null ? "N/A" : quantity.toString()]} onChange={this.handleHeadsetsChange}>
                        <option value={""}>N/A</option>
                        {headsetOptions.map(option => <option id={`headset_opt_${option.toString()}`} key={`headset_opt_${option.toString()}`} value={option.toString()}>{option.toString()}</option>)}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateHeadsetCount
};


function mapStateToProps({condition}) {
    return {condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(Headset);