import React from 'react';
import snakeCase from "lodash.snakecase";

const DataPair = ({label, value, defaultValue, id, children, required}) => (

    <div className="pb-2">
        <div className="pl-0 pr-0">
            <label htmlFor={id || snakeCase(label)} className="col-form-label font-weight-lighter">{required && <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>}{label}</label>
        </div>
        <div className="pl-0 pr-0">
            { children
                ? <div id={id || snakeCase(label)}>{children}</div>
            : <label id={id || snakeCase(label)} className="col-form-label font-weight-bolder">{value ? value : defaultValue}</label>
             }
        </div>
    </div>
);

export default DataPair;