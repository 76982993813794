import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import {updateLastServiceDate} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import DatePicker from "../../common/DatePicker";


class LastServiceDate extends React.Component {

    formattedDate = (date) => {
        if(date.indexOf('-')>0) {
            return `${date.substr(5, 2)}/${date.substr(8, 2)}/${date.substr(0, 4)}`;
        } else {
            return `${date.substr(4, 2)}/${date.substr(6, 2)}/${date.substr(0, 4)}`;
        }
    };

    handleLastServiceDateChange = (e) => {
        let formattedDate;
        if(!e) {
            formattedDate = "";
        } else {
            const splitDate = e.split("/");
            formattedDate = `${splitDate[2]}-${splitDate[0].padStart(2, "0")}-${splitDate[1].padStart(2, "0")}`;
        }
        this.props.updateLastServiceDate(formattedDate);
    };

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        if(this.props.condition && (this.props.condition.serviceRequired === undefined || this.props.condition.serviceRequired === null)) {
            return <></>
        }

        const value = this.props.condition.lastServiceDate;
        const lastServiceDate = (value && value !== '0') && this.formattedDate(value);

        return (
            <Col xs={12} sm={12} md={4} lg={3} xl={3} >
                <DataPair label="Last Service Date">
                    <DatePicker id="last-service-date"
                                value={lastServiceDate}
                                onChange={this.handleLastServiceDateChange}
                                key={`lastServiceDate${value}`}/>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateLastServiceDate
};

function mapStateToProps({condition}) {
    return {condition};
}
export default connect(mapStateToProps, matchDispatchToProps)(LastServiceDate);