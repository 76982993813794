import Col from "@prism/col";
import Row from "@prism/row";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {setCurrentConditionTabIndex} from "../../../actions/naviationActions";
import {
    BATTERY_CHARGE_IS_REQUIRED,
    BLOCK_TYPE_IS_REQUIRED,
    CONFIRM_ODOMETER_IS_REQUIRED,
    COUNTRY_IS_REQUIRED,
    CYLINDER_COUNT_IS_REQUIRED,
    DISPLACEMENT_IS_REQUIRED,
    DRIVETRAIN_IS_REQUIRED,
    EXTERIOR_COLOR_IS_REQUIRED,
    FUEL_TYPE_IS_REQUIRED,
    INDUCTION_IS_REQUIRED,
    INTERIOR_COLOR_IS_REQUIRED,
    MISSING_FUEL_LEVEL_MESSAGE,
    ODO_VALUE_MISSING_MESSAGE,
    SEAT_TRIM_IS_REQUIRED,
    SUB_SERIES_IS_REQUIRED,
    TRANSMISSION_IS_REQUIRED,
    TRIM_IS_REQUIRED,
    WHEELS_IS_REQUIRED,
    YEAR_IS_REQUIRED
} from "../../../utils/messages";
import Icon from "@prism/icon";
import {ELECTRIC_ENGINE_CATEGORIES, EXTERIOR, INTERIOR, WHEEL_IDS} from "../../../utils/constants";
import {ENABLE_CONFIRM_ODOMETER} from "../../../config/env";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";

function ConfigureReview(props) {

    const {
        designatedDescription,
        designatedDescriptionResponse,
        originalDesignatedDescription,
        condition,
        unit
    } = props;

    const [isValid, setIsValid] = useState(false)
    const [exteriorColorIsValid, setExteriorColorIsValid] = useState(false)
    const [interiorColorIsValid, setInteriorColorIsValid] = useState(false)
    const [trimIsValid, setTrimIsValid] = useState(false)
    const [seatTrimIsValid, setSeatTrimIsValid] = useState(false)
    const [wheelsIsValid, setWheelsIsValid] = useState(false)
    const [countryIsValid, setCountryIsValid] = useState(false)
    const [yearIsValid, setYearIsValid] = useState(false)
    const [drivetrainIsValid, setDrivetrainIsValid] = useState(false)
    const [transmissionIsValid, setTransmissionIsValid] = useState(false)
    const [fuelLevelIsValid, setFuelLevelIsValid] = useState(false)
    const [odometerIsValid, setOdometerIsValid] = useState(false)
    const [fuelTypeIsValid, setFuelTypeIsValid] = useState(false)
    const [cylinderCountIsValid, setCylinderCountIsValid] = useState(false)
    const [blockTypeIsValid, setBlockTypeIsValid] = useState(false)
    const [displacementIsValid, setDisplacementIsValid] = useState(false)
    const [inductionIsValid, setInductionIsValid] = useState(false)
    const [confirmOdometerIsValid, setConfirmOdometerIsValid] = useState(false)
    const [batteryChargeIsValid, setBatteryChargeIsValid] = useState(false)
    const [subSeriesIsValid, setSubSeriesIsValid] = useState(false)

    useEffect(() => {
        console.log("validating...")
        checkValidation();
    });

    const fuelType = designatedDescription?.powertrain?.engine?.fuelCategory;
    const isElectric = ELECTRIC_ENGINE_CATEGORIES.includes(fuelType);

    const installedColorIsPresent = (type) => {
        if ((type === INTERIOR && interiorColorIsNotSelected()) || (type === EXTERIOR && exteriorColorIsNotSelected())) {
            return false;
        }
        let colors = type === INTERIOR ? designatedDescription.colors.interior : designatedDescription.colors.exterior
        let color;
        if (type === EXTERIOR) {
            color = colors?.find(color => !!color.isDesignated && color.isPrimary !== false);
        } else {
            color = colors?.find(color => !!color.isDesignated);
        }
        return !!color
    }

    const interiorColorIsNotSelected = () => {
        return !designatedDescription?.colors?.interior?.find(color => !!color.isDesignated);
    }

    const exteriorColorIsNotSelected = () => {
        return !designatedDescription?.colors?.exterior?.find(color => !!color.isDesignated);
    }

    const trimIsPresent = () => {
        return !(!designatedDescription || !designatedDescription.trim || !designatedDescription.trim.normalizedName);
    }

    const seatTrimIsPresent = () => {
        return !(!installedColorIsPresent(INTERIOR) || !designatedDescription.colors.interior[0].materialType || !designatedDescription.colors.interior[0].materialType.length)
    }

    const wheelsIsPresent = () => {
        const installedEquipment = designatedDescription.installedEquipment?.filter(e => e.classification === 'EXTERIOR' && e.generics?.some(g => WHEEL_IDS.includes(g.id)));
        return installedEquipment?.length > 0;
    }

    const countryIsPresent = () => {
        return !(!designatedDescription || !designatedDescription.country || !designatedDescription.country.code);
    }

    const yearIsPresent = () => {
        return !(!designatedDescription || !designatedDescription.year);
    }

    const fuelLevelIsPresent = () => {
        return ELECTRIC_ENGINE_CATEGORIES.includes(designatedDescription?.powertrain?.engine?.fuelCategory) || !!condition.fuelLevel;
    }

    const odometerIsPresent = () => {
        const conditionOdometer = condition.odometer;
        const unitOdometer = unit.odometer;
        if (!conditionOdometer || !unitOdometer) {
            return false;
        }
        return (conditionOdometer.reading !== null && typeof conditionOdometer.reading == 'number') && (unitOdometer.reading !== null && typeof unitOdometer.reading == 'number')
    }

    const drivetrainIsPresent = () => {
        return !!designatedDescription.powertrain?.drivetrain?.type
    }

    const transmissionIsPresent = () => {
        return !!designatedDescription?.powertrain?.transmission?.type
    }

    const fuelTypeIsPresent = () => {
        return !!designatedDescription?.powertrain?.engine?.fuelCategory;
    }

    const cylinderCountIsPresent = () => {
        return !!designatedDescription?.powertrain?.engine?.cylinderCount;
    }

    const blockTypeIsPresent = () => {
        return !!designatedDescription?.powertrain?.engine?.cylinderCount;
    }

    const displacementIsPresent = () => {
        return !!designatedDescription?.powertrain?.engine?.displacement?.amount;
    }

    const inductionIsPresent = () => {
        return !!designatedDescription?.powertrain?.engine?.aspiration;
    }

    const batteryChargeIsPresent = () => {
        return fuelType === "Electric" ? !!condition.chargePercentage : true;
    }

    const subSeriesIsPresent = () => {
        const originalIdentifiers = originalDesignatedDescription?.thirdPartyIdentifiers?.manheim?.filter(identifier => !!identifier.ssubse)
        const designatedIdentifiers = designatedDescription?.thirdPartyIdentifiers?.manheim
        if (originalIdentifiers?.length > 1) {
            return designatedIdentifiers?.length === 1;
        }
        return true;
    }

    const checkValidation = () => {
        setExteriorColorIsValid(installedColorIsPresent(EXTERIOR))
        setInteriorColorIsValid(installedColorIsPresent(INTERIOR))
        setTrimIsValid(trimIsPresent())
        setSeatTrimIsValid(seatTrimIsPresent())
        setWheelsIsValid(wheelsIsPresent())
        setCountryIsValid(countryIsPresent())
        setYearIsValid(yearIsPresent())
        setFuelLevelIsValid(fuelLevelIsPresent())
        setOdometerIsValid(odometerIsPresent())
        setDrivetrainIsValid(drivetrainIsPresent())
        setTransmissionIsValid(transmissionIsPresent())
        setFuelTypeIsValid(enginePropertyIsValid('fuelType'))
        setCylinderCountIsValid(enginePropertyIsValid('cylinderCount'))
        setDisplacementIsValid(enginePropertyIsValid('displacement'))
        setInductionIsValid(enginePropertyIsValid('induction'))
        setBlockTypeIsValid(enginePropertyIsValid('blockType'))
        setConfirmOdometerIsValid(confirmOdometerIsSelected())
        setBatteryChargeIsValid(batteryChargeIsPresent())
        setSubSeriesIsValid(subSeriesIsPresent())
        setIsValid(
            exteriorColorIsValid
            && interiorColorIsValid
            && trimIsValid
            && seatTrimIsValid
            && wheelsIsValid
            && countryIsValid
            && yearIsValid
            && fuelLevelIsValid
            && odometerIsValid
            && drivetrainIsValid
            && fuelTypeIsValid
            && blockTypeIsValid
            && cylinderCountIsValid
            && displacementIsValid
            && inductionIsValid
            && confirmOdometerIsValid
            && batteryChargeIsValid
            && subSeriesIsValid
        )
        props.configurePanelIsValid(isValid)
    };

    const enginePropertyIsValid = (property) => {
        if (!designatedDescriptionResponse && !isElectric) {
            switch (property) {
                case 'fuelType':
                    return fuelTypeIsPresent();
                case 'blockType':
                    return blockTypeIsPresent();
                case 'cylinderCount':
                    return cylinderCountIsPresent();
                case 'displacement':
                    return displacementIsPresent();
                case 'induction':
                    return inductionIsPresent();
                default:
                    return true;
            }
        }
        return true;
    }

    const confirmOdometerIsSelected = () => {
        return ENABLE_CONFIRM_ODOMETER === 'true' ? props.globalDisplay?.odometerConfirmed : true;
    }

    const navigateToConfigureTab = () => {
        props.setCurrentConditionTabIndex(0);
    };

    const getValidationMessages = () => {
        const validationMessages = [];
        if (!transmissionIsValid) {
            validationMessages.push(TRANSMISSION_IS_REQUIRED)
        }
        if (!countryIsValid) {
            validationMessages.push(COUNTRY_IS_REQUIRED)
        }
        if (!yearIsValid) {
            validationMessages.push(YEAR_IS_REQUIRED)
        }
        if (!exteriorColorIsValid) {
            validationMessages.push(EXTERIOR_COLOR_IS_REQUIRED)
        }
        if (!interiorColorIsValid) {
            validationMessages.push(INTERIOR_COLOR_IS_REQUIRED)
        }
        if (!trimIsValid) {
            validationMessages.push(TRIM_IS_REQUIRED)
        }
        if (!seatTrimIsValid) {
            validationMessages.push(SEAT_TRIM_IS_REQUIRED)
        }
        if (!wheelsIsValid) {
            validationMessages.push(WHEELS_IS_REQUIRED)
        }
        if (!fuelLevelIsValid) {
            validationMessages.push(MISSING_FUEL_LEVEL_MESSAGE)
        }
        if (!odometerIsValid) {
            validationMessages.push(ODO_VALUE_MISSING_MESSAGE);
        }
        if (!drivetrainIsValid) {
            validationMessages.push(DRIVETRAIN_IS_REQUIRED)
        }
        if (!fuelTypeIsValid) {
            validationMessages.push(FUEL_TYPE_IS_REQUIRED)
        }
        if (!cylinderCountIsValid) {
            validationMessages.push(CYLINDER_COUNT_IS_REQUIRED)
        }
        if (!displacementIsValid) {
            validationMessages.push(DISPLACEMENT_IS_REQUIRED)
        }
        if (!inductionIsValid) {
            validationMessages.push(INDUCTION_IS_REQUIRED)
        }
        if (!blockTypeIsValid) {
            validationMessages.push(BLOCK_TYPE_IS_REQUIRED)
        }
        if (!confirmOdometerIsValid) {
            validationMessages.push(CONFIRM_ODOMETER_IS_REQUIRED)
        }
        if (!batteryChargeIsValid) {
            validationMessages.push(BATTERY_CHARGE_IS_REQUIRED)
        }
        if (!subSeriesIsValid) {
            validationMessages.push(SUB_SERIES_IS_REQUIRED)
        }
        return validationMessages.join(", ")
    };

    const validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    };

    const validationFailureIcon = () => {
        return <Icon glyph="notification-circle" color="danger" className="status-icon"/>
    };

    return (<Row className={`px-6 py-4 mx-0 review-row ${!isValid && " show-is-clickable"}`}
                 onClick={isValid ? undefined : navigateToConfigureTab}>
        <Col className="flex-grow-1">
            <Row className="h2 review-row-title"><Col>Configure</Col></Row>
            {!isValid && <Row className="review-error-message"><Col>{getValidationMessages()}</Col></Row>}
        </Col>
        <Col className="flex-grow-0">
            {isValid ? validationSuccessIcon() : validationFailureIcon()}
        </Col>
    </Row>)
}

function mapStateToProps(
    {
        designatedDescription,
        designatedDescriptionResponse,
        consignment,
        condition,
        unit,
        globalDisplay
    }
) {
    const originalDesignatedDescription = getDesignatedDescription({'designatedDescriptionResponse': designatedDescriptionResponse});
    return {
        designatedDescription,
        designatedDescriptionResponse,
        originalDesignatedDescription,
        consignment,
        condition,
        unit,
        globalDisplay
    };
}

const mapDispatchToProps = {
    setCurrentConditionTabIndex
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureReview);
