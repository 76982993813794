import React from "react";
import "../../styles/section.css";

const SectionBreak = (props) => {

  return(
    <hr className="section-break" />
  )
};

export default SectionBreak;