import React from "react";
import {connect} from "react-redux";

import Message from "@prism/message";

import {deleteMessage} from "../../actions/statusMessageActions";

class MessageWrapper extends React.Component {

    state = {isOpen: true};

    componentDidMount () {
        this.setState({isOpen: this.props.isOpen || true});
        if(this.props.text && this.props.type === 'success') {
            setTimeout(this.hideMessage, 5000)
        }
    }

    hideMessage = () => {
        this.setState({isOpen: false});
        setTimeout(() => this.props.deleteMessage(this.props.id), 1000);
    };

    render () {
        const {type, text} = this.props;
        return (
            <Message color={type} isOpen={this.state.isOpen}>{text}</Message>
        )
    }
}

const mapDispatchToProps = {
    deleteMessage
};

export default connect(null, mapDispatchToProps)(MessageWrapper);