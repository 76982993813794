import {
    SHOW_PUSH_PANEL,
    LOAD_WORK_ORDER_SUCCESS,
    SHOW_CLOSE_INSPECTION_CONFIRMATION,
    HIDE_CLOSE_INSPECTION_CONFIRMATION,
    SHOW_LOGOUT_MODAL,
    HIDE_LOGOUT_MODAL, HIDE_PROFILE_MODAL_INSIDE_WO, SUBMIT_WORK_ORDER_PROGRESS, SUBMIT_WORK_ORDER_ERROR
} from "../actions/dispatchTypes";

export const SCAN_PANEL = 'scan';
export const PROFILE_PANEL = 'profile';

const defaultState = {
    saveState: false
};

export default function deviceStorageControllerReducer(state = defaultState, action) {
    switch(action.type) {
        case SHOW_PUSH_PANEL:
            return {saveState: (action.panelName !== SCAN_PANEL && action.panelName !== PROFILE_PANEL)};
        case LOAD_WORK_ORDER_SUCCESS:
            return {saveState: true};
        case SHOW_CLOSE_INSPECTION_CONFIRMATION:
        case SHOW_LOGOUT_MODAL:
        case SUBMIT_WORK_ORDER_PROGRESS:
            return {saveState: false};
        case HIDE_CLOSE_INSPECTION_CONFIRMATION:
        case HIDE_LOGOUT_MODAL:
        case HIDE_PROFILE_MODAL_INSIDE_WO:
        case SUBMIT_WORK_ORDER_ERROR:
            return {saveState: true};
        default:
            return state;
    }
}
