import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {ELECTRIC_ENGINE_CATEGORIES, PLEASE_SELECT} from "../../../utils/constants";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";

const Displacement = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        DDSResponse,
        onDisplacementChange,
        globalDisplay,
        updateCylinderCount
    } = props

    const {cylinderCountId} = globalDisplay
    const originalDDS = getDesignatedDescription({'designatedDescriptionResponse': DDSResponse})
    const [options, setOptions] = useState([]);
    const fuelType = designatedDescription?.powertrain?.engine?.fuelCategory;
    const displacement = designatedDescription?.powertrain?.engine?.displacement?.amount;
    const originalDisplacement = originalDDS?.powertrain?.engine?.displacement?.amount;
    const isElectric = ELECTRIC_ENGINE_CATEGORIES.includes(fuelType);
    const cylinderCount = designatedDescription?.powertrain?.engine?.cylinderCount;

    useEffect(() => {
        if (cylinderCountId) {
            async function fetchData() {
                return await Api.getDisplacements({cylinderCountId});

            }

            fetchData().then(r => setOptions(r.items?.length ? r.items : []));
        } else if (!displacement && cylinderCount) {
            async function fetchCylinderCounts() {
                return await Api.getCylinderCounts();
            }

            fetchCylinderCounts().then(cylinderCountsResponse => {
                let cylinderCountWithId = cylinderCountsResponse?.items?.find(resp => parseInt(resp.name) === cylinderCount);
                if (cylinderCountWithId) {
                    updateCylinderCount(cylinderCountWithId)
                }
            })
        }
    }, [fuelType, cylinderCountId, cylinderCount, displacement, updateCylinderCount]);

    if ((!hasDDSResponse && !fuelType) || isElectric) {
        return <></>
    } else {
        return (
            hasDDSResponse && !!displacement && !!originalDisplacement ?
                <Form.Group>
                    <DataPair id="configure-displacement" label="Displacement">
                        {displacement}
                    </DataPair>
                </Form.Group> :
                (!hasDDSResponse || (hasDDSResponse && !originalDisplacement && !!cylinderCount)) && <Form.Group>
                    <DataPair id="configure-displacement" label="Displacement" required={true}>
                        <Dropdown disabled={options.length < 1}
                                  onChange={(e) => onDisplacementChange(options, e)}
                                  value={displacement}
                                  id='displacement-dropdown'>
                            {!displacement && <option value=''>{PLEASE_SELECT}</option>}
                            {options?.map(option => {
                                return (
                                    <option
                                        value={option.name}
                                        key={option.name}>
                                        {option.name}
                                    </option>
                                )
                            })}
                        </Dropdown>
                    </DataPair>
                </Form.Group>
        )
    }

}

export default Displacement;