import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React from "react";
import {BODY_STYLES, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";

const BodyStyle = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onBodyStyleChange
    } = props


    const bodyStyle = designatedDescription?.bodyDescription?.primaryBodyStyle;



    return(
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-body-style" label="Body Style">
                    {bodyStyle}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-body-style" label="Body Style" required={true}>
                    <Dropdown disabled={props.disabled}
                              onChange={(e) => onBodyStyleChange(BODY_STYLES, e)}
                              value={bodyStyle}
                              id='body-style-dropdown'>
                        {!bodyStyle && <option value=''>{PLEASE_SELECT}</option>}
                        {BODY_STYLES.map(option => {
                            return (<option key={option.name}
                                            value={option.name}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>

    )

}

export default BodyStyle;