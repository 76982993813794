import {connect} from "react-redux";
import KeyFob from './keyfob.svg';
import DamagePlaceHolder from './Damages.svg';
import '../../styles/photos.css';

const ImageSetButton = (props) => {
    /* TODO generalize this component to apply to damage images */
    let firstImage

    if(props.damageImages && props.imageType === 'damage') {
        firstImage = props.damageImages.find(image => image.status !== 'DELETED')
    }
    if(props.miscellaneousImages && props.imageType === 'miscellaneous') {
        firstImage = props.miscellaneousImages.find(image => image.status !== 'DELETED')
    }
    return (
        <div id={props.imageType + "-images-button"}
             className="image-set-button"
             onClick={() => props.setActiveTab(props.imageType)}>

            <div className="header">{props.header}</div>
            <img alt="placeholder" src={firstImage ? firstImage.url : (props.imageType === 'miscellaneous'? KeyFob: DamagePlaceHolder)}/>
            <div className="d-flex justify-content-center">
              <div className="footer-decoration" />
              <span className="icon prism-icon-camera footer-camera-icon" />
            </div>
        </div>
    )
}

function mapStateToProps({miscellaneousImages, damageImages}) {
    return {miscellaneousImages, damageImages};
}

export default connect(mapStateToProps)(ImageSetButton);