import React from "react";
import Button from "@prism/button";
import Confirm from "../../common/Confirm";

class DeleteDamageButton extends React.Component {

    render() {
        return (
            <>
                <Confirm title={this.props.damage.item}
                         description="This will permanently delete the line item. Are you sure you want to delete it?"
                         submitDescription="Delete"
                         cancelDescription="Cancel">
                    {confirm => (

                        <Button id={'delete-' + this.props.damage.damageKey}
                                disabled={this.props.disabled}
                                onClick={confirm(this.props.onClick)}
                                className={"mx-2 my-0"}>
                            <i className="icon prism-icon-trash large-button-icon text-secondary"/>
                        </Button>
                    )}
                </Confirm>
            </>
        )
    }
}


export default DeleteDamageButton;