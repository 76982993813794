import Col from "@prism/col";
import Row from "@prism/row";
import React from "react";

const CheckboxQuestion = (props) => {
    let labelText = props.question.question.slice(0, props.question.question.indexOf('*') === -1 ? props.question.question.length : props.question.question.indexOf('*'));
    let requiredFlag = props.required ? '*' : null;

    return (
        <Col className="my-1" id={props.question.id}>
            <Row>
                <Col className="font-size-sm px-0">
                    <div>{labelText}{requiredFlag && <span className="text-danger">{requiredFlag}</span>}</div>
                </Col>
                <Col className="flex-grow-0">
                    <div className="checkbox--custom" id={props.question.question}>
                        <label className="col-form-label"><input type="checkbox" onChange={props.onChange} checked={props.question.answer === 'true'}/></label>
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

export default CheckboxQuestion;
