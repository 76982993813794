import React from "react";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {
    ELECTRIC_ENGINE_CATEGORIES,
    FUEL_LEVEL_OPTIONS,
    PLEASE_SELECT
} from "../../../utils/constants";
import {FUEL_LEVEL_DAMAGE} from "../../../utils/constantsDamages"
import {
    calculateDamageKey,
    deleteAutoDamage,
    updateFuelLevel,
    updateFuelLevelDamage,
} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {toggleDamageModal} from "../../../actions/globalDisplayActions";
import AddDamageModal from "./AddDamageModal";
import update from "immutability-helper";
import Form from "@prism/form";

class FuelLevel extends React.Component {

    state = {
        fuelLevelDamage: FUEL_LEVEL_DAMAGE,
        hasGasDamageRule: null
    };

    handleFuelLevel = async (e) => {
        this.props.updateFuelLevel(e.target.value);
        const minFuelLevel = ("fuelLevel" in this.props.damageRules.rules) && this.props.damageRules.rules.fuelLevel !== null ? this.props.damageRules.rules.fuelLevel : 0;
        const selectedFuelLevel = e.target.value === "" ? null : e.target.value.toUpperCase();
        const currentFuelLevel = {
            "EMPTY": 0,
            "UNKNOWN": 0,
            "N/A": 0,
            "1/8": 0.125,
            "1/4": 0.25,
            "1/2": 0.5,
            "3/4": 0.75,
            "FULL": 1
        }[selectedFuelLevel]

        await this.setFuelLevelDamage()

        let existingFuelDamage = null
        if (this.props.condition.damages && this.state.fuelLevelDamage.damageKey) {
            existingFuelDamage = this.props.condition.damages.find(existingDamage => calculateDamageKey(existingDamage) === this.state.fuelLevelDamage.damageKey)
        }

        if (currentFuelLevel <= minFuelLevel) {
            // Only add damage if it does not exist already...
            if (this.props.consignment.fuelLevelRequired && this.state.fuelLevelDamage.hasGasDamageRule && !existingFuelDamage) {
                this.props.toggleDamageModal("fuelLevel");
            }
        } else if (currentFuelLevel > minFuelLevel && existingFuelDamage) {
            this.props.deleteAutoDamage(existingFuelDamage)
        }
    }

    setFuelLevelDamage = () => {
        let hasGasDamageRule = this.props.damageRules.damages.some((damage) => damage.item === "Gas");
        const gasDamage = this.props.damageRules.damages.find(damage => damage.item === "Gas");
        if (!!gasDamage) {
            let damageKey = calculateDamageKey(gasDamage)
            let fuelLevelDamage = update(this.state.fuelLevelDamage, {
                $merge: {
                    hasGasDamageRule: hasGasDamageRule,
                    damageKey: damageKey
                }
            });
            this.setState({fuelLevelDamage})
        }
    }

    getFuelLevelOptions = () => {
        return FUEL_LEVEL_OPTIONS.map(option => {
            return <option value={option.code} key={option.code}>{option.description}</option>;
        });
    };

    handleCancelClick = () => {
        this.props.updateFuelLevel(null);
        this.props.toggleDamageModal("fuelLevel");
    };

    render() {
        const {designatedDescription} = this.props;
        const engine = designatedDescription?.powertrain?.engine;
        const isElectric = engine ? ELECTRIC_ENGINE_CATEGORIES.includes(engine.fuelCategory) : false;

        if (this.props.condition === null || isElectric || !engine) {
            return <></>
        }

        let fuelLevelValue = this.props.condition.fuelLevel || "";
        this.props.updateFuelLevel(fuelLevelValue);
        const defaultSelection = fuelLevelValue === null || fuelLevelValue === undefined ? undefined : [fuelLevelValue];

        return (
            <Form.Group>
                {this.props.consignment.fuelLevelRequired &&
                    <AddDamageModal showModal={this.props.globalDisplay.fuelLevel}
                                    onCancelClick={this.handleCancelClick}
                                    toggle={() => this.props.toggleDamageModal("fuelLevel")}
                                    damage={this.state.fuelLevelDamage}/>}
                <DataPair label={"Fuel Level"} id={"fuel_level"} required={!isElectric}>
                    <Dropdown
                        type="text"
                        id={"edit-fuel-level"}
                        value={defaultSelection}
                        onChange={this.handleFuelLevel}>
                        <option value={""} disabled>{PLEASE_SELECT}</option>
                        {this.getFuelLevelOptions()}
                    </Dropdown>
                </DataPair>
            </Form.Group>
        )
    }
}

const matchDispatchToProps = {
    toggleDamageModal,
    deleteAutoDamage,
    updateFuelLevel,
    updateFuelLevelDamage
};

function mapStateToProps({condition, damageRules, globalDisplay, consignment, designatedDescription}) {
    return {condition, damageRules, globalDisplay, consignment, designatedDescription};
}

export default connect(mapStateToProps, matchDispatchToProps)(FuelLevel);
