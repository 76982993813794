export const interiorColor = (uiDisplayDetails, designatedDescription) => {
    let fromDesignatedDescription = interiorColorFromDesignatedDescription(designatedDescription)
    let fromUiDisplayDetails = interiorColorFromUiDisplayDetails(uiDisplayDetails);
    return fromUiDisplayDetails || fromDesignatedDescription || 'No Color Available';
}

export const interiorColorFromDesignatedDescription = (designatedDescription) => {
    const interiorColor = designatedDescription?.colors?.interior?.find(checkInstalledColor);

    if (interiorColor?.oemName) {
        return interiorColor.oemName;
    } else if (interiorColor?.normalizedName) {
        return interiorColor.normalizedName;
    }
    return null;
}

export const interiorColorFromUiDisplayDetails = (uiDisplayDetails) => {
    return uiDisplayDetails?.interiorColor || null;
}

export const exteriorColor = (uiDisplayDetails, designatedDescription) => {
    let fromUiDisplayDetails = exteriorColorFromUiDisplayDetails(uiDisplayDetails);
    let fromDesignatedDescription = exteriorColorFromDesignatedDescription(designatedDescription)
    return fromUiDisplayDetails || fromDesignatedDescription || 'No Color Available';
};

export const exteriorColorFromDesignatedDescription = (designatedDescription) => {
    const exteriorColor = designatedDescription?.colors?.exterior?.find(checkInstalledColor);

    if (exteriorColor?.oemName) {
        return exteriorColor.oemName;
    } else if (exteriorColor?.normalizedName) {
        return exteriorColor.normalizedName;
    }
    return null;
}

export const exteriorColorFromUiDisplayDetails = (uiDisplayDetails) => {
    return uiDisplayDetails?.exteriorColor || null;
}

export const vehicleTitle = (uiDisplayDetails) => {
    let manheimStandardDesc = uiDisplayDetails?.manheimStandardDesc
    return manheimStandardDesc ? manheimStandardDesc : ymms(uiDisplayDetails);
};

export const ymms = (uiDisplayDetails) => {
    return ymmsFrom(uiDisplayDetails) || "";
};

export const ymmsFrom = (data) => {
    if (!data?.year) {
        return null;
    }
    const {year, make, model, subSeries} = data;
    return `${year} ${make} ${model} ${!!subSeries ? subSeries : ""}`
}

export const vinSplit = (vin) => {
    return vin ? {
        last8: vin.substring(vin.length - 8),
        prefix: vin.substring(0, vin.length - 8)
    } : {
        last8: '',
        prefix: ''
    };
};

export const colorsForIncompleteItem = (colors) => {
    let exteriorColor = colors.substring(0, colors.indexOf(','))
    let interiorColor = colors.substring(colors.indexOf(',') + 1)

    return (exteriorColor.length > 0 ? exteriorColor : 'No Color Available') + '/'
        + (interiorColor.length > 0 ? interiorColor : 'No Color Available')
}

export const getColors = (colorInfo) => {
    if (colorInfo !== null && colorInfo?.uiElement2) {
        return colorsForIncompleteItem(colorInfo.uiElement2);
    } else {
        return `${exteriorColor(colorInfo, null)}/${interiorColor(colorInfo, null)}`;
    }
}

export const getDescForIncomplete = (incompleteWorkItem) => {
    return incompleteWorkItem.uiElement3 ? incompleteWorkItem.uiElement3
        : (incompleteWorkItem.uiElement1 ? incompleteWorkItem.uiElement1 : '')
}

function checkInstalledColor(color) {
    if (color.installed) {
        return color;
    }
}
