import React from 'react';
import {
    DamageHeader,
    HailDamageHeader,
    DamageRow,
    HailDamageRow,
    DamageFooter,
    HailDamageFooter
} from './DamageCalculatorTableElements'

const DamageCalculatorTable = (props) => {
    const {damages, type, deleteDamage} = props

    const isHailDamage = () => {
        return type === 'Hail';
    }

    return (
        isHailDamage() ? (
            <table className="unified-table damage-calculator-table">
                <HailDamageHeader/>
                <tbody>
                {damages.map((damage, index) => {
                    return (<HailDamageRow
                        key={damage.id}
                        damage={damage}
                        index={index}
                        deleteDamage={deleteDamage}/>)
                })}
                <HailDamageFooter damages={damages}></HailDamageFooter>
                </tbody>
            </table>) : (
            <table className="unified-table damage-calculator-table">
                <DamageHeader/>
                <tbody>
                {damages.map((damage, index) => {
                    return (<DamageRow
                        key={damage.id}
                        damage={damage}
                        index={index}
                        deleteDamage={deleteDamage}/>)
                })}
                <DamageFooter damages={damages}></DamageFooter>
                </tbody>
            </table>
        )
    )
}

export default DamageCalculatorTable;
