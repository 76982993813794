import React from "react";
import Button from "@prism/button";
import Confirm from "../../../common/Confirm";
import Icon from "@prism/icon";
import { deleteDamageImage } from "../../../../actions/damageImageActions";
import { connect } from "react-redux";
import { noop } from "lodash";
import {isNativeApp} from "../../../../utils/utils";

const DeleteDamageImageButton = (props) => {
    const handleConfirm = props.onConfirm || noop;

    const handleDeletePhotoClick = () => {
        if(isNativeApp()) {
            window.NativeApp.deletePhoto(props.workOrderInfo.workOrderNumber, props.damageKey);
        }
        props.deleteDamageImage(props.damageKey);
    };

    return (
        <>
            <Confirm
                title="Delete Photo"
                description="This will permanently delete the image. Are you sure you want to delete it?"
                submitDescription="Delete"
                cancelDescription="Cancel"
            >
                {(confirm) => (
                    <Button
                        id={"delete-image-" + props.damageKey}
                        disabled={props.disabled}
                        onClick={confirm(() => {
                            handleDeletePhotoClick();
                            handleConfirm();
                        })}
                        className={`delete-photo-button ${
                            props.className && " " + props.className
                        }`}
                        style={props.style}
                    >
                        <Icon glyph="trash" className="large-button-icon mr-2" />
                        Delete Photo
                    </Button>
                )}
            </Confirm>
        </>
    );
};

function mapStateToProps({ workOrderInfo }) {
    return { workOrderInfo };
}

const matchDispatchToProps = {
    deleteDamageImage,
};

export default connect(mapStateToProps, matchDispatchToProps)(DeleteDamageImageButton);