import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Action } from '@prism/actions';

const TabSizes = ['sm', 'md'];
const TabItem = ({
  active,
  addOnAppend,
  addOnPrepend,
  className: classNameFromProps,
  iconOnly: propIconOnly,
  disabled,
  label,
  onClick,
  size,
  textOnly: propTextOnly,
  ...props
}) => {
  const iconOnly = propIconOnly || !label;
  const textOnly = propTextOnly || !(addOnAppend || addOnPrepend);
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const className = classNames(
    'tab',
    { active: !!active },
    classNameFromProps,
  );

  return (
    <Action
      {...props}
      disabled={disabled}
      className={className}
      onClick={props.href ? null : handleClick}
      addOnAppend={addOnAppend}
      addOnPrepend={addOnPrepend}
      iconOnly={iconOnly}
      textOnly={textOnly}
      label={!iconOnly ? label : null}
      role="tab"
      size={size}
      aria-selected={!!active}
    >
      {label && !iconOnly && <span className="tab-label">{label}</span>}
    </Action>
  );
};

TabItem.propTypes = {
  active: PropTypes.bool,
  addOnAppend: PropTypes.string,
  addOnPrepend: PropTypes.string,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  label: (props) => {
    const hasIcon = props.addOnAppend || props.addOnPrepend;
    if (!hasIcon && typeof props.label !== 'string') {
      return new Error(
        'Invalid prop sequence. Either `label`, `addOnAppend`, `addOnPrepend`, or a combination must be supplied; none were given to '
      );
    }
    return null;
  },
  onClick: PropTypes.func,
  size: PropTypes.oneOf(TabSizes),
  textOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
};

TabItem.defaultProps = {
  active: false,
  addOnAppend: '',
  addOnPrepend: '',
  className: '',
  iconOnly: false,
  onClick: () => {},
  size: TabSizes[1],
  textOnly: false,
  disabled: false,
  label: '',
  href: null,
};

TabItem.displayName = 'TabItem';

export default TabItem;
