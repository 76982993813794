export const MISSING_KEYS_MESSAGE = "Keys must have a value";
export const REQUIRED_KEYS_MESSAGE = "key is required";
export const MISSING_WARRANTY_BOOKS_MESSAGE = "Warranty Books is required";
export const MISSING_OWNERS_MANUALS_MESSAGE = "Owner's Manual is required";
export const MISSING_PAINT_TYPE_MESSAGE = "Paint type must be selected";
export const MISSING_CHARGING_CABLE_MESSAGE = "Charging Cable is required";

export const MISSING_INTERIOR_ODOR_MESSAGE = "Interior Odor must be selected";
export const MISSING_EMISSIONS_MESSAGE = "Emissions must be selected";
export const MISSING_FUEL_LEVEL_MESSAGE = "Fuel Level must be selected"
export const MISSING_ENGINE_STARTS_MESSAGE = "Engine Starts is required";
export const MISSING_SERVICE_REQUIRED_MESSAGE = "Service Required is required"
export const MISSING_SERVICE_REQUIRED_SERVICES_MESSAGE = "Engine Oil and/or Brake Fluid service must be selected"
export const MISSING_DRIVABLE_MESSAGE = "Drivable is required";
export const MISSING_FLOOD_DAMAGE_MESSAGE = "Flood Damage is required";
export const MISSING_STRUCTURAL_DAMAGE_MESSAGE = "Structural Damage is required";
export const MISSING_STRUCTURAL_ALTERATION_MESSAGE = "Structural Alteration is required";
export const NO_ANNOUNCEMENTS = "No Announcements";
export const MISSING_MANUFACTURER =  " Manufacturer is required";
export const MISSING_SIZE =  " Size is required";
export const MISSING_DEPTH =  " Tread Depth is required";
export const MISSING_SPARE =  " Spare is required";
export const MISSING_CONSTRUCTION =  " Construction is required";
export const MISSING_DAMAGE_SEVERITY_MESSAGE = "Severity is required";
export const MISSING_DAMAGE_CHARGEABLE_MESSAGE = "Chargeable is required";
export const MISSING_DAMAGE_PRICING = "Pricing is required";
export const SUBMISSION_ERROR_MESSAGE = "There was an error when attempting to submit this inspection. Please try again. If this issue persists, please contact support.";
export const MISSING_WRAP_VEHICLE_COLOR = "Wrapped vehicle color is required";

export const EXTERIOR_COLOR_IS_REQUIRED = "Exterior Color is required";
export const INTERIOR_COLOR_IS_REQUIRED = "Interior Color is required";

export const TRIM_IS_REQUIRED = "Trim is required";
export const SEAT_TRIM_IS_REQUIRED = "Seat Trim is required";
export const ODO_VALUE_MISSING_MESSAGE = "Odometer value is required";
export const WHEELS_IS_REQUIRED = "Wheels is required";
export const COUNTRY_IS_REQUIRED = "Country is required";
export const TRANSMISSION_IS_REQUIRED = "Transmission is required";
export const YEAR_IS_REQUIRED = "Year is required";
export const DRIVETRAIN_IS_REQUIRED = "Drivetrain is required";
export const FUEL_TYPE_IS_REQUIRED = "Fuel Type is required";
export const CYLINDER_COUNT_IS_REQUIRED = "Cylinder Count is required";
export const DISPLACEMENT_IS_REQUIRED = "Displacement is required";
export const INDUCTION_IS_REQUIRED = "Induction is required";
export const BLOCK_TYPE_IS_REQUIRED = "Block Type is required";
export const CONFIRM_ODOMETER_IS_REQUIRED = "Odometer value must be confirmed";
export const BATTERY_CHARGE_IS_REQUIRED = "Battery Charge is required";
export const SUB_SERIES_IS_REQUIRED = "Sub-Series is required";
