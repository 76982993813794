import Api from '../components/Api';
import {GET_WORK_LIST_SUCCESS, GET_WORK_LIST_REQUESTED} from "./dispatchTypes";

export const getWorkList = (params) => async dispatch => {
    dispatch({ type: GET_WORK_LIST_REQUESTED });

    dispatch({
        type: GET_WORK_LIST_SUCCESS,
        payload: await Api.getWorkList(params)
    });
};
