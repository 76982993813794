import Form from "@prism/form";
import {useEffect, useState} from "react";
import Api from "../../Api";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {PLEASE_SELECT} from "../../../utils/constants";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";

const Drivetrain = (props) => {
    const {
        designatedDescription,
        DDSResponse,
        onDrivetrainChange
    } = props
    const hasDDSResponse = !!DDSResponse
    const originalDDS = getDesignatedDescription({'designatedDescriptionResponse': DDSResponse})
    const [options, setOptions] = useState([]);
    const drivetrain = designatedDescription?.powertrain?.drivetrain?.type;
    const originalDriveTrain = originalDDS?.powertrain?.drivetrain?.type;

    useEffect(() => {
        async function fetchData() {
            return await Api.getDrivetrains();
        }

        if (!hasDDSResponse || !drivetrain || !originalDriveTrain) {
            fetchData().then(r => setOptions(r.items));
        }
    }, [hasDDSResponse, drivetrain, originalDriveTrain]);

    return (
        hasDDSResponse && !!drivetrain && !!originalDriveTrain ?
            <Form.Group>
                <DataPair id="configure-drivetrain" label="Drivetrain">
                    {drivetrain}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-drivetrain" label="Drivetrain" required={true}>
                    <Dropdown disabled={(!drivetrain && !options?.length) || props.disabled}
                              onChange={onDrivetrainChange}
                              value={drivetrain}
                              id='drivetrain-dropdown'>
                        {!drivetrain && <option value=''>{PLEASE_SELECT}</option>}
                        {options?.map(option => {
                            return (<option key={option.id}
                                            value={option.name}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>
    )
}

export default Drivetrain;