import React from 'react';
import {connect} from "react-redux";

import Button from '@prism/button';
import Col from '@prism/col';
import Row from '@prism/row';

import Note from "./Note";
import "../../styles/view-f5-notes.css";
import AddEditNote from "./AddEditNote";

class ViewF5Notes extends React.Component {

    state = {
        showAddNote: false
    };

    showAddNote = () => {
        this.setState({showAddNote: true})
    };

    cancelAddNote = () => {
        this.setState({showAddNote: false})
    };

    renderNoNotesMessage = () => {
        return <Row><Col>
            <div className="text-secondary-dark prism-h4" id='no-f5-notes-message'>No F5 Notes available.</div>
        </Col></Row>;
    };

    renderNotes = () => {
        let existingNotes = this.props.f5Notes.existingNotes || [];

        if (existingNotes.length === 0 && !this.state.showAddNote) {
            return this.renderNoNotesMessage();
        }

        return existingNotes.map((note, index) => {
            if (note.notesText.slice(0, 2) !== "**") {
                return <Note key={index} index={index} note={note} noteType="F5Note"/>
            } else {
                return null
            }
        });
    };

    render() {

        if (!this.props.f5Notes || this.props.f5Notes === {}) {
            return null;
        }

        return (
            <div className="container" id="f5-notes-content">
                <div className="sticky-top mt-0 pt-2 mb-4">
                    <Col className="mt-3 no-top-margin px-0">
                        <Row>
                            <Col>
                                <span className='text-secondary-dark prism-h1'>F5 Notes</span>
                            </Col>
                            <Col className='flex-grow-0'>
                                <i className="icon prism-icon-cross cursor-pointer" id='cross-button'
                                   onClick={this.props.hideF5NotesPanel}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col align="left">
                                <Button id="add-f5-note" color="secondary" className="m-2"
                                        onClick={this.showAddNote}
                                        disabled={this.state.showAddNote}
                                >
                                    <i className="icon btn-icon__left prism-icon-plus"/>Add Notes
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </div>

                {this.state.showAddNote && <Row className="notes-row">
                    <AddEditNote cancelAddNote={this.cancelAddNote} noteType="F5Note"/>
                </Row>}
                <div className='mx-2'>
                    {this.renderNotes()}
                </div>

            </div>
        )
    }
}

function mapStateToProps({f5Notes}) {
    return {f5Notes};
}

export default connect(mapStateToProps, null)(ViewF5Notes);
