import Form from "@prism/form";
import Button from "@prism/button";
import React from "react";

const TrimFinder = (props) => {
    const {
        designatedDescriptionResponse,
        onClick
    } = props

    const hasDDSResponse = !!designatedDescriptionResponse;

    return (hasDDSResponse && designatedDescriptionResponse.vcrResponse?.questions?.length > 0 &&
        <Form.Group>
            <Button
                className="btn-full-width"
                color="secondary"
                onClick={onClick}
                id="trim-finder-button">
                Run TrimFinder
            </Button>
        </Form.Group>
    )
}

export default TrimFinder;