import React from "react";
import Row from "@prism/row";
import AnnouncementList from "./AnnouncementList";
import FloodDamage from "./FloodDamage"
import StructuralDamage from "./StructuralDamage";
import StructureAlteration from "./StructureAlteration";
import Section from "../../common/Section";
import SectionBreak from "../../common/SectionBreak";

const Announcements = () => {

    return (
        <Section heading="Announcements" containerBodyClass="p-3">
            <Row>
                <FloodDamage/>
                <StructuralDamage/>
                <StructureAlteration/>
            </Row>
            <SectionBreak/>
            <Row>
                <AnnouncementList/>
            </Row>
            <Row className="ml-3">
                <p className="pr-3 font-weight-lighter"><span className="col-form-label font-weight-bolder text-danger pr-1">*</span>Required</p>
            </Row>
        </Section>
    )

};

export default Announcements;