import {ADD_BMW_REP_NOTE, DELETE_BMW_REP_NOTE} from "./dispatchTypes";

export const addRepNote = (note) => dispatch => {
    dispatch({
        type: ADD_BMW_REP_NOTE,
        note
    })
};

export const deleteRepNote = (note) => dispatch => {
    dispatch({
        type: DELETE_BMW_REP_NOTE,
        note
    })
};
