import {HIDE_ALL_PUSH_PANELS, SHOW_PUSH_PANEL} from "./dispatchTypes";
import {ERROR_MESSAGE_PHRASES} from "../utils/constants";

export const hideAllPushPanels = () => dispatch => {
    dispatch({
        type: HIDE_ALL_PUSH_PANELS
    });
};

export const showPushPanel = panelName => dispatch => {
    dispatch({
        type: SHOW_PUSH_PANEL,
        panelName
    });
};

export const togglePushPanel = panelName => (dispatch, getState) => {
    if (panelName === getState().pushPanels.activePanel) {
        hideAllPushPanels()(dispatch);
    } else {
        showPushPanel(panelName)(dispatch);
    }
};

export const toggleScanPushPanel = () => (dispatch, getState) => {
    if (canToggleScan(getState())) {
        togglePushPanel('scan')(dispatch, getState);
    }
};

export const canToggleScan = (state) => {

    if (!state.consignment) {
        return false;
    }

    if (state.workOrderInfo.workOrderNumber === '') {
        return false;
    }

    if (state.statusMessages) {
        if (state.statusMessages.length >= 1 && ERROR_MESSAGE_PHRASES.some(phrase => state.statusMessages[0].text.includes(phrase))) {
            return false;
        }
    }
    return true;
};