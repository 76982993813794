import React from 'react';
import {connect} from "react-redux";

import Col from '@prism/col';
import Row from '@prism/row';

import WorkListItemStatus from './WorkListItemStatus'
import {
    vehicleTitle,
    vinSplit,
    getColors,
    getDescForIncomplete
} from "../../utils/vehicleDescriptors"
import {setWorkOrderInfo} from "../../actions/scanActions";
import {showDeleteIncompleteInspectionModal} from "../../actions/globalDisplayActions";
import "../../styles/work-list-item.css"
import Button from "@prism/button";
import {OPENABLE_STATUSES, SUCCESS} from "../../utils/constants";
import {OK} from "../../utils/constants";
import {isNativeApp} from "../../utils/utils";
import ConfirmationOrDecisionModal from "../ConfirmationOrDecisionModal";

class WorkListItem extends React.Component {

    state = {
        modalOpen: false
    };

    handleClick = async () => {
        if(!this.canOpenRecord()) {
            this.showLockedModal();
            return;
        }
        const locationCode = this.props.incompleteWorkItem ? this.props.incompleteWorkItem.auctionCode : this.props.stagedInspection.vehicle.locationCode;
        if(isNativeApp() && locationCode && window.NativeApp.getLocationCode() !== locationCode) {
            window.NativeApp.setLocationCode(locationCode);
        }

        const workOrder = this.props.incompleteWorkItem ? this.props.incompleteWorkItem.workOrder : this.props.stagedInspection.vehicle.workOrderNumber;
        await this.props.setWorkOrderInfo({auctionCode: locationCode, workOrderNumber: workOrder});
        this.props.submitScan(locationCode, workOrder);
    };

    handleStatusClick = () => {
        if(!this.shouldDisplayError()) {
            this.showLockedModal();
        }
    }

    getStatusBody = () => {
        if (this.props.stagedInspection?.status) {
            let details = this.props.stagedInspection.status.details;
            let statuses = details && Object.entries(details).filter(([k, v]) => (v.status !== "SKIPPED" && v.status !== "SUCCESS"))
            return (
                <>
                    {this.getFailedMessage()}
                    {
                        statuses?.map(status => {
                            return <p>-- {status[0]} is in {status[1].status} status</p>;
                        })
                    }
                </>
            )
        }
        return (<p>No status available</p>)
    }

    getFailedMessage() {
        if(!this.canOpenRecord()) {
            return <p>Looks like this inspection submission is in progress, please click refresh to get the latest status update. Once submission has completed, you can then re open the inspection. Thank you!</p>;
        } else {
            return <p>Looks like this inspection submission is Failed due to following error. This inspection can be resubmitted by rescanning the work order or by clicking on the work order from the Recent section. Thank you!</p>;
        }
    }

    getModalHeader() {
        if(!this.canOpenRecord()) {
            return "Work Order In Process"
        }
        return "Work Order Failed"
    }

    canOpenRecord = () => {
        if (this.props.incompleteWorkItem) {
            return true;
        }
        if(!this.props.stagedInspection?.status) {
            return false;
        }
        const { overAllStatus } = this.props.stagedInspection.status;
        return OPENABLE_STATUSES.includes(overAllStatus);
    };
    shouldDisplayError = () => {
        if(!this.props.stagedInspection?.status) {
            return false;
        }
        const { overAllStatus } = this.props.stagedInspection.status;
        return SUCCESS.includes(overAllStatus);
    };

    showLockedModal = () => {
        this.setState({ modalOpen: true });
    };

    hideModal = () => {
        this.setState({ modalOpen: false });
    };

    handleDelete = (event, currentWorkorder) => {
        event.stopPropagation();
        return this.props.showDeleteIncompleteInspectionModal(currentWorkorder);
    }

    render() {
        const incompleteWorkItem = this.props.incompleteWorkItem;
        if(!incompleteWorkItem && !this.props.stagedInspection) {
            return <span>No Data</span>;
        }

        try {
            const {vehicle, status, uiDisplayDetails} = this.props.stagedInspection ? this.props.stagedInspection : '';
            const {prefix, last8} = vinSplit(incompleteWorkItem ? incompleteWorkItem?.vin : uiDisplayDetails?.vin);
            const desc = incompleteWorkItem ? getDescForIncomplete(incompleteWorkItem) : vehicleTitle(uiDisplayDetails);
            const wo = incompleteWorkItem ? incompleteWorkItem?.workOrder : vehicle?.workOrderNumber;
            const colorInfo = getColors(incompleteWorkItem ? incompleteWorkItem : uiDisplayDetails);

            return (
                <Row className={`work-list-item mb-2 flex-nowrap ${!this.canOpenRecord() ? "text-primary" : "text-white"}`}>
                    <Col className={`mb-1 row ${!this.canOpenRecord() ? "text-primary" : "text-white"}`}  onClick={this.handleClick}>
                        <h4 className={`font-weight-bold mb-0 col work-order-number ${!this.canOpenRecord() ? "text-primary" : "text-white"}`}>#{wo}</h4>
                        <Col sm={12} className="ymms">{desc}</Col>
                        <Col sm={12} className="vin font-size-md font-weight-lighter">{prefix}<span
                            className="font-weight-normal">{last8}</span></Col>
                        <Col sm={12} className="Color">{colorInfo}</Col>
                    </Col>
                    {!incompleteWorkItem ?
                        <Col className="flex-grow-0 pl-0 work-list-item-status-container" onClick={this.handleStatusClick}>
                            <WorkListItemStatus status={status}/>
                        </Col> :
                        <Button onClick={(event)=>{this.handleDelete(event, wo)}}>
                            <i style={{fontSize: '20px'}}
                                className="icon prism-icon-trash cursor-pointer work-list-item-status-container text-primary" />
                        </Button>}
                    <ConfirmationOrDecisionModal
                        shouldShowModal={this.state.modalOpen}
                        modalHeader={this.getModalHeader()}
                        modalBody={this.getStatusBody()}
                        modalType="locked-record"
                        confirmationText={OK}
                        handleClose={this.hideModal}
                    />
                </Row>
            )
        } catch(err) {
            console.error("Error rendering work list item:", err);
        }

        try {
            const wo = incompleteWorkItem ? incompleteWorkItem?.workOrder : this.props.stagedInspection.vehicle.workOrderNumber;
            return (
                <Row className="work-list-item text-white mb-2">
                    <Col sm={6} className="mb-1 prism-h3 text-smoke">
                        <h4 className="work-order-number font-weight-bold mb-0 text-smoke"
                            onClick={this.handleClick}>#{wo}</h4>
                    </Col>
                    <Button onClick={(event)=>{this.handleDelete(event, wo)}}>
                        <i style={{fontSize: '20px'}}
                            className="icon prism-icon-trash cursor-pointer work-list-item-status-container text-primary" />
                    </Button>
                </Row>
            )
        } catch(err) {
            console.log("Error rendering backup work list item:", err);
        }

        return <span>No Items to Display</span>;
    }
}

const mapDispatchToProps = {
    setWorkOrderInfo,
    showDeleteIncompleteInspectionModal
}


export default connect(null, mapDispatchToProps)(WorkListItem);