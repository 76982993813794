import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React from "react";
import {PLEASE_SELECT, WHEEL_IDS, WHEEL_TYPES} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";

const Wheels = (props) => {
    const {
        designatedDescription,
        onWheelsChange
    } = props

    const getInstalledWheelCode = () => {
        if (!designatedDescription || !designatedDescription.installedEquipment) {
            return null;
        }
        const installedEquipment = designatedDescription.installedEquipment.filter(e => e.classification === 'EXTERIOR' && e.generics?.some(g => WHEEL_IDS.includes(g.id)));

        if (installedEquipment.length > 1) {
            return installedEquipment.find(e => e.installedReason.toLowerCase() !== 'standard')?.generics.find(g => WHEEL_IDS.includes(g.id)).id;
        } else {
            return installedEquipment?.[0]?.generics?.find(g => WHEEL_IDS.includes(g.id)).id;
        }
    }

    return(
        <Form.Group>
            <DataPair id="wheels-dropdown" label="Wheels" required={true}>
                <Dropdown disabled={!designatedDescription?.trim}
                          onChange={onWheelsChange}
                          value={getInstalledWheelCode()}
                          id='configure-wheels'>
                    {!getInstalledWheelCode() && <option value=''>{PLEASE_SELECT}</option>}
                    {WHEEL_TYPES.map(option => {
                        return (<option key={option.id}
                                        value={option.id}>{option.name}</option>)
                    })}
                </Dropdown>
            </DataPair>
        </Form.Group>
    )

}

export default Wheels;