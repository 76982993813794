import React from "react";
import {connect} from "react-redux";
import Col from '@prism/col';
import Row from '@prism/row';
import FlatCar from "./FlatCar";
import {
    clearItemsList,
    clearSelectedFlatCarPanel,
    handlePanelClick
} from "../../../../actions/flatCarActions";
import "../../../../styles/flat-car.css";
import {Typeahead} from 'react-bootstrap-typeahead';
import {
    hideFlatCarPanel,
    showExteriorFlatCarView,
    showInteriorFlatCarView,
    showMechanicalFlatCarView,
    showStructuralFlatCarView
} from "../../../../actions/globalDisplayActions";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ErrorBoundary from "../../../common/ErrorBoundary";

class FlatCarPanel extends React.Component {

    constructor(props) {
        super(props);

        this.itemRef = React.createRef();
        this.transformWrapperRef = React.createRef();
        this.itemWrapperRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            selectedItem: null,
            itemText: '',
            open: true
        };
    };

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside, true);
    };

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside, true);
    };

    handleClickOutside(event) {
        if (this.itemWrapperRef && this.itemWrapperRef.current && !this.itemWrapperRef.current.contains(event.target)) {
            this.setState({
                open: false
            })
        }
    };

    handleSelectedItemChange = (e) => {
        if (e.length) {
            this.setState({
                open: false,
                selectedItem: e[0]
            }, () => this.props.setSelectedItem(this.state.selectedItem))
        }
    };

    reOpenList = () => {
        this.setState({
            open: true
        })
    };

    clearSelectedItem = () => {
        this.itemRef.current.clear();
    };

    clearAllSelections = () => {
        this.clearSelectedItem();
        this.props.clearItemsList();
        this.props.clearSelectedFlatCarPanel();
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedFlatCarPanel !== prevProps.selectedFlatCarPanel) {
            this.setState({
                open: true
            })
        }
    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.flatCarView !== this.props.flatCarView) {
            this.clearSelectedItem();
        }
    };

    resetTransform = () => {
        this.transformWrapperRef.current && this.transformWrapperRef.current.resetTransform();
    };

    render() {
        if (!this.props.selectedFlatCarPanel) {
            return (
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={0}
                    initialPositionY={0}
                    ref={this.transformWrapperRef}
                >
                    <div id="flat-car-panel" className="container">
                        <Row>
                            <Col xs={12}>
                                <div className="flat-car-container mx-2">
                                    <TransformComponent wrapperClass="full-width" contentClass="full-width">
                                        <FlatCar clearSelectedItem={this.clearSelectedItem}/>
                                    </TransformComponent>
                                </div>
                                <div id="flat-car-input-container" ref={this.itemWrapperRef}>
                                    <Typeahead
                                        ref={this.itemRef}
                                        id="flat-car-item-list"
                                        options={[]}
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </TransformWrapper>
            )
        } else {
            return (
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={0}
                    initialPositionY={0}
                    ref={this.transformWrapperRef}
                >
                    <div id="flat-car-panel" className="container">
                        <Row>
                            <Col xs={12}>
                                <div className="flat-car-container mx-2">
                                    <TransformComponent wrapperClass="full-width" contentClass="full-width">
                                        <FlatCar clearSelectedItem={this.clearSelectedItem}/>
                                    </TransformComponent>
                                </div>
                                <div id="flat-car-search-field" ref={this.itemWrapperRef}>
                                    <ErrorBoundary>
                                    <Typeahead
                                        ref={this.itemRef}
                                        id="flat-car-item-list"
                                        className="mt-3 mx-2"
                                        open={this.state.open}
                                        style={{
                                            border: '1px solid #babcbe',
                                            zIndex: '1001',
                                            display: this.props.hideItemSearch || this.props.isApiLoading  ? "none" : "block"
                                        }}
                                        maxResults={1000}
                                        paginate={true}
                                        placeholder="Search Items for Selected Panel"
                                        onFocus={this.reOpenList}
                                        labelKey={option => `${option.description}`}
                                        options={this.props.itemList.items || []}
                                        onChange={this.handleSelectedItemChange}
                                        clearButton={true}
                                    />
                                    </ErrorBoundary>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </TransformWrapper>
            )
        }
    }
}

const matchDispatchToProps = {
    clearItemsList,
    clearSelectedFlatCarPanel,
    hideFlatCarPanel,
    showExteriorFlatCarView,
    showInteriorFlatCarView,
    showMechanicalFlatCarView,
    showStructuralFlatCarView,
    handlePanelClick
};

function mapStateToProps({globalDisplay, itemList}) {
    const {flatCarView, selectedFlatCarPanel} = globalDisplay;
    return {flatCarView, itemList, selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps, null, {forwardRef: true})(FlatCarPanel);
