import React from "react";
import Row from "@prism/row";
import InteriorOdor from "./InteriorOdor";
import Emissions from "./Emissions";
import Section from "../../common/Section";

const Summary = () => {
    return (
        <Section heading="Summary">
            <Row className="mx-0 mb-3">
                <Emissions/>
                <InteriorOdor/>
            </Row>
        </Section>
    )
};

export default Summary;