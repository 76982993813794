import React from 'react';
import {connect} from "react-redux";
import AddDamageButton from "./AddDamageButton";
import DamageLineItem from "./DamageLineItem";
import {sortDamagesByArea} from "../../../utils/utils";

class Damages extends React.Component {

    render() {
        if (!this.props.condition) {
            return null
        }
        return (
            <div id="damage-list-view" className="p-3">

                {this.props.condition.damages?.length > 0 ? sortDamagesByArea(this.props.condition.damages).map(damage => (
                    <DamageLineItem damage={damage} key={damage.damageKey}/>
                )) : <div id={"no-damage-found"} className={"my-6 align-center"}>NO DAMAGE FOUND</div>}
                {
                    (!this.props.pushPanels || !this.props.pushPanels.activePanel) &&
                    <AddDamageButton disabled={false}/>
                }

            </div>
        )
    }

}

const matchDispatchToProps = {};

function mapStateToProps({condition, pushPanels}) {
    return {condition, pushPanels};
}

export default connect(mapStateToProps, matchDispatchToProps)(Damages);
