import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import "../../../styles/review-panel.css";
import "../../../utils/messages"
import {
    NO_ANNOUNCEMENTS
} from "../../../utils/messages";

class AnnouncementsReview extends React.Component {

    getValidationMessages = () => {
        const validationMessages = []
        if( this.props.announcements !== undefined && this.props.announcements.items !== undefined){
            this.props.announcements.items.map(item => item.announcements)
                .forEach(
                    anns => anns.map(
                        ann => validationMessages.push(ann.text)
                    )
                );
        }
        if(validationMessages.length === 0){
            validationMessages.push(NO_ANNOUNCEMENTS);
        }
        return validationMessages

    }

    render() {
        return (
            <Row className={`px-6 py-4 mx-0 review-row ${" show-is-clickable"}`}
                 >
                <Col className="flex-grow-1" id={'announcements'}>

                    {this.getValidationMessages().map(ann => {
                        return (
                            <Row className="h2 review-list-rows"><Col>{ann}</Col></Row>)
                    })}
                </Col>
            </Row>

        );
    }
}

function mapStateToProps({condition, description, announcements}) {
    return {condition, description, announcements};
}

const matchDispatchToProps = {
}

export default connect(mapStateToProps, matchDispatchToProps)(AnnouncementsReview);
