import React, {useEffect, useState} from "react";
import {COLOR_GENERIC, COLOR_OEM, NOT_SPECIFIED, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import {isOemColor} from "../../../utils/utils";
import isEmpty from "lodash.isempty";

const SeatTrimDropdown = (props) => {

    const {designatedDescription, designatedDescriptionResponse} = props;

    const [options, setOptions] = useState([]);
    const [showGenericOptions, setShowGenericOptions] = useState(null);
    const [showOemOptions, setShowOemOptions] = useState(null);
    const [interiorColor, setInteriorColor] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [seatTrim, setSeatTrim] = useState('');

    useEffect(() => {
        const interiorColors = designatedDescription?.colors?.interior;
        const hasDesignatedIntColor = interiorColors?.find(color => !!color.isDesignated)

        if (hasDesignatedIntColor) {
            setInteriorColor(designatedDescription.colors.interior[0])
        } else {
            setInteriorColor(null);
        }

        const getInteriorColorCategory = (interiorColor) => {
            if (!interiorColor) {
                return null;
            }
            return isOemColor(interiorColor) ? COLOR_OEM : COLOR_GENERIC;
        }

        const interiorColorCategory = getInteriorColorCategory(interiorColor);

        if (isEmpty(interiorColor) || interiorColorCategory === null) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        const hasMaterialType = () => {
            return !!designatedDescription?.colors?.interior?.[0].materialType && designatedDescription.colors.interior[0].materialType.length > 0;
        }

        if (interiorColor && hasMaterialType()) {
            setSeatTrim(designatedDescription.colors.interior[0].materialType[0])
        } else {
            setSeatTrim('')
        }

        const getOriginalVehicle = () =>  {
            const catalogVehicles = designatedDescriptionResponse?.catalogVehicles || [];
            return catalogVehicles.find(vehicle => vehicle.catalogId === designatedDescription.catalogId);
        }

        const getOriginalColor = (originalVehicle, interiorColor) => {
            let originalColor;
            let originalColors = originalVehicle?.colors?.interior || [];
            if (interiorColor && interiorColor.oemOptionCode && originalColors.length) {
                originalColor = originalColors.find(color => color.oemOptionCode === interiorColor.oemOptionCode)
            } else if (interiorColor && interiorColor.oemName && originalColors.length) {
                originalColor = originalColors.find(color => color.oemName === interiorColor.oemName)
            }
            return originalColor;
        }

        const originallyMissingMaterialType = (interiorColor, interiorColorCategory) => {
            if (interiorColor && interiorColorCategory === COLOR_OEM) {
                const originalColor = getOriginalColor(getOriginalVehicle(), interiorColor);
                return !originalColor?.materialType?.length;
            }
            return false;
        }

        const originalMaterialNotSpecified = (interiorColor, interiorColorCategory) => {
            if (interiorColor && interiorColorCategory === COLOR_OEM) {
                const originalColor = getOriginalColor(getOriginalVehicle(), interiorColor);
                return originalColor?.materialType?.includes(NOT_SPECIFIED);
            }
            return false;
        }

        const originallyMissingMaterial = originallyMissingMaterialType(interiorColor, interiorColorCategory);
        const materialNotSpecified = originalMaterialNotSpecified(interiorColor, interiorColorCategory);

        if (interiorColorCategory === COLOR_OEM) {
            if (!originallyMissingMaterial && !materialNotSpecified) {
                setShowGenericOptions(false)
                setShowOemOptions(true)
                setOptions([interiorColor])
            } else {
                setShowGenericOptions(true)
                setShowOemOptions(false)
                setOptions(props.genericInteriorTypes)
            }
        } else if (interiorColorCategory === COLOR_GENERIC) {
            setShowGenericOptions(true)
            setShowOemOptions(false)
            setOptions(props.genericInteriorTypes)
        }

    }, [props, interiorColor, designatedDescription?.colors?.interior, designatedDescription?.catalogId, designatedDescriptionResponse?.catalogVehicles]);

    const seatTrimIsSelected = () => {
        let installedColor;
        if (designatedDescription?.colors && designatedDescription.colors?.interior) {
            installedColor = designatedDescription.colors.interior.find(color => !!color.isDesignated);
        }
        return !!(installedColor && installedColor.materialType && installedColor.materialType.length)
    }

    return (
        <Dropdown
            value={seatTrim}
            key={seatTrim}
            id='configure-seat-trim'
            onChange={props.onChange}
            disabled={disabled}
        >
            {!seatTrimIsSelected() &&
                <option value='' disabled>{PLEASE_SELECT}</option>}
            {(options.length && showOemOptions) && options.map(
                option => {
                    return (<option
                        value={option.materialType}
                        key={option.oemOptionCode}
                    >{option.materialType?.join('/')}</option>)
                }
            )}
            {(options.length && showGenericOptions) && options.map(
                option => {
                    return (<option
                        value={option.name}
                        key={option.name}
                    >{option.name}</option>)
                }
            )}
        </Dropdown>
    )
};

export default SeatTrimDropdown;