import React from 'react';

import Button from '@prism/button';
import Col from '@prism/col';
import Message from '@prism/message';
import RadioButton from '@prism/radiobutton';
import Row from '@prism/row';

import {formatDollarsAndCents} from "../../formatters";
import OutlinePanel, {PanelHead} from "../common/OutlinePanel";

class Pricing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            damage: {},
            currentIndex: 0,
            pricingTypes: [],
            radioToggle: {
                id: '',
                checked: false,
                key: ''
            },
            statusMessage: {text: '', color: ''}
        };
    }

    componentWillMount() {
        this.setState({
            damage: this.props.damage,
            pricingTypes: this.props.pricingTypes,
            showCalculator: true,
        });
    };

    componentDidMount() {
    }

    updatePricing = () => {

        let available = false;
        let pricingOptions = [];
        this.state.pricingTypes.forEach(type => {
            if (type.has(this.state.radioToggle.key)) {
                available = true;
                pricingOptions = type.get(this.state.radioToggle.key);
            }
        });
        if (!available) {
            return this.setErrorMessage('Please select a part to save this damage');
        }

        const selectedPrice = pricingOptions.find(value => value.id === this.state.radioToggle.id);

        //Get the selected account price and set flag to hide calculator
        if (this.state.currentIndex === 0 && (selectedPrice.repairDollars || selectedPrice.repairHours)) {
            this.setState({
                showCalculator: false,
            });
        }

        let pricePlanList = this.state.damage.pricePlanIds || [];
        const pricePlanId = {
            pricingType: this.state.radioToggle.key.toLowerCase(),
            pricePlanId: selectedPrice.id
        };

        pricePlanList.push(pricePlanId);

        if (this.state.currentIndex < this.state.pricingTypes.length - 1) {
            return this.setState((prevState) => ({
                currentIndex: this.state.currentIndex + 1,
                statusMessage: {text: '', color: ''},
                radioToggle: {},
                damage: {
                    ...prevState.damage,
                    pricePlanIds: pricePlanList
                }
            }));
        }

        if (this.state.currentIndex === this.state.pricingTypes.length - 1) {
            return this.setState((prevState) => ({
                damage: {
                    ...prevState.damage,
                    pricePlanIds: pricePlanList
                },
                radioToggle: {},
                statusMessage: {text: '', color: ''}
            }), () => {
                this.props.handlePricingUpdate(this.state.damage);
                this.props.hidePricingOverlay();
            });
        }
    };

    onClick = (e) => {
        const {id, value, checked} = e.target;
        this.setState({
            radioToggle: {
                id,
                checked,
                key: value
            }
        })
    };

    setErrorMessage = (errorMessage) => {
        this.setState({statusMessage: {text: errorMessage, color: 'danger'}});
    };

    createTable = () => {

        for (let [k, v] of this.state.pricingTypes[this.state.currentIndex]) {
            return (
                <Row>
                    <Col>
                        <Row className="mt-3 mb-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <span
                                            className="font-weight-bold"
                                            style={{fontSize: '1.8rem'}}>Select {k} Part
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span
                                            className="font-size-md">{this.state.damage.item + ', ' + this.state.damage.damage + ', ' + this.state.damage.severity}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <table className="panel-table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-center">Paint</th>
                                        <th className="text-center">Repair</th>
                                        <th className="text-center">R&I</th>
                                        <th className="text-center">Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {v.map((price) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td colSpan={5}>{price.description}</td>
                                                </tr>
                                                <tr key={price.id} color="primary">
                                                    <td>
                                                        <RadioButton
                                                            label=""
                                                            id={price.id}
                                                            value={k}
                                                            checked={this.state.radioToggle.id === price.id ? this.state.radioToggle.checked : false}
                                                            onClick={this.onClick}/>
                                                    </td>
                                                    <td className="text-center">
                                                        <div>{price.paintHours ? price.paintHours.toFixed(1) : '-'}</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div>{price.repairDollars ? formatDollarsAndCents(price.repairDollars) : '-'}</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div>{price.partHours ? price.partHours.toFixed(1) : '-'}</div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div>{formatDollarsAndCents(price.partCost)}</div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <div className='sticky-bottom bg-white' style={{bottom: 0, paddingBottom: '1px'}}>
                            {this.state.pricingTypes.length > 1 && <Row>
                                <Col xs={12}
                                     className="text-right font-size-sm font-weight-light mb-2">Step {this.state.currentIndex + 1} of {this.state.pricingTypes.length}
                                </Col>
                            </Row>}
                            <Row
                                className="mb-3"><Col xs={12}>
                                <Button
                                    className="btn-full-width" color="primary"
                                    onClick={this.updatePricing}>Continue</Button>
                            </Col>
                            </Row>
                            <Row
                                className="mb-3">
                                <Col xs={12}>
                                    <Button
                                        outline className="btn-full-width"
                                        onClick={this.props.cancelPricingOverlay}>Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>)
        }
    } ;

    render() {
        return (
            <OutlinePanel className="bg-white" id={this.props.id}>
                <PanelHead className="bg-ash sticky-top">
                    <Row>
                        <Col className="prism-h1 text-right mb-0 pr-4">
                            <i id="repair-summary-panel-close-icon"
                               onClick={this.props.cancelPricingOverlay}
                               className="icon prism-icon-cross"/>
                        </Col>
                    </Row>
                </PanelHead>
                <Row>
                    <Col className="pl-0 pr-0">
                        <Message
                            id="damage-calculator-error-message"
                            isOpen={this.state.statusMessage.text.length > 0}
                            color={this.state.statusMessage.color}>{this.state.statusMessage.text}
                        </Message>
                    </Col>
                </Row>
                {
                    this.createTable()
                }
            </OutlinePanel>
        )
    }
}

export default Pricing;
