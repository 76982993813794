import React from 'react';
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updatePaintType} from "../../../actions/conditionActions";
import {connect} from "react-redux";

class PaintType extends React.Component {

    handlePaintTypeChange = (e) => {
        this.props.updatePaintType(e.currentTarget.value)
    };

    render() {

        if(!this.props.condition) {return null}
        const paintType = this.props.condition.paintType ? [this.props.condition.paintType] : [];

        return (
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                <DataPair label="Paint Type" required>
                    <Dropdown key={`paint-type${paintType}`} value={paintType} onChange={this.handlePaintTypeChange}>
                        <option value={""} disabled>Select One...</option>
                        {this.props.staticOptionValues && this.props.staticOptionValues.paintTypes.map(option => {
                            return (
                                <option key={option.description} value={option.description}>{option.description}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updatePaintType
};


function mapStateToProps({condition, staticOptionValues}) {
    return {condition, staticOptionValues};
}

export default connect(mapStateToProps, matchDispatchToProps)(PaintType);