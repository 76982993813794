import React from "react";
import "../styles/index.css";
import "../styles/vehicle-info-header.css";
import "../styles/add-damage-panel.css";
import "../styles/hero-panel.css"
import '../styles/Accordion.css';
import "../styles/close-inspection-modal.css";
import "../styles/audit-error-modal.css"
import "@prism/styles/prism.css";
import "@prism/styles/prism/accordion.css";
import "@prism/styles/prism/autocomplete.css";
import "@prism/styles/prism/button.css";
import '@prism/styles/prism/buttongroup.css';
import "@prism/styles/prism/checkbox.css";
import "@prism/styles/prism/chip.css";
import '@prism/styles/prism/dropdown.css';
import '@prism/styles/icon-font.css';
import "@prism/styles/prism/input.css";
import "@prism/styles/prism/inputgroup.css"
import "@prism/styles/prism/form.css";
import "@prism/styles/prism/radiobutton.css";
import "@prism/styles/prism/switch.css";
import "@prism/styles/prism/tabs.css";
import "@prism/styles/prism/actions.css";
import "@prism/styles/prism/modal.css";
import '@prism/styles/prism/filmstrip.css';
import '@prism/styles/prism/imageviewer.css';
import '@prism/styles/prism/message.css';
import "../styles/profile-panel.css";
import "../styles/App.css";
import "../styles/modal.css"
import "../styles/tabs.css"
import "../styles/custom-tabs.css";
import Api from './Api';
import Row from "@prism/row";
import Col from "@prism/col";
import ButtonTrayPanel from "./buttonTray/ButtonTrayPanel";
import ScannerInput from "./ScannerInput";
import SpinnerOverlay from "./Spinner/SpinnerOverlay";
import VehicleInformationHeader from "./vehicleInfoHeader/VehicleInformationHeader";
import ConditionContent from "./condition/ConditionContent";
import {ScanSubmitter} from "../actions/ScanSubmitter";

import {setCurrentConditionTabIndex} from "../actions/naviationActions";
import {hideAllPushPanels, togglePushPanel, toggleScanPushPanel} from "../actions/pushPanelActions";
import {loadingWorkOrder, loadingWorkOrderComplete} from "../actions/progressActions";
import {getUserDetails} from "../actions/userDetailsActions";
import {resubmitForL1, setScanAuctionCode, submitScan} from "../actions/scanActions";
import {fetchAnnouncementsDictionary} from "../actions/dataListActions";
import {
    clearVehicleDataFromState,
    hideAuditExistsModal,
    hideCancelButtonForLoad,
    hideCloseInspectionConfirmModal,
    hideLogoutModal,
    hideProfileModalWhileInsideWO,
    hideWorkOrderErrorModal,
    showCancelButtonForLoad,
    showCloseInspectionConfirmModal,
    showLogoutModal,
    showNonNativeButtonTray,
    updateVehicleIsSold
} from "../actions/globalDisplayActions";
import NotesContainer from './comments/NotesContainer';

import {connect} from "react-redux";
import Profile from "./profile/Profile";
import {setApplicationFlags} from "../actions/appFlagsActions";
import {updateDamageImage} from "../actions/damageImageActions";
import PhotoPanelContent from "./photoPanelContent/PhotoPanelContent";
import {APPROVALS_API_URL, ENVIRONMENT, LOG_ROCKET_APP_NAME, PENDO_API_KEY} from "../config/env";
import {isNativeApp} from "../utils/utils";
import {addMiscellaneousImage} from "../actions/miscellaneousImageActions";
import AuditModeErrorModal from "./AuditModeErrorModal";
import ConfirmationOrDecisionModal from "./ConfirmationOrDecisionModal";
import {
    AUDIT_EXISTS,
    AUDIT_EXISTS_DECISION_MESSAGE,
    CANCEL,
    CLOSE_INSPECTION_BODY,
    CLOSE_INSPECTION_HEADER,
    CONFIRM,
    CONTINUE,
    LOGOUT_BODY,
    LOGOUT_HEADER,
    OK,
    SESSION_TIMEOUT_BODY,
    SESSION_TIMEOUT_HEADER
} from "../utils/constants";
import RepurposeModal from "./RepurposeModal";
import LogRocket from "logrocket";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.scanSubmitter = new ScanSubmitter();
        this.state = {
            sessionTimeout: false,
            handleWorkOrderFromScannerInput: null,
        }
    }

    startBackendHeartbeat = () => {
        this.timeoutID = setTimeout(() => this.handleBackendHeartbeat());
    };

    stopBackendHeartbeat = () => {
        clearTimeout(this.timeoutID);
    };

    handleBackendHeartbeat = () => {
        //Call our api to get the current user's details
        Api.getUserDetails().then((response) => {
            //If we don't get a response back, timeout the session, and stop the backend heartbeat
            this.updateNativeUserDetails(response)
            if (response === null) {
                this.setState({sessionTimeout: true});
                this.stopBackendHeartbeat();
            }
            //If we do get a response back, reset our timer and don't call this again for 90s
            else {
                this.timeoutID = setTimeout(() => this.handleBackendHeartbeat(), 90000);
            }
        }).catch(err => {
            console.debug("Error:", err);
            this.timeoutID = setTimeout(() => this.handleBackendHeartbeat(), 90000);
        });
        return this.timeoutID;
    };

    handleSessionTimeout = () => {
        if (isNativeApp()) {
            window.NativeApp.onSessionTimeOut();
        } else {
            window.location.href = '/logout';
        }
    };

    handleLogoutUser = () => {
        this.clearSession();
        if (isNativeApp()) {
            window.NativeApp.onLogoutConfirmed();
        } else {
            window.location.href = '/logout';
        }
    };

    handleCloseInspectionModal = () => {
        this.props.hideCloseInspectionConfirmModal();
        this.resetInspection();
    };

    handleCancelAuditExistsModal = () => {
        this.props.hideAuditExistsModal();
        this.resetInspection();
    };

    handleContinueAuditExistsModal = () => {
        this.props.hideAuditExistsModal();
    };

    resetInspection() {
        this.props.toggleScanPushPanel();
        this.props.setCurrentConditionTabIndex(1);
        this.props.clearVehicleDataFromState();

        if (isNativeApp()) {
            window.NativeApp.setSupportOnlyOptionsVisible();
        }
    }

    clearSession = async () => {
        await Api.clearSession().then((response) => {
            console.log("User cache cleared");
        }).catch(err => {
            console.log("Failed to clear cache on logout", err);
        })
    };

    handleLogoutClick = () => {
        try {
            console.log("Are you sure you want to logout ...");
            this.props.showLogoutModal();
        } catch (error) {
            console.log("Error calling nativeapp_logout:", error);
        }
    };

    nativeapp_onLocationChanged = (request) => {
        try {
            if (this.props.pushPanels.activePanel === 'scan') {
                this.props.setScanAuctionCode(request);
                console.log("Auction code set to ", request);
            } else {
                console.log("Scan panel was not open .. not updating location")
            }
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onLocationChanged:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onScanClick = (request) => {
        // receives scan button click with work order number
        try {
            if (this.props.pushPanels.activePanel !== 'scan') {
                this.props.showCloseInspectionConfirmModal();
            }
            this.setAuctionCode(request);
            console.log("Scanner button clicked");
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onScanClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onLogoutClick = (request) => {
        try {
            console.log("Logout button clicked");
            this.props.showLogoutModal();
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_logout:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onConditionClick = (request) => {
        try {
            console.log("Condition button clicked");
            this.props.showPanel("condition")
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onConditionClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onCrClick = (request) => {
        try {
            console.log("CR button clicked");
            this.props.togglePushPanel("photos");
            this.props.showPanel("photos")
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onCrClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onProfileClick = () => {
        console.log("Profile icon clicked");
        try {
            if (this.props.pushPanels.activePanel === 'profile') {
                if (!this.props.workOrderNumber) {
                    this.props.togglePushPanel("scan");
                } else {
                    this.props.hideProfileModalWhileInsideWO();
                    this.props.hideAllPushPanels()
                }
            } else {
                this.props.togglePushPanel("profile");
            }
            return ({status: 'ok', payload: {received_data: "Profile icon clicked"}});
        } catch (error) {
            console.log("Error calling nativeapp_onProfileClick", error);
            return ({status: 'failed', payload: {error: error}});
        }
    };

    nativeapp_onPhotosClick = (request) => {
        try {
            console.log("Photos button clicked");
            this.props.togglePushPanel("photos");
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onPhotosClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onPhotoCaptured = (localHref, tag) => {
        try {
            console.log("Updating image list with new photo", {localHref, tag});
            switch (this.imageType(tag)) {
                case 'damage':
                    this.props.updateDamageImage(tag, localHref);
                    break;
                case 'miscellaneous':
                    this.props.addMiscellaneousImage(tag, localHref);
                    break;
                default:
                    console.warn("image was not recognized type")
                    return null;
            }

            return ({status: 'ok', payload: {received_data: {localHref: localHref, tag: tag}}});
        } catch (error) {
            console.log("Error calling nativeapp_onPhotoCaptured:", error);
            return ({status: 'failed', payload: {received_data: {localHref: localHref, tag: tag}, error: error}});
        }
    };

    imageType = (tag) => {
        if (tag.match(/^L1-/)) {
            return 'damage';
        } else if (tag.match(/^MISCELLANEOUS\d+/)) {
            return 'miscellaneous'
        } else {
            return null;
        }
    };

    nativeapp_onCommentsClick = (request) => {
        try {
            console.log("Comments button clicked");
            this.props.togglePushPanel("comments");
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onCommentsClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onSummaryClick = (request) => {
        try {
            console.log("Summary button clicked");
            this.props.hideAllPushPanels();
            this.props.showPanel("condition");
            this.props.setCurrentConditionTabIndex(4);
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onSummaryClick:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onApplicationPaused = (request) => {
        try {
            console.log("Native: Application paused");
            //this.stopBackendHeartbeat();
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onApplicationPaused:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onApplicationResumed = (request) => {
        try {
            console.log("Native: Application resumed");
            //this.startBackendHeartbeat();
            return ({status: 'ok', payload: {received_data: request}});
        } catch (error) {
            console.log("Error calling nativeapp_onApplicationResumed:", error);
            return ({status: 'failed', payload: {received_data: request, error: error}});
        }
    };

    nativeapp_onBarCodeScan = (workOrderNumber) => {
        //Native barcode scanner clicked
        //receives work order number from barcode scanner
        console.log("active panel: ", this.props.pushPanels.activePanel)
        if (this.props.pushPanels.activePanel === 'scan') {
            try {
                console.log("Barcode scanned", workOrderNumber);
                this.setState({workOrderNumber: workOrderNumber}, () => {
                    this.handleWorkOrderAndSubmitScan(workOrderNumber);
                });
                return ({status: 'ok', payload: {received_data: workOrderNumber}});
            } catch (error) {
                console.log("Error calling nativeapp_onBarcodeScan:", error);
                return ({status: 'failed', payload: {received_data: workOrderNumber, error: error}});
            }
        } else {
            console.log("scan panel not open");
        }
    };

    updateWorkOrderNumber = (workOrderNumber) => {
        this.setState({workOrderNumber});
    };

    updateNativeUserDetails = (userDetails) => {
        try {
            if (userDetails !== null && userDetails !== undefined) {
                window.NativeApp.setOAuthTokenAndTag(userDetails.userToken, userDetails.userId, userDetails.emailId, userDetails.role.scopes);
            } else {
                // profile icon should only be available when the user is logged in
                window.NativeApp.setProfileIcon(false)
            }
        } catch (e) {
            console.error("Error giving oauth token to native:", e);
        }
    };

    async componentDidMount() {

        // redirect to 401 if web view and in prod or uat
        if (!isNativeApp() && ['uat', 'prod'].includes(ENVIRONMENT)) {
            window.location.href = "/401";
        }

        this.props.setApplicationFlags();

        this.startBackendHeartbeat();

        await this.props.getUserDetails();

        if (this.props.userDetails === null) {
            window.location.href = `${APPROVALS_API_URL}/authenticate?applicationName=smart_inspect`;
        } else if (this.props.userDetails.role == null) {
            //TODO
            //this.props.disableAllButtons();
            window.location.href = "/401";
        } else {
            await this.props.fetchAnnouncementsDictionary();

            if (typeof (window.NativeApp) !== 'undefined') {
                console.log(`getting location code from native ${window.NativeApp.getLocationCode()}`);
                this.setAuctionCode(window.NativeApp.getLocationCode());
                this.updateNativeUserDetails(this.props.userDetails);
            } else {
                //TODO: Turn off showing the button tray
                this.props.showNonNativeButtonTray();
            }
        }

        // Start Pendo
        this.loadPendoScript();
        this.initLogRocket();
    }

    initLogRocket = () => {
        LogRocket.init(LOG_ROCKET_APP_NAME);
        LogRocket.identify(this.props.userDetails.userId, {
            name: this.props.userDetails.fullName,
            email: this.props.userDetails.emailId
        });
    }

    setAuctionCode = async (auctionCode) => {
        if (auctionCode) {
            await this.props.setScanAuctionCode(auctionCode);
            console.log("auction code set to ", auctionCode);
        }
    };

    submitScan = async (auctionCode, workOrderNumber) => {
        await this.props.hideCancelButtonForLoad();
        let timerId = this.showCancelButtonTimer();
        await this.props.submitScan(this.scanSubmitter, timerId, {auctionCode, workOrderNumber});
    };

    handleWorkOrderAndSubmitScan = async (workOrderNumber) => {
        if (this.state.handleWorkOrderFromScannerInput) {
            await this.state.handleWorkOrderFromScannerInput(workOrderNumber);
        }
        this.submitScan(this.props.scanAuctionCode, workOrderNumber);
    };

    showCancelButtonTimer = () => {
        return setTimeout(() => this.props.showCancelButtonForLoad(), 5000)
    };

    pendoScript = () => {
        return `(function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

            pendo.initialize({
                visitor: {
                    id: "${this.props.userDetails.emailId}"
                },
                account: {
                    id: "SmartInspect"
                }
            });
            
        })("${PENDO_API_KEY}");`
    }

    loadPendoScript = () => {
        if (['prod', 'ba'].includes(ENVIRONMENT)) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = this.pendoScript();
            document.head.appendChild(s);
        }
    }

    handleCloseWorkOrderErrorModal = () => {
        this.props.updateVehicleIsSold(false)
        this.props.hideWorkOrderErrorModal()
    };

    handleContinueWorkOrderErrorModal = () => {
        this.props.hideWorkOrderErrorModal()
        let timerId = this.showCancelButtonTimer()
        this.props.resubmitForL1(this.scanSubmitter, timerId)
    };

    getConfirmationText = () => {
        return this.props.vehicleIsSold || this.props.approvalExists ? CONTINUE : OK
    };

    render() {
        if (!this.props.userDetails || this.props.userDetails === {}) {
            return null
        }
        return (
            <div className="App">
                {this.props.progress.fetchWoInProgress ?
                    <SpinnerOverlay id='loading-spinner' showCancelButton={true} scanSubmitter={this.scanSubmitter}
                                    size={100}/> : null}
                <Row className="mx-0 px-0">
                    <ButtonTrayPanel
                        name="scan"
                        showClose={false}
                        onRequestClose={() => this.props.hideAllPushPanels()}>
                        <ScannerInput
                            submitScan={this.submitScan}
                            scanner={this.scanSubmitter}
                            disabled={this.props.progress.fetchWoInProgress}
                            workOrderNumber={this.state.workOrderNumber}
                            updateWorkOrderNumber={this.updateWorkOrderNumber}
                            setChildFunction={(func) => this.setState({handleWorkOrderFromScannerInput: func})}
                            // updateScanWorkOrderState = {this.updateScanWorkOrderState}/>
                        />
                    </ButtonTrayPanel>
                    <ButtonTrayPanel name="profile" width="87vw" from="right" showClose={false}
                                     overlayClassName="profile-slider">
                        <Profile/>
                    </ButtonTrayPanel>
                    <ButtonTrayPanel name="photos" width="100vw">
                        <PhotoPanelContent/>
                    </ButtonTrayPanel>
                    <ButtonTrayPanel name="comments" width="100vw" textColor='black'>
                        <NotesContainer/>
                    </ButtonTrayPanel>
                    <Col id="header-container" className="pl-0 bg-white">
                        {/* SESSION TIMEOUT MODAL */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={this.state.sessionTimeout}
                            modalHeader={SESSION_TIMEOUT_HEADER}
                            modalBody={SESSION_TIMEOUT_BODY}
                            modalType="session-timeout"
                            confirmationText={OK}
                            handleContinue={this.handleSessionTimeout}
                        />
                        {/* LOG OUT MODAL */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={this.props.shouldShowLogoutModal}
                            modalHeader={LOGOUT_HEADER}
                            modalBody={LOGOUT_BODY}
                            modalType="logout"
                            confirmationText={OK}
                            cancelText={CANCEL}
                            handleClose={this.props.hideLogoutModal}
                            handleContinue={this.handleLogoutUser}
                        />
                        {/* CLOSE INSPECTION MODAL (M LOGO CLICK) */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={this.props.showCloseInspectionConfirm}
                            modalHeader={CLOSE_INSPECTION_HEADER}
                            modalBody={CLOSE_INSPECTION_BODY}
                            modalType="close-inspection"
                            confirmationText={CONFIRM}
                            cancelText={CANCEL}
                            handleClose={this.props.hideCloseInspectionConfirmModal}
                            handleContinue={this.handleCloseInspectionModal}
                        />
                        {/* AUDIT EXISTS MODAL */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={this.props.shouldShowAuditExistsModal && !this.props.navigation.selectedConditionTabIndex}
                            modalHeader={AUDIT_EXISTS}
                            modalBody={AUDIT_EXISTS_DECISION_MESSAGE}
                            modalType="audit-exists"
                            confirmationText={CONTINUE}
                            cancelText={CANCEL}
                            handleClose={this.handleCancelAuditExistsModal}
                            handleContinue={this.handleContinueAuditExistsModal}
                        />
                        {/* VEHICLE IS SOLD OR APPROVAL EXISTS OR OTHER ERROR MODAL */}
                        <ConfirmationOrDecisionModal
                            shouldShowModal={this.props.errorFetchingWorkOrder}
                            modalHeader={this.props.vehicleErrorType}
                            modalBody={this.props.vehicleErrorMsg}
                            modalType="work-order-error"
                            confirmationText={this.getConfirmationText()}
                            cancelText={this.props.vehicleIsSold || this.props.approvalExists ? CANCEL : null}
                            handleClose={this.handleCloseWorkOrderErrorModal}
                            handleContinue={this.handleContinueWorkOrderErrorModal}
                        />
                        <AuditModeErrorModal
                            submitScan={this.submitScan}
                        />
                        <RepurposeModal/>
                        {this.props.consignment && <VehicleInformationHeader/>}
                        {this.props.consignment && <ConditionContent/>}
                    </Col>
                </Row>
            </div>
        )
    }

}

const matchDispatchToProps = {
    setCurrentConditionTabIndex,
    hideAllPushPanels,
    getUserDetails,
    submitScan,
    fetchAnnouncementsDictionary,
    showNonNativeButtonTray,
    setApplicationFlags,
    setScanAuctionCode,
    togglePushPanel,
    toggleScanPushPanel,
    showLogoutModal,
    showCloseInspectionConfirmModal,
    hideCloseInspectionConfirmModal,
    clearVehicleDataFromState,
    updateDamageImage,
    addMiscellaneousImage,
    loadingWorkOrder,
    loadingWorkOrderComplete,
    showCancelButtonForLoad,
    hideCancelButtonForLoad,
    hideProfileModalWhileInsideWO,
    hideWorkOrderErrorModal,
    resubmitForL1,
    updateVehicleIsSold,
    hideAuditExistsModal,
    hideLogoutModal
};

function mapStateToProps(
    {
        navigation,
        userDetails,
        globalDisplay,
        pushPanels,
        damageImages,
        workOrderInfo,
        addMiscellaneousImage,
        progress,
        consignment
    }
) {
    const {
        showButtonTray,
        errorFetchingWorkOrder,
        vehicleErrorMsg,
        vehicleErrorType,
        approvalExists,
        vehicleIsSold,
        hasAudit,
        shouldShowAuditExistsModal,
        showCloseInspectionConfirm,
        shouldShowLogoutModal
    } = globalDisplay;
    const {auctionCode, scanAuctionCode, workOrderNumber} = workOrderInfo;
    return {
        navigation,
        userDetails,
        showButtonTray,
        pushPanels,
        damageImages,
        auctionCode,
        scanAuctionCode,
        workOrderNumber,
        addMiscellaneousImage,
        progress,
        consignment,
        errorFetchingWorkOrder,
        vehicleErrorMsg,
        vehicleErrorType,
        approvalExists,
        vehicleIsSold,
        hasAudit,
        shouldShowAuditExistsModal,
        showCloseInspectionConfirm,
        shouldShowLogoutModal
    };
}

export default connect(mapStateToProps, matchDispatchToProps, null, {forwardRef: true})(App);
