import {LOAD_WORK_ORDER_SUCCESS} from "../actions/dispatchTypes";

export default function heartbeatReducer(state = {}, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return {...state, loadTimestamp: action.payload.loadTimestamp}
        default:
            return state;
    }
}
