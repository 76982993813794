import React, {useEffect} from 'react';
import Keys from './Keys';
import EntertainmentSystem from "./EntertainmentSystem";
import Documentation from "./Documentation";
import AdditionalAccessories from "./AdditionalAccessories";

const AccessoriesPanel = (props) => {

    useEffect(() => {
        window.scrollTo(0,0);
    });

    return (
        <div id="accessories-panel" className="bg-white p-3">
            <Keys/>
            <EntertainmentSystem/>
            <Documentation/>
            <AdditionalAccessories/>
            <div className="col mb-2">
              <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>
              <label className="prism-h2 item font-weight-bolder">Required</label>
            </div>
        </div>
    )
};

export default AccessoriesPanel