import React from "react";
import Modal from "@prism/modal";
import PropTypes from "prop-types";

class CostEffectiveModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userConfirmed: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if((prevProps.formComplete === false && this.props.formComplete === true) || (prevProps.isRepairCostEffective !== this.props.isRepairCostEffective)) {
            this.setState(() => {
                return { userConfirmed: false }
            })
        }
    };

    calculateVisible = () => {
        return this.props.isRepairCostEffective === false && this.state.userConfirmed === false;
    };

    handleCancel = () => {
        this.props.handleModalClose(false);
        this.setState({ userConfirmed: true });
    };

    handleOk = () => {
        this.props.handleModalClose(true);
        this.setState({ userConfirmed: true });
    };

    render() {
        let isVisible = this.calculateVisible();
        return (
            <Modal id='cost-effective-modal' isOpen={isVisible} width="100vw">
                <Modal.Header>REPAIR NOT COST EFFECTIVE</Modal.Header>
                <Modal.Body className="mx-2">
                    Dent Repair is not Cost Effective. Item action will be set to Replace.
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Okay text="Cancel" onClick={this.handleCancel} />
                    <Modal.Okay text="Continue" onClick={this.handleOk} />
                </Modal.Footer>
            </Modal>
        )
    }
}

CostEffectiveModal.propTypes = {
    isRepairCostEffective: PropTypes.bool.isRequired,
    formComplete: PropTypes.bool.isRequired,
    handleModalClose: PropTypes.func.isRequired
};

export default CostEffectiveModal;