import React from "react";
import Modal from "@prism/modal";
import MultimediaViewer from "@fyusion/prism-viewer";
import ErrorBoundary from "../../common/ErrorBoundary";
import {connect} from "react-redux";

const SuggestedDamageImageModal = (props) => {
    const {showModal, toggle, imageTitle, damageKey, imageUrl} = props;

    return (
        <Modal isOpen={showModal} toggle={toggle} className="image-overlay">
            <Modal.Header toggle={toggle}/>
            <Modal.Body>
                <ErrorBoundary>
                    <MultimediaViewer
                        id="suggested-damage-image-modal-content"
                        hideAddOns={true}
                        key={props.globalDisplay.damageImageChecksum}
                        data={[
                            {
                                category: "DMG",
                                src: {
                                    thumbnail: "",
                                    lowRes: imageUrl,
                                },
                                description: imageTitle,
                                damageKey: damageKey,
                            },
                        ]}
                    />
                </ErrorBoundary>
            </Modal.Body>
            {/*Change originally part of US1430932. Waiting for additional functionality so commenting out for now*/}
            {/*<Modal.Footer style={{justifyContent: "center", padding: "0rem", marginTop: "0.5rem"}}>*/}
            {/*    <Col id="image-modal-footer-content">*/}
            {/*        <Col>*/}
            {/*            <TakePhotoButton hasPhoto damageKey={damageKey} title={imageTitle}/>*/}
            {/*        </Col>*/}
            {/*    </Col>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
};


function mapStateToProps({globalDisplay}) {
    return {globalDisplay};
}

export default connect(mapStateToProps, null)(SuggestedDamageImageModal);
