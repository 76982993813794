import {
    CLEAR_INCOMPLETE_DAMAGE,
    FLOOD_DAMAGE_SELECTED,
    HIDE_AUDIT_EXISTS_MODAL,
    HIDE_BUTTON_TRAY,
    HIDE_CANCEL_BUTTON_FOR_LOAD,
    HIDE_CLOSE_INSPECTION_CONFIRMATION,
    HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL,
    HIDE_DAMAGE_SUCCESS_MESSAGE,
    HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL,
    HIDE_FLAT_CAR_PANEL,
    HIDE_LOCKED_RECORD_MODAL,
    HIDE_LOGOUT_MODAL,
    HIDE_PROFILE_MODAL_INSIDE_WO,
    HIDE_REDECODE_MODAL,
    HIDE_TRIM_FINDER,
    HIDE_WORK_ORDER_ERROR_MODAL,
    SAVE_INCOMPLETE_DAMAGE,
    SET_CLEAR_PANEL_OFF,
    SET_CLEAR_PANEL_ON,
    SHOW_ADD_DAMAGE_PANEL,
    SHOW_ADD_DAMAGE_VIEW,
    SHOW_ADD_EDIT_DAMAGE,
    SHOW_AUDIT_EXISTS_MODAL,
    SHOW_BUTTON_TRAY,
    SHOW_CANCEL_BUTTON_FOR_LOAD,
    SHOW_CLOSE_INSPECTION_CONFIRMATION,
    SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL,
    SHOW_CURRENT_FLAT_CAR_VIEW,
    SHOW_DAMAGE_LIST,
    SHOW_DAMAGE_SUCCESS_MESSAGE,
    SHOW_DAMAGE_SUMMARY_VIEW,
    SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL,
    SHOW_EXTERIOR_FLAT_CAR_VIEW,
    SHOW_FLAT_CAR_PANEL,
    SHOW_INTERIOR_FLAT_CAR_VIEW,
    SHOW_LOGOUT_MODAL,
    SHOW_MECHANICAL_FLAT_CAR_VIEW,
    SHOW_REDECODE_MODAL,
    SHOW_STRUCTURAL_FLAT_CAR_VIEW,
    SHOW_SUGGESTED_DAMAGES_PANEL,
    SHOW_TRIM_FINDER,
    STRUCTURAL_ALTERATION_SELECTED,
    STRUCTURAL_DAMAGE_SELECTED,
    SUBMIT_WORK_ORDER_ERROR,
    SUBMIT_WORK_ORDER_PROGRESS,
    SUBMIT_WORK_ORDER_SUCCESS,
    TOGGLE_BRAKE_FLUID_SERVICE_SELECTED,
    TOGGLE_DAMAGE_MODAL,
    TOGGLE_ENGINE_OIL_SERVICE_SELECTED,
    UNLOAD_WORK_ORDER,
    UPDATE_ODOMETER_CONFIRMED,
    UPDATE_VEHICLE_IS_SOLD
} from "./dispatchTypes";

export const showAddEditDamage = (damage) => dispatch => {
    dispatch({
        type: SHOW_ADD_EDIT_DAMAGE,
        currentDamage: damage
    });
};

export const showLogoutModal = () => dispatch => {
    dispatch({
        type: SHOW_LOGOUT_MODAL
    });
};

export const hideProfileModalWhileInsideWO = () => dispatch => {
    dispatch({
        type: HIDE_PROFILE_MODAL_INSIDE_WO
    });
};

export const hideDamageSuccessMessage = () => dispatch => {
    dispatch({
        type: HIDE_DAMAGE_SUCCESS_MESSAGE
    });
};

export const showDamageSuccessMessage = () => dispatch => {
    dispatch({
        type: SHOW_DAMAGE_SUCCESS_MESSAGE
    });
};

export const hideLogoutModal = () => dispatch => {
    dispatch({
        type: HIDE_LOGOUT_MODAL
    });
};

export const showRedecodeModal = () => dispatch => {
    dispatch({
        type: SHOW_REDECODE_MODAL
    });
};

export const hideLockedRecordModal = () => dispatch => {
    dispatch({
        type: HIDE_LOCKED_RECORD_MODAL
    })
}

export const hideRedecodeModal = () => dispatch => {
    dispatch({
        type: HIDE_REDECODE_MODAL
    });
};

export const showDamageListView = () => dispatch => {
    dispatch({
        type: SHOW_DAMAGE_LIST
    })
}

export const showFlatCarPanel = () => dispatch => {
    dispatch({
        type: SHOW_FLAT_CAR_PANEL
    })
};

export const hideFlatCarPanel = () => dispatch => {
    dispatch({
        type: HIDE_FLAT_CAR_PANEL
    })
};

export const showCurrentFlatCarView = (view) => dispatch => {
    dispatch({
        type: SHOW_CURRENT_FLAT_CAR_VIEW,
        currentFlatCarView: view
    })
};

export const showExteriorFlatCarView = () => dispatch => {
    dispatch({
        type: SHOW_EXTERIOR_FLAT_CAR_VIEW
    })
};

export const showInteriorFlatCarView = () => dispatch => {
    dispatch({
        type: SHOW_INTERIOR_FLAT_CAR_VIEW
    })
};

export const showMechanicalFlatCarView = () => dispatch => {
    dispatch({
        type: SHOW_MECHANICAL_FLAT_CAR_VIEW
    })
};

export const showStructuralFlatCarView = () => dispatch => {
    dispatch({
        type: SHOW_STRUCTURAL_FLAT_CAR_VIEW
    })
};

export const hideWorkOrderErrorModal = () => dispatch => {
    dispatch({
        type: HIDE_WORK_ORDER_ERROR_MODAL
    });
};

export const hideAuditExistsModal = () => dispatch => {
    dispatch({
        type: HIDE_AUDIT_EXISTS_MODAL
    });
};

export const showAuditExistsModal = () => dispatch => {
    dispatch({
        type: SHOW_AUDIT_EXISTS_MODAL
    });
};

export const showNonNativeButtonTray = () => dispatch => {
    dispatch({
        type: SHOW_BUTTON_TRAY
    });
};

export const hideNonNativeButtonTray = () => dispatch => {
    dispatch({
        type: HIDE_BUTTON_TRAY
    });
};

export const toggleDamageModal = (modalType) => dispatch => {
    dispatch({
        type: TOGGLE_DAMAGE_MODAL,
        modalType: modalType
    });
};

export const setFloodDamageSelected = () => dispatch => {
    dispatch({
        type: FLOOD_DAMAGE_SELECTED
    })
};

export const setStructuralDamageSelected = () => dispatch => {
    dispatch({
        type: STRUCTURAL_DAMAGE_SELECTED
    })
};

export const setStructuralAlterationSelected = () => dispatch => {
    dispatch({
        type: STRUCTURAL_ALTERATION_SELECTED
    })
};

export const toggleEngineOilServiceSelected = () => dispatch => {
    dispatch({
        type: TOGGLE_ENGINE_OIL_SERVICE_SELECTED
    })
};

export const toggleBrakeFluidServiceSelected = () => dispatch => {
    dispatch({
        type: TOGGLE_BRAKE_FLUID_SERVICE_SELECTED
    })
};

export const showCloseInspectionConfirmModal = () => dispatch => {
    dispatch({
        type: SHOW_CLOSE_INSPECTION_CONFIRMATION
    });
};

export const hideCloseInspectionConfirmModal = () => dispatch => {
    dispatch({
        type: HIDE_CLOSE_INSPECTION_CONFIRMATION
    });
};

export const clearVehicleDataFromState = () => dispatch => {
    dispatch({
        type: UNLOAD_WORK_ORDER
    })
}

export const startSubmitWorkOrderProgress = () => dispatch => {
    dispatch({
        type: SUBMIT_WORK_ORDER_PROGRESS
    })
}
export const setWorkOrderSubmitSuccess = () => dispatch => {
    dispatch({
        type: SUBMIT_WORK_ORDER_SUCCESS
    })
}

export const setWorkOrderSubmitError = () => dispatch => {
    dispatch({
        type: SUBMIT_WORK_ORDER_ERROR
    })
}

export const showCancelButtonForLoad = () => dispatch => {
    dispatch({
        type: SHOW_CANCEL_BUTTON_FOR_LOAD
    })
};

export const hideCancelButtonForLoad = () => dispatch => {
    dispatch({
        type: HIDE_CANCEL_BUTTON_FOR_LOAD
    })
};

export const updateVehicleIsSold = (vehicleIsSold) => dispatch => {
    dispatch({
        type: UPDATE_VEHICLE_IS_SOLD,
        vehicleIsSold
    })
};

export const showContinueIncompleteWorkOrderModal = () => dispatch => {
    dispatch({
        type: SHOW_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL
    })
};

export const hideContinueIncompleteWorkOrderModal = () => dispatch => {
    dispatch({
        type: HIDE_CONTINUE_INCOMPLETE_WORK_ORDER_MODAL
    })
};

export const showDeleteIncompleteInspectionModal = (currentWorkOrder) => dispatch => {
    dispatch({
        type: SHOW_DELETE_INCOMPLETE_INSPECTION_MODAL,
        currentWorkOrder: currentWorkOrder
    })
}

export const hideDeleteIncompleteInspectionModal = () => dispatch => {
    dispatch({
        type: HIDE_DELETE_INCOMPLETE_INSPECTION_MODAL
    })
}

export const showDamageSummaryView = () => dispatch => {
    dispatch({
        type: SHOW_DAMAGE_SUMMARY_VIEW
    })
}

export const showAddDamageView = () => dispatch => {
    dispatch({
        type: SHOW_ADD_DAMAGE_VIEW
    })
}

export const showAddDamagePanel = () => dispatch => {
    dispatch({
        type: SHOW_ADD_DAMAGE_PANEL
    })
}

export const showSuggestedDamagesPanel = () => dispatch => {
    dispatch({
        type: SHOW_SUGGESTED_DAMAGES_PANEL
    })
}

export const setClearPanelOn = () => dispatch => {
    dispatch({
        type: SET_CLEAR_PANEL_ON
    })
}
export const setClearPanelOff = () => dispatch => {
    dispatch({
        type: SET_CLEAR_PANEL_OFF
    })
}

export const saveIncompleteDamage = (damage) => (dispatch) => {
    dispatch({
        type: SAVE_INCOMPLETE_DAMAGE,
        payload: damage
    })
};

export const clearIncompleteDamage = () => (dispatch) => {
    dispatch({
        type: CLEAR_INCOMPLETE_DAMAGE
    })
};

export const showTrimFinder = () => dispatch => {
    dispatch({
        type: SHOW_TRIM_FINDER
    })
}
export const hideTrimFinder = () => dispatch => {
    dispatch({
        type: HIDE_TRIM_FINDER
    })
}

export const updateOdometerConfirmed = (odometerConfirmed) => (dispatch) => {
    dispatch({
        type: UPDATE_ODOMETER_CONFIRMED,
        payload: odometerConfirmed
    })
}