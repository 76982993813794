import React from "react";
import Headset from "./Headset";
import Remote from "./Remote";
import Section from "../../common/Section";
import Row from "@prism/row";

const EntertainmentSystem = (props) => {
    return (
        <Section heading="Entertainment System">
            <Row className="mx-0">
                <Headset/>
                <Remote/>
            </Row>
        </Section>
    )
}

export default EntertainmentSystem;