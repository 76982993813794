import {
    CLEAR_SELECTED_FLAT_CAR_PANEL,
    GET_ITEMS_FOR_PANEL_FAILURE,
    GET_ITEMS_FOR_PANEL_SUCCESS,
    SET_SELECTED_FLAT_CAR_PANEL
} from "./dispatchTypes";
import Api from "../components/Api";
import {VEHICLE_SUB_TYPE_FLAT_CAR_MAPPING} from "../utils/constants";

export const handlePanelClick = (panelId) => async (dispatch,getState) => {
    const conditionFlatCarTypeId = getState().condition.flatCarTypeId;
    const descriptionVehicleSubTypeCode = getState().designatedDescription ?
        getState().designatedDescription.bodyDescription?.vehicleType : null;
    const descriptionFlatCarTypeId = descriptionVehicleSubTypeCode ?
      VEHICLE_SUB_TYPE_FLAT_CAR_MAPPING[descriptionVehicleSubTypeCode] : 1;
    const flatCarTypeId = conditionFlatCarTypeId ?
      conditionFlatCarTypeId : descriptionFlatCarTypeId ? descriptionFlatCarTypeId : 1;
    const responseItems = await Api.getItemsForPanel({panelId, flatCarTypeId});

    dispatch({
        type: SET_SELECTED_FLAT_CAR_PANEL,
        panelId: panelId
    });

    if(responseItems.count) {
        dispatch({
            type: GET_ITEMS_FOR_PANEL_SUCCESS,
            items: responseItems.items
        });
    } else {
        dispatch({
            type: GET_ITEMS_FOR_PANEL_FAILURE
        });
    }
};

export const clearItemsList = () => dispatch => {
    dispatch({
        type: GET_ITEMS_FOR_PANEL_SUCCESS,
        items: []
    })
};

export const clearSelectedFlatCarPanel = () => dispatch => {
    dispatch({
        type: CLEAR_SELECTED_FLAT_CAR_PANEL
    })
};