import {LOAD_WORK_ORDER_SUCCESS, UNLOAD_WORK_ORDER, UPDATE_CONSIGNMENT} from "../actions/dispatchTypes";
import {HYUNDAI_DEALER_GROUPS} from "../utils/constants";

export default function consignmentReducer(state = null, action) {
    switch (action.type) {
        case UPDATE_CONSIGNMENT:
            return action.consignment;
        case LOAD_WORK_ORDER_SUCCESS:
            if(action.payload.consignment == null) {return null}
            return getManufacturerFlags(action.payload.consignment, action.payload.account.groupCode);
        case UNLOAD_WORK_ORDER:
            return null;
        default:
            return state;
    }
}

function getManufacturerFlags(consignment, groupCode) {
    const isFord = consignment.manufactureCode === 'FOR';
    const isDlr = consignment.manufactureCode === 'DLR';
    const isNissan = consignment.manufactureCode === 'NIS';
    const isToyota = consignment.manufactureCode === 'TOY';
    const isHyundai = consignment.manufactureCode === "HYUN";
    const isKia = consignment.manufactureCode === "KIA";
    const isHonda = consignment.manufactureCode === "HOND";
    const isVolkswagen = consignment.manufactureCode === "VW";
    const isBmw = consignment.manufactureCode === "BMW";
    const isMercedes = consignment.manufactureCode === "MBC";
    const isGM = consignment.manufactureCode === "GM";
    const isHyundaiDlr = consignment.manufactureCode === "DLR" && HYUNDAI_DEALER_GROUPS.includes(groupCode);
    const isEditOem = isFord || isNissan || isToyota || isHyundai || isKia || isBmw || isMercedes;
    const fuelLevelRequired = isFord || isToyota || isKia || isHyundai;
    return {...consignment, isFord, isToyota, isDlr, isNissan, isHyundai, isKia, isHonda, isVolkswagen, isBmw, isMercedes, isGM, isHyundaiDlr, isEditOem, fuelLevelRequired}
}