import {SET_APPLICATION_FLAGS} from "../actions/dispatchTypes";

export default function appFlagsReducer(state = null, action) {
    switch (action.type) {
        case SET_APPLICATION_FLAGS:
            return action.payload || state;
        default:
            return state;
    }
}

