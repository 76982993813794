import React, {useState} from "react";
import {connect} from "react-redux";
import Col from '@prism/col';
import Row from '@prism/row';
import DamageImages from "../condition/damage/DamageImages";
import MiscImages from "./MiscImages";
import "../../styles/push-panel.css";
import ImageSetButton from './ImageSetButton'
import Icon from "@prism/icon";

const PhotoPanelContent = (props) => {
    const [activeTab, setActiveTab] = useState(undefined)
    console.log("photo panel activeTab", activeTab);
    return (
        <Col id='photos-container'>
            <Row className="mx-0">
                <Col xs={1} className="mt-1">
                    {activeTab && <Icon glyph="chevron-left" className="photo-panel-back-button" onClick={() => setActiveTab(undefined)}/>}
                </Col>
                <Col xs={10}>
                    <h1 className="text-white prism-h1 align-center mb-3">{activeTab} Photos</h1>
                </Col>
            </Row>

            <Row className="mx-0">
                {!activeTab && <>
                    <Col xs={12} lg={6} className="flex-grow-1 xs=6">
                        <ImageSetButton
                            setActiveTab={setActiveTab}
                            imageType='damage'
                            header='DAMAGES'
                        />
                    </Col>

                    <Col xs={12} lg={6} className="flex-grow-1 xs=6">
                        <ImageSetButton
                            setActiveTab={setActiveTab}
                            imageType='miscellaneous'
                            header='MISCELLANEOUS'
                        />
                    </Col>
                </>
                }
            {activeTab === 'damage' ? <DamageImages/> : <></>}
            {activeTab === 'miscellaneous' ? <MiscImages/> : <></>}

            </Row>
        </Col>
    );
};


function mapStateToProps({miscellaneousImages}) {
    return {miscellaneousImages};
}

export default connect(mapStateToProps)(PhotoPanelContent);