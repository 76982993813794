import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '@prism/input';

import {emojiCheck} from "../../utils/utils";

const propTypes = {
    rows: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
};

const defaultProps = {
    onChange: null,
};

class TextCounter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            remaining: 120,
            max: 120,
            reachedMax: false,
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.handleCount = this.handleCount.bind(this);
    }

    componentDidMount() {
        this.setState({ max: this.props.maxLength,
                        remaining: this.props.maxLength}, () => {
            this.props.value && this.handleChange({target: {value: this.props.value}});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.props.value && this.handleChange({target: {value: this.props.value}});
        }
    }

    handleChange(event) {
        if (emojiCheck(event.target.value)) {
            const value = event.target.value.replace(/[\r\n\v]+/g, "");

            if (value.length > this.state.max) {
                return null
            }
            this.setState({
                remaining: this.props.maxLength - value.length,
            });
            this.handleCount(event);
        }
    }
    handlePaste(e) {
        const targetData = e.clipboardData.getData('text').split('');
        const dataLength = targetData.length;
        let limitData = [];
        let index;
        for (index = 0; index < this.state.max && index < dataLength; index++) {
            limitData.push(targetData[index]);
        }
        let dataJoin = limitData.join('');
        this.handleChange({target: {value: dataJoin}});
    }

    handleCount(e) {
        let { value } = e.target;
        value = value.replace(/[\r\n\v]+/g, "");
        const { reachedMax, max, remaining } = this.state;

        const setReachedMax = isMax => {
            this.setState({ reachedMax: isMax });
        };

        const setRemaining = rem => {
            this.setState({ remaining: rem });
        };
        const setValue = value => {
            const { onChange } = this.props;
            this.setState({ value });
            onChange && onChange({ value });
        };

        if (reachedMax && value.length < max) {
            let newLength = max - value.length;
            setRemaining(newLength);
            setReachedMax(false);
            setValue(value);
        } else if (this.state.remaining === 0) {
            let newVal = value.slice();
            setReachedMax(true);
            setRemaining(max - value.length);
            setValue(newVal);
        } else if (remaining < 1 && value.length > max) {
            let newVal = value.slice(0, remaining);
            setReachedMax(true);
            setValue(newVal);
            setRemaining(max - value.length);
        } else {
            let newLength = max - value.length;
            setRemaining(newLength);
            setValue(value);
        }
    }

    render() {
        const { remaining, value} = this.state;
        const { label, labelClass, placeholder, rows, disabled, id="exampleFormControlTextarea1", ref } = this.props;

        const counterClass = `form-text mt-8 textarea-counter__count ${remaining === 0 && 'text-danger'}`;
        return (
            <div className="textarea-counter">
                <Input.Label htmlFor="exampleFormControlTextarea1" className={labelClass}>{label}</Input.Label>
                <Input
                    ref={ref}
                    type={ rows === '1' ? "text" :"textarea"}
                    className="form-control text-area__textarea "
                    id={id}
                    placeholder={placeholder || 'Enter some text'}
                    rows={rows}
                    value={value}
                    onChange={this.handleChange}
                    onPaste={e => this.handlePaste(e)}
                    disabled={disabled}
                />
                <small id="textCounter" className={counterClass}>
                    {remaining} characters remaining
                </small>
            </div>
        );
    }
}

TextCounter.propTypes = propTypes;

TextCounter.defaultProps = defaultProps;

export default TextCounter;