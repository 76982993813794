import React, {useState} from "react";
import SlidePanel from "../../../SlidePanel";
import Row from "@prism/row";
import Col from "@prism/col";
import Api from "../../../Api";
import OutlinePanel from "../../../common/OutlinePanel";
import TrimFinderQuestion from "./TrimFinderQuestion";
import TrimFinderTrimList from "./TrimFinderTrimList";
import SpinnerOverlay from "../../../Spinner/SpinnerOverlay";

const TrimSelector = (props) => {
    const {
        designatedDescriptionResponse,
        globalDisplay,
        hideTrimFinder,
        onTrimChange
    } = props

    const {showTrimFinder} = globalDisplay


    const [DDSResponse, setDDSResponse] = useState(designatedDescriptionResponse);
    const vcrResponse = DDSResponse?.vcrResponse
    const questions = vcrResponse?.questions;
    const vehicleTrims = vcrResponse?.vehicleTrims;
    const [isQuestionLoading, setIsQuestionLoading] = useState(false);


    const closeTrimFinder = () => {
        setDDSResponse(designatedDescriptionResponse);
        hideTrimFinder();
    }

    const handleAnsSelection = (ans) => {
        Object.assign(questions?.[questions.length - 1], {answer: ans})
        async function fetchData() {
            setIsQuestionLoading(true)
            return await Api.getCatalogVehicleForSelectedTrim({
                vin: vcrResponse.vin,
                sessionId: vcrResponse.sessionId,
                questions: questions,
                includeQuestions: true,
                vehicleTrims: vehicleTrims,
                reDecodeVin: globalDisplay.isRedecode
            });
        }

        fetchData().then(response => {
            setDDSResponse(response)
            setIsQuestionLoading(false)
        });

    }

    const handleTrimChange = (ans) => {
        console.log("onTrimChange, ", ans)
        onTrimChange(ans);
        closeTrimFinder();

    }

    console.log("questions, ", questions);


    return(
        <SlidePanel width={"100vw"} id="scan-panel"
                    isOpen={showTrimFinder}
                    from='left'
                    className="bg-white"
        >
            <Col id="trim-selector">
                <Row className="bg-ash flex-row-reverse mb-1 navbar">
                    <Col className="flex-grow-0">
                        <i style={{fontSize: '30px'}} className="icon prism-icon-cross cursor-pointer"
                           id='close-trim-finder' onClick={closeTrimFinder}/>
                    </Col>
                </Row>
                {isQuestionLoading && (
                    <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/>
                )}
                <OutlinePanel>
                    <TrimFinderQuestion question={questions?.[questions.length-1]} onClick={handleAnsSelection}/>
                    <TrimFinderTrimList vehicleTrims={vehicleTrims} onTrimChange={handleTrimChange}/>
                </OutlinePanel>
            </Col>

        </SlidePanel>

    )

}

export default TrimSelector;