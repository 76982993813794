import {
    LOADING_WORK_ORDER,
    LOADING_WORK_ORDER_COMPLETE,
    SUBMIT_WORK_ORDER_ERROR, SUBMIT_WORK_ORDER_PROGRESS,
    SUBMIT_WORK_ORDER_SUCCESS
} from "../actions/dispatchTypes";

const defaultState = {
    fetchWoInProgress: false
};

export default function progressReducer(state = defaultState, action) {
    switch (action.type) {
        case LOADING_WORK_ORDER:
            return {...state, fetchWoInProgress: true};
        case LOADING_WORK_ORDER_COMPLETE:
            return {...state, fetchWoInProgress: false};
        case SUBMIT_WORK_ORDER_SUCCESS:
        case SUBMIT_WORK_ORDER_ERROR:
            return {...state, submitInProgress: false}
        case SUBMIT_WORK_ORDER_PROGRESS:
            return {...state, submitInProgress: true}
        default:
            return state;
    }
};
