import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import "../../../styles/review-panel.css";
import {setCurrentConditionTabIndex} from "../../../actions/naviationActions";
import "../../../utils/messages"
import {
    MISSING_KEYS_MESSAGE,
    MISSING_WARRANTY_BOOKS_MESSAGE,
    MISSING_OWNERS_MANUALS_MESSAGE,
    MISSING_PAINT_TYPE_MESSAGE,
    MISSING_CHARGING_CABLE_MESSAGE, REQUIRED_KEYS_MESSAGE
} from "../../../utils/messages";
import {CHARGING_CABLE_CATEGORIES, KEY_TYPES} from "../../../utils/constants";

class AccessoriesReview extends React.Component {

    state = {
        keysIsValid: false,
        warrantyBookIsValid: false,
        ownersManualsIsValid: false,
        paintTypeIsValid: false,
        chargingCableIsValid: false,
        isValid: false,
        missingKeyType: ""
    };

    componentDidMount() {
        this.checkValidation();
    }

    validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    };

    validationFailureIcon = () => {
        return <Icon glyph="notification-circle" color="danger" className="status-icon"/>
    };

    requiredKeyIsPresent = () => {
        let requiredKeyIsSelected = true
        if (this.props.damageRules?.rules?.keys?.length) {
            this.props.damageRules.rules.keys.forEach(keyRule => {
                let conditionKey = KEY_TYPES.find(keyType => keyType.keyRuleType === keyRule.type)
                if (!keyRule.optional && !this.props.condition.keys?.find(key => key.type === conditionKey.type)) {
                    this.setState({
                        missingKeyType: conditionKey.text
                    })
                    requiredKeyIsSelected = false
                }
            })
        }
        return requiredKeyIsSelected
    }

    checkValidation = () => {
        const keysIsValid = this.props.condition.keys.length;
        const requiredKeyIsPresent = this.requiredKeyIsPresent()
        const warrantyBookIsValid = this.props.condition.warrantyBook === false || this.props.condition.warrantyBook === true;
        const ownersManualsIsValid = this.props.condition.ownerManual === false || this.props.condition.ownerManual === true;
        const paintTypeIsValid = !!this.props.condition.paintType;
        const isElectric = this.props.designatedDescription?.powertrain?.engine ? CHARGING_CABLE_CATEGORIES.includes(this.props.designatedDescription.powertrain.engine.fuelCategory) : false;
        const chargingCableIsValid = isElectric ? this.props.condition.chargingCable === false || this.props.condition.chargingCable === true : true;
        const isValid = keysIsValid && requiredKeyIsPresent && warrantyBookIsValid && ownersManualsIsValid && paintTypeIsValid && chargingCableIsValid;
        this.setState({
            keysIsValid,
            requiredKeyIsPresent,
            warrantyBookIsValid,
            ownersManualsIsValid,
            paintTypeIsValid,
            chargingCableIsValid,
            isValid
        });
        this.props.accessoriesPanelIsValid(isValid);
    };

    navigateToAccessoriesTab = () => {
        this.props.setCurrentConditionTabIndex(1);
    };

    getValidationMessages = () => {
        const validationMessages = [];
        if (!this.state.keysIsValid && !this.state.requiredKeyIsPresent) {
            validationMessages.push(`${this.state.missingKeyType} ${REQUIRED_KEYS_MESSAGE}`)
        } else if (!this.state.keysIsValid) {
            validationMessages.push(MISSING_KEYS_MESSAGE)
        } else if (!this.state.requiredKeyIsPresent) {
            validationMessages.push(`${this.state.missingKeyType} ${REQUIRED_KEYS_MESSAGE}`)
        }

        if (!this.state.warrantyBookIsValid) {
            validationMessages.push(MISSING_WARRANTY_BOOKS_MESSAGE)
        }
        if (!this.state.ownersManualsIsValid) {
            validationMessages.push(MISSING_OWNERS_MANUALS_MESSAGE)
        }
        if (!this.state.paintTypeIsValid) {
            validationMessages.push(MISSING_PAINT_TYPE_MESSAGE)
        }
        if (!this.state.chargingCableIsValid) {
            validationMessages.push(MISSING_CHARGING_CABLE_MESSAGE)
        }
        return validationMessages.join(", ")
    };

    render() {
        const {isValid} = this.state;
        return (
            <Row className={`px-6 py-4 mx-0 review-row ${!isValid && " show-is-clickable"}`}
                 onClick={isValid ? undefined : this.navigateToAccessoriesTab}>
                <Col className="flex-grow-1">
                    <Row className="h2 review-row-title"><Col>Accessories</Col></Row>
                    {!isValid && <Row className="review-error-message"><Col>{this.getValidationMessages()}</Col></Row>}
                </Col>
                <Col className="flex-grow-0">
                    {isValid ? this.validationSuccessIcon() : this.validationFailureIcon()}
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({condition, designatedDescription, damageRules}) {
    return {condition, designatedDescription, damageRules};
}

const matchDispatchToProps = {
    setCurrentConditionTabIndex
};

export default connect(mapStateToProps, matchDispatchToProps)(AccessoriesReview);
