import {
    LOAD_WORK_ORDER_SUCCESS
} from "../actions/dispatchTypes";

export default function vehicleCatalogResolverReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}