import React from 'react';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import Row from "@prism/row";
import {connect} from "react-redux";
import {CONDITION_ANNOUNCEMENT, FLOOD_DAMAGE} from "../../../utils/constants";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {setFloodDamageSelected} from "../../../actions/globalDisplayActions";
import DataPair from "../../common/DataPair";

class FloodDamage extends React.Component {

    handleFloodDamage = (e) => {
        this.props.setFloodDamageSelected();

        if (e.currentTarget.value=== "true") {
            if (!this.announcementExists("AN0015")){
                this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, "AN0015", FLOOD_DAMAGE);
            }

        } else {
            this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, "AN0015");
        }
    };

    announcementExists = (code) => {
        if (this.props.condition.isNewCondition && !this.props.globalDisplay.floodDamageSelected)
        {
            return null
        }
        if (!this.props.announcements.items) {
            return false
        }
        const conditionAnnouncements = this.props.announcements.items.find(announcement => announcement.source === CONDITION_ANNOUNCEMENT);
        if (!conditionAnnouncements) {
            return false
        }
        return conditionAnnouncements.announcements.filter(announcement => announcement.referenceCode === code).length > 0
    };

    render() {
        return (
            <Col xs={6}>
                <DataPair label="Flood Damage" id="flood-damage" required>
                    <Row>
                        <Col className="pl-0"  xs={6}>
                            <RadioButton label="Yes"
                                         name="floodDamage"
                                         value={true}
                                         checked={this.announcementExists("AN0015") === true}
                                         onClick={this.handleFloodDamage}


                            />
                        </Col>
                        <Col xs={6}>
                            <RadioButton label="No"
                                         name="floodDamage"
                                         value={false}
                                         checked={this.announcementExists("AN0015") === false}
                                         onClick={this.handleFloodDamage}
                            />
                        </Col>
                    </Row>

                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    setFloodDamageSelected

};


function mapStateToProps({condition, description, announcements, globalDisplay}) {
    return {condition, description,announcements, globalDisplay};
}
export default connect(mapStateToProps, matchDispatchToProps)(FloodDamage);