import React from "react";

const HailDamageHeader = () => {
    return (
        <thead>
        <tr>
            <th>Dent</th>
            <th className="pl-4">Size</th>
            <th className="pl-4">Dents</th>
            <th className="pl-4">Cost</th>
            <th/>
        </tr>
        </thead>
    )
}

export default HailDamageHeader;
