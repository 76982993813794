import React from "react";
import Col from "@prism/col";
import {connect} from "react-redux";
import {userFriendlyFormat} from "../../formatters";

const Inspector = (props) => {

    return(
        <Col>
            <div className="inspector-decoration">
                <div className="inspector-overlay-box"/>
                <div className="font-weight-lighter inspector">INSPECTOR</div>
            </div>
            <div className="mt-2">
                <div
                    className="font-weight-bolder inspector-name">{!!props?.condition?.modifiedBy ? props.condition.modifiedBy : '--'}</div>
                <div className="font-weight-lighter date-time">
                    {!!props?.condition?.modifiedDate ? `${userFriendlyFormat(props.condition.modifiedDate)} GMT` : '--'}
                </div>
            </div>
        </Col>
    )
};

function mapStateToProps({condition}) {
    return {condition};
}
export default connect(mapStateToProps)(Inspector);