import React from 'react';
import Button from '@prism/button'
import Row from "@prism/row";
import Col from "@prism/col";
import isEqual from "lodash.isequal";
import {connect} from "react-redux";
import Api from '../../Api';
import {togglePushPanel} from "../../../actions/pushPanelActions";
import {
    clearVehicleDataFromState,
    setWorkOrderSubmitError,
    setWorkOrderSubmitSuccess,
    startSubmitWorkOrderProgress
} from "../../../actions/globalDisplayActions";
import update from "immutability-helper";
import Message from "@prism/message";
import {SUBMISSION_ERROR_MESSAGE} from "../../../utils/messages";
import {isNativeApp} from "../../../utils/utils";
import {
    ELECTRIC_FUEL_CATEGORIES,
    EXTERIOR,
    HARDTOP,
    INTERIOR,
    KILOMETERS_TITLE_CASE,
    MILES_TITLE_CASE,
    ROOF_IDS,
    VEHICLE_IS_SOLD,
    WHEEL_IDS
} from "../../../utils/constants";
import cloneDeep from "lodash.clonedeep";
import {exteriorColor, interiorColor} from "../../../utils/vehicleDescriptors";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";
import debounce from "debounce";

class FinishButton extends React.Component {

    state = {
        hasSubmitError: false,
        errorMessage: ""
    };

    wheelsAreUpdated = () => {
        const originalDescription = this.props.originalDesignatedDescription;
        const currentDescription = this.props.designatedDescription;
        let originalWheels;
        let currentWheels;
        if (originalDescription.installedEquipment?.length) {
            const originalGenerics = originalDescription.installedEquipment.map(equip => equip.generics).flat();
            originalWheels = originalGenerics.filter(generic => this.isAWheelGeneric(generic))
        }
        if (currentDescription.installedEquipment.length) {
            const currentGenerics = currentDescription.installedEquipment.map(equip => equip.generics).flat();
            currentWheels = currentGenerics.filter(generic => this.isAWheelGeneric(generic))
        }
        return !isEqual(originalWheels, currentWheels);
    }

    // there were no roof generics in the original generics, they updated roof then changed it back to Hardtop
    // we do not want to submit Hardtop in that scenario
    roofWasUpdatedAndThenRevertedToHardtop = (originalRoofs, currentRoofs) => {
        return !originalRoofs?.length && !!currentRoofs.find(generic => generic.id === HARDTOP.id);
    }

    getHardtop = (installedEquipment) => {
        return installedEquipment?.find(e => e.generics?.find(g => g.name === HARDTOP.name));
    }

    hardtopIsPresent = (installedEquipment) => {
        return !!this.getHardtop(installedEquipment);
    }

    roofIsUpdated = () => {
        const originalEquipment = this.props.originalDesignatedDescription.installedEquipment;
        const currentEquipment = this.props.designatedDescription.installedEquipment;
        let originalRoofs;
        let currentRoofs;
        if (originalEquipment?.length) {
            const originalGenerics = originalEquipment.map(equip => equip.generics).flat();
            originalRoofs = originalGenerics.filter(generic => this.isARoofGeneric(generic))
        }
        if (currentEquipment.length) {
            const currentGenerics = currentEquipment.map(equip => equip.generics).flat();
            currentRoofs = currentGenerics.filter(generic => this.isARoofGeneric(generic))
        }
        const roofIsUpdated = !isEqual(originalRoofs, currentRoofs);
        return roofIsUpdated && this.roofWasUpdatedAndThenRevertedToHardtop(originalRoofs, currentRoofs) ? false : roofIsUpdated;
    }

    isAWheelGeneric = (generic) => {
        return !!generic ? WHEEL_IDS.includes(generic.id) : false;
    }

    isARoofGeneric = (generic) => {
        return !!generic ? (ROOF_IDS.includes(generic.id) || generic.id === HARDTOP.id) : false;
    }
    designatedDescriptionIsUpdated = () => {
        return !this.props.designatedDescriptionResponse.fromTrustedSource || (!isEqual(this.props.originalDesignatedDescription, this.props.designatedDescription))
    };

    buildDesignatedDescription = () => {
        if (this.props.designatedDescriptionResponse) {
            const interiorColorIsUpdated = this.colorHasBeenUpdated(INTERIOR);
            const exteriorColorIsUpdated = this.colorHasBeenUpdated(EXTERIOR);
            const wheelsAreUpdated = this.wheelsAreUpdated();
            const roofIsUpdated = this.roofIsUpdated();
            const originalPowertrain = this.props.originalDesignatedDescription?.powertrain
            const designatedPowertrain = this.props.designatedDescription?.powertrain
            const powertrainIsUpdated = !isEqual(originalPowertrain, designatedPowertrain);
            const originalThirdPartyIdentifiers = this.props.originalDesignatedDescription?.thirdPartyIdentifiers
            const designatedThirdPartyIdentifiers = this.props.designatedDescription?.thirdPartyIdentifiers
            const thirdPartyIdentifiersIsUpdated = !isEqual(originalThirdPartyIdentifiers, designatedThirdPartyIdentifiers);

            if (interiorColorIsUpdated || exteriorColorIsUpdated || wheelsAreUpdated || powertrainIsUpdated || thirdPartyIdentifiersIsUpdated || roofIsUpdated) {
                return {
                    isUpdated: true,
                    // trusted designations have the designated description href in the response, needed by backend for update call
                    href: (!this.props.designatedDescriptionResponse.href ? null : this.props.designatedDescriptionResponse.href),
                    catalogVehicle: {
                        catalogId: this.props.designatedDescription.catalogId,
                        colors: this.buildColors(interiorColorIsUpdated, exteriorColorIsUpdated),
                        installedEquipment: this.buildInstalledEquipment(wheelsAreUpdated, roofIsUpdated),
                        powertrain: this.buildPowertrain(designatedPowertrain, powertrainIsUpdated),
                        thirdPartyIdentifiers: this.buildThirdPartyIdentifiers(designatedThirdPartyIdentifiers, thirdPartyIdentifiersIsUpdated)
                    }
                }
            } else {
                return {
                    isUpdated: this.designatedDescriptionIsUpdated(),
                    href: (!this.props.designatedDescriptionResponse.href ? null : this.props.designatedDescriptionResponse.href),
                    catalogVehicle: {
                        catalogId: this.props.designatedDescription.catalogId
                    }
                }
            }
        } else {
            let catalogVehicle = cloneDeep(this.props.designatedDescription)
            delete catalogVehicle.colors.interior[0].installed;
            delete catalogVehicle.colors.interior[0].isDesignated;
            catalogVehicle.colors.exterior.forEach(c => {
                delete c.installed
                delete c.isDesignated
            });
            return {
                isUpdated: true,
                catalogVehicle: catalogVehicle
            }
        }
    }

    buildColors(interiorColorIsUpdated, exteriorColorIsUpdated) {
        let colors;
        if (interiorColorIsUpdated || exteriorColorIsUpdated) {
            colors = {};
        }
        if (interiorColorIsUpdated) {
            let interiorColor = cloneDeep(this.props.designatedDescription.colors.interior[0])
            colors.interior = [this.buildColor(interiorColor)];
        }
        if (exteriorColorIsUpdated) {
            let exteriorColor = cloneDeep(this.props.designatedDescription.colors.exterior?.find(color => color.isPrimary !== false))
            colors.exterior = [this.buildColor(exteriorColor)];
        }
        return colors;
    }

    buildInstalledEquipment(wheelsAreUpdated, roofIsUpdated) {
        let installedEquipment;
        let equipmentWithoutIds;
        let installedEquipmentWithIds;
        if (wheelsAreUpdated || roofIsUpdated) {
            // custom installedEquipment (including build a vin from AiM) will not have IDs
            // if wheels have changed, we need to also include any equipment without an id or that data will be lost
            equipmentWithoutIds = this.props.designatedDescription.installedEquipment?.filter(e => !e.id);
            installedEquipmentWithIds = this.props.designatedDescription.installedEquipment.filter(e => e.id).map(o => ({id: o.id}));
            installedEquipment = equipmentWithoutIds.concat(installedEquipmentWithIds)
            if (!roofIsUpdated && this.hardtopIsPresent(installedEquipment)) {
                const hardtop = this.getHardtop(installedEquipment);
                installedEquipment = installedEquipment.filter(e => !isEqual(e, hardtop))
            }
        }
        return installedEquipment;
    }

    buildPowertrain(designatedPowertrain, powertrainIsUpdated) {
        let powertrain;

        let drivetrain;
        const originalDrivetrain = this.props.originalDesignatedDescription?.powertrain?.drivetrain?.type;
        const designatedDrivetrain = this.props.designatedDescription?.powertrain?.drivetrain?.type;

        if (!isEqual(originalDrivetrain, designatedDrivetrain)) {
            drivetrain = {
                type: designatedDrivetrain
            }
        }

        let engine;
        const originalAspiration = this.props.originalDesignatedDescription?.powertrain?.engine?.aspiration;
        const designatedAspiration = this.props.designatedDescription?.powertrain?.engine?.aspiration;

        if (!isEqual(originalAspiration, designatedAspiration)) {
            engine = {
                aspiration: designatedAspiration
            }
        }

        const originalDisplacement = this.props.originalDesignatedDescription?.powertrain?.engine?.displacement?.amount;
        const designatedDisplacement = this.props.designatedDescription?.powertrain?.engine?.displacement?.amount;

        if (!isEqual(originalDisplacement, designatedDisplacement)) {
            engine = {
                displacement: {
                    amount: designatedDisplacement,
                    unitOfMeasure: "Liter"
                }
            }
        }
        let transmission;
        const originalTransmission = this.props.originalDesignatedDescription?.powertrain?.transmission?.type;
        const designatedTransmission = this.props.designatedDescription?.powertrain?.transmission?.type;

        if (!isEqual(originalTransmission, designatedTransmission)) {
            transmission = {
                type: designatedTransmission
            }
        }

        if (powertrainIsUpdated) {
            delete designatedPowertrain?.engine?.primaryDescription;
            powertrain = {
                drivetrain,
                engine,
                transmission
            };
        }

        return powertrain;
    }

    buildThirdPartyIdentifiers(designatedThirdPartyIdentifiers, thirdPartyIdentifiersIsUpdated) {
        let thirdPartyIdentifiers;
        if (thirdPartyIdentifiersIsUpdated) {
            thirdPartyIdentifiers = {
                manheim: designatedThirdPartyIdentifiers.manheim
            }
        }
        return thirdPartyIdentifiers;
    }

    buildColor(color) {
        delete color.installed;
        delete color.isDesignated;
        if (color.rgbHex === "") {
            delete color.rgbHex;
        }
        if (color.oemOptionCode === "") {
            delete color.oemOptionCode;
        }
        return color;
    }

    colorHasBeenUpdated = (type) => {
        const ddResponse = this.props.designatedDescriptionResponse;
        if (ddResponse.catalogVehicles?.length > 1 || ddResponse.vcrResponse?.vehicle_trims?.length > 1) {
            return true;
        }
        const colors = this.props.designatedDescription.colors
        const designatedColors = type === INTERIOR ? colors.interior?.filter(c => !!c.isDesignated) : colors.exterior?.filter(c => !!c.isDesignated)
        const originalColors = this.props.originalDesignatedDescription.colors
        let originalDesignatedColors = null;
        if (!!originalColors) {
            if (type === INTERIOR && !!originalColors.interior) {
                originalDesignatedColors = originalColors.interior.filter(c => !!c.isDesignated)
            } else if (type === EXTERIOR && !!originalColors.exterior) {
                originalDesignatedColors = originalColors.exterior.filter(c => !!c.isDesignated)
            }
        }
        return !isEqual(originalDesignatedColors, designatedColors)
    }

    hasUpdatedMiscImages = () => {
        return this.props.miscellaneousImages && this.props.miscellaneousImages.some(image => image.status === 'UPDATED' || image.status === 'DELETED')
    };

    submitStagedInspection = () => {
        this.props.startSubmitWorkOrderProgress();

        let entertainmentSystemHeadset = parseInt(this.props.condition.entertainmentSystemHeadset)
        if (isNaN(entertainmentSystemHeadset)) {
            entertainmentSystemHeadset = null;
        }

        let entertainmentSystemRemote = parseInt(this.props.condition.entertainmentSystemRemote)
        if (isNaN(entertainmentSystemRemote)) {
            entertainmentSystemRemote = null;
        }

        const {designatedDescription, unit} = this.props;
        const isElectric = designatedDescription?.powertrain?.engine ? ELECTRIC_FUEL_CATEGORIES.includes(designatedDescription.powertrain.engine.fuelCategory) : false;

        let currentRangeUnits = null;
        if (isElectric) {
            currentRangeUnits = unit?.odometer?.units?.toUpperCase().startsWith('K') ? KILOMETERS_TITLE_CASE : MILES_TITLE_CASE;
        }

        let newCondition = update(this.props.condition, {
            $merge: {
                entertainmentSystemHeadset: entertainmentSystemHeadset,
                entertainmentSystemRemote: entertainmentSystemRemote,
                images: {details: this.props.damageImages},
                currentRangeUnits: currentRangeUnits
            }
        });

        const payload = {
            vehicle: {
                groupCode: this.props.account.groupCode,
                sourceApplication: 'SMART_INSPECT',
                manufactureCode: this.props.consignment.manufactureCode,
                workOrderNumber: parseInt(this.props.workOrderInfo.workOrderNumber),
                locationCode: this.props.workOrderInfo.auctionCode,
                sellerNumber: parseInt(this.props.consignment.manheimAccountNumber),
                consignment: this.props.consignment,
                vin: this.props.unit?.vin
            },
            announcements: (isEqual(this.props.originalWorkOrder.announcements, this.props.announcements)) ? {
                ...this.props.announcements,
                isUpdated: this.props.announcements.updated
            } : {...this.props.announcements, isUpdated: true},
            condition: newCondition,
            designatedDescription: this.buildDesignatedDescription(),
            miscellaneousImage: (this.hasUpdatedMiscImages()) ?
                {
                    ...this.props.originalWorkOrder.miscellaneousImage,
                    images: {details: this.props.miscellaneousImages},
                    isUpdated: true,
                } :
                {
                    ...this.props.originalWorkOrder.miscellaneousImage,
                    images: null,
                    isUpdated: false
                },
            f5Notes: {
                isUpdated: this.props.f5Notes.isUpdated || false,
                notes: this.props.f5Notes.existingNotes
            },
            metrics: {
                startTimestamp: this.props.heartbeat.loadTimestamp,
                inspectionType: this.props.condition.inspectionType,
                subType: (isNativeApp() && window.NativeApp.getAuditMode()) ? 'AUDIT' : 'INSPECTION',
                deviceSerialNumber: (isNativeApp()) ? window.NativeApp.getDeviceSerial() : null,
                deviceActiveNetwork: (isNativeApp()) ? window.NativeApp.getConnectionType() : null,
                deviceManufacturer: (isNativeApp()) ? window.NativeApp.getDeviceManufacturer() : null,
                deviceModel: (isNativeApp()) ? window.NativeApp.getDeviceModel() : null,
                deviceSdkVersion: (isNativeApp()) ? window.NativeApp.getDeviceSdkVersion() : null,
                appVersion: (isNativeApp()) ? window.NativeApp.getAppVersion() : null,
                repurposed: this.props.repurpose?.repurposed || null,
                repurposedSnapshotId: this.props.repurpose?.repurposed === 'Y' ? this.props.repurpose.snapshotId : null
            },
            unit: {
                href: this.props.unit.href,
                odometer: this.props.unit.odometer,
                isUpdated: !!(this.props.originalUnit && (!isEqual(this.props.originalUnit, this.props.unit)))
            },
            uiDisplayDetails: {
                year: this.props.designatedDescription?.year,
                make: this.props.designatedDescription?.make,
                model: this.props.designatedDescription?.model?.normalizedName,
                vin: this.props.unit?.vin,
                subSeries: this.props.designatedDescription?.thirdPartyIdentifiers?.manheim?.[0]?.ssubse,
                manheimStandardDesc: this.props.designatedDescription?.title,
                interiorColor: interiorColor(null, this.props.designatedDescription),
                exteriorColor: exteriorColor(null, this.props.designatedDescription)
            },
            suggestedDamages: this.props.suggestedDamages,
            status: {
                overAllStatus: "PENDING",
                numberOfRetries: 0
            }
        };

        console.log("payload", payload);
        Api.submitStagedInspect(payload)
            .then(response => {
                console.log("resp..", response);
                if (!!response.stagedInspectHref) {
                    console.log("success");
                    this.setState({hasSubmitError: false, errorMessage: ""})
                    return response;
                } else {
                    const jsonObject = JSON.parse(response);
                    console.log("Error when calling submit")
                    if (jsonObject.statusCode === 409) {
                        this.setState({hasSubmitError: true, errorMessage: jsonObject.error})
                    }
                    throw new Error("Error submitting to smartInspect");
                }
            })
            .then(data => {
                console.log("submitInspectSuccess: ", JSON.stringify(data, null, 4))
                this.submitInspectSuccess(data);
                this.props.clearVehicleDataFromState();
                this.props.togglePushPanel("scan");
            })
            .catch(err => {
                console.log("error: ", err)
                this.props.setWorkOrderSubmitError();
                if (!!this.state.errorMessage) {
                    this.setState({hasSubmitError: true})
                } else {
                    this.setState({hasSubmitError: true, errorMessage: SUBMISSION_ERROR_MESSAGE})
                }
            })

    };

    submitInspectSuccess = (data) => {
        this.props.setWorkOrderSubmitSuccess();
        (isNativeApp() && !!data.stagedInspectHref) && window.NativeApp.onSuccessfulSubmit(this.props.workOrderInfo.workOrderNumber, data.stagedInspectHref);
    };

    finishButtonDisabled = () => {
        return this.props.disabled || this.props.progress.submitInProgress || this.props.globalDisplay.vehicleIsSold;
    }

    render() {
        const onSubmitClick = debounce(this.submitStagedInspection, 3000, true);
        const buttonName = this.props.globalDisplay.vehicleIsSold ? VEHICLE_IS_SOLD : "FINISH"
        return (
            <>
                {this.state.hasSubmitError && <Row>
                    <Col>
                        <Message id="submission-error-message" color="danger">
                            {this.state.errorMessage}
                        </Message>
                    </Col>
                </Row>}
                <Row className="mx-0 px-0">
                    <Col className="my-6">
                        <Button className="btn-full-width"
                                color="secondary"
                                disabled={this.finishButtonDisabled()}
                                onClick={onSubmitClick}>{buttonName}
                        </Button>
                    </Col>
                </Row>
            </>

        )
    }
}

const matchDispatchToProps = {
    clearVehicleDataFromState,
    togglePushPanel,
    setWorkOrderSubmitSuccess,
    setWorkOrderSubmitError,
    startSubmitWorkOrderProgress
};

function mapStateToProps(
    {
        originalWorkOrder,
        condition,
        description,
        designatedDescriptionResponse,
        designatedDescription,
        originalDescription,
        workOrderInfo,
        damageImages,
        announcements,
        f5Notes,
        account,
        consignment,
        miscellaneousImages,
        heartbeat,
        unit,
        originalUnit,
        progress,
        suggestedDamages,
        globalDisplay,
        repurpose
    }
) {
    const originalDesignatedDescription = getDesignatedDescription({'designatedDescriptionResponse': designatedDescriptionResponse});
    return {
        originalWorkOrder,
        condition,
        description,
        designatedDescriptionResponse,
        designatedDescription,
        originalDesignatedDescription,
        originalDescription,
        workOrderInfo,
        damageImages,
        announcements,
        f5Notes,
        account,
        consignment,
        miscellaneousImages,
        heartbeat,
        unit,
        originalUnit,
        progress,
        suggestedDamages,
        globalDisplay,
        repurpose
    };
}

export default connect(mapStateToProps, matchDispatchToProps)(FinishButton);