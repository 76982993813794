import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {BASE_TRIM, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";
import Input from "@prism/input";
import TrimDropdown from "./TrimDropdown";

const Trim = (props) => {
    const {
        designatedDescription,
        designatedDescriptionResponse,
        onTrimChange,
        globalDisplay,
        hasMultipleTrims
    } = props

    const hasDDSResponse = !!designatedDescriptionResponse;
    const {modelId} = globalDisplay

    const [options, setOptions] = useState([]);
    const trim = designatedDescription?.trim?.normalizedName;

    useEffect( () => {
        if(modelId) {
            async function fetchData() {
                return await Api.getTrims({modelId});
            }
            fetchData().then(r => setOptions(r.items?.length ? r.items : BASE_TRIM));
        }

    }, [modelId]);

    return(
        hasDDSResponse && hasMultipleTrims ?
            <Form.Group>
                <Input.Label>
                    <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>Trim
                </Input.Label>
                <TrimDropdown
                    designatedDescription={designatedDescription}
                    designatedDescriptionResponse={designatedDescriptionResponse}
                    onChange={onTrimChange}
                />
            </Form.Group> :
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-trim" label="Trim">
                    {trim}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-trim" label="Trim" required={true}>
                    <Dropdown disabled={(!trim && !options?.length)|| !modelId || props.disabled}
                              onChange={onTrimChange}
                              value={trim}
                              id='trim-dropdown'>
                        {!trim && <option value=''>{PLEASE_SELECT}</option>}
                        {options?.map(option => {
                            return (<option value={option.name}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>

    )

}

export default Trim;