import React from 'react';
import {KEY_TYPES} from "../../../utils/constants";
import KeyDisplay from "./KeyDisplay";
import Row from "@prism/row";
import Col from "@prism/col";
import Section from "../../common/Section";
import {connect} from "react-redux";

class Keys extends React.Component {

  render() {
    return (
      <Section heading="Keys" required>
        <Row className="mx-0">
          {KEY_TYPES.map(keyType => (
            <Col xs={6} sm={6} md={4} lg={3} xl={2} key={keyType.type}>
              <KeyDisplay keyType={keyType}
                          displayKey={this.props.condition && this.props.condition.keys.find(key => {
                            return keyType.type === key.type
                          })}/>
            </Col>
          ))}
        </Row>

      </Section>
    )
  }
}

const matchDispatchToProps = {};

function mapStateToProps({condition}) {
  return {condition};
}

export default connect(mapStateToProps, matchDispatchToProps)(Keys);