import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import "../../../styles/review-panel.css";
import {
    MISSING_DAMAGE_CHARGEABLE_MESSAGE,
    MISSING_DAMAGE_PRICING,
    MISSING_DAMAGE_SEVERITY_MESSAGE
} from "../../../utils/messages";
import {showDamageSummaryView} from "../../../actions/globalDisplayActions";

class DamageReview extends React.Component {

    state = {
        severityIsValid: false,
        chargeableIsValid: false,
        pricingIsValid: false
    };

    componentDidMount() {
        this.checkValidation();
    }

    checkValidation = () => {
        const severityIsValid = this.isValidSeverity(this.props.condition);
        const chargeableIsValid = this.isValidChargeable(this.props.condition);
        const pricingIsValid = !this.props.condition.missingPricing;
        const isValid = severityIsValid && chargeableIsValid && pricingIsValid;
        this.setState({
            severityIsValid,
            chargeableIsValid,
            pricingIsValid,
            isValid
        });
        this.props.damagePanelIsValid(isValid);
    };

    isValidSeverity = (condition) => {
        let severityValidArray = [];
        if (condition && condition.damages && condition.damages.length > 0) {
            severityValidArray = condition.damages.map(damage => {
                return damage.severityCode !== '' && typeof damage.severityCode !== 'undefined'
            });
        }
        return severityValidArray.every(severityValid => severityValid === true);
    }

    isValidChargeable = (condition) => {
        let chargeableValidArray = [];
        if (condition && condition.damages && condition.damages.length > 0) {
            chargeableValidArray = condition.damages.map(damage => {
                return damage.chargeable !== '' && typeof damage.chargeable !== 'undefined'
            });
        }
        return chargeableValidArray.every(chargeableValid => chargeableValid === true);
    }

    validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    }

    validationFailureIcon = () => {
        return <Icon glyph="notification-circle" color="danger" className="status-icon"/>
    }

    navigateToDamageTab = () => {
        this.props.showDamageSummaryView();
    }

    getValidationMessages = () => {
        const validationMessages = [];
        if (!this.state.severityIsValid) {
            validationMessages.push(MISSING_DAMAGE_SEVERITY_MESSAGE)
        }
        if (!this.state.chargeableIsValid) {
            validationMessages.push(MISSING_DAMAGE_CHARGEABLE_MESSAGE)
        }
        if (!this.state.pricingIsValid) {
            validationMessages.push(MISSING_DAMAGE_PRICING)
        }
        return validationMessages.join(", ")
    }

    render() {
        const {isValid} = this.state;
        return (
            <Row className={`px-6 py-4 mx-0 review-row ${!isValid && " show-is-clickable"}`}
                 onClick={isValid ? undefined : this.navigateToDamageTab}>
                <Col className="flex-grow-1">
                    <Row className="h2 review-row-title"><Col>Damage</Col></Row>
                    {!isValid && <Row className="review-error-message"><Col>{this.getValidationMessages()}</Col></Row>}
                </Col>
                <Col className="flex-grow-0">
                    {isValid ? this.validationSuccessIcon() : this.validationFailureIcon()}
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({condition}) {
    return {condition};
}

const matchDispatchToProps = {
    showDamageSummaryView
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageReview);