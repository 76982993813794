import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";

const Year = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onYearChange
    } = props

    const [options, setOptions] = useState([]);
    const year = designatedDescription?.year;

    useEffect(() => {

        async function fetchData() {
            return await Api.getYears();
        }

        if (!hasDDSResponse) {
            fetchData().then(r => setOptions(r.items?.sort((a, b) => parseInt(b.name) - parseInt(a.name))));
        }


    }, [hasDDSResponse]);

    return (
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-year" label="Year">
                    {year}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-year" label="Year" required={true}>
                    <Dropdown disabled={(!year && !options?.length) || props.disabled}
                              onChange={onYearChange}
                              value={year}
                              id='year-dropdown'>
                        {!year && <option value=''>{PLEASE_SELECT}</option>}
                        {options?.map(option => {
                            return (<option key={option.id}
                                            value={option.name}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>

    )

}

export default Year;