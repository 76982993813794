import {GET_USER_DETAILS} from "../actions/dispatchTypes";

export default function userDetailsReducer(state = null, action) {
    switch (action.type) {
        case GET_USER_DETAILS:
            if(action.payload === null) {return null}

            if(action.payload.role === null) {return action.payload}

            return processUserScopes(action.payload);
        default:
            return state;
    }
}

const processUserScopes = (action) => {
    return {...action,
        role: {
            name: action.role.name,
            scopes: {
                isAuditor: action.role.scopes.includes("AUDIT_INSPECTION")
            }
        }
    }
};



