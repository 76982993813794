import Form from "@prism/form";
import React from "react";
import Input from "@prism/input";
import SeatTrimDropdown from "./SeatTrimDropdown";

const SeatTrim = (props) => {
    const {designatedDescription, designatedDescriptionResponse, onSeatTrimChange, taxonomyData} = props

    return (
        <Form.Group>
            <Input.Label>
                <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>Seat Trim
            </Input.Label>
            <SeatTrimDropdown
                onChange={onSeatTrimChange}
                genericInteriorTypes={taxonomyData.interiorTypes}
                designatedDescription={designatedDescription}
                designatedDescriptionResponse={designatedDescriptionResponse}
            />
        </Form.Group>
    )

}

export default SeatTrim;