import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import Api from "../../Api";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";

const Transmission = (props) => {
    const {
        designatedDescription,
        designatedDescriptionResponse,
        hasDDSResponse,
        onTransmissionChange
    } = props

    const originalDesignation = getDesignatedDescription({'designatedDescriptionResponse': designatedDescriptionResponse})
    const [options, setOptions] = useState([]);
    const transmission = designatedDescription?.powertrain?.transmission?.type;
    const originalTransmission = originalDesignation?.powertrain?.transmission?.type;

    useEffect(() => {
        async function fetchData() {
            return await Api.getTransmissions();
        }

        if (!hasDDSResponse || !transmission || !originalTransmission) {
            fetchData().then(r => setOptions(r.items));
        }

    }, [hasDDSResponse, transmission, originalTransmission]);

    return (
        hasDDSResponse && !!transmission && !!originalTransmission ?
            <Form.Group>
                <DataPair id="configure-transmission" label="Transmission">
                    {transmission}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-transmission" label="Transmission" required={true}>
                    <Dropdown disabled={(!transmission && !options?.length) || props.disabled}
                              onChange={onTransmissionChange}
                              value={transmission}
                              id='transmission-dropdown'>
                        {!transmission && <option value=''>{PLEASE_SELECT}</option>}
                        {options?.map(option => {
                            return (<option key={option.id}
                                            value={option.name}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>

    )

}

export default Transmission;