import React from "react";
import Col from "@prism/col";
import {connect} from "react-redux";
import {updateServiceNotes} from "../../../actions/conditionActions";
import TextCounter from "../../common/TextCounter";

class ServiceNotes extends React.Component {

    handleServiceNotes = (e) => {
        this.props.updateServiceNotes(e.value);
    };

    render() {
        if(this.props.condition && (this.props.condition.serviceRequired === undefined || this.props.condition.serviceRequired === null)) {
            return <></>
        }

        return (
            <Col xs={12} sm={12} md={4} lg={3} xl={3}>

                <TextCounter
                    id='edit-service-notes'
                    onChange={this.handleServiceNotes}
                    placeholder="Enter Notes"
                    label="Service Notes"
                    labelClass="col-form-label font-weight-lighter"
                    maxLength={40}
                    rows="1"
                    value={this.props.condition.serviceNotes}
                    key={`serviceNotes-${this.props.workOrderInfo && this.props.workOrderInfo.workOrderNumber}`}
                />

            </Col>
        )

    }
}

const matchDispatchToProps = {
    updateServiceNotes
};

function mapStateToProps({condition, workOrderInfo}) {
    return {condition, workOrderInfo};
}

export default connect(mapStateToProps, matchDispatchToProps)(ServiceNotes);