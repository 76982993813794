import React from "react";
import Modal from "@prism/modal";
import Col from "@prism/col";
import MultimediaViewer from "@fyusion/prism-viewer";

import {connect} from "react-redux";
import ErrorBoundary from "../common/ErrorBoundary";
import DeleteMiscImageButton from "./DeleteMiscImageButton";

const MiscImageModal = (props) => {
  const { showModal, toggle, image } = props;

  const handleClose = () => {
    toggle();
  };

  return (
    <Modal isOpen={showModal} toggle={toggle} className="image-overlay">
      <Modal.Header toggle={toggle} />
      <Modal.Body>
        <ErrorBoundary>
            <MultimediaViewer
                id="misc-image-modal-content"
                hideAddOns={true}
                key={image.imageTag}
                data={[image]}
            />
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center", padding:"0rem", marginTop: "0.5rem" }}>
          <Col>
            <DeleteMiscImageButton
              style={{ width: "100%" }}
              tag={image.imageTag}
              onConfirm={handleClose}
            />
          </Col>
      </Modal.Footer>
    </Modal>
  );
};


function mapStateToProps({globalDisplay}) {
    return {globalDisplay};
}

export default connect(mapStateToProps, null)(MiscImageModal);
