export const KEY_TYPES = [
    {text: 'Master', type: 'Master', keyRuleType: 'Master/Door Key', itemSubItem: '042004'},
    {text: "Spare", type: "Spare", keyRuleType: 'Spare Key', itemSubItem: '042007'},
    {text: "Remote/Fob", type: "Remote-Fob", keyRuleType: 'Remote/Fob Key', itemSubItem: '042800'},
    {text: "Smart", type: "Smart", keyRuleType: 'Smart Key', itemSubItem: '042011'},
    {text: "Combo/Proximity", type: "Combo", keyRuleType: "Combo Key", itemSubItem: '042008'}
];

export const ELECTRIC_FUEL_CATEGORIES = ["Gasoline Plug-In Hybrid", "Gasoline Hybrid", "Electric"];
export const CHARGING_CABLE_CATEGORIES = ["Gasoline Plug-In Hybrid", "Electric"];
export const ELECTRIC_ENGINE_CATEGORIES = ["Electric", "Hydrogen"];
export const INDUCTIONS = ["Natural", "Supercharged", "Turbocharged", "Turbocharged/Supercharged"];

export const TIRE_DEPTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
export const TIRE_MANUFACTURERS = ["Americus", "Apacha", "Arisun", "Arizonian", "Armstrong", "Aspen", "Atrezzo", "Atturo", "Autopar", "Avon", "Barum", "BFGoodrich", "BigO", "Bridgestone", "Carlisle", "Commodore", "Contender", "Continental", "Cooper", "Corsa", "Crosswind", "Custom 428", "Dick Cepek", "Dominator", "Douglas", "Dunlop", "DWT", "Epic", "E-Z-Go", "Falken", "Federal", "Fierce", "Firestone", "Fisk", "Fulda", "Fuzion", "GBC", "General", "Generic", "Geostar", "Geotrac", "Gladiator", "Goodyear", "Grand Prix", "Greenball", "GT", "Hankook", "Hartland", "Hercules", "Hifly", "Hoosier", "Interco", "Journey", "Kanati", "Kelly", "Kenda", "Kumho", "Laufenn", "Marshal", "Mastercraft", "Maxxis", "Mesa", "Metzler", "Michelin", "Mickey Thompson", "Milestar", "Motomaster", "Mountain Journey", "MudClaw", "Nankang", "Neptune", "Nexen", "Nitto", "Nokian", "Ohtsu", "Other", "Pathfinder", "Pegasus", "Performer", "Phantom", "Pirelli", "Pit Bull", "Primewell", "Pro Comp", "Provider", "Radial A/S", "Rage", "Riken", "Road Hugger", "Sentry", "Sentury", "Sigma", "Spare", "STI", "Sumitomo", "Super Swamper", "Taskmaster", "Tensor", "Terramax", "Thunder", "Titan", "Touring SDL", "Towmax", "Toyo", "Trailfinder", "Trivant", "Uniroyal", "Veento", "Velozza", "Vision", "Vogue", "Vredestein", "WestLake", "Wild Trail", "Winter TXI", "Winter XSI", "Yokohama"];
export const STANDARD_TIRE_WIDTHS = ["105", "115", "125", "135", "145", "155", "165", "175", "185", "195", "205", "215", "225", "235", "245", "255", "265", "275", "285", "295", "305", "315", "325", "335", "345", "355", "375", "395", "405"];
export const RATIO_TIRE_WIDTHS = ["24X", "25X", "26X", "27X", "29X", "30X", "31X", "32X", "33X", "34X", "35X", "36X", "37X", "38X", "39X", "40X", "42X"];
export const OTHER_TIRE_WIDTHS = ["6.4", "7", "7.5", "8", "8.75", "9.5"];
export const STANDARD_ASPECT_RATIOS = ["20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"];
export const ALTERNATE_ASPECT_RATIOS = ["7.5", "8.5", "9.5", "10.5", "11.5", "12.5", "13.5", "14", "14.5", "15.5", "16.5", "18.5"];
export const WHEEL_DIAMETERS = ["10", "12", "13", "14", "15", "16", "16.5", "17", "17.5", "18", "19", "19.5", "20", "21", "22", "22.5", "23", "24", "25", "26", "28", "30"];
export const SPARE_TIRE_TYPES = [
    {code: "none", description: "None"},
    {code: "compact-spare", description: "Compact"},
    {code: "full-spare", description: "Full"},
    {code: "missing-compact-spare", description: "Missing Compact"},
    {code: "missing-full-spare", description: "Missing Full"}
];

export const WHEEL_TYPES = [
    {id: 20, name: "Aluminum Wheels"},
    {id: 22, name: "Steel Wheels"},
    {id: 73, name: "Chrome Wheels"}
];

export const WHEEL_IDS = [20, 22, 73];

export const ROOF_TYPES = [
    {id: 82, name: "Convertible Hardtop"},
    {id: 83, name: "Convertible Soft Top"},
    {id: 105, name: "Dual Moonroof"},
    {id: 188, name: "Panoramic Roof"},
    {id: 290, name: "Sun/Moonroof"},
    {id: 295, name: "Targa Roof"},
    {id: 315, name: "T-Top"},
];
export const ROOF_IDS = [82, 83, 105, 188, 290, 295, 315];
export const HARDTOP = {id: 447, name: "Hardtop"}

export const TIRE_LOCATION_MAP = {
    leftFront: {description: "LF Tire", locationCode: "EL"},
    rightFront: {description: "RF Tire", locationCode: "ET"},
    leftRear: {description: "LR Tire", locationCode: "EL"},
    rightRear: {description: "RR Tire", locationCode: "ET"},
    spare: {description: "Spare Tire", locationCode: "ER"}
};
export const TIRE_LOCATIONS = ["Left Front", "Left Rear", "Right Rear", "Right Front", "Spare"]
export const HYUNDAI_DEALER_GROUPS = ["HYUC", "HYFC", "HYMF"];
export const BMW_GROUP_CODES = ['BMW', 'BMWA', 'BMWE', 'BMWF', 'BMWR', 'BMWS', 'BMWU'];
export const MBC_GROUP_CODES = ['C2G', 'CCBV', 'DEB', 'DEBC', 'DTF', 'MBC', 'MBCA', 'MBCB', 'MBCM', 'MBDW', 'MBNA', 'MBUS'];
export const SERVICE_REQUIRED_OPTIONS = [
    {code: true, description: 'Yes'},
    {code: false, description: 'No'},
    {code: 'null', description: 'Unknown'}
];
export const FUEL_LEVEL_OPTIONS = [
    {code: "FULL", description: "Full"},
    {code: "3/4", description: "3/4"},
    {code: "1/2", description: "1/2"},
    {code: "1/4", description: "1/4"},
    {code: "1/8", description: "1/8"},
    {code: "EMPTY", description: "Empty"},
    {code: 'UNKNOWN', description: "N/A"}
];

export const CONDITION_ANNOUNCEMENT = 'CONDITION';
export const VEHICLE_NOTE = 'VEHICLE_NOTE';
export const ODOMETER_INOPERABLE = 'Odometer Inoperable';
export const CHARGING_CABLE_MISSING = 'Hybrid or Elec Charger Missing';
export const ODOMETER_INOPERABLE_STATUS = 'Inoperable';
export const ODOMETER_OPERABLE_STATUS = 'Operable';
export const FLOOD_DAMAGE = 'Flood Damage';
export const STRUCTURAL_DAMAGE = 'Structural Damage';
export const STRUCTURAL_ALTERATION = "Structural Alteration";
export const APPROVAL_RECORD_EXISTS = 'Approval Record Exists';
export const VEHICLE_IS_SOLD = 'VEHICLE IS SOLD';
export const AUDIT_EXISTS = 'AUDIT EXISTS';
export const PLEASE_SELECT = 'Please Select...'
export const ENGINE_OIL = 'Engine Oil'
export const BRAKE_FLUID = 'Brake Fluid'
export const SMART_INSPECT = 'SMART_INSPECT'
export const FYU_DAMAGE_ANALYSIS = 'FYU_DAMAGE_ANALYSIS'
export const INSPECTOR_PROVIDED = 'Inspector Provided'
export const INTERIOR = 'interior'
export const EXTERIOR = 'exterior'
export const COLOR_GENERIC = 'generic'
export const COLOR_OEM = 'oem'

export const CHARGEABLE_OPTIONS = [
    {code: 'L', description: 'Lot Dmg'},
    {code: 'S', description: 'Supplemental'},
    {code: 'T', description: 'Transportation Dmg'}
];

export const VEHICLE_SUB_TYPE_FLAT_CAR_MAPPING = {
    "Convertible": 1,
    "Van": 3,
    "SUV": 4,
    "Hatchback": 1,
    "Wagon": 4,
    "Car": 1,
    "Truck": 2,
    "Sport Utility": 4,
    "Chassis": 2
};

export const ODOMETER_INOP_ANNOUNCEMENT_CODE = "AN0025";
export const CHARGING_CABLE_MISSING_ANNOUNCEMENT_CODE = "AN0042";
export const ODOMETER_INOP_DAMAGE_KEY = 'L1-0531-01-IS';
export const KILOMETER_OPTIONS_FROM_UNIT = ["KILOMETERS", "K", "KM"]
export const KILOMETER_STATIC_VALUE = 'KiloMeter'
export const KILOMETERS_TITLE_CASE = 'Kilometers'
export const KILOMETERS_ALL_CAPS = 'KILOMETERS'
export const MILES_TITLE_CASE = 'Miles'
export const MILES_ALL_CAPS = 'MILES'
export const NOT_SPECIFIED = 'Not Specified'
export const SPARE = 'Spare';
export const COMPACT_SPARE = 'compact-spare';

export const BODY_STYLES =
    [
        {
            name: "Convertible",
            vehicleSubType: "Car",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000001"
        },
        {
            name: "Coupe",
            vehicleSubType: "Car",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000001"
        },
        {
            name: "Hatchback",
            vehicleSubType: "Car",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000001"
        },
        {
            name: "Minivan",
            vehicleSubType: "Van",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000004"
        },
        {
            name: "Pickup",
            vehicleSubType: "Truck",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000002"
        },
        {
            name: "SUV",
            vehicleSubType: "Sport Utility",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000003"
        },
        {
            name: "Sedan",
            vehicleSubType: "Car",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000001"
        },
        {
            name: "Van",
            vehicleSubType: "Van",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000004"
        },
        {
            name: "Wagon",
            vehicleSubType: "Car",
            vehicleTypeId: "00000000-0000-1000-0000-000100000100",
            vehicleSubTypeId: "00000000-0000-1000-0000-000100000001"
        }
    ]

export const COUNTRIES =
    [
        {code: "USA", name: "United States"},
        {code: "CAN", name: "Canada"}
    ]

export const BASE_TRIM = [{name: "Base"}]

export const ADDED = 'ADDED'
export const DELETED = 'DELETED'
export const EDITED = 'EDITED'
export const MANUAL = 'MANUAL'
export const ERROR_MESSAGE_PHRASES = ['not found', 'Please contact support']
export const OPENABLE_STATUSES = ['SUCCESS', 'INVALID', 'FAILED'];
export const SUCCESS = ['SUCCESS'];
export const STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE = 'AN0037'
export const USE_EXISTING = 'USE_EXISTING'
export const UPDATED = 'UPDATED'
export const CANCEL = 'Cancel'
export const CONTINUE = 'Continue'
export const CONFIRM = 'Confirm'
export const OK = 'Ok'
export const YES = 'Yes'
export const NO = 'No'
export const DECODE = 'Decode'

export const MISSING_PRICING_MESSAGE = 'Damage is missing pricing and needs to be corrected before saving';
export const MISSING_ESTIMATE_MESSAGE = 'Unable to fetch pricing. Please try again or enter manually.';
export const DAMAGES_MISSING_PRICING_MESSAGE = 'Damage(s) are missing pricing and need to be corrected before submitting';
export const AUDIT_EXISTS_DECISION_MESSAGE = 'Audit has already been performed. Would you like to continue?';
export const AUDIT_EXISTS_CONFIRMATION_MESSAGE = 'Audit has already been performed.';
export const CLOSE_INSPECTION_HEADER = 'SCAN PAGE'
export const CLOSE_INSPECTION_BODY = 'Do you want to save and exit this WO?'
export const REDECODE_HEADER = 'Re-decode VIN'
export const REDECODE_BODY = 'Some trim data may be lost. Are you sure you would like to re-decode this vehicle?'
export const LOGOUT_HEADER = 'LOGOUT'
export const LOGOUT_BODY = 'Are you sure you want to logout?'
export const SESSION_TIMEOUT_BODY = 'Your session has expired due to inactivity'
export const SESSION_TIMEOUT_HEADER = 'SESSION TIMEOUT'
export const LOCKED_RECORD_HEADER = 'Work Order In Process'
export const LOCKED_RECORD_BODY = 'Looks like this inspection submission is in progress, please click refresh to get the latest status update. Once submission has completed, you can then re open the inspection. Thank you!'
export const REPURPOSE_HEADER = 'Previous Inspection Data Available'
export const REPURPOSE_BODY = 'Previous inspection data exists for this vehicle. Would you like to use this data for the current inspection?'

export const CRSO = 'CR/SO'
