import React from 'react';
import DataPair from "../../common/DataPair";
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import {updateEngineStarts} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import Row from "@prism/row";

class EnginesStarts extends React.Component {

    handleEnginesStarts = (e) => {
        console.log("enter")
        this.props.updateEngineStarts(e.currentTarget.value === "true")
        console.log("target value : ",e.currentTarget.value)
    };

    render() {

        if (!this.props.condition) {
            return null
        }

        const {engineStarts} = this.props.condition;

        return (
            <Col xs={6}>
                <DataPair label="Engine Starts" id="engines-starts" required>
                    <Row  >
                        <Col className="pl-0" xs={5}>
                            <RadioButton label="Yes"
                                         name="engineStarts"
                                         value={true}
                                         checked={engineStarts === true}
                                         onClick={this.handleEnginesStarts}
                            />
                        </Col>
                        <Col xs={7}>
                            <RadioButton label="No"
                                         name="engineStarts"
                                         value={false}
                                         checked={engineStarts === false}
                                         onClick={this.handleEnginesStarts}
                            />
                        </Col>
                    </Row>

                </DataPair>
            </Col>
        )

    }
}

const matchDispatchToProps = {
    updateEngineStarts
};


function mapStateToProps({condition, description}) {
    return {condition, description};
}

export default connect(mapStateToProps, matchDispatchToProps)(EnginesStarts);