import React from "react";

import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import Row from '@prism/row';
import Input from '@prism/input';
import {connect} from "react-redux";
import Form from "@prism/form";
import {updateOdometerReading, updateOdometerUnits} from "../../../actions/unitActions";
import {
    deleteAutoDamage,
    setConditionOdometerFromUnit,
    updateOdometerOperable
} from "../../../actions/conditionActions";
import OdometerReadingModal from "./OdometerReadingModal";
import AddDamageModal from "./AddDamageModal";
import Checkbox from "@prism/checkbox";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {
    CONDITION_ANNOUNCEMENT,
    KILOMETER_OPTIONS_FROM_UNIT,
    KILOMETER_STATIC_VALUE,
    MILES_TITLE_CASE,
    ODOMETER_INOP_ANNOUNCEMENT_CODE,
    ODOMETER_INOPERABLE,
    ODOMETER_INOPERABLE_STATUS,
    ODOMETER_OPERABLE_STATUS
} from "../../../utils/constants";
import {ODOMETER_CHECK} from "../../../utils/constantsDamages";
import {odometerInopAnnouncementExists} from "../../../utils/utils";
import {ENABLE_CONFIRM_ODOMETER} from "../../../config/env";
import {updateOdometerConfirmed} from "../../../actions/globalDisplayActions";

class Odometer extends React.Component {

    state = {}

    toggleModal = () => {
        const {modalOpen} = this.state;
        this.setState({
            modalOpen: !modalOpen,
        });
    };

    toggleDamageModal = () => {
        const {showDamageModal} = this.state;
        this.setState({
            showDamageModal: !showDamageModal,
        });
    };

    handleMileageChange = (e) => {
        const value = e.target.value;
        const reading = value === "" ? null : parseInt(value) || 0;
        this.props.updateOdometerReading(reading);
        this.props.updateOdometerConfirmed(true);
    }

    showModal = (e) => {
        if (this.props.originalUnit && Math.abs(this.props.originalUnit.odometer.reading - this.props.unit.odometer.reading) >= 5) {
            this.setState({modalOpen: true})
        }
    };

    submitConfirm = (value) => {
        this.props.updateOdometerReading(value);
    }

    cancelSubmit = () => {
        this.props.updateOdometerReading(this.props.originalUnit.odometer.reading);
    }

    changeUnits = (e) => {
        this.props.updateOdometerUnits(e.target.value);
    }

    handleInoperableCheckChanged = (event, canceled = null) => {
        const checked = event.currentTarget.checked;
        if (!checked && canceled !== true) {
            this.props.updateOdometerReading('');
        }
        this.props.updateOdometerOperable(checked ? ODOMETER_INOPERABLE_STATUS : ODOMETER_OPERABLE_STATUS);
        this.handleOdometerInoperableAnnouncement(checked);
        this.handleOdometerCheckDamage(checked);
    };

    handleOdometerInoperableAnnouncement = (isInoperable) => {
        if (isInoperable) {
            this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE, ODOMETER_INOPERABLE);
        } else {
            this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE);
        }
    };

    handleOdometerCheckDamage = (isInoperable) => {
        if (isInoperable) {
            this.toggleDamageModal()
        } else {
            const odoDamage = this.props.automatedDamages.find(damage => damage.item === ODOMETER_CHECK.item)
            if (odoDamage) {
                this.props.deleteAutoDamage(odoDamage);
            }
        }
    };

    handleCancelClick = () => {
        this.handleInoperableCheckChanged({currentTarget: {checked: false}}, true)
        this.toggleDamageModal();
    }

    handleSaveClick = () => {
        this.props.updateOdometerReading(0);
    }

    getOdometerUnits = (odometerUnits) => {
        if (KILOMETER_OPTIONS_FROM_UNIT.includes(odometerUnits)) {
            return KILOMETER_STATIC_VALUE;
        }
        return MILES_TITLE_CASE;
    }

    handleConfirmOdometerCheckChanged = (event) => {
        this.props.updateOdometerConfirmed(event.currentTarget.checked);
    }

    render() {
        const hasOdometer = this.props.unit && this.props.unit.odometer;
        const odometerInoperable = hasOdometer && odometerInopAnnouncementExists(this.props.announcements);

        const odometerReading = hasOdometer && this.props.unit.odometer.reading;
        const odometerUnits = hasOdometer && this.props.unit.odometer.units;

        const odometerConfirmed = hasOdometer && this.props.globalDisplay?.odometerConfirmed;
        return (

            <Form.Group>
                <OdometerReadingModal showModal={this.state.modalOpen}
                                      toggle={this.toggleModal}
                                      updatedOdometerReading={odometerReading}
                                      originalOdometerReading={this.props.originalUnit && this.props.originalUnit.odometer.reading}
                                      submitConfirm={this.submitConfirm}
                                      cancelSubmit={this.cancelSubmit}/>
                <AddDamageModal showModal={this.state.showDamageModal}
                                onCancelClick={this.handleCancelClick}
                                onSaveClick={this.handleSaveClick}
                                toggle={this.toggleDamageModal}
                                damage={ODOMETER_CHECK}/>

                <DataPair label="Odometer" required={true}>
                    <Row className="container pl-0">
                        <Col xs={6} md={6} lg={4} xl={4} className="px-0">
                            <Input
                                type="text"
                                id="vehicle-mileage"
                                value={odometerReading}
                                disabled={odometerInoperable}
                                name="input"
                                maxLength={6}
                                onChange={this.handleMileageChange}
                                onBlur={this.showModal}
                            />
                        </Col>
                        <Col xs={6} md={6} lg={3} xl={3} className="pr-0">
                            <Dropdown onChange={this.changeUnits}
                                      value={this.getOdometerUnits(odometerUnits)}
                                      disabled={odometerInoperable}
                                      id='vehicle-odometer-units'>
                                {this.props.staticOptionValues && this.props.staticOptionValues.odometerUnitTypes.map(option => {
                                    return (<option key={option.code}
                                                    value={option.code}>{option.description}</option>)
                                })}
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row className="container pl-0">
                        <Col xs={6} sm={6} md={6} lg={4} xl={4} className="pl-0 pt-2" style={{alignSelf: "center"}}>
                            <Checkbox label="Inoperable"
                                      key={odometerInoperable}
                                      name="inoperable"
                                      id="odometer-inoperable"
                                      checked={odometerInoperable}
                                      className="ml-0"
                                      onChange={this.handleInoperableCheckChanged}
                            />
                        </Col>
                        { ENABLE_CONFIRM_ODOMETER === 'true' &&
                            <Col xs={6} sm={6} md={6} lg={3} xl={3} className="pl-1 pt-2" style={{alignSelf: "center"}}>
                                <Checkbox label="Confirm Odometer"
                                          key={odometerConfirmed}
                                          name="confirm"
                                          id="confirm-odometer"
                                          checked={odometerConfirmed}
                                          className="confirm-odometer"
                                          onChange={this.handleConfirmOdometerCheckChanged}
                                          style={{borderColor: odometerConfirmed ? '' : 'red'}}
                                />
                            </Col>
                        }
                    </Row>
                </DataPair>
            </Form.Group>
        )
    }
}

const matchDispatchToProps = {
    setConditionOdometerFromUnit,
    updateOdometerReading,
    updateOdometerUnits,
    updateOdometerOperable,
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    deleteAutoDamage,
    updateOdometerConfirmed
};

function mapStateToProps(
    {
        originalUnit,
        description,
        staticOptionValues,
        automatedDamages,
        announcements,
        condition,
        unit,
        globalDisplay
    }
) {
    return {originalUnit, description, staticOptionValues, automatedDamages, announcements, condition, unit, globalDisplay};
}

export default connect(mapStateToProps, matchDispatchToProps)(Odometer);