import Input from "@prism/input";
import Row from "@prism/row";
import Col from "@prism/col";
import Button from "@prism/button";
import Form from "@prism/form";
import React from "react";

const Vin = (props) => {
    const {
        unit,
        designatedDescriptionResponse,
        showRedecodeModal
    } = props

    return(
        <Form.Group>
            <Input.Label>
                VIN
            </Input.Label>
            <Row className={"px-0"}>
                <Col xs={8} className={"pl-0"}>
                    <Input
                        value={unit && unit.vin}
                        id="configure-vin"
                    />
                </Col>
                <Col xs={4} className={"pr-0"}>
                    <Button
                        color="secondary"
                        disabled={!designatedDescriptionResponse}
                        onClick={showRedecodeModal}
                        id="re-decode-button">
                        Re-decode
                    </Button>
                </Col>
            </Row>
        </Form.Group>
    )
}

export default Vin;