import React from "react";
import Button from "@prism/button";

class CapturePhotoButton extends React.Component {
  render() {
    return (
      <Button
        id={this.props.id}
        className={"mx-2"}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        <i className="icon prism-icon-camera text-secondary" />
      </Button>
    );
  }
}

export default CapturePhotoButton;
