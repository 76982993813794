import {RESET_SUBMIT_STATUS, SET_TAB_INDEX} from "./dispatchTypes";

export const setCurrentConditionTabIndex = (tabIndex) => (dispatch) => {
    dispatch({
        type: SET_TAB_INDEX,
        selectedConditionTabIndex: tabIndex
    });
}

export const resetSubmitStatus = () => dispatch => {
    dispatch({
        type: RESET_SUBMIT_STATUS
    })
}
