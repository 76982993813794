import React from "react";
import {connect} from "react-redux";
import {handlePanelClick} from "../../../../../actions/flatCarActions";
import FlatCarUtil from "../FlatCarUtil";

const MinivanExterior = (props) => {
    const {handlePanelStriping, StripedPattern, SelectedStripedPattern, StripedPatternLegend, isSuggestedDamagesPresent} = FlatCarUtil();
    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -80 463.32 465">
            <StripedPattern/>
            <SelectedStripedPattern/>
            <StripedPatternLegend/>
            <g id="Layer_2" data-name="Layer 2" className="flat-car rotate">
                <g id="minivan-exterior">
                    <path id="Body" className="panel-dark"
                          d="M350,213.93h37.05s32.38,1.41,47.63-14.56c3.67-3.84,6.46-10.48,8.36-18.58.46-2,2.87-4,3.23-6.12a149.1,149.1,0,0,0-.07-47.19c-.41-2.39-2.89-4.69-3.43-6.87-1.9-7.59-4.59-13.8-8.09-17.46-15.25-16-47.63-14.54-47.63-14.54H350a1.36,1.36,0,0,1,0-.17h-6.24l.06.17h-62l-34.16-.09S96,88.46,94.88,89.82C93.47,91.47,83,109.25,83,151.26s10.48,59.82,11.89,61.45C96,214.07,247.65,214,247.65,214l34.16-.08,62,0-.09.26H350Z"/>
                    <g id="Spare_Wheel" data-name="Spare Wheel" onClick={() => handlePanelClick(30)}>
                        <path id="Rim" className={`panel-dark${handlePanelStriping(30)}`}
                              d="M25.12,165.35c-.39,1.26-1.05,3.19-1.05,3.19a8.91,8.91,0,0,0,2.67.33,8.79,8.79,0,0,0,2.66-.33s-.65-1.93-1-3.19a6.17,6.17,0,0,1-.35-2.84c0-1.23.32-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.51a6.11,6.11,0,0,1,1.76,2.26c.62,1.17,1.52,3,1.52,3a8.54,8.54,0,0,0,2.12-1.65,8.76,8.76,0,0,0,1.65-2.12s-1.83-.91-3-1.52a6.19,6.19,0,0,1-2.25-1.76c-.85-.89-3.53-4-4.52-5.11,1.5-.11,5.58-.39,6.81-.42a6,6,0,0,1,2.84.36c1.26.38,3.19,1,3.19,1a8.67,8.67,0,0,0,.34-2.67,8.91,8.91,0,0,0-.33-2.67s-1.94.66-3.2,1a6,6,0,0,1-2.84.36c-1.23,0-5.31-.32-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.26-1.76c1.16-.62,3-1.52,3-1.52a8.54,8.54,0,0,0-1.65-2.12,8.79,8.79,0,0,0-2.12-1.66s-.9,1.84-1.52,3A6,6,0,0,1,33.54,143c-.89.84-4,3.53-5.11,4.52-.1-1.5-.39-5.58-.42-6.81a6,6,0,0,1,.36-2.84c.38-1.26,1-3.2,1-3.2a8.91,8.91,0,0,0-2.67-.33,8.91,8.91,0,0,0-2.67.33s.66,1.94,1,3.2a6,6,0,0,1,.36,2.84c0,1.22-.31,5.31-.42,6.81-1.13-1-4.22-3.68-5.11-4.52a6,6,0,0,1-1.76-2.26c-.62-1.16-1.52-3-1.52-3a8.87,8.87,0,0,0-2.12,1.65,8.67,8.67,0,0,0-1.65,2.13s1.83.9,3,1.52a6.14,6.14,0,0,1,2.27,1.76c.84.89,3.53,4,4.51,5.11-1.49.1-5.58.38-6.81.42A6.17,6.17,0,0,1,13,150c-1.26-.39-3.2-1.05-3.2-1.05a8.91,8.91,0,0,0-.33,2.67,8.79,8.79,0,0,0,.33,2.66s1.94-.65,3.2-1a6.17,6.17,0,0,1,2.84-.35c1.23,0,5.31.31,6.81.42-1,1.13-3.67,4.22-4.52,5.11a6.09,6.09,0,0,1-2.25,1.76c-1.17.61-3,1.52-3,1.52a8.54,8.54,0,0,0,1.65,2.12,8.63,8.63,0,0,0,2.11,1.65s.91-1.83,1.53-3a6,6,0,0,1,1.76-2.25c.89-.85,4-3.54,5.11-4.52.11,1.5.38,5.58.42,6.81A6.19,6.19,0,0,1,25.12,165.35Zm-1.91-12.27v-2.92l2.06-2.07H28.2l2.06,2.06v2.93l-2.07,2.06H25.28Z"/>
                        <path id="Tire" className={`panel-dark${handlePanelStriping(30)}`}
                              d="M53.48,151.62a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,53.48,151.62Zm-43.85,0a17.11,17.11,0,1,1,17.11,17.11A17.12,17.12,0,0,1,9.63,151.62Z"/>
                    </g>
                    <path id="Left_Front_Wheel" data-name="Left Front Wheel"
                          className={`panel-dark${handlePanelStriping(8)}`}
                          onClick={() => handlePanelClick(8)}
                          d="M415.5,26.74a26.74,26.74,0,1,0-26.74,26.74A26.74,26.74,0,0,0,415.5,26.74ZM398.82,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C397.9,38.7,398.73,40.38,398.82,40.56Zm.79-5.26a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C402.38,36.71,400.71,35.88,399.61,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11a6.08,6.08,0,0,1,2.26-1.75c1.11-.6,2.8-1.43,3-1.51a16.9,16.9,0,0,1,3.06,7.4C405.45,24.15,403.68,24.75,402.49,25.12Zm-5.17-9.24a6.25,6.25,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C398.73,13.1,397.9,14.78,397.32,15.88ZM387.13,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C386.17,10,386.77,11.82,387.13,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C375.11,16.76,376.79,17.6,377.9,18.18ZM375,28.36a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.8.42-1,1.13-3.67,4.22-4.51,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C372.07,29.32,373.84,28.73,375,28.36Zm5.17,9.24A6.11,6.11,0,0,1,382,35.34c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C378.78,40.37,379.61,38.7,380.2,37.6Z"/>
                    <path id="Left_Rear_Wheel" data-name="Left Rear Wheel"
                          className={`panel-dark${handlePanelStriping(19)}`}
                          onClick={() => handlePanelClick(19)}
                          d="M189.84,26.74A26.74,26.74,0,1,0,163.1,53.48,26.74,26.74,0,0,0,189.84,26.74ZM173.16,40.56a17,17,0,0,1-7.41,3.07c-.07-.2-.66-2-1-3.16a6,6,0,0,1-.35-2.84c0-1.23.31-5.32.42-6.81,1.13,1,4.22,3.67,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C172.24,38.7,173.07,40.38,173.16,40.56ZM174,35.3a6,6,0,0,1-2.25-1.76c-.85-.89-3.54-4-4.52-5.11,1.5-.1,5.58-.39,6.81-.42a6,6,0,0,1,2.83.36c1.2.36,3,1,3.16,1a17,17,0,0,1-3.07,7.41C176.72,36.71,175.05,35.88,174,35.3Zm-9.39-5h-2.93l-2.06-2.06V25.28l2.06-2.06h2.92l2.07,2.06V28.2Zm12.27-5.15a6.21,6.21,0,0,1-2.85.35c-1.22,0-5.3-.31-6.8-.42,1-1.13,3.67-4.22,4.51-5.11A6,6,0,0,1,174,18.19c1.1-.6,2.79-1.43,3-1.51a16.9,16.9,0,0,1,3.06,7.4C179.79,24.15,178,24.75,176.83,25.12Zm-5.17-9.24a6.14,6.14,0,0,1-1.76,2.26c-.89.85-4,3.53-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a5.93,5.93,0,0,1,.36-2.84c.36-1.19,1-3,1-3.16a17,17,0,0,1,7.41,3.07C173.08,13.1,172.24,14.78,171.66,15.88ZM161.47,13a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.11-4.52a6,6,0,0,1-1.76-2.26c-.59-1.1-1.42-2.79-1.51-3a17,17,0,0,1,7.41-3.07C160.51,10,161.11,11.82,161.47,13Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.54,4,4.52,5.11-1.5.1-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.2-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.42C149.45,16.76,151.14,17.6,152.24,18.18Zm-2.87,10.18a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.58-2.78,1.41-3,1.51a17,17,0,0,1-3.07-7.42C146.41,29.32,148.18,28.73,149.37,28.36Zm5.17,9.24a6.11,6.11,0,0,1,1.75-2.26c.9-.85,4-3.54,5.12-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16A17,17,0,0,1,153,40.56C153.12,40.37,154,38.7,154.54,37.6Z"/>
                    <path id="Right_Front_Wheel" data-name="Right Front Wheel"
                          className={`panel-dark${handlePanelStriping(32)}`}
                          onClick={() => handlePanelClick(32)}
                          d="M415.5,276.28A26.74,26.74,0,1,0,388.76,303,26.74,26.74,0,0,0,415.5,276.28ZM398.82,290.1a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.91,5.91,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.68,5.11,4.52a6.25,6.25,0,0,1,1.76,2.26C397.9,288.24,398.73,289.92,398.82,290.1Zm.79-5.26a6.11,6.11,0,0,1-2.26-1.76c-.84-.89-3.53-4-4.51-5.11,1.5-.1,5.58-.39,6.8-.42a6.08,6.08,0,0,1,2.85.36c1.19.36,3,1,3.15,1a17,17,0,0,1-3.06,7.41C402.41,286.26,400.72,285.43,399.61,284.84Zm-9.4-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.06v2.93Zm12.27-5.16a6,6,0,0,1-2.83.36c-1.23,0-5.31-.31-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.42C405.46,273.69,403.68,274.29,402.48,274.65Zm-5.16-9.22a6.3,6.3,0,0,1-1.76,2.25c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-2.95,1-3.16a17.12,17.12,0,0,1,7.41,3.07C398.73,262.64,397.9,264.32,397.32,265.43Zm-10.19-2.88a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.16,6.16,0,0,1-1.75-2.25c-.59-1.1-1.42-2.78-1.51-3a17.12,17.12,0,0,1,7.41-3.07C386.17,259.6,386.77,261.36,387.13,262.55Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.51,5.11-1.49.11-5.58.39-6.8.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.41C375.12,266.31,376.8,267.14,377.9,267.72ZM375,277.9a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.11.59-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.41C372.06,278.87,373.83,278.27,375,277.9Zm5.17,9.24a6,6,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16a17,17,0,0,1-7.41-3.07C378.78,289.93,379.61,288.24,380.2,287.14Z"/>
                    <path id="Right_Rear_Wheel" data-name="Right Rear Wheel"
                          className={`panel-dark${handlePanelStriping(43)}`}
                          onClick={() => handlePanelClick(43)}
                          d="M189.84,276.28A26.74,26.74,0,1,0,163.1,303,26.74,26.74,0,0,0,189.84,276.28ZM173.16,290.1a17,17,0,0,1-7.41,3.07c-.06-.19-.66-2-1-3.16a5.91,5.91,0,0,1-.36-2.84c0-1.23.31-5.31.42-6.81,1.13,1,4.22,3.68,5.11,4.52a6.14,6.14,0,0,1,1.76,2.26C172.24,288.24,173.08,289.92,173.16,290.1Zm.8-5.26a6.06,6.06,0,0,1-2.27-1.76c-.84-.89-3.53-4-4.51-5.11,1.5-.1,5.58-.39,6.8-.42a6.08,6.08,0,0,1,2.85.36c1.19.36,3,1,3.15,1a17,17,0,0,1-3.06,7.41C176.75,286.26,175.06,285.43,174,284.84Zm-9.41-5h-2.92l-2.06-2.06v-2.92l2.06-2.06h2.93l2.06,2.06v2.93Zm12.27-5.16A6,6,0,0,1,174,275c-1.23,0-5.31-.31-6.81-.42,1-1.13,3.67-4.22,4.52-5.11a6,6,0,0,1,2.25-1.76c1.1-.58,2.77-1.41,3-1.51a17,17,0,0,1,3.07,7.42C179.8,273.69,178,274.29,176.82,274.65Zm-5.16-9.22a6.19,6.19,0,0,1-1.76,2.25c-.89.85-4,3.54-5.11,4.52-.11-1.49-.39-5.58-.42-6.81a6,6,0,0,1,.35-2.84c.37-1.19,1-2.95,1-3.16a17.12,17.12,0,0,1,7.41,3.07C173.07,262.64,172.24,264.32,171.66,265.43Zm-10.19-2.88a6.05,6.05,0,0,1,.36,2.84c0,1.23-.32,5.31-.42,6.81-1.13-1-4.22-3.67-5.12-4.52a6.16,6.16,0,0,1-1.75-2.25c-.59-1.1-1.42-2.78-1.51-3a17.12,17.12,0,0,1,7.41-3.07C160.51,259.6,161.11,261.36,161.47,262.55Zm-9.23,5.17a6,6,0,0,1,2.26,1.76c.84.89,3.53,4,4.52,5.11-1.5.11-5.59.39-6.81.42a6.17,6.17,0,0,1-2.84-.35c-1.19-.37-3-1-3.16-1a17,17,0,0,1,3.07-7.41C149.46,266.31,151.14,267.14,152.24,267.72Zm-2.87,10.18a6.17,6.17,0,0,1,2.84-.35c1.22,0,5.31.32,6.81.42-1,1.13-3.68,4.22-4.52,5.11a6,6,0,0,1-2.26,1.76c-1.1.59-2.79,1.42-3,1.51a17,17,0,0,1-3.07-7.41C146.4,278.87,148.17,278.27,149.37,277.9Zm5.17,9.24a6,6,0,0,1,1.76-2.26c.89-.85,4-3.54,5.11-4.52.1,1.5.38,5.58.42,6.81a6,6,0,0,1-.36,2.84c-.36,1.19-1,3-1,3.16A17,17,0,0,1,153,290.1C153.12,289.93,154,288.24,154.54,287.14Z"/>
                    <path id="Front_Bumper" data-name="Front Bumper"
                          className={`panel-dark${handlePanelStriping(2)}`}
                          onClick={() => handlePanelClick(2)}
                          d="M434,95s13.27.52,17.74,6,11.63,23.32,11.63,50.8-6.55,42.75-11.4,49.4-18.2,7.44-18.2,7.44c8.93-7.28,12.23-23.9,12.23-23.9l4.66-6s2.79-7.67,2.79-26.94-2.83-27.17-2.83-27.17l-5-6.15S442.32,100.54,434,95Z"/>
                    <path id="Rear_Bumper" data-name="Rear Bumper"
                          className={`panel-dark${handlePanelStriping(29)}`}
                          onClick={() => handlePanelClick(29)}
                          d="M88.66,211.87s-10.72-1.54-15.38-7.31-10.06-24.34-10.06-53S68,106.92,73,100,88.9,91.2,88.9,91.2c-4.2,8.76-8.51,29.7-8.51,29.7l-2.81,1s-3,9.52-3,29.64,3.05,29.88,3.05,29.88l3.22,1.15S83.82,201.87,88.66,211.87Z"/>
                    <path id="Hood" className={`panel-dark${handlePanelStriping(5)}`}
                          onClick={() => handlePanelClick(5)}
                          d="M415.5,201.37c2.94-5.15,10.49-16.25,8.61-26.62,3.38-4.25,11-5.5,15-10.5s4-21.38,0-27.13-11.5-5.62-15.62-10.75c1.62-7.37-4-19.76-7-25.58s-43.43.25-43.43.25,10.53,23.66,10.53,50.16-10.53,50.17-10.53,50.17S412.55,206.52,415.5,201.37Z"/>
                    <path id="Right_Side_Glass" data-name="Right Side Glass"
                          className={`panel-white${handlePanelStriping(67)}`}
                          onClick={() => handlePanelClick(67)}
                          d="M207,208.71a50.69,50.69,0,0,1,1.95-15.41,1.61,1.61,0,0,1,1.56-1.13c7.36.15,47,.92,58.42,1.19a3.18,3.18,0,0,1,3,2.46c.86,3.64,2.38,8.77,5.05,12.81C260.16,208.51,220.21,209.23,207,208.71Z"/>
                    <path id="Right_Rear_Glass" data-name="Right Rear Glass"
                          className={`panel-white${handlePanelStriping(42)}`}
                          onClick={() => handlePanelClick(42)}
                          d="M116.81,208.71c-1.41-2,13.33-11.56,13.33-11.56s41.45-6.36,65.87-5.34a2.38,2.38,0,0,1,2.22,1.86c.72,3.14,1.51,7.26-.39,15C181,208.51,130,209.23,116.81,208.71Z"/>
                    <path id="Right_Front_Glass" data-name="Right Front Glass"
                          className={`panel-white${handlePanelStriping(66)}`}
                          onClick={() => handlePanelClick(66)}
                          d="M289.64,207.2c-2.59-2.5-5.78-6.77-8.36-13.91,33.28,1.69,67.06,7.39,73.86,13.12C355.43,206.67,328.93,207.3,289.64,207.2Z"/>
                    <path id="Right_Headlight" data-name="Right Headlight"
                          className={`panel-white${handlePanelStriping(4)}`}
                          onClick={() => handlePanelClick(4)}
                          d="M437.92,174.77c-.25,30.75-18,26.83-18,26.83s7.24-14.18,7.9-21.95C428.18,175.15,435.92,173.39,437.92,174.77Z"/>
                    <path id="Left_Headlight" data-name="Left Headlight"
                          className={`panel-white${handlePanelStriping(3)}`}
                          onClick={() => handlePanelClick(3)}
                          d="M437.92,127.77c-2,1.38-9.74-.38-10.12-4.87-.66-7.78-7.9-22-7.9-22S437.67,97,437.92,127.77Z"/>
                    <path id="Windshield" data-name="Windshield"
                          className={`panel-white${handlePanelStriping(1)}`}
                          onClick={() => handlePanelClick(1)}
                          d="M365.23,101s10.79,11.28,12,50.22c-1.18,39-12,50.23-12,50.23-11.74,2.81-43.67-11-43.67-11,8-17.6,7.51-39.2,7.51-39.2s.46-21.59-7.51-39.19C321.56,112.07,353.49,98.23,365.23,101Z"/>
                    <path id="Left_Front_Glass" data-name="Left Front Glass"
                          className={`panel-white${handlePanelStriping(65)}`}
                          onClick={() => handlePanelClick(65)}
                          d="M355.14,96.11c-6.79,5.73-40.56,11.45-73.86,13.13,2.59-7.16,5.79-11.42,8.38-13.9C328.93,95.24,355.43,95.86,355.14,96.11Z"/>
                    <path id="Left_Side_Glass" data-name="Left Side Glass"
                          className={`panel-white${handlePanelStriping(68)}`}
                          onClick={() => handlePanelClick(68)}
                          d="M277,94.4c-2.67,4-4.19,9.16-5.05,12.8a3.18,3.18,0,0,1-3,2.46c-11.46.27-51.06,1-58.42,1.19a1.61,1.61,0,0,1-1.56-1.13A50.69,50.69,0,0,1,207,94.31C220.21,93.79,260.16,94.51,277,94.4Z"/>
                    <path id="Left_Rear_Glass" data-name="Left Rear Glass"
                          className={`panel-white${handlePanelStriping(18)}`}
                          onClick={() => handlePanelClick(18)}
                          d="M197.84,94.4c1.9,7.69,1.11,11.81.39,14.95a2.38,2.38,0,0,1-2.22,1.86c-24.42,1-65.87-5.34-65.87-5.34s-14.74-9.59-13.33-11.56C130,93.79,181,94.51,197.84,94.4Z"/>
                    <path id="Left_Rear_Lights" data-name="Left Rear Lights"
                          className={`panel-white${handlePanelStriping(22)}`}
                          onClick={() => handlePanelClick(22)}
                          d="M96.65,95.37c3.75-3.75,7-2.4,7-2.4s-8.68,27.9-14.83,27.9C88.82,120.87,92.9,99.12,96.65,95.37Z"/>
                    <path id="Rear_Glass" data-name="Rear Glass"
                          className={`panel-white${handlePanelStriping(28)}`}
                          onClick={() => handlePanelClick(28)}
                          d="M109.11,93.73l8.69,15.15s-5.59,15.54-5.59,42.38,5.59,42.39,5.59,42.39l-8.69,15.15c-1.86,0-13.62-31.14-13.62-57.54S107.25,93.69,109.11,93.73Z"/>
                    <path id="Right_Rear_Lights" data-name="Right Rear Lights"
                          className={`panel-white${handlePanelStriping(21)}`}
                          onClick={() => handlePanelClick(21)}
                          d="M97,207.3c-3.75-3.75-7.84-25.5-7.84-25.5,6.15,0,14.84,27.9,14.84,27.9S100.74,211.05,97,207.3Z"/>
                    <path id="Left_Mirror" data-name="Left Mirror"
                          className={`panel-dark${handlePanelStriping(11)}`}
                          onClick={() => handlePanelClick(11)}
                          d="M351.18,83.71c-2.18-5.39-4.16-10.39-11-9.62,0,0,1.74,8.79,3.58,14.35H350C349.62,86.86,351.81,85.27,351.18,83.71Z"/>
                    <path id="Right_Mirror" data-name="Right Mirror"
                          className={`panel-dark${handlePanelStriping(35)}`}
                          onClick={() => handlePanelClick(35)}
                          d="M351.18,218.83c.62-1.53-1.49-3.08-1.23-4.64h-6.23c-1.82,5.56-3.55,14.26-3.55,14.26C347,229.22,349,224.22,351.18,218.83Z"/>
                    <polygon id="Left_Front_Door" data-name="Left Front Door"
                             className={`panel-dark${handlePanelStriping(10)}`}
                             onClick={() => handlePanelClick(10)}
                             points="285.83 27.55 350.02 27.55 361.42 71.69 285.83 71.42 285.83 27.55"/>
                    <path id="Left_Front_Fender" data-name="Left Front Fender"
                          className={`panel-dark${handlePanelStriping(7)}`}
                          onClick={() => handlePanelClick(7)}
                          d="M358.47,27.55c.42,17.24,13.55,29.74,31.3,29.74,19,0,29.25-15.6,29.34-30.34h7.64A21.28,21.28,0,0,1,448,46.1l1.44,14.12a3.84,3.84,0,0,1,0,.43c0,1.52-.35,6.54-4.81,7.36C432,70.32,402,71.66,362.44,71.69L351,27.55Z"/>
                    <g id="Left_Rear_Qtr_Panel" data-name="Left Rear Qtr Panel" onClick={() => handlePanelClick(17)}>
                        <path className={`panel-dark${handlePanelStriping(17)}`}
                              d="M95,70.94c10.19-44.2,15.86-44.16,34.54-44h3.34c.11,23.63,19.62,29.88,30,29.88a35.45,35.45,0,0,0,13.84-3c12-5.73,16.75-18.8,17.09-26.32h18.54c-.85,5.9-2.21,11.81-3.52,17.52-1.95,8.54-4,17.37-4.29,26.19l-35.39-.07Z"/>
                    </g>
                    <g id="Left_Rear_Door" data-name="Left Rear Door" onClick={() => handlePanelClick(14)}>
                        <path className={`panel-dark${handlePanelStriping(14)}`}
                              d="M205.51,71.26c.31-8.78,2.33-17.58,4.28-26.1,1.32-5.74,2.68-11.67,3.53-17.61h71.52V71.42Z"/>
                    </g>
                    <path id="Left_Rocker" data-name="Left Rocker"
                          className={`panel-dark${handlePanelStriping(13)}`}
                          onClick={() => handlePanelClick(13)}
                          d="M193.88,26.56c.45-1.4,3.4-9.31,11.78-9.31,2.49,0,28,.15,57.49.33,37.11.22,79.17.47,84.34.47,8.14,0,10.53,7.14,10.91,8.51H346.79v0l-.22-.05-.37.1H285.83l-.2,0h-.79v0Z"/>
                    <polygon id="Right_Front_Door" data-name="Right Front Door"
                             className={`panel-dark${handlePanelStriping(34)}`}
                             onClick={() => handlePanelClick(34)}
                             points="285.83 231.6 361.42 231.33 350.02 275.47 285.83 275.47 285.83 231.6"/>
                    <path id="Right_Front_Fender" data-name="Right Front Fender"
                          className={`panel-dark${handlePanelStriping(31)}`}
                          onClick={() => handlePanelClick(31)}
                          d="M362.44,231.33c39.6,0,69.55,1.37,82.18,3.68,4.46.82,4.81,5.84,4.81,7.36a4.17,4.17,0,0,1,0,.44L448,256.92a21.28,21.28,0,0,1-21.22,19.16h-7.64c-.09-14.75-10.37-30.34-29.34-30.34-17.75,0-30.88,12.49-31.3,29.73H351Z"/>
                    <g id="Right_Rear_Qtr_Panel" data-name="Right Rear Qtr Panel" onClick={() => handlePanelClick(41)}>
                        <path className={`panel-dark${handlePanelStriping(41)}`}
                              d="M169.13,231.84l35.39-.08c.31,8.83,2.34,17.65,4.29,26.19,1.31,5.71,2.67,11.62,3.52,17.52H193.79c-.34-7.52-5.14-20.59-17.09-26.31a35.29,35.29,0,0,0-13.84-3c-10.33,0-29.84,6.25-30,29.88h-3.34c-18.68.15-24.35.19-34.54-44Z"/>
                    </g>
                    <g id="Right_Rear_Door" data-name="Right Rear Door" onClick={() => handlePanelClick(38)}>
                        <path className={`panel-dark${handlePanelStriping(38)}`}
                              d="M284.84,231.6v43.87H213.32c-.85-5.94-2.21-11.87-3.53-17.61-1.95-8.51-4-17.32-4.28-26.1Z"/>
                    </g>
                    <path id="Right_Rocker" data-name="Right Rocker"
                          className={`panel-dark${handlePanelStriping(37)}`}
                          onClick={() => handlePanelClick(37)}
                          d="M284.84,276.46l.2,0h.79v0h60.41l.51.14,0-.14H358.4c-.36,1.3-2.74,8.51-10.91,8.51-5.17,0-47.23.25-84.34.47-29.51.18-55,.33-57.49.33-8.35,0-11.3-7.84-11.78-9.31Z"/>
                    <path id="Roof" className={`panel-dark${handlePanelStriping(6)}`}
                          onClick={() => handlePanelClick(6)}
                          d="M117.8,152c0-21.9,4.34-34.26,5.31-35.23a5.35,5.35,0,0,1,3.77-1.56s59.75,2.6,76.41,2.6c12.59,0,107.32-2.6,107.32-2.6a5.35,5.35,0,0,1,3.78,1.56c1,1,6.52,13.33,6.52,35.23s-5.56,34.26-6.52,35.23a5.35,5.35,0,0,1-3.78,1.56s-91.57-2.6-107.32-2.6-76.41,2.6-76.41,2.6a5.35,5.35,0,0,1-3.77-1.56C122.14,186.27,117.8,173.92,117.8,152Z"/>
                    <path id="Lift_Gate" data-name="Lift Gate"
                          className={`panel-dark${handlePanelStriping(25)}`}
                          onClick={() => handlePanelClick(25)} style={{transform: "translate(-4px, -3px)"}}
                          d="M124.3,112l-10.5-19.9c-9.1,0.2-14.9,0.4-15.1,0.6c-1.4,1.7-11.9,19.4-11.9,61.4   s10.5,59.8,11.9,61.5c0.2,0.3,5.8,0.5,14.7,0.6l11.2-19.2C111.2,158.2,124.3,112,124.3,112z M100.5,98.3c3.8-3.8,7-2.4,7-2.4   s-8.7,27.9-14.8,27.9C92.7,123.8,96.7,102.1,100.5,98.3z M100.8,210.2c-3.8-3.8-7.8-25.5-7.8-25.5c6.1,0,14.8,27.9,14.8,27.9   S104.6,214,100.8,210.2z M99.3,154.2c0-26.4,11.8-57.6,13.6-57.5l8.7,15.1c0,0-5.6,15.5-5.6,42.4c0,26.8,5.6,42.4,5.6,42.4   l-8.7,15.2C111.1,211.7,99.3,180.6,99.3,154.2z"/>
                </g>
            </g>
            {isSuggestedDamagesPresent() &&
                <>
                    <rect id="exterior-legend" x="0" y="350" width="5%" height="5%" fill="url(#suggested-legend)"></rect>
                    <text id="exterior-legend-text" x="28" y="366" fill="var(--charcoal)">Suggested Damage</text>
                </>
            }
        </svg>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay}) {
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps)(MinivanExterior)