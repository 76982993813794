import React from 'react';
import {connect} from "react-redux";
import {sortDamagesByArea} from "../../../utils/utils";
import SuggestedDamageLineItem from "./SuggestedDamageLineItem";
import DamageSuccessMessage from "../../common/DamageSuccessMessage";
import Button from "@prism/button";
import {setClearPanelOff, showAddDamagePanel} from "../../../actions/globalDisplayActions";

const SuggestedDamages = (props) => {

    const {showAddDamagePanel, setClearPanelOff, suggestedDamages, condition, setApiLoading} = props;

    const groupByItem = (input) => {
        return input.reduce((map, currentValue) => {
            let groupKey = currentValue.artCodes.item;

            function panelHasDamages() {
                return currentValue.artCodes.panelId != null && currentValue.artCodes.panelId === props.globalDisplay.selectedFlatCarPanel;
            }

            function isDuplicateDamage() {
                return !!condition.damages?.find(damage => damage.damageKey === currentValue.artCodes.damageKey);
            }

            if (!currentValue.action && panelHasDamages() && !isDuplicateDamage()) {
                if (!map[groupKey]) {
                    map[groupKey] = [];
                }
                map[groupKey].push(currentValue);
            }
            return map;
        }, {});
    };

    const groupedDamages = suggestedDamages.damages?.length > 0 && groupByItem(sortDamagesByArea(suggestedDamages.damages, true))

    const handleAddDamageClick = () => {
        setClearPanelOff()
        showAddDamagePanel()
    };

    return (
        <div id="damage-list-view" className="p-3">
            <DamageSuccessMessage/>
            {groupedDamages && Object.keys(groupedDamages).map(damage => (
                groupedDamages[damage].map(suggestedDamage => (
                    <SuggestedDamageLineItem
                        key={damage}
                        damage={suggestedDamage}
                        setApiLoading={setApiLoading}
                        // groupedDamages={groupedDamages}
                    />
                ))
            ))
            }
            <Button
                id="add-new-damage-button"
                className="w-100"
                color="secondary"
                onClick={handleAddDamageClick}
            >
                ADD NEW DAMAGE
            </Button>
        </div>
    )
};

const mapDispatchToProps = {
    showAddDamagePanel,
    setClearPanelOff
};

function mapStateToProps({suggestedDamages, condition, globalDisplay}) {
    return {
        suggestedDamages,
        condition,
        globalDisplay
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedDamages);