import React from 'react';
import {connect} from "react-redux";

import Button from '@prism/button';
import Col from '@prism/col';
import Row from '@prism/row';

import {deleteF5Note} from "../../actions/f5NotesActions";
import {deleteRepNote} from "../../actions/clientSpecificsActions";
import Confirm from "../common/Confirm";
import AddEditNote from "./AddEditNote";

class Note extends React.Component {

    state = {
        isEdit: false
    };

    showEditNote = () => {
        this.setState({isEdit: true})
    };

    cancelEdit = () => {
        this.setState({isEdit: false})
    };

    displayDate = (dateString) => {
        let year = dateString.substring(0, 4);
        let month = dateString.substring(4, 6);
        let day = dateString.substring(6, 8);
        return `${month}/${day}/${year}`;
    };

    render() {
        const {note, index, noteType} = this.props;

        return (
            <Row className=" notes-row">
                <Col xs={12}>
                    <Row className="flex-grow-1 mt-3">
                        <Col xs={6}>
                            <h5 className="text-secondary-dark mb-2" id={'notes-user-' + index}>{note.addedBy}</h5>
                        </Col>
                        <Col xs={6}>
                            <h5 className="text-black text-right mb-2"
                                id={'notes-date-' + index}>{this.displayDate(note.addedDate)}</h5>
                        </Col>
                    </Row>
                </Col>
                {this.state.isEdit ? <AddEditNote cancelAddNote={this.cancelEdit} note={note} noteType={noteType}/> :

                    <Col xs={12}>
                        <Row className="flex-grow-1 mb-2">
                            <Col className="flex-grow-1">
                                <span className="text-black f5-note-text" id={'notes-comment-' + index}>{note.notesText}</span>
                            </Col>
                        </Row>
                        {(this.props.userDetails.userId.substring(0, 10) === note.addedBy.trim().substring(0, 10)) &&
                            <Row className='mb-2'>
                                <Col xs={6}>
                                    <Button id={`edit-${index}`} onClick={this.showEditNote} color="secondary"
                                            size="sm" className="mr-0 mt-0 btn-full-width"><i
                                        className="icon prism-icon-edit"/></Button>
                                </Col>
                                <Col xs={6}>
                                    <Confirm title="Delete note"
                                             description="Are you sure you want to delete this note?"
                                             submitDescription="Delete"
                                             cancelDescription="Cancel">
                                        {confirm => (
                                            {
                                                F5Note: <Button id={`delete-${index}`}
                                                                onClick={confirm(() => this.props.deleteF5Note(note))}
                                                                color="primary" size="sm"
                                                                className="mt-0 mr-3 btn-full-width delete-note-button"><i
                                                    className="icon prism-icon-trash"/></Button>,
                                                RepNote: <Button id={`delete-${index}`}
                                                                 onClick={confirm(() => this.props.deleteRepNote(note))}
                                                                 color="primary" size="sm"
                                                                 className="mt-0 mr-3 btn-full-width delete-note-button"><i
                                                    className="icon prism-icon-trash"/></Button>
                                            }[noteType]
                                        )}
                                    </Confirm>


                                </Col>
                            </Row>
                        }
                    </Col>
                }

            </Row>
        )
    }
}

const matchDispatchToProps = {
    deleteF5Note,
    deleteRepNote
};

function mapStateToProps({userDetails}) {
    return {userDetails};
}

export default connect(mapStateToProps, matchDispatchToProps)(Note);