import React from "react";
import ConditionTabControls from "./ConditionTabControls"

const ConditionContent = () => {
    return(
        <>
            <ConditionTabControls />
        </>
    )
};

export default ConditionContent;