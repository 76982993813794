import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {updateInteriorOdor} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {PLEASE_SELECT} from "../../../utils/constants";


class InteriorOdor extends React.Component {

    handleChange = (e) => {
        const newOdor = e.currentTarget.value;
        this.props.updateInteriorOdor(newOdor);
    }

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        const value = this.props.condition.interiorOdor;
        const defaultSelection = value === null || value === undefined ? undefined : [value];

        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                <DataPair label={"Interior Odor"} required>
                    <Dropdown id={"interior_odor"} key={`interior-odor${value}`} value={defaultSelection} onChange={this.handleChange}>
                        <option value={""} disabled>{PLEASE_SELECT}</option>
                        {this.props.staticOptionValues && this.props.staticOptionValues.interiorOdor.map(option => {
                            return (
                                <option key={option.description} value={option.description}>{option.description}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateInteriorOdor
};

function mapStateToProps({condition, staticOptionValues}) {
    return {condition, staticOptionValues};
}
export default connect(mapStateToProps, matchDispatchToProps)(InteriorOdor);