
export const logObject = (componentName, functionName, objectName, object) => (dispatch, getState) => {
    const {auctionCode, workOrderNumber} = getState().workOrderInfo;
    const name = `${objectName}-${auctionCode}-${workOrderNumber}`
    console.debug(`${componentName}->${functionName}->${name}`, JSON.stringify(object))
};

export const logInfo = (componentName, info) => (dispatch, getState) => {
    const {auctionCode, workOrderNumber} = getState().workOrderInfo;
    const logLine = `${info} ${auctionCode}-${workOrderNumber}`
    console.debug(`${componentName}->${logLine}`)
};
