import React from "react";
import MinivanExterior from "./MinivanExterior";
import MinivanInterior from "./MinivanInterior";
import Mechanical from "../Mechanical";
import MinivanStructural from "./MinivanStructural";
import {connect} from "react-redux";

const Minivan = (props) => {
    switch (props.flatCarView) {
        case "interior":
            return <MinivanInterior clearSelectedItem={props.clearSelectedItem}/>;
        case "exterior":
            return <MinivanExterior clearSelectedItem={props.clearSelectedItem}/>;
        case "structural":
            return <MinivanStructural clearSelectedItem={props.clearSelectedItem}/>;
        case "mechanical":
            return <Mechanical  clearSelectedItem={props.clearSelectedItem}/>;
        default:
            return <MinivanExterior clearSelectedItem={props.clearSelectedItem}/>;

    }
};

function mapStateToProps({globalDisplay}) {
    const {flatCarView} = globalDisplay;
    return {flatCarView};
}

export default connect(mapStateToProps)(Minivan);