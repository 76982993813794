import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import Button from "@prism/button";
import {addSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import {addDamage} from "../../../actions/conditionActions";
import {showDamageSuccessMessage} from "../../../actions/globalDisplayActions";
import {addSuggestedDamageImage} from "../../../actions/damageImageActions";
import {clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";
import * as pricingUtils from "./PricingUtils";
import SlidePanel from "../../SlidePanel";
import Pricing from "../Pricing";
import DamageCalculator from "./DamageCalculator/DamageCalculator";
import Api from "../../Api";
import csrf from "../../../csrf";
import {isRentalReturnType} from "./PricingUtils";

const AddSuggestedDamageButton = (props) => {
    const {
        account,
        addDamage,
        addSuggestedDamage,
        addSuggestedDamageImage,
        consignment,
        suggestedDamage,
        showDamageSuccessMessage,
        workOrderInfo,
        clearSelectedFlatCarPanel,
        panelSuggestedDamages,
        setApiLoading,
        isCRSO,
        damageRules
    } = props;

    const isFordOrToyota = () => {
        return isFord() || isToyota();
    }
    const isFord = () => {
        return damageRules.directReturnCode === 'Y' && consignment.manufactureCode === "FOR";
    }
    const isToyota = () => {
        return (consignment.categoryCode === "TRP" && consignment.manufactureCode === "TOY");
    }

    const [showPricing, setShowPricing] = useState(false);
    const [pricingTypesList, setPricingTypesList] = useState([]);
    const [dmg, setDmg] = useState({});
    const [showCalculator, setShowCalculator] = useState(false);
    const [damageEstimatorQuestions, setDamageEstimatorQuestions] = useState([]);
    const [showTurnInCalculator, setShowTurnInCalculator] = useState(isFordOrToyota());

    useEffect(() => {
        if (pricingTypesList.length !== 0) {
            setShowPricing(true)
        }
    }, [pricingTypesList]);

    function getDamageKey() {
        return suggestedDamage.newDamageKey ? suggestedDamage.newDamageKey : suggestedDamage.artCodes.damageKey;
    }

    const handleAddSuggestedDamage = async () => {
        setApiLoading(true)
        const chargeableOptions = await pricingUtils.fetchChargeableOptions(suggestedDamage, account, consignment);
        const actionOptions = await pricingUtils.fetchActions(suggestedDamage, account, consignment, workOrderInfo);
        setApiLoading(false)
        let damage = await pricingUtils.buildDamageFromSuggestedDamage(suggestedDamage, getDamageKey(), chargeableOptions, actionOptions);
        if (isCRSO) {
            setDmg(damage)
        } else {
            addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
            addDamage(damage);
            addImageAndAfterAddCleanUp();
        }
    }

    const handlePricingAndAddDamage = async (damage) => {
        let estimateResponse;
        try {
            estimateResponse = await getEstimate(damage);
        } catch (e) {
            return;
        }
        const {overlays, pricingTypes} = await pricingUtils.getPricingTypesAndOverlays(estimateResponse)
        const estimatorQuestions = await fetchDamageEstimatorQuestions(damage);
        await updateDamageEstimatorQuestions(estimatorQuestions);
        if (!!estimateResponse && Object.keys(estimateResponse).length !== 0) {
            setPricingTypesList(pricingTypes);
            damage.pricePlanIds = await pricingUtils.getPricePlans(estimateResponse);
            const callPricing = overlays.length !== 0 && pricingTypes?.length === 0;
            if (!estimatorQuestions?.questions?.length && callPricing) {
                await validatePricingAndAddDamage(damage);
            }
        } else {
            console.log("Empty estimate response")
            // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
        }
    }

     const validatePricingAndAddDamage = async (damage) => {
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (await pricingUtils.pricingIsValid(finalPricing)) {
            await handleAddDamage(damage, finalPricing);
        }
    }
    const handleAddDamage = async (damage, finalPricing) => {
        addSuggestedDamage({...suggestedDamage, isEdited: props.isEdited});
        addDamage(await pricingUtils.mapDamagePricing(damage, finalPricing));
        addImageAndAfterAddCleanUp();
    }

    const addImageAndAfterAddCleanUp = () => {
        if (!!suggestedDamage.path) {
            addSuggestedDamageImage(getDamageKey(), suggestedDamage.path)
        }
        showDamageSuccessMessage();
        if (!panelSuggestedDamages()) clearSelectedFlatCarPanel();
    }


    const getEstimate = async (damage) => {
        setApiLoading(true);
        let estimateResponse;
        try {
            estimateResponse = await pricingUtils.fetchEstimate(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Estimate call failed")
            // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
        }
        return estimateResponse;
    }

    const getPricing = async (damage) => {
        let response;
        try {
            setApiLoading(true);
            response = await pricingUtils.fetchPricing(props, damage);
            setApiLoading(false);
        } catch (e) {
            setApiLoading(false);
            console.log("Pricing call failed")
            // TODO error handling will happen in US1430935. Please remove this comment and log statement when that card is worked
            return e;
        }
        return response;
    }

    async function getFinalPricing(pricingResponse) {
        let finalPricing = {};
        if (Array.isArray(pricingResponse) && !!pricingResponse.length) {
            finalPricing = await pricingUtils.getFinalPricing(pricingResponse)
        }
        return finalPricing;
    }

    const handlePricingUpdate = async (damage) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            pricePlanIds: damage.pricePlanIds
        }))
        const pricingResponse = await getPricing(damage);
        const finalPricing = await getFinalPricing(pricingResponse);
        if (await pricingUtils.pricingIsValid(finalPricing)) {
            await handleAddDamage(damage, finalPricing);
        }
    };

    const cancelPricingOverlay = async () => {
        setDmg(prevDamage => ({
            ...prevDamage,
            repairLaborCost: null,
            repairLaborHours: null,
            paintLaborCost: null,
            paintLaborHours: null,
            partLaborCost: null,
            partLaborHours: null,
            partCost: null,
            finalPartCost: null,
            pricePlanIds: []
        }))
        setShowPricing(false);
    };

    const hidePricingOverlay = async () => {
        setShowPricing(false);
    };

    const isDisabled = useMemo(() => {
        return !suggestedDamage.artCodes.damage ||
            !suggestedDamage.artCodes.damageCode ||
            !suggestedDamage.artCodes.severity ||
            !suggestedDamage.artCodes.severityCode;
    }, [suggestedDamage]);

    const updateDamageEstimatorQuestions = async (response) => {
        debugger
        if (!!response?.questions?.length) {
            setDamageEstimatorQuestions(response);
            setShowCalculator(true);
        } else {
            setShowCalculator(false);
            setShowTurnInCalculator(false);
            console.log("Empty estimate questions response")
        }
    }

    const fetchDamageEstimatorQuestions = async (damage) => {
        setApiLoading(true);
        const questions = await Api.getDamageEstimatorQuestions(buildQuestionParams(damage));
        setApiLoading(false);
        return questions;
    }

    const getDirectReturnCode = () => {
        return damageRules.directReturnCode === 'Y' ? 'YES' : damageRules.directReturnCode;
    }

    const buildQuestionParams = (damage) => {
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';
        let params = {
            itemCode: damage.itemCode,
            subItemCode: damage.subItemCode,
            damageCode: damage.damageCode,
            severityCode: damage.severityCode,
            headers: headers
        }
        if (isFord() && showTurnInCalculator) {
            params.manufactureCode = consignment.manufactureCode
            params.directTurnIn = getDirectReturnCode()
        } else if (isToyota() && showTurnInCalculator) {
            params.manufactureCode = consignment.manufactureCode
            params.categoryCode = consignment.categoryCode
        } else {
            params.actionCode = damage.actionCode
            params.chargeable = damage.chargeable
        }
        return params;

    }

    const hideDamageCalculator = () => {
        setShowCalculator(false);
        setDmg({})
    }

    const handleActionChange = async ({target: {options, selectedIndex, value}}) => {
        if(!!value) {
            setDmg(prevDamage => ({
                ...prevDamage,
                action: options[selectedIndex].text,
                actionCode: value,
            }));
        }
        setShowCalculator(false);
        setDamageEstimatorQuestions([]);
    }

    useEffect( () => {
        const changeOfAction = async () => {
            await handlePricingAndAddDamage(dmg);
        }
        if(dmg.actionCode !== undefined) {
            changeOfAction();
        }

    }, [dmg.actionCode]);


    const handleChargeableChange = async ({target: {options, selectedIndex, value}}) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            chargeable: value,
            chargeableDescription: options[selectedIndex].text,
        }));
    };

    const updatePricingForDamage = async ({repairLaborCost, repairLaborHours, aluminum}) => {
        setDmg(prevDamage => ({
            ...prevDamage,
            repairLaborCost: repairLaborCost,
            repairLaborHours: repairLaborHours,
            aluminum: aluminum
        }));
        setShowCalculator(false);
        await validatePricingAndAddDamage(dmg);
    };

    const resetTurnInCalculatorFlag = () => {
        setShowTurnInCalculator(false);
    };

    return (
        <>
            {showCalculator &&
                <SlidePanel isOpen={showCalculator} width={"100vw"} showHeader={true}
                            contentClassName="bg-white" from="left" zIndex={4001}>
                    <DamageCalculator hideDamageCalculator={hideDamageCalculator}
                                      id="damage-calculator"
                                      damageEstimatorQuestions={damageEstimatorQuestions}
                                      isRentalReturnType={isRentalReturnType(damageEstimatorQuestions)}
                                      manufactureCode={consignment.manufactureCode}
                                      directReturnCode={getDirectReturnCode()}
                                      categoryCode={consignment.categoryCode}
                                      damage={pricingUtils.buildDamage(dmg)}
                                      updatePricingForDamage={updatePricingForDamage}
                                      resetTurnInCalculatorFlag={resetTurnInCalculatorFlag}
                                      updateAction={handleActionChange}
                                      updateChargable={handleChargeableChange}
                    />
                </SlidePanel>}
            {showPricing && <SlidePanel
                isOpen={showPricing}
                width={"100vw"}
                showHeader={true}
                contentClassName="bg-white"
                from="right"
                zIndex={4001}>
                <Pricing
                    hidePricingOverlay={hidePricingOverlay}
                    id="pricing-overlay"
                    damage={dmg}
                    pricingTypes={pricingTypesList}
                    handlePricingUpdate={handlePricingUpdate}
                    cancelPricingOverlay={cancelPricingOverlay}
                />
            </SlidePanel>}
            <Button id={'add-suggested-' + suggestedDamage.artCodes.damageKey}
                    onClick={handleAddSuggestedDamage}
                    disabled={isDisabled}
                    className={"mx-1 my-0"}
                    color="secondary">
                <i className={"icon prism-icon-checkmark p-1 " + (isDisabled ? 'text-light-gray' : 'text-white')}/>
            </Button>
        </>
    );
};

const matchDispatchToProps = {
    addDamage,
    addSuggestedDamage,
    addSuggestedDamageImage,
    showDamageSuccessMessage,
    clearSelectedFlatCarPanel
};

function mapStateToProps({account, condition, consignment, unit, workOrderInfo, damageRules}) {
    return {account, condition, consignment, unit, workOrderInfo, damageRules};
}

export default connect(mapStateToProps, matchDispatchToProps)(AddSuggestedDamageButton);
