import React from "react";
import {connect} from "react-redux";
import Row from "@prism/row";
import Col from "@prism/col";
import Icon from "@prism/icon";
import "../../../styles/review-panel.css";
import {setCurrentConditionTabIndex} from "../../../actions/naviationActions";
import "../../../utils/messages"

class AnnouncementsHeader extends React.Component {

    validationSuccessIcon = () => {
        return <Icon glyph="checkmark-circle" color="success" className="status-icon"/>
    }

    navigateToAccessoriesTab = () => {
        this.props.setCurrentConditionTabIndex(3);
    }

    render() {
        return (
                <Row className={`px-6 py-4 mx-0 review-row show-is-clickable`}
                     onClick={this.navigateToAccessoriesTab}>
                    <Col className="flex-grow-1">
                        <Row className="h2 review-row-title"><Col>Announcements</Col></Row>
                    </Col>
                    <Col className="flex-grow-0">
                        {this.validationSuccessIcon()}
                    </Col>
                </Row>
        );
    }
}

function mapStateToProps() {
    return {};
}

const matchDispatchToProps = {
    setCurrentConditionTabIndex
}

export default connect(mapStateToProps, matchDispatchToProps)(AnnouncementsHeader);
