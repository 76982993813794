import {LOAD_WORK_ORDER_SUCCESS} from "../actions/dispatchTypes";

export default function staticOptionValuesReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload.fieldValues || state;
        default:
            return state;
    }
}
