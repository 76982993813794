import {LOAD_WORK_ORDER_SUCCESS} from "../actions/dispatchTypes";
import cloneDeep from 'lodash.clonedeep';

export default function originalUnitReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return cloneDeep(action.payload.unit) || state;
        default:
            return state;
    }
}