import React from "react";
import Button from "@prism/button";

const DamageRow = (props) => {
    const {damage, index, deleteDamage} = props
    return (
        <tr>
            <td className="px-0">{index + 1}</td>
            <td className="text-left pl-4">{damage.length}</td>
            <td className="text-left pl-4">{damage.width}</td>
            <td className="text-left pl-4">{damage.hours}hr</td>
            <td><Button outline
                        className='btn-icon-only'
                        value={damage.id}
                        onClick={deleteDamage}>
                <i className="icon prism-icon-cancel-circle"/>
            </Button>
            </td>
        </tr>
    )
}

export default DamageRow;
