import {GET_WORK_LIST_REQUESTED, GET_WORK_LIST_SUCCESS} from "../actions/dispatchTypes";

export default function workListReducer(state = {}, action) {
    switch (action.type) {
        case GET_WORK_LIST_REQUESTED:
            return {isFetching: true};
        case GET_WORK_LIST_SUCCESS:
            return {...action.payload, isFetching: false};
        default:
            return state;
    }
}
