import * as React from "react";
import Modal from "@prism/modal";

export default class ConfirmStatusChange extends React.Component {
    state = {
        open: false,
        callback: null
    };

    show = callback => event => {
        event.preventDefault();

        event = {
            ...event,
            target: { ...event.target, value: event.target.value }
        };

        this.setState({
            open: true,
            callback: () => callback(event)
        })
    };

    hide = () => this.setState({ open: false, callback: null });

    confirm = () => {
        this.state.callback();
        this.hide()
    };

    render() {
        return (
            <React.Fragment>
                {this.props.children(this.show)}

                <Modal isOpen={this.state.open}>
                    <Modal.Header><div className="h1 text-white my-2" id="confirmation-title">{this.props.title}</div></Modal.Header>
                    <Modal.Body>
                        <p>{this.props.description}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.Cancel id="confirmation-cancel" text={this.props.cancelDescription} onClick={this.hide}/>
                        <Modal.Okay id="confirmation-submit" text={this.props.submitDescription} onClick={this.confirm}/>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}
