import React from "react";
import ReactDOM from "react-dom";
import {applyMiddleware, compose, createStore} from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import Routes from "./routes/routes";
import {persistReducer, persistStore} from "redux-persist";
import {deviceStorage} from "./state/deviceStorage";
import deviceStorageControllerReducer from "./reducers/deviceStorageControllerReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    version: 1,
    storage: deviceStorage,
    blacklist: [deviceStorageControllerReducer],
    manualPersist: true
}

const loadStateReducer = (state, action) => {
    if(action.type === 'hydrate') {
        return reducers(action.payload, action);
    }
    return reducers(state, action);
}

const persistedReducer = persistReducer(persistConfig, loadStateReducer);

const store = createStore(
    persistedReducer,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
);

export const persistor = persistStore(store);

ReactDOM.render(<Routes store={store} />, document.querySelector("#root"));
