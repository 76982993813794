import {
    LOAD_WORK_ORDER_SUCCESS,
    SET_REPURPOSED_FLAG,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";

export default function repurposeReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload.repurpose || state;
        case SET_REPURPOSED_FLAG:
            return {...state, repurposed: action.payload.repurposed}
        case UNLOAD_WORK_ORDER:
            return null;
        default:
            return state;
    }
}
