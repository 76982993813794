import Api from "../components/Api";
import {LOAD_AUCTION_LOCATIONS, LOAD_LOCATIONS_FAILURE} from "./dispatchTypes";

export const getAuctionLocations = () => async (dispatch) => {
    const response = await Api.getAuctionLocations();
    if (response.items) {
        dispatch({
            type: LOAD_AUCTION_LOCATIONS,
            payload: response
        })
    } else {
        dispatch({
            type: LOAD_LOCATIONS_FAILURE
        })
    }


};