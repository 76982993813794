import React from 'react';
import SlidePanel from "../SlidePanel";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {togglePushPanel} from "../../actions/pushPanelActions";
import Col from "@prism/col";
import Row from "@prism/row";

class ButtonTrayPanel extends React.Component {

    render() {
        const {activePanel, name, showButtonTray, from, showClose, overlayClassName, textColor} = this.props;
        const color = textColor || 'white';
        return(
            <SlidePanel {...this.props}
                        id={`${name}-panel`}
                        isOpen={(activePanel === name)}
                        offset={showButtonTray}
                        from={from}
                        overlayClassName={overlayClassName}>
                {showClose && <Row className="flex-row-reverse mb-0 pb-0 navbar mx-0">
                    <Col className={`flex-grow-0 text-${color} px-0`}>
                        <i style={{fontSize: '20px'}} className="icon prism-icon-cross cursor-pointer"
                           id='close-button-tray' onClick={() => this.props.togglePushPanel(name)} />
                    </Col>
                </Row>}
                {this.props.children}
            </SlidePanel>);
    }
}

const matchDispatchToProps = {
    togglePushPanel
};

function mapStateToProps({pushPanels, globalDisplay}) {
    const {showButtonTray} = globalDisplay;
    const {activePanel} = pushPanels;
    return {activePanel, showButtonTray};
}

export default connect(mapStateToProps, matchDispatchToProps)(ButtonTrayPanel);

ButtonTrayPanel.propTypes = {
    name: PropTypes.string.isRequired,
    showClose: PropTypes.bool
};

ButtonTrayPanel.defaultProps = {
    showClose: true
};