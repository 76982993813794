import {
    DELETE_MESSAGE,
    CLEAR_ALL_MESSAGES
} from "./dispatchTypes";

export const deleteMessage = (id) => dispatch => {
    dispatch({
        type: DELETE_MESSAGE,
        id
    })
};

export const clearAllMessages = () => dispatch => {
    dispatch({
        type: CLEAR_ALL_MESSAGES
    })
}

