import React from 'react';

import Col from '@prism/col';
import {CheckboxQuestion, RadioQuestion, RangeQuestion, SelectQuestion} from './QuestionTypes';

class Question extends React.Component {

    constructor(props) {
        super(props);
        this.questionHandleChange = this.questionHandleChange.bind(this);
    }

    questionHandleChange = (event) => {
        const type = this.props.question.uiElementType;
        let answer = '';
        switch (type) {
            case 'Select':
                answer = event.target.value;
                break;
            case 'Checkbox':
                answer = event.currentTarget.checked ? 'true' : 'false';
                break;
            case 'Range':
                answer = event.value.toString();
                break;
            case 'Radio':
                answer = event.target.value;
                break;
            default:
                console.log('Unknown question type')
        }
        if (answer !== this.props.question.answer) {
            this.props.updateAnswer(this.props.question.id, answer);
        }
    }

    render() {
        let question = this.props.question;
        let required = !((this.props.question.optional && this.props.question.mandatory === 'Y') || (this.props.question.mandatory === 'N'))

        switch(question.uiElementType) {
            case "Checkbox":
                return (
                    <div className="col-12 p-0">
                        <CheckboxQuestion question={question} onChange={this.questionHandleChange} required={required}/>
                    </div>
                )
            case "Radio":
                return (
                    <div className="flex-grow-1">
                        <RadioQuestion question={question} onChange={this.questionHandleChange} required={required}/>
                    </div>
                )
            case "Range":
                return (
                    <div className="flex-grow-1">
                        <RangeQuestion question={question} onChange={this.questionHandleChange} min={parseInt(question.answers[0])} max={parseInt(question.answers[question.answers.length-1])} required={required}/>
                    </div>
                )
            case "Select":
                return (
                    <div className="flex-grow-1 col-12 p-0">
                        <SelectQuestion question={question} onChange={this.questionHandleChange} required={required}/>
                    </div>
                )
            case "Label":
                let trailingText = question.question.match(/\(.+\)/)[0];
                let questionText = question.question.slice(0,question.question.indexOf(trailingText));
                return (
                    <div className="col-12 p-0">
                        <Col className="mb-2">{questionText}&nbsp;<span className="font-size-sm font-weight-light">{trailingText}</span></Col>
                    </div>
                )
            default:
                return (
                    <div className="flex-grow-1">
                        <Col className="mb-2">Unmatched element {question.uiElementType}</Col>
                    </div>
                )
        }
    }
}

export default Question;
