import React from "react";
import SUVExterior from "./SUVExterior";
import SUVInterior from "./SUVInterior";
import Mechanical from "../Mechanical";
import SUVStructural from "./SUVStructural";
import {connect} from "react-redux";

const SUV = (props) => {
    switch (props.flatCarView) {
        case "interior":
            return <SUVInterior clearSelectedItem={props.clearSelectedItem}/>;
        case "exterior":
            return <SUVExterior clearSelectedItem={props.clearSelectedItem}/>;
        case "structural":
            return <SUVStructural clearSelectedItem={props.clearSelectedItem}/>;
        case "mechanical":
            return <Mechanical  clearSelectedItem={props.clearSelectedItem}/>;
        default:
            return <SUVExterior clearSelectedItem={props.clearSelectedItem}/>;

    }
};

function mapStateToProps({globalDisplay}) {
    const {flatCarView} = globalDisplay;
    return {flatCarView};
}

export default connect(mapStateToProps)(SUV);