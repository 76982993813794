import React from 'react';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import {updateChargingCable, addDamage} from "../../../actions/conditionActions";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {
    CHARGING_CABLE_CATEGORIES, CHARGING_CABLE_MISSING, CHARGING_CABLE_MISSING_ANNOUNCEMENT_CODE,
    CONDITION_ANNOUNCEMENT
} from "../../../utils/constants";
import {MISSING_CHARGING_CABLE_DAMAGE} from "../../../utils/constantsDamages";
import {connect} from "react-redux";
import Row from "@prism/row";
import AddDamageModal from "../condition/AddDamageModal";
import {deleteAutoDamage} from "../../../actions/conditionActions";
import DataPair from "../../common/DataPair";
import {FormGroup} from "@prism/form";

class ChargingCable extends React.Component {

    state = {};

    handleChargingCableChange = (e) => {
        if(this.props.condition[e.currentTarget.name] === (e.currentTarget.value === "true")) {return}
        this.props.updateChargingCable(e.currentTarget.value === "true");
        if (e.currentTarget.value === "true") {
            const chargingCableDamage = this.props.automatedDamages.find(damage => damage.item === MISSING_CHARGING_CABLE_DAMAGE.item);
            if(chargingCableDamage){
                this.props.deleteAutoDamage(chargingCableDamage);
                //delete announcement
                this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, CHARGING_CABLE_MISSING_ANNOUNCEMENT_CODE);
            }
        } else {
            this.toggleDamageModal()
            //add announcement
            this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, CHARGING_CABLE_MISSING_ANNOUNCEMENT_CODE, CHARGING_CABLE_MISSING);
        }
    };

    addChargingCableDamage = () => {
        this.props.addDamage(MISSING_CHARGING_CABLE_DAMAGE);
    };

    handleCancelClick = () => {
        //forcing user to select Yes or No
        this.props.updateChargingCable(null);
        this.toggleDamageModal();
        //delete announcement
        this.props.deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, CHARGING_CABLE_MISSING_ANNOUNCEMENT_CODE);
    };

    toggleDamageModal = () => {
        const {showDamageModal} = this.state;
        this.setState({
            showDamageModal: !showDamageModal,
        });
    };

    render() {

        const isElectric = this.props.designatedDescription?.powertrain?.engine ? CHARGING_CABLE_CATEGORIES.includes(this.props.designatedDescription.powertrain.engine.fuelCategory) : false;
        const {chargingCable} = this.props.condition;

        if (isElectric) {
            return (
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="my-2 ml-3">
                    <AddDamageModal showModal={this.state.showDamageModal}
                                    onCancelClick={this.handleCancelClick}
                                    toggle={this.toggleDamageModal}
                                    damage={MISSING_CHARGING_CABLE_DAMAGE}/>
                    <Row className="align-items-center" id="charging-cable">
                        <DataPair label="Charging Cable Present?" required>
                            <FormGroup className="m-0" row>
                                <RadioButton className=""
                                             label="Yes"
                                             name="chargingCable"
                                             value={true}
                                             checked={chargingCable === true}
                                             onClick={this.handleChargingCableChange}
                                />
                                <RadioButton className="ml-3"
                                             label="No"
                                             name="chargingCable"
                                             value={false}
                                             checked={chargingCable === false}
                                             onClick={this.handleChargingCableChange}
                                />
                            </FormGroup>
                        </DataPair>
                    </Row>
                </Col>
            )
        } else {
            return null
        }
    }
}

const matchDispatchToProps = {
    updateChargingCable,
    addDamage,
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    deleteAutoDamage
};


function mapStateToProps({condition, designatedDescription, automatedDamages}) {
    return {condition, designatedDescription, automatedDamages};
}

export default connect(mapStateToProps, matchDispatchToProps)(ChargingCable);