import React from "react";

import Col from "@prism/col";
import {togglePushPanel} from "../../actions/pushPanelActions";
import {connect} from "react-redux";
import Ymmt from "./Ymmt";
import Seller from "./Seller";
import Inspector from "./Inspector";
import {isAuditMode} from "../../utils/utils";

const HeroContent = (props) => {
  return(
    <div className="hero-content mt-4" onClick={() => props.togglePushPanel("hero")}>
      <Col>
          <div className="pb-3" style={{border: "2px solid #babcbe"}}>
              <div className="work-order-content p-2 h3">WO #<span
                  className="font-weight-bold">{props.workOrderInfo.workOrderNumber}</span></div>
              <div className="pb-3">
                  <Ymmt/>
              </div>
              <div>
                  <Seller/>
              </div>
              <div>
                  {isAuditMode() && <Inspector/>}
              </div>
          </div>
      </Col>
    </div>
  )
};
const matchDispatchToProps = {
  togglePushPanel
};

function mapStateToProps({workOrderInfo, description}) {
    return {workOrderInfo, description};
}

export default connect(mapStateToProps, matchDispatchToProps)(HeroContent);