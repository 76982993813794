import React from "react";
import "../../styles/spinner.css";

function Spinner(props) {

    const size = props.size ? props.size : 30;
    const className = props.className;

    return(
    <div className="spinner" style={{width: `${size}px`, height: `${size}px`}}>
        <i style={{fontSize: `${size}px`}} className={`icon prism-icon-spinner spinner-icon ${className}`} id='spinner' />
    </div>
    )
}

export default Spinner;