import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import {updateServiceDue} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import Input from "@prism/input";


class ServiceDue extends React.Component {

    handleChange = (e) => {
        const value = e.target.value;
        const serviceDue = value === "" ? null : parseInt(value) || 0;
        this.props.updateServiceDue(serviceDue);
    };

    render() {

        if(this.props.condition===null) {
            return <></>
        }

        if(this.props.condition && (this.props.condition.serviceRequired === undefined || this.props.condition.serviceRequired === null)) {
            return <></>
        }
        return (
            <Col xs={6} sm={6} md={4} lg={3} xl={3} className='mb-2'>
                <DataPair label="Service Due(m)">
                    <Input
                        type="text"
                        id="service-due-in-miles"
                        value={this.props.condition.serviceDueInMiles}
                        maxLength={6}
                        onChange={this.handleChange}
                    />
                </DataPair>
            </Col>
        )
    }
}

const matchDispatchToProps = {
    updateServiceDue
};

function mapStateToProps({condition}) {
    return {condition};
}
export default connect(mapStateToProps, matchDispatchToProps)(ServiceDue);