import {LOAD_WORK_ORDER_SUCCESS, UNLOAD_WORK_ORDER} from "../actions/dispatchTypes";

export default function originalWorkOrderReducer(state = {}, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return action.payload;
        case UNLOAD_WORK_ORDER:
            return {};
        default:
            return state;
    }
}
