import React from "react";
import {handlePanelClick} from "../../../../../actions/flatCarActions";
import "../../../../../styles/flat-car.css"
import {connect} from "react-redux";

const SedanInterior = (props) => {

    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -120 473.02 490">
            <g id="Layer_2" data-name="Layer 2" className="flat-car rotate">
                <g id="sedan-interior">
                    <path className="panel-op-25"
                          d="M336.29,221.7c.94-2.33-2.49-4.68-1.68-7H388s46.63,2,68.59-21,22-115.6,0-138.57-68.59-21-68.59-21H334.63c-.83-2.35,2.6-4.72,1.66-7-3.14-7.77-6-15-15.85-13.85a214.44,214.44,0,0,0,5.23,20.9l-105.12,0S73.67,34.57,72,34s-52.37,9.59-54.4,12S0,63.89,0,124.39s15.55,76.06,17.58,78.41,52.72,12.5,54.4,12,148.57-.12,148.57-.12l105.12,0a213.71,213.71,0,0,0-5.23,20.9C330.29,236.67,333.15,229.47,336.29,221.7Zm74.41-16.24,4.1-13.59s36.27.52,46.23-26C461,196.86,410.7,205.46,410.7,205.46Zm-54.17-8.74c-16.91,4.06-78.74-15.87-78.74-15.87,11.48-25.35,10.81-56.46,10.81-56.46s.67-31.1-10.81-56.45c0,0,61.83-19.93,78.74-15.87,0,0,15.53,16.24,17.23,72.32C372.06,180.48,356.53,196.72,356.53,196.72ZM461,82.22c-10-26.51-46.23-26-46.23-26l-4.1-13.57S461,51.24,461,82.22ZM342,45c-9.77,8.25-74.26,16.48-122.21,18.9,3.73-10.3,8.33-16.45,12.07-20C288.4,43.71,342.41,44.6,342,45Zm-128.35-1c-4.81,7.25-7,15.55-8,20.45-9.21.27-17.36.23-23.81-.16a228.8,228.8,0,0,1-42.15-6.65,67.59,67.59,0,0,1,6.95-12.33C165.64,44.5,189.36,44.1,213.64,43.94Zm-74.46,1.62a64.49,64.49,0,0,0-5,10.58A188.32,188.32,0,0,1,110.51,48C116.33,47,126.4,46.17,139.18,45.56Zm-100.49,4c-15.13,4-16.06,41.51-16.06,41.51L11.4,92.31S15.62,46.57,38.69,49.57ZM11.4,155.4l11.23,1.22s.93,37.52,16.06,41.51C15.62,201.14,11.4,155.4,11.4,155.4Zm33.49-8.76c-.42-7.38-.75-14.8-.81-22.25s.39-14.84.81-22.24a192.71,192.71,0,0,1,2.64-23.34c.38-2.09,3.71-20.05,6.39-20,0,0,48,1.36,73,13.18,0,0-3.38,16.91-3.73,52.39.35,35.51,3.73,52.4,3.73,52.4-25,11.82-73,13.18-73,13.18-2.68.06-6-17.88-6.39-20A192.48,192.48,0,0,1,44.89,146.64Zm65.62,54.14a188.32,188.32,0,0,1,23.72-8.13,65,65,0,0,0,4.93,10.58C126.38,202.62,116.33,201.81,110.51,200.78Zm36.09,2.78a66.5,66.5,0,0,1-6.95-12.35,228.8,228.8,0,0,1,42.15-6.65c6.45-.39,14.6-.43,23.81-.17,1,4.91,3.24,13.22,8,20.48C189.36,204.69,165.66,204.3,146.6,203.56ZM231.83,205c-3.74-3.6-8.34-9.75-12.05-20,47.93,2.43,112.41,10.65,122.21,18.9C342.41,204.18,288.4,205.1,231.83,205Z"/>
                    <g id="Right_Front_Seat" data-name="Left Front Seat" onClick={() => handlePanelClick(51)}>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                                 points="278.4 193.93 278.4 155.19 262.85 156.01 262.88 193.12 278.4 193.93"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                                 points="240.23 191.93 262.28 193.09 262.28 156.04 240.23 157.09 240.23 191.93"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                              d="M234.4,205.94c2.9,0,5.26-2.62,5.26-5.85V149.75a5.6,5.6,0,0,0-5.31-5.86l-11.3-2.07a3.37,3.37,0,0,0-3.94,2.75l-2.36,13.8a9.28,9.28,0,0,1,4.07-1h10.11a1.79,1.79,0,0,1,1.79,1.79v29.1a1.8,1.8,0,0,1-1.79,1.8H219.75a8,8,0,0,1-3.05-.6l2.58,15.11A3.36,3.36,0,0,0,223,207.4Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                              d="M230.93,189.53a1.22,1.22,0,0,0,1.21-1.22v-29.1a1.21,1.21,0,0,0-1.21-1.21H220.82a8.77,8.77,0,0,0-8.76,8.76v15.08a7.7,7.7,0,0,0,7.69,7.69Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                              d="M278.71,194.52l-38.48-2v7.91c28.24,13,41.93,7.6,46.5,4.67,3.3-2.11,4.47-4.47,4.47-5.86v-.14C288.24,199.81,284.92,199.23,278.71,194.52Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                              d="M279.11,194.09c6.27,4.76,9.51,5.17,12.45,4.31,3.11-.91,5.62-9.55,6-20.55.08-1.23.13-2.47.13-3.68,0-1.66-.08-3.34-.24-5-.61-10-3-17.34-5.85-18.19-3.28-1-6.51-.64-12.45,3.87Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 51 ? ' active' : ''}`}
                              d="M240.23,156.51l38.36-2,.05,0c6.26-4.77,9.6-4.86,12.21-4.26l.33,0a2.38,2.38,0,0,0,0-.39c0-1.39-1.18-3.77-4.51-5.91-4.62-2.95-18.39-8.4-46.61,4.59Z"/>
                    </g>
                    <path id="Cargo_Area" data-name="Cargo Area" className={`panel-dark${props.selectedFlatCarPanel === 26 ? ' active' : ''}`} onClick={() => handlePanelClick(26)}
                          d="M96.54,45.5S88.19,74,88.19,124.75,96.54,204,96.54,204c-32.92.1-64-11.77-64.73-12.32a4.18,4.18,0,0,1-1.6-2.14,199,199,0,0,1-11.32-65.38A196.73,196.73,0,0,1,29.7,59.86a4.22,4.22,0,0,1,1.61-2.17C32.08,57.14,63.86,44.1,96.54,45.5Z"/>
                    <path id="Center_Console" data-name="Center Console" className={`panel-dark${props.selectedFlatCarPanel === 61 ? ' active' : ''}`} onClick={() => handlePanelClick(61)}
                          d="M321.91,108.26l-16.76,5.67-53.74-.13a3.18,3.18,0,0,0-3.2,3.19V132.5a3.19,3.19,0,0,0,3.19,3.19h53.8l16.69,5.3v-5.37L306.83,131a2.38,2.38,0,0,1-1.68-2.27v-8.32a2.4,2.4,0,0,1,1.66-2.27l15.08-4.8Z"/>
                    <g id="Headliner" onClick={() => handlePanelClick(62)}>
                        <path className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`}
                              d="M232.34,118.17a2,2,0,0,0-2-2h-6.52c-4.2,0-7.64,3.12-7.64,6.93v2.84c0,3.81,3.44,6.92,7.64,6.92h6.52a2,2,0,0,0,2-2Z"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="237.77" y="122.6" width="0.76" height="4.05"
                              transform="translate(362.78 -113.53) rotate(90)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="236.19" y="116.48" width="0.76" height="4.05"
                              transform="translate(153.08 -132.57) rotate(45)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="236.19" y="128.47" width="0.76" height="4.05"
                              transform="translate(-22.99 205.5) rotate(-45)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="210.21" y="122.35" width="0.76" height="4.05"
                              transform="translate(86.21 334.96) rotate(-90)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="211.79" y="128.47" width="0.76" height="4.05"
                              transform="translate(269.93 372.8) rotate(-135)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="211.79" y="116.48" width="0.76" height="4.05"
                              transform="translate(446 52.26) rotate(135)"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="223.99" y="109.57" width="0.76" height="4.05"/>
                        <rect className={`panel-dark${props.selectedFlatCarPanel === 62 ? ' active' : ''}`} x="223.99" y="135.63" width="0.76" height="4.05"/>
                    </g>
                    <path id="Carpet" className={`panel-dark${props.selectedFlatCarPanel === 54 ? ' active' : ''}`} onClick={() => handlePanelClick(54)}
                          d="M205.73,44.32H187.66a7.11,7.11,0,0,0-7.11,7.11V97.9a7.12,7.12,0,0,0,7.11,7.12h18.07Z"/>
                    <path id="Carpet-2" data-name="Carpet" className={`panel-dark${props.selectedFlatCarPanel === 56 ? ' active' : ''}`} onClick={() => handlePanelClick(56)}
                          d="M205.73,143.72H187.66a7.12,7.12,0,0,0-7.11,7.12v46.47a7.12,7.12,0,0,0,7.11,7.12h18.07Z"/>
                    <polygon id="LF_Door_Panel" data-name="LF Door Panel" className={`panel-dark${props.selectedFlatCarPanel === 57 ? ' active' : ''}`} onClick={() => handlePanelClick(57)}
                             points="231.59 0 308.54 33.27 297.72 33.27 246.28 19.8 228.93 5.1 231.59 0"/>
                    <polygon id="RF_Door_Panel" data-name="RF Door Panel" className={`panel-dark${props.selectedFlatCarPanel === 59 ? ' active' : ''}`} onClick={() => handlePanelClick(59)}
                             points="231.59 248.12 308.54 214.84 297.72 214.84 246.28 228.32 228.93 243.01 231.59 248.12"/>
                    <polygon id="LR_Door_Panel" data-name="LR Door Panel" className={`panel-dark${props.selectedFlatCarPanel === 58 ? ' active' : ''}`} onClick={() => handlePanelClick(58)}
                             points="121.83 0 198.79 33.27 187.97 33.27 136.53 19.8 119.18 5.1 121.83 0"/>
                    <polygon id="RR_Door_Panel" data-name="RR Door Panel" className={`panel-dark${props.selectedFlatCarPanel === 60 ? ' active' : ''}`} onClick={() => handlePanelClick(60)}
                             points="121.83 248.12 198.79 214.84 187.97 214.84 136.53 228.32 119.18 243.01 121.83 248.12"/>
                    <path id="RF_Mat" data-name="RF Mat" className={`panel-dark${props.selectedFlatCarPanel === 55 ? ' active' : ''}`} onClick={() => handlePanelClick(55)}
                          d="M303,198.46l.26-52.09a3.8,3.8,0,0,1,5.06-3.59l10.22,4.11v54.37l-12.48.2A3,3,0,0,1,303,198.46Z"/>
                    <path id="LF_Mat" data-name="LF Mat" className={`panel-dark${props.selectedFlatCarPanel === 53 ? ' active' : ''}`} onClick={() => handlePanelClick(53)}
                          d="M308.27,105.71l10.22-4.11V98.83C313.79,96.2,310.32,87,310.32,76s3.47-20.24,8.17-22.87V47.23L306,47A3,3,0,0,0,303,50l.26,52.09A3.8,3.8,0,0,0,308.27,105.71Z"/>
                    <path id="Dash" className={`panel-dark${props.selectedFlatCarPanel === 45 ? ' active' : ''}`} onClick={() => handlePanelClick(45)}
                          d="M332.09,76c0,11.66-3.89,21.32-9,23.27V200.39l27.36-3.09s17.66-16.12,18.92-72.89-18.92-74.06-18.92-74.06S341,48.05,323.09,47v5.67C328.2,54.64,332.09,64.3,332.09,76Z"/>
                    <ellipse id="Steering_Wheel" data-name="Steering Wheel" className={`panel-dark${props.selectedFlatCarPanel === 46 ? ' active' : ''}`} cx="321.3" cy="75.72" onClick={() => handlePanelClick(46)}
                             rx="8.23" ry="19.36"/>
                    <polygon id="Left_Visor" data-name="Left Visor" className={`panel-dark${props.selectedFlatCarPanel === 63 ? ' active' : ''}`} onClick={() => handlePanelClick(63)}
                             points="381.83 46.19 377.87 46.19 369.1 54.6 369.1 89.1 381.83 89.1 381.83 46.19"/>
                    <polygon id="Right_Visor" data-name="Right Visor" className={`panel-dark${props.selectedFlatCarPanel === 64 ? ' active' : ''}`} onClick={() => handlePanelClick(64)}
                             points="381.83 200.14 377.87 200.14 369.1 191.72 369.1 157.23 381.83 157.23 381.83 200.14"/>
                    <path id="Mirror" className={`panel-dark${props.selectedFlatCarPanel === 47 ? ' active' : ''}`} onClick={() => handlePanelClick(47)}
                          d="M381.64,134.78v-9.87h4.73v-.49h-4.73V114.1a2.89,2.89,0,0,0-2.89-2.89h-4.34v26.91h3.89A3.35,3.35,0,0,0,381.64,134.78Z"/>
                    <g id="Left_Front_Seat" data-name="Right Front Seat" onClick={() => handlePanelClick(48)}>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                                 points="278.4 94.37 278.4 55.62 262.85 56.45 262.88 93.56 278.4 94.37"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                                 points="240.23 92.37 262.28 93.53 262.28 56.48 240.23 57.52 240.23 92.37"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                              d="M234.4,106.37c2.9,0,5.26-2.62,5.26-5.85V50.18a5.6,5.6,0,0,0-5.31-5.86l-11.3-2.07A3.37,3.37,0,0,0,219.11,45l-2.36,13.81a9.14,9.14,0,0,1,4.07-1h10.11a1.79,1.79,0,0,1,1.79,1.79v29.1a1.79,1.79,0,0,1-1.79,1.79H219.75a8,8,0,0,1-3.05-.6l2.58,15.11a3.36,3.36,0,0,0,3.76,2.78Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                              d="M230.93,90a1.21,1.21,0,0,0,1.21-1.21V59.65a1.22,1.22,0,0,0-1.21-1.22H220.82a8.77,8.77,0,0,0-8.76,8.76V82.27A7.7,7.7,0,0,0,219.75,90Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                              d="M278.71,95l-38.48-2v7.91c28.24,13,41.93,7.61,46.5,4.68,3.3-2.11,4.47-4.48,4.47-5.87v-.14C288.24,100.24,284.92,99.66,278.71,95Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                              d="M279.11,94.53c6.27,4.75,9.51,5.16,12.45,4.3,3.11-.91,5.62-9.54,6-20.55.08-1.23.13-2.47.13-3.68,0-1.66-.08-3.33-.24-5-.61-10-3-17.35-5.85-18.19-3.28-1-6.51-.64-12.45,3.86Z"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 48 ? ' active' : ''}`}
                              d="M240.23,57l38.36-2,.05,0c6.26-4.76,9.6-4.86,12.21-4.25l.33,0a2.38,2.38,0,0,0,0-.39c0-1.39-1.18-3.77-4.51-5.9-4.62-3-18.39-8.41-46.61,4.58Z"/>
                    </g>
                    <polygon id="CR_Seat" data-name="CR Seat" className="panel-dark panel-outline"
                             points="122.96 109.21 172.13 109.21 172.08 141.12 122.91 141.21 122.96 109.21"/>
                    <path id="Rear_Seat_Back" data-name="Rear Seat Back" className="panel-dark panel-outline"
                          d="M106.72,47.75A4,4,0,0,1,111,44.4c7.63.54,11.5,5.68,11.5,15.28v.47c-.1,7.61-.16,33-.16,64.59s.06,57,.16,64.59v.47c0,9.59-3.87,14.73-11.5,15.28a4,4,0,0,1-4.25-3.36l-2.47-14.15h9.29a1.79,1.79,0,0,0,1.6-1.83V159.09a1.53,1.53,0,0,0-1.48-1.67h-9.35l.08-19.13c.94,0,4.16,0,6.37,0a1.4,1.4,0,0,0,1.38-1.38v-24.3a1.47,1.47,0,0,0-1.33-1.49c-.92,0-5.11,0-6.42.1l-.08-19.1h9.27a1.57,1.57,0,0,0,1.51-1.69V63.64A1.69,1.69,0,0,0,113.42,62l-9.17-.06Z"/>
                    <path id="CR_Headrest" data-name="CR Headrest" className="panel-dark panel-outline"
                          d="M99.67,111.72h8.09a3.8,3.8,0,0,1,3.8,3.8v17.87a4.34,4.34,0,0,1-4.34,4.34H99.67a.55.55,0,0,1-.55-.55V112.27A.55.55,0,0,1,99.67,111.72Z"
                          transform="translate(210.68 249.45) rotate(180)"/>
                    <path id="LR_Headrest" data-name="LR Headrest" className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`} onClick={() => handlePanelClick(49)}
                          d="M96.83,62.55h12.79a4.9,4.9,0,0,1,4.9,4.9V86a5.58,5.58,0,0,1-5.58,5.58H96.83a.76.76,0,0,1-.76-.76V63.31A.76.76,0,0,1,96.83,62.55Z"
                          transform="translate(210.58 154.09) rotate(180)"/>
                    <path id="RR_Headrest" data-name="RR Headrest" className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`} onClick={() => handlePanelClick(50)}
                          d="M96.88,158h12.79a4.9,4.9,0,0,1,4.9,4.9V181.4A5.59,5.59,0,0,1,109,187H96.88a.76.76,0,0,1-.76-.76V158.76A.76.76,0,0,1,96.88,158Z"
                          transform="translate(210.68 344.99) rotate(180)"/>
                    <g id="Right_Rear_Seat" data-name="Right Rear Seat" onClick={() => handlePanelClick(50)}>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`}
                              d="M123,192.63,160.25,194l.46.26c5.24,4,8.21,4.27,11.31,3.33l.16-.07h.09a1.53,1.53,0,0,1,0,.3c0,1.17-1,3.05-3.77,4.83-3.59,2.29-17.54,8.71-46.79-4.73Z"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`}
                                 points="160.02 158.76 160.02 193.41 144.8 192.85 144.63 157.98 160.02 158.76"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`}
                                 points="144.05 157.95 144.22 192.84 123.04 192.06 122.91 156.87 144.05 157.95"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`}
                                 points="122.91 141.79 172.25 141.87 172.16 158.82 122.91 156.29 122.91 141.79"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 50 ? ' active' : ''}`}
                              d="M172.16,159.39l.09,37.47a1.76,1.76,0,0,1-.4.18c-3.07.94-5.91.65-11.26-3.59l-.14-34.67Z"/>
                    </g>
                    <g id="Left_Rear_Seat" data-name="Left Rear Seat" onClick={() => handlePanelClick(49)}>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`}
                              d="M121.74,51.56c19.29-8.84,36.59-10.41,46.31-4.19,3.09,2,4.21,4.11,4.23,5.35l-.27-.1c-3.34-1-6.36-.75-11.86,3.6L123,57.79Z"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`}
                                 points="144.9 57.56 159.97 56.8 159.97 91.37 144.9 92.17 144.9 57.56"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`}
                                 points="123.05 58.36 144.32 57.59 144.32 92.2 122.87 93.34 123.05 58.36"/>
                        <polygon className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`}
                                 points="172.16 91.3 172.25 108.63 122.96 108.63 122.87 93.92 172.16 91.3"/>
                        <path className={`panel-dark panel-outline${props.selectedFlatCarPanel === 49 ? ' active' : ''}`}
                              d="M160.55,56.64c5.83-4.6,8.67-4.27,11.3-3.47a1.76,1.76,0,0,1,.4.18l-.09,37.37-11.61.62Z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay}){
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps)(SedanInterior)