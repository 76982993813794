import React from "react";


import Button from "@prism/button";

class EditDamageButton extends React.Component {

    render() {
        return (
            <>
                <Button id={this.props.id}
                        disabled={this.props.disabled}
                        className={"mx-2 my-0"}
                        onClick={this.props.onClick}
                >
                    <i className="icon prism-icon-edit large-button-icon text-secondary"/>
                </Button>
            </>
        )
    }
}


export default EditDamageButton;