import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React from "react";
import {COUNTRIES, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";

const Country = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onCountryChange
    } = props

    const country = designatedDescription?.country?.code;

    return(
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-country" label="Country">
                    {country}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-country" label="Country" required={true}>
                    <Dropdown disabled={props.disabled}
                              onChange={(e) => onCountryChange(COUNTRIES, e)}
                              value={country}
                              id='country-dropdown'>
                        {!country && <option value=''>{PLEASE_SELECT}</option>}
                        {COUNTRIES.map(option => {
                            return (<option key={option.code}
                                            value={option.code}>{option.name}</option>)
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>

    )

}

export default Country;