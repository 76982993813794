import React from "react";
import Col from "@prism/col";
import {addAutoDamage, deleteAutoDamage} from "../../../actions/conditionActions";
import {toggleBrakeFluidServiceSelected, toggleEngineOilServiceSelected} from "../../../actions/globalDisplayActions";
import {connect} from "react-redux";
import Checkbox from "@prism/checkbox";
import {BRAKE_FLUID, ENGINE_OIL,} from "../../../utils/constants";
import {SERVICE_REQUIRED_DAMAGE} from "../../../utils/constantsDamages";
import Row from "@prism/row";
import Api from "../../Api";
import AddDamageModal from "./AddDamageModal";

class EngineOilAndBrakeFluid extends React.Component {

    state = {};
    getServiceRequiredDamage = () => {
        if (this.props.condition && this.props.condition.damages) {
            return this.props.condition.damages.find(damage => damage.item === SERVICE_REQUIRED_DAMAGE.item);
        }
        return null;
    };

    componentDidMount() {
        if (this.props.account.isBmw) {
            let serviceRequiredDamage = this.getServiceRequiredDamage();
            if (serviceRequiredDamage && serviceRequiredDamage.notes?.toLowerCase().includes(ENGINE_OIL.toLowerCase())) {
                if (!this.props.globalDisplay.engineOilServiceSelected) {
                    this.props.toggleEngineOilServiceSelected();
                }
            }
            if (serviceRequiredDamage && serviceRequiredDamage.notes?.toLowerCase().includes(BRAKE_FLUID.toLowerCase())) {
                if (!this.props.globalDisplay.brakeFluidServiceSelected) {
                    this.props.toggleBrakeFluidServiceSelected();
                }
            }
        }
    }

    getChargeableOptions = async () => {
        return Api.getChargeableOptions({
            itemCode: SERVICE_REQUIRED_DAMAGE.itemCode,
            subItemCode: SERVICE_REQUIRED_DAMAGE.subItemCode,
            damageCode: SERVICE_REQUIRED_DAMAGE.damageCode,
            severityCode: SERVICE_REQUIRED_DAMAGE.severityCode,
            groupCode: this.props.account.groupCode,
            category: this.props.consignment.categoryCode,
            listOptions: true
        });
    }

    handleServiceChangeChecked = async (e) => {
        if (e.currentTarget.name === BRAKE_FLUID) {
            await this.props.toggleBrakeFluidServiceSelected();
        } else {
            await this.props.toggleEngineOilServiceSelected();
        }

        if (this.props.globalDisplay.engineOilServiceSelected || this.props.globalDisplay.brakeFluidServiceSelected) {
            let damage = SERVICE_REQUIRED_DAMAGE;
            damage.notes = this.getDamageNote();

            this.setState({
                damage: damage,
            }, () => this.toggleDamageModal());
        }
    };


    handleServiceChange = async (e) => {
        if (e.target.checked) {
            this.setState({
                target: e
            }, () => this.handleServiceChangeChecked(e))

        } else {
            if (e.currentTarget.name === BRAKE_FLUID) {
                this.props.toggleBrakeFluidServiceSelected();
            } else {
                this.props.toggleEngineOilServiceSelected();
            }

            let serviceRequiredDamage = this.getServiceRequiredDamage();
            await this.props.deleteAutoDamage(SERVICE_REQUIRED_DAMAGE);
            const notes = serviceRequiredDamage?.notes?.split(', ');
            if (notes?.length > 1) {
                serviceRequiredDamage.notes = notes.filter(note => note.toLowerCase() !== e.currentTarget?.name?.toLowerCase()).join("");
                this.props.addAutoDamage(serviceRequiredDamage);
            }
        }
    }
    getDamageNote = () => {
        const noteArray = []
        if (this.props.globalDisplay.engineOilServiceSelected) {
            noteArray.push(ENGINE_OIL)
        }
        if (this.props.globalDisplay.brakeFluidServiceSelected) {
            noteArray.push(BRAKE_FLUID)
        }
        return noteArray.join(', ')
    }

    showCheckboxes = () => {
        return (
            this.props.condition
            && this.props.condition.serviceRequired !== undefined
            && this.props.condition.serviceRequired !== null
            && this.props.account.isBmw
        )
    }

    disableCheckboxes = () => {
        return (
            this.props.condition
            && !this.props.condition.serviceRequired
            && this.props.account.isBmw
        )
    }

    toggleDamageModal = () => {
        const {showDamageModal} = this.state;
        this.setState({
            showDamageModal: !showDamageModal,
        });
    };

    handleCancelClick = () => {
        if (this.state.target.currentTarget.name === BRAKE_FLUID) {
            this.props.toggleBrakeFluidServiceSelected();
        } else {
            this.props.toggleEngineOilServiceSelected();
        }
        this.toggleDamageModal();
    };

    onSaveClick = () => {
        if (this.props.condition.damages) {
            this.props.deleteAutoDamage(SERVICE_REQUIRED_DAMAGE)
        }
    }

    render() {

        if (this.props.condition === null || !this.showCheckboxes()) {
            return <></>
        }

        return (<>
                <AddDamageModal showModal={this.state.showDamageModal}
                                onCancelClick={this.handleCancelClick}
                                onSaveClick={this.onSaveClick}
                                toggle={this.toggleDamageModal}
                                damage={this.state.damage}
                />
                <Col xs={6} sm={6} md={4} lg={3} xl={3} className='my-2'>
                    {this.props.account.isBmw && <Row>
                        <Col xs={12} md={6} className='flex-grow-1 pr-0 mb-3'>
                            <Checkbox label="Engine Oil"
                                      key={this.props.globalDisplay.engineOilServiceSelected ? this.props.globalDisplay.engineOilServiceSelected.toString() : 'engine-oil'}
                                      name="Engine Oil"
                                      id="engine-oil"
                                      checked={this.props.globalDisplay.engineOilServiceSelected}
                                      className="ml-0"
                                      disabled={this.disableCheckboxes()}
                                      onChange={this.handleServiceChange}
                            />
                        </Col>
                        <Col xs={12} md={6} className='flex-grow-1 pr-0'>
                            <Checkbox label="Brake Fluid"
                                      key={this.props.globalDisplay.brakeFluidServiceSelected ? this.props.globalDisplay.brakeFluidServiceSelected.toString() : 'brake-fluid'}
                                      name="Brake Fluid"
                                      id="brake-fluid"
                                      checked={this.props.globalDisplay.brakeFluidServiceSelected}
                                      className="ml-0"
                                      disabled={this.disableCheckboxes()}
                                      onChange={this.handleServiceChange}
                            />
                        </Col>
                    </Row>}
                </Col>
            </>)
    }
}

const mapDispatchToProps = {
    addAutoDamage, deleteAutoDamage, toggleEngineOilServiceSelected, toggleBrakeFluidServiceSelected
};

function mapStateToProps({condition, account, automatedDamages, globalDisplay, consignment}) {
    return {condition, account, automatedDamages, globalDisplay, consignment};
}

export default connect(mapStateToProps, mapDispatchToProps)(EngineOilAndBrakeFluid);