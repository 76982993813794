export function calculateButtonStates(appState={}) {
    let profile = 'enabled'
    let scan = 'enabled';
    let photos = 'enabled';
    let capture = 'enabled';
    let condition = 'enabled';
    let comments = 'enabled';
    let summary = 'enabled';
    let reports = 'enabled';

    if(appState.activePanel === 'scan') {
        profile = 'disabled';
        scan = 'selected';
        photos = 'disabled';
        capture = 'disabled';
        condition = 'disabled';
        comments = 'disabled';
        summary = 'disabled';
        reports = 'disabled';
    }

    if(appState.activePanel === 'profile') {
        profile = 'selected'
    }

    if(appState.activePanel === 'photos') {
        photos = 'selected'
    }

    if(appState.activePanel === 'capture') {
        capture = 'selected';
    }

    if(appState.activePanel === 'condition') {
        condition = 'selected';
    }

    if(appState.activePanel === 'comments') {
        comments = 'selected';
    }

    if(appState.activePanel === 'summary') {
        summary = 'selected';
    }

    if(appState.activePanel === 'reports') {
        reports = 'selected';
    }

    return {
        profile: profile,
        scan: scan,
        photos: photos,
        capture: capture ,
        condition: condition ,
        comments: comments ,
        summary: summary ,
        reports: reports
    };
}