import {
    ADD_SUGGESTED_DAMAGE_IMAGE,
    DELETE_DAMAGE,
    DELETE_DAMAGE_IMAGE,
    HANDLE_KEEP_PHOTO_RESPONSE,
    SET_DAMAGE_IMAGES,
    UNLOAD_WORK_ORDER,
    UPDATE_DAMAGE_IMAGE
} from "../actions/dispatchTypes";
import {DELETED, UPDATED, USE_EXISTING} from "../utils/constants";

export default function damageImageReducer(state = null, action) {

    switch (action.type) {
        case UNLOAD_WORK_ORDER:
            return null;
        case SET_DAMAGE_IMAGES:
            return action.damageImages;
        case DELETE_DAMAGE_IMAGE:
            return deleteDamageImage(state, action.payload.key);
        case UPDATE_DAMAGE_IMAGE:
            return addOrUpdateDamageImage(state, action, UPDATED);
        case HANDLE_KEEP_PHOTO_RESPONSE:
            return handleKeepPhotoResponse(state, action);
        case DELETE_DAMAGE:
            return deleteDamageImage(state, action.payload.damageKey);
        case ADD_SUGGESTED_DAMAGE_IMAGE:
            return addOrUpdateDamageImage(state, action, USE_EXISTING);
        default:
            return state;
    }
}

function deleteDamageImage(state, damageKey) {
    return Object.assign([], state.map(damageImage => {
        if (damageImage.key === damageKey) {
            damageImage.status = DELETED;
        }
        return damageImage;
    }));
}

function removeOrphanedDamagePhoto(newState, damageKey) {
    const orphanedImageIndex = newState.map(
        damageImage => damageImage.key === damageKey
    ).indexOf(true);
    if (orphanedImageIndex > -1) {
        newState.splice(orphanedImageIndex, 1);
    }
    return newState;
}

function handleKeepPhotoResponse(state, action) {
    const payload = action.payload
    let newState = [...state];
    // use originalDamageKey to remove the image from the image map since it is now an orphaned image (image with no corresponding damage)
    // use damageKeyAtWorkOrderLoad to mark the image for deletion from the image set
    if (payload.keepPrevPhoto) {
        if (payload.damageKeyAtWorkOrderLoad !== payload.originalDamageKey) {
            // damage has been edited multiple times
            // removing the image that no longer has a corresponding damage
            newState = removeOrphanedDamagePhoto(newState, payload.originalDamageKey)
        } else {
            // only need to mark it deleted the first time it's edited
            newState = deleteDamageImage(newState, payload.damageKeyAtWorkOrderLoad);
        }
        return newState.concat([{
            key: payload.key,
            previousKey: payload.damageKeyAtWorkOrderLoad,
            status: USE_EXISTING,
            url: payload.url
        }]);
    } else if (payload.keepPrevPhoto === false && !payload.url) {
        // not keeping the original photo and did not take a new photo. marking the image for deletion
        return deleteDamageImage(newState, payload.damageKeyAtWorkOrderLoad);
    } else if (payload.keepPrevPhoto === false && !!payload.url) {
        // not keeping the original photo and took a new one
        newState = deleteDamageImage(newState, payload.damageKeyAtWorkOrderLoad);
        return newState.concat([{
            key: payload.key,
            status: UPDATED,
            url: payload.url
        }]);
    }
}

function addOrUpdateDamageImage(state, action, status) {
    if (state.filter(damageImage => damageImage.key === action.payload.key).length) {
        return Object.assign([], state.map(damageImage => {
            if (damageImage.key === action.payload.key) {
                damageImage.url = action.payload.url;
                damageImage.status = status
            }
            return damageImage;
        }));
    } else {
        return state.concat([{key: action.payload.key, status: status, url: action.payload.url}]);
    }
}
