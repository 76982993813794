import {
    LOAD_WORK_ORDER_SUCCESS,
    RE_DECODE_VIN,
    SET_DESIGNATED_DESCRIPTION_FROM_VCR,
    UNLOAD_WORK_ORDER
} from "../actions/dispatchTypes";
import {NOT_SPECIFIED} from "../utils/constants";

export default function designatedDescriptionResponseReducer(state = null, action) {
    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return getDesignatedDescriptionResponse(action.payload.designatedDescriptionResponse) || state;
        case SET_DESIGNATED_DESCRIPTION_FROM_VCR:
            return action.payload;
        case RE_DECODE_VIN:
            return action.payload.designatedDescriptionResponse;
        case UNLOAD_WORK_ORDER:
            return null;
        default:
            return state;
    }
}

const getDesignatedDescriptionResponse = (response) => {
    if (response?.catalogVehicles) {
        response.catalogVehicles.forEach(vehicle => {
            if (!!vehicle.colors?.interior) {
                vehicle.colors.interior.map(color => {
                    if (!color.normalizedName) {
                        color.normalizedName = NOT_SPECIFIED
                    }
                    return color;
                })
            }
        })
    }
    return response;
}
