import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React, {useEffect, useState} from "react";
import {PLEASE_SELECT} from "../../../utils/constants";
import Api from "../../Api";
import {Typeahead} from "react-bootstrap-typeahead";

const Model = (props) => {
    const {
        designatedDescription,
        hasDDSResponse,
        onModelChange,
        globalDisplay
    } = props

    const {vehicleTypeId,
        vehicleSubTypeId,
        makeId} = globalDisplay

    const [options, setOptions] = useState([]);
    const model = designatedDescription?.model?.normalizedName;

    const handleChange = (options) => {
        onModelChange(options[0])
    }

    useEffect( () => {
        if(makeId) {
            async function fetchData() {
                return await Api.getModels({vehicleTypeId, vehicleSubTypeId, makeId});
            }
            fetchData().then(r => setOptions(r.items));
        }

    }, [makeId, vehicleSubTypeId, vehicleTypeId]);

    return(
        hasDDSResponse ?
            <Form.Group>
                <DataPair id="configure-model" label="Model">
                    {model}
                </DataPair>
            </Form.Group> :
            <Form.Group>
                <DataPair id="configure-model" label="Model" required={true}>
                    <Typeahead
                        disabled={!makeId || props.disabled}
                        id="model-dropdown"
                        placeholder={PLEASE_SELECT}
                        onChange={handleChange}
                        options={options? options : []}
                        labelKey="name"
                        clearButton
                        selected={options?.filter(item => item.name === model)}
                        emptyLabel={'Loading...'}
                    />
                </DataPair>
            </Form.Group>

    )

}

export default Model;