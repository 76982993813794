import React, {useCallback, useEffect, useState} from 'react';
import Col from "@prism/col";
import RadioButton from "@prism/radiobutton";
import Row from "@prism/row";
import {connect} from "react-redux";
import {CONDITION_ANNOUNCEMENT, STRUCTURAL_DAMAGE, STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE} from "../../../utils/constants";
import {addAnnouncementByCode, deleteAnnouncementByCode} from "../../../actions/announcementsActions";
import {setStructuralDamageSelected} from "../../../actions/globalDisplayActions";
import DataPair from "../../common/DataPair";
import {logObject} from "../../../actions/loggingActions";

function StructuralDamage(props) {
    const {
        condition,
        addAnnouncementByCode,
        setStructuralDamageSelected,
        deleteAnnouncementByCode,
        globalDisplay,
        announcements
    } = props;
    const [hasStructuralDamage, setHasStructuralDamage] = useState(null);

    const announcementExists = useCallback((referenceCode) => {
        const conditionAnnouncements = announcements?.items?.find(announcement => announcement.source === CONDITION_ANNOUNCEMENT);
        return conditionAnnouncements?.announcements?.filter(announcement => announcement.referenceCode === referenceCode).length > 0
    }, [announcements]);

    const handleFrameDamage = (e) => {
        setStructuralDamageSelected(true);
        logObject('StructuralDamage', 'handleFrameDamage', 'selectedValue', e.currentTarget.value)
        if (e.currentTarget.value === "true") {
            setHasStructuralDamage(true);
            addAnnouncementByCode(CONDITION_ANNOUNCEMENT, STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE, STRUCTURAL_DAMAGE);
        } else {
            setHasStructuralDamage(false);
            deleteAnnouncementByCode(CONDITION_ANNOUNCEMENT, STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE);
        }
    };

    useEffect(() => {
        if (announcementExists(STRUCTURAL_DAMAGE_ANNOUNCEMENT_CODE)) {
            setHasStructuralDamage(true);
            setStructuralDamageSelected(true);
        } else if (globalDisplay.structuralDamageSelected) {
            setHasStructuralDamage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition?.damages, announcements])

    return (
        <>
            <Col className="pr-0" xs={6}>
                <DataPair label="Structural Damage" id="frame-damage" required>
                    <Row>
                        <Col className="pl-0 pr-0" xs={6}>
                            <RadioButton label="Yes"
                                         name="frameDamage"
                                         value={true}
                                         checked={hasStructuralDamage === true}
                                         onClick={handleFrameDamage}
                            />
                        </Col>
                        <Col className="pl-0" xs={6}>
                            <RadioButton label="No"
                                         name="frameDamage"
                                         value={false}
                                         checked={hasStructuralDamage === false}
                                         onClick={handleFrameDamage}
                            />
                        </Col>
                    </Row>
                </DataPair>
            </Col>
        </>
    )
}

const matchDispatchToProps = {
    addAnnouncementByCode,
    deleteAnnouncementByCode,
    setStructuralDamageSelected,
    logObject
};

function mapStateToProps({condition, description, announcements, globalDisplay}) {
    return {condition, description, announcements, globalDisplay};
}

export default connect(mapStateToProps, matchDispatchToProps)(StructuralDamage);