import React from "react";
import {connect} from "react-redux";
import Col from "@prism/col";
import {odometerInopAnnouncementExists} from "../../utils/utils";
import {KILOMETERS_TITLE_CASE, MILES_TITLE_CASE} from "../../utils/constants";

const Ymmt = (props) => {
    let {announcements, designatedDescriptionResponse, unit} = props
    let odometerReading;
    let odometerUnits;
    const getModel = (designatedDescriptionResponse) => {
        return !designatedDescriptionResponse.model ? '' : designatedDescriptionResponse.model;
    }
    if (unit.odometer && unit.odometer.units) {
        odometerUnits = unit.odometer.units.toUpperCase().startsWith('K') ? KILOMETERS_TITLE_CASE : MILES_TITLE_CASE
    } else {
        odometerUnits = MILES_TITLE_CASE
    }

    if (!unit.odometer) {
        odometerReading = "-"
    } else if(!unit.odometer.reading || odometerInopAnnouncementExists(announcements)) {
        odometerReading = `- ${odometerUnits}`
    } else {
        odometerReading = `${unit.odometer.reading.toLocaleString()} ${odometerUnits}`
    }

    return (
        <Col>
            {designatedDescriptionResponse && <div
                className="font-weight-bolder year-make-model">{`${designatedDescriptionResponse.year} ${designatedDescriptionResponse.make} ${getModel(designatedDescriptionResponse)}`}</div>}
            {unit && <div className="font-weight-lighter vin">{unit.vin}</div>}
            <div className="font-weight-lighter odometer-hero">{odometerReading}</div>
        </Col>
    )
};

function mapStateToProps({unit, designatedDescriptionResponse, announcements}) {
    return {unit, designatedDescriptionResponse, announcements};
}

export default connect(mapStateToProps)(Ymmt);