import Form from "@prism/form";
import DataPair from "../../common/DataPair";
import React from "react";
import Dropdown from "../../common/Dropdown";
import {PLEASE_SELECT} from "../../../utils/constants";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";

const SubSeries = (props) => {
    const {
        designatedDescription,
        DDSResponse,
        onSubSeriesChange
    } = props;

    const manheimIdentifiers = designatedDescription?.thirdPartyIdentifiers?.manheim;
    const manheimWithSubSeries = !!manheimIdentifiers ? manheimIdentifiers.filter(identifier => !!identifier.ssubse) : []
    const isSelected = manheimIdentifiers?.length === 1;
    const subSeries = manheimWithSubSeries?.length === 1 ? manheimWithSubSeries[0].ssubse : null;

    const originalDDS = getDesignatedDescription({'designatedDescriptionResponse': DDSResponse})
    const originalManheimIdentifiers = originalDDS?.thirdPartyIdentifiers?.manheim;
    const options = !!originalManheimIdentifiers ? originalManheimIdentifiers.filter(identifier => !!identifier.ssubse) : []
    const hasMultipleSubSeries = options.length > 1;

    return (
        (!!designatedDescription?.trim && !hasMultipleSubSeries && !!subSeries) ? (
            <Form.Group>
                <DataPair
                    id="configure-sub-series"
                    label="Sub-Series"
                    children={subSeries}>
                </DataPair>
            </Form.Group>
        ) : (!!designatedDescription?.trim && hasMultipleSubSeries) ? (
            <Form.Group>
                <DataPair id="configure-sub-series" label="Sub-Series" required={true}>
                    <Dropdown
                        onChange={onSubSeriesChange}
                        value={subSeries}
                        id='sub-series-dropdown'>
                        {!isSelected && <option value=''>{PLEASE_SELECT}</option>}
                        {options.map(option => {
                            return (
                                <option
                                    value={option.ssubse}
                                    key={option.ssubse}>
                                    {option.ssubse}
                                </option>
                            )
                        })}
                    </Dropdown>
                </DataPair>
            </Form.Group>
        ) : null
    )
}

export default SubSeries;