import React, {useState, useEffect, useRef} from 'react';
import Api from '../../../Api';
import Dropdown from "../../../common/Dropdown";
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import isEqual from "lodash.isequal";
import {PLEASE_SELECT} from "../../../../utils/constants";

function DamageDropdown(props) {

    const [options, setOptions] = useState([]);
    const prevProps = useRef({item: {}});

    useEffect(() => {

        function isMissingInputs(props) {
            if (!props) {
                return true
            }
            return !props.item.itemCode || !props.item.subItemCode;
        }

        async function getDamageTypes(signal, props) {
            return Api.getDamages({
                ...props.item,
                groupCode: props.groupCode,
                category: props.categoryCode, signal: signal
            });
        }

        if (!isEqual(prevProps.current, props)) {
            if (isMissingInputs(props)) {
                setOptions([]);
                prevProps.current = props;
            } else if ((prevProps.current.item.itemCode !== props.item.itemCode) || (prevProps.current.item.subItemCode !== props.item.subItemCode)) {
                const abortController = new AbortController();
                const signal = abortController.signal;

                getDamageTypes(signal, props).then(response => {
                    if (response.items) {
                        if (!response.items.find(item => item.damage.code === props.value)) {
                            props.value && response.items.push({
                                damage: {
                                    code: props.value,
                                    description: props.description
                                }
                            })
                        }
                        setOptions(response.items);
                        if (response.items.length === 1 && !props.value) {
                            const selectedOption = response.items[0].damage;
                            const selectedOptions = [{}, {text: selectedOption.description}];
                            props.onChange({
                                target: {
                                    options: selectedOptions,
                                    selectedIndex: 1,
                                    value: selectedOption.code
                                }
                            })
                        }
                    } else {
                        setOptions([]);
                        props.setStatusMessage("There was a problem fetching Damage list")
                    }
                });
                prevProps.current = props;
            }
        }


    }, [props]);


    return (
        <Dropdown disabled={(props.value === '' && options.length === 0) || props.disabled} onChange={props.onChange}
                  value={props.value}
                  id={props.id || 'damage-dropdown-comp'}>
            {!props.value && <option value='' disabled>{PLEASE_SELECT}</option>}
            {options.length === 0 && props.value && <option value={props.value}>{props.description}</option>}
            {options.length && options.map(option => {
                return (
                    <option key={option.damage.code} value={option.damage.code}>{option.damage.description}</option>)
            })}
        </Dropdown>
    )

}

export default DamageDropdown;
