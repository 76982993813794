import React from "react";
import "../../styles/section.css";
import {uuid} from "../../utils/utils";
import Row from "@prism/row";
import Col from "@prism/col";

const Section = (props) => {

  return(
    <div className={`section-container ${props.containerClass ? props.containerClass : ''}`}>

        <Row className={`section-head ${props.containerHeadingClass ? props.containerHeadingClass : ''}`}
             id={props.id ? props.id : uuid}>
            {props.required && <span className="col-form-label font-weight-bolder text-danger pr-1">*</span>}
            {props.heading}

            <Col></Col>
            {props.suggestedDamage && <span><i className="icon prism-icon-bolt"/></span>}

        </Row>


      <div className={`${props.containerBodyClass ? props.containerBodyClass : 'pt-2'}`}>{props.children}</div>
    </div>
  )
};

export default Section;