import DataPair from "../../common/DataPair";
import Form from "@prism/form";
import React from "react";
import {ELECTRIC_ENGINE_CATEGORIES, INDUCTIONS, PLEASE_SELECT} from "../../../utils/constants";
import Dropdown from "../../common/Dropdown";
import {getDesignatedDescription} from "../../../reducers/designatedDescriptionReducer";

const Induction = (props) => {
    const {
        designatedDescription,
        DDSResponse,
        onInductionChange,
    } = props

    const hasDDSResponse = !!DDSResponse;
    const originalDDS = getDesignatedDescription({'designatedDescriptionResponse': DDSResponse});
    const fuelType = designatedDescription?.powertrain?.engine?.fuelCategory;
    const induction = designatedDescription?.powertrain?.engine?.aspiration;
    const originalInduction = originalDDS?.powertrain?.engine?.aspiration;
    const isElectric = ELECTRIC_ENGINE_CATEGORIES.includes(fuelType);

    if ((!hasDDSResponse && !fuelType) || isElectric) {
        return <></>
    } else {
        return (
            hasDDSResponse && !!induction && !!originalInduction ?
                <Form.Group>
                    <DataPair id="configure-induction" label="Induction">
                        {induction}
                    </DataPair>
                </Form.Group> :
                <Form.Group>
                    <DataPair id="configure-induction" label="Induction" required={true}>
                        <Dropdown
                            onChange={onInductionChange}
                            value={induction}
                            id='induction-dropdown'>
                            {!induction && <option value=''>{PLEASE_SELECT}</option>}
                            {INDUCTIONS.map(option => {
                                return (
                                    <option
                                        value={option}
                                        key={option}>
                                        {option}
                                    </option>
                                )
                            })}
                        </Dropdown>
                    </DataPair>
                </Form.Group>
        )
    }

}

export default Induction;