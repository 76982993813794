import React from 'react';
import Spinner from './Spinner';
import "../../styles/spinner.css";
import Button from "@prism/button";
import {connect} from "react-redux";

class SpinnerOverlay extends React.Component {

    handleCancel = () => {
        this.props.scanSubmitter.abort();
    };

    render() {
        return (
            <div className="spinner-overlay" id={this.props.id}>
                <div className={this.props.showCancelButton ? "spinner-container-with-button" : "spinner-container"}>
                    <Spinner size={this.props.size}/>
                </div>
                {this.props.showCancelButton &&
                <div className="cancel-button-container">
                    <div style={{display: this.props.showCancelButtonForLoad ? "inline-block" : "none"}}>
                        <div className="text-secondary-dark">Sorry, this is taking longer than expected. You can continue to wait or Cancel and retry.</div>
                        <div id='button-container' style={{display: "inline-block"}}>
                            <Button color="secondary" className="m-2" id='cancel-spinner' onClick={this.handleCancel}>Cancel</Button>
                        </div>
                    </div>
                </div>
                }
            </div>
        )

    }
}

function mapStateToProps({globalDisplay}) {
    const {showCancelButtonForLoad} = globalDisplay;
    return {showCancelButtonForLoad}
}

export default connect(mapStateToProps)(SpinnerOverlay);