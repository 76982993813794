import React from 'react';
import {connect} from "react-redux";
import update from "immutability-helper";

import Button from '@prism/button';
import Col from '@prism/col';
import Row from '@prism/row';

import TextCounter from "../common/TextCounter"
import {updateCondition} from "../../actions/conditionActions";


class EditSellerNotes extends React.Component {

    state = {
        notesToSeller: null
    };

    handleEditSellerNotes = (event) => {
        this.setState({
            notesToSeller: event.value
        })
    };

    handleSave = () => {
        let newCondition = update(this.props.condition, {
            $merge: {
                notesToSeller: this.state.notesToSeller
            }
        });
        this.props.updateCondition(newCondition);
        this.props.hideEditPanel();
    };

    render() {
        if (!this.props.condition) {
            return null;
        }

        return (
            <Col className="mt-3" id="edit-seller-notes">
                <Row>
                    <Col>
                        <h1 className='text-secondary-dark prism-h1'>Edit Seller Notes</h1>
                    </Col>
                    <Col className='flex-grow-0'>
                        <i className="icon prism-icon-cross text-secondary-dark cursor-pointer" id='cross-button'
                           onClick={this.props.hideEditPanel}/>
                    </Col>
                </Row>
                <Row className="flex-nowrap">
                    <div className="col">
                        <label className="prism-h2 item text-secondary-dark" id='edit-vehicle'>Notes</label>
                    </div>
                </Row>
                <Row className="mb-3 text-secondary-dark">
                    <Col>
                        <TextCounter
                            onChange={this.handleEditSellerNotes}
                            placeholder="Enter Seller Notes"
                            maxLength={300}
                            value={this.props.condition.notesToSeller}
                            rows="8"
                            id="edit-seller-notes-input"
                        />
                    </Col>
                </Row>
                <Row className="mb-4 flex-row-reverse">
                    <Col className="flex-grow-0">
                        <Button color="primary" className="m-0" onClick={this.handleSave}>SAVE</Button>
                    </Col>
                    <Col className="flex-grow-0 pr-0">
                        <Button outline color="dark" className="m-0" onClick={this.props.hideEditPanel}>CANCEL</Button>
                    </Col>
                </Row>
            </Col>
        )
    };
}

const mapDispatchToProps = {
    updateCondition
};

function mapStateToProps({condition}) {
    return {condition};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSellerNotes);
export {EditSellerNotes};
