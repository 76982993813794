import Col from "@prism/col";
import DataPair from "../../../../common/DataPair";
import {NumberInput} from "@prism/input";
import React from "react";

const RangeQuestion = (props) => {
    let labelText = props.question.question.slice(0, props.question.question.indexOf('*') === -1 ? props.question.question.length : props.question.question.indexOf('*'));
    let requiredFlag = props.required ? '*' : null

    return (
        <Col className="col-12 my-2" id={props.question.id}>
            <DataPair label={<div>{labelText}{requiredFlag && <span className="text-danger">{requiredFlag}</span>}</div>} id={props.question.question}>
                <NumberInput color="primary" className="justify-unset" value={props.question.answer && parseInt(props.question.answer)} min={props.min || 0} max={props.max || 100} onChange={props.onChange} required={props.required}/>
                {props.question.invalid && <span className="text-danger font-size-sm"><i className="icon prism-icon-notification-circle text-danger" /> Required</span>}
            </DataPair>
        </Col>
    )
}

export default RangeQuestion;
