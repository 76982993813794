import React from "react";
import TruckKingCabExterior from "./TruckKingCabExterior";
import TruckKingCabInterior from "./TruckKingCabInterior";
import Mechanical from "../Mechanical";
import TruckKingCabStructural from "./TruckKingCabStructural";
import {connect} from "react-redux";

const TruckKingCab = (props) => {
    switch (props.flatCarView) {
        case "interior":
            return <TruckKingCabInterior clearSelectedItem={props.clearSelectedItem}/>;
        case "exterior":
            return <TruckKingCabExterior clearSelectedItem={props.clearSelectedItem}/>;
        case "structural":
            return <TruckKingCabStructural clearSelectedItem={props.clearSelectedItem}/>;
        case "mechanical":
            return <Mechanical clearSelectedItem={props.clearSelectedItem}/>;
        default:
            return <TruckKingCabExterior clearSelectedItem={props.clearSelectedItem}/>;
    }
};

function mapStateToProps({globalDisplay}) {
    const {flatCarView} = globalDisplay;
    return {flatCarView};
}

export default connect(mapStateToProps)(TruckKingCab);