import {FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS, FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR} from "../actions/dispatchTypes";

export default function dataListsReducer(state = defaultState(), action) {
    switch (action.type) {
        case FETCH_ANNOUNCEMENTS_DICTIONARY_SUCCESS:
            return setAnnouncementsDictionary(state, action.payload);
        case FETCH_ANNOUNCEMENTS_DICTIONARY_ERROR:
            return {...state, announcementsDictionary: []};
        default:
            return state;
    }
}

function setAnnouncementsDictionary(state, payload) {
    return {
        ...state,
        announcementsDictionary: payload
    }
}

function defaultState() {
    return {
        announcementsDictionary: []
    }
}