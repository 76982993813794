import React from 'react';
import {toggleDamageModal} from "../../../actions/globalDisplayActions";
import {addAutoDamage, calculateDamageKey, deleteAutoDamage} from "../../../actions/conditionActions";
import {
    PLEASE_SELECT,
    SPARE_TIRE_TYPES,
    TIRE_DEPTHS,
    TIRE_LOCATION_MAP,
    TIRE_MANUFACTURERS
} from "../../../utils/constants";
import {MISSING_SPARE_TIRE_DAMAGE} from "../../../utils/constantsDamages";
import TireSizeSelector from "./TireSizeSelector"
import Row from "@prism/row";
import Col from "@prism/col";
import Button from "@prism/button";
import ButtonGroup from "@prism/buttongroup";
import Input from "@prism/input";
import {Typeahead} from "react-bootstrap-typeahead";
import DataPair from "../../common/DataPair"
import update from "immutability-helper";
import Dropdown from "../../common/Dropdown";
import snakeCase from "lodash.snakecase";
import camelCase from "lodash.camelcase";

import {connect} from "react-redux";
import AddDamageModal from "../condition/AddDamageModal";

import "../../../styles/tire.css";

class Tire extends React.Component {

    state = {
        showSizeSelector: false,
        selectedSpareType: null
    };

    componentDidMount() {
        if (!this.props.tire.construction) {
            this.handleConstructionButtonClick("R")
        }
    }

    getTireDamageFromRules = () => {
        const tireDamageItem = TIRE_LOCATION_MAP[camelCase(this.props.tire.location)];
        let tireDamage = this.props.damageRules.damages.find (damage => damage.item === tireDamageItem.description);
        tireDamage.damageKey = calculateDamageKey(tireDamage);
        this.setState({tireDamage})
    };

    getMissingSpareTireDamage = () => {
        let tireDamage = MISSING_SPARE_TIRE_DAMAGE;
        tireDamage.damageKey = calculateDamageKey(tireDamage);
        this.setState({tireDamage})
    };

    handleToggleSizeSelector = () => {
        this.setState({showSizeSelector: !this.state.showSizeSelector});
    };

    handleSizeUpdate = (size) => {
        const {width, aspectRatio, rimDiameter} = size;
        const separator = width.toString().slice(-1) === "X" ? "" : "/";
        const displaySize = `${width}${separator}${aspectRatio}-${rimDiameter}`;

        let storedWidth;
        if(width === parseInt(width)) {
            storedWidth = width;
        } else {
            storedWidth = parseInt(width.toString().replace("X", "").replace(".",""));
        }
        const storedRatio = parseInt(aspectRatio.toString().replace(".", ""));

        const updatedTire = update(this.props.tire, {
            $merge: {
                width: storedWidth,
                aspectRatio: storedRatio,
                rimDiameter: parseFloat(rimDiameter),
                size: displaySize
            }
        });

        this.props.updateTire(updatedTire);
    };

    handleSpareTypeChange = async (e) => {
        const value = e.currentTarget.value;
        await this.clearSpareTireDetails();
        if (value.startsWith("missing")) {
            const wornTireDamage = this.getWornTireFromCondition();
            this.deleteAutoDamageIfExists(wornTireDamage);

            await this.getMissingSpareTireDamage();
            if (!this.hasMissingSpareTireDamage() || (this.state.selectedSpareType !== value)) {
                this.props.toggleDamageModal(camelCase(this.props.tire.location))
            }
        } else if(value !== 'full' && this.hasWornTireDamage) {
            const wornTireDamage = this.getWornTireFromCondition();
            this.deleteAutoDamageIfExists(wornTireDamage);
            const missingTireDamage = this.getMissingSpareTireDamagefromCondition();
            this.deleteAutoDamageIfExists(missingTireDamage);
        }
        else {
            const missingTireDamage = this.getMissingSpareTireDamagefromCondition();
            this.deleteAutoDamageIfExists(missingTireDamage);
        }

        this.saveSpareType(value);
    };

    handleManufacturerChange = (make) => {
        const updatedTire = update(this.props.tire, {
            $merge: {
                manufacturer: make[0]
            }
        });

        this.props.updateTire(updatedTire);
    };

     handleTreadDepthChange = async (e) => {
        const minTreadDepth = parseInt((this.props.damageRules.rules.treadDepth).split("/")[0]);
        const newDepth = e.target.value ==="" ? null : parseInt(e.target.value);
        this.saveTreadDepth(newDepth);
        if (newDepth < minTreadDepth) {
            await this.getTireDamageFromRules();
            if (!this.props.condition.damages || !this.props.condition.damages.find(dmg => dmg.damageKey === this.state.tireDamage.damageKey)) {
                this.props.toggleDamageModal(camelCase(this.props.tire.location))
            }else if(this.props.condition.damages && this.props.condition.damages.find(dmg => dmg.damageKey === this.state.tireDamage.damageKey) &&
                !(this.props.automatedDamages.length && this.props.automatedDamages.find((autoDamage) => autoDamage.damageKey === this.state.tireDamage.damageKey))){
                this.props.addAutoDamage(this.state.tireDamage)
            }
        } else {
            if(this.hasWornTireDamage()) {
                this.props.deleteAutoDamage(this.getWornTireFromCondition());
            }
        }
    };

    saveTreadDepth(newDepth) {
        const updatedTire = update(this.props.tire, {
            $merge: {
                depth: newDepth
            }
        });
        this.props.updateTire(updatedTire);
    }

    saveSpareType = (spareType) => {
        let conditionSpareType;
        let updatedTire;

        switch (spareType) {
            case 'none':
                conditionSpareType = 'none';
                break;
            case 'compact-spare':
            case 'missing-compact-spare':
                conditionSpareType = 'compact-spare';
                break;
            case 'full-spare':
            case 'missing-full-spare':
                conditionSpareType = 'full-spare';
                break;
            default:
                conditionSpareType = null;
        }

        if (spareType && (spareType.startsWith('missing') || spareType === 'compact-spare')) {
            updatedTire = update(this.props.tire, {
                $merge: {
                    lowProfile: false,
                    manufacturer: "Other",
                    construction: "R",
                    spareType: conditionSpareType
                }
            });
        } else if(spareType === 'none') {
            updatedTire = update(this.props.tire, {
                $merge: {
                    lowProfile: false,
                    manufacturer: "Other",
                    construction: "R",
                    spareType: conditionSpareType
                }
            });
        }
        else {
            updatedTire = update(this.props.tire, {
                $merge: {
                    spareType: conditionSpareType,
                    lowProfile: false,
                    construction: "R"
                }
            });
        }

        this.props.updateTire(updatedTire);
    };

    clearSpareTireDetails = () => {
        const updatedTire = update(this.props.tire, {
            $merge: {
                lowProfile: null,
                manufacturer: null,
                construction: null,
                depth: null,
                width: null,
                aspectRatio: null,
                rimDiameter: null,
                size: null
            }
        });
        this.props.updateTire(updatedTire);
    };

    handleCancelClick = () => {
        if(this.isSpareTire() && this.state.tireDamage.damageCode === 'MS') {
            this.saveSpareType(null);
        } else {
            this.saveTreadDepth(null);
        }
        this.props.toggleDamageModal(camelCase(this.props.tire.location));
    };

    handleConstructionButtonClick = (value) => {
        const updatedTire = update(this.props.tire, {
            $merge: {
                construction: value,
                lowProfile: value === 'ZR'
            }
        });

        this.props.updateTire(updatedTire);
    };

    copyTire = (e) => {
        const sourceTire = this.props.validTires.find(tire => tire.location === e.target.value);
        const updatedTire = update(this.props.tire, {
            $merge: {
                manufacturer: sourceTire.manufacturer,
                width: sourceTire.width,
                aspectRatio: sourceTire.aspectRatio,
                size: sourceTire.size,
                rimDiameter: sourceTire.rimDiameter,
                construction: sourceTire.construction,
                lowProfile: sourceTire.lowProfile
            }
        });
        this.props.updateTire(updatedTire);
    };

    isSpareTire = () => {
        return this.props.tire.location === 'Spare';
    };

    getMissingSpareTireDamagefromCondition = () => {
        if(!this.props.condition.damages) {return undefined}
        const tireDamage = MISSING_SPARE_TIRE_DAMAGE;
        const tireDamageKey = `${tireDamage.itemCode}-${tireDamage.subItemCode}-${tireDamage.damageCode}`;
        return this.props.condition.damages.find(damage => damage.damageKey.includes(tireDamageKey))
    };

    hasMissingSpareTireDamage = () => {
      const spareTireDamage = this.getMissingSpareTireDamagefromCondition();
      return !!spareTireDamage;
    };

    getWornTireFromCondition = () => {
        if(!this.props.condition.damages) {return undefined}
        const tireDamageItem = TIRE_LOCATION_MAP[camelCase(this.props.tire.location)];
        const tireDamage = this.props.damageRules.damages.find (damage => damage.item === tireDamageItem.description);
        const tireDamageKey = `${tireDamage.itemCode}-${tireDamage.subItemCode}-${tireDamage.damageCode}`;
        return this.props.condition.damages.find(damage => damage.damageKey.includes(tireDamageKey));
    };

    hasWornTireDamage = () => {
        const wornTireDamage = this.getWornTireFromCondition();
        return !!wornTireDamage;
    };

    deleteAutoDamageIfExists = (damage) => {
        if(damage) {
            this.props.deleteAutoDamage(damage);
        }
    };

    setSpareTypeForView = () => {
        let selectedSpareType = this.props.tire.spareType;
        if(selectedSpareType && selectedSpareType !== "none") {
            if (this.hasMissingSpareTireDamage()) {
                selectedSpareType = `missing-${selectedSpareType}`;
            }
        }
        if(selectedSpareType !== this.state.selectedSpareType) {
            this.setState({
                selectedSpareType
            })
        }
    };

    render() {
        const {manufacturer, size, depth, construction} = this.props.tire;
        const isSpareTire = this.isSpareTire();
        const fullSizeTire = !isSpareTire || (this.state.selectedSpareType === "full-spare");
        isSpareTire && this.setSpareTypeForView();

        return (

            <div id={`${snakeCase(this.props.tire.location)}`} style={{width: "100%"}}>
                {this.state.tireDamage && <AddDamageModal showModal={this.props.globalDisplay[camelCase(this.props.tire.location)]}
                                onCancelClick={this.handleCancelClick}
                                toggle={() => this.props.toggleDamageModal(camelCase(this.props.tire.location))}
                                damage={this.state.tireDamage}/>}
                <TireSizeSelector isOpen={this.state.showSizeSelector}
                                  handleToggleSizeSelector={this.handleToggleSizeSelector}
                                  handleSizeUpdate={this.handleSizeUpdate}
                                  tire={this.props.tire}/>


                {isSpareTire && <Row className="mx-0 mb-3">
                    <Col xs={12} sm={12} md={4} lg={3} xl={2} className="px-0">
                        <DataPair label="Tire Type" id={`${snakeCase(this.props.tire.location)}__spare-type`}>
                            <Dropdown value={this.state.selectedSpareType} onChange={this.handleSpareTypeChange}>
                                <option value="" disabled>{PLEASE_SELECT}</option>
                                {SPARE_TIRE_TYPES.map(spare => (
                                    <option key={spare.code} name={spare.code} value={spare.code}>{spare.description}</option>
                                ))}
                            </Dropdown>
                        </DataPair>
                    </Col>
                </Row>}

                {fullSizeTire && <Row className="mx-0 mb-3">
                        <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                            <DataPair label="Manufacturer"
                                      id={`${snakeCase(this.props.tire.location)}__tire-manufacturer`}>
                                <Typeahead className="autocomplete-tire"
                                           clearButton={false}
                                           defaultSelected={manufacturer ? [manufacturer] : [""]}
                                           options={TIRE_MANUFACTURERS}
                                           placeholder="Please select..."
                                           minLength={0}
                                           size="small"
                                           onChange={this.handleManufacturerChange}
                                           id={`${snakeCase(this.props.tire.location)}__tire-manufacturer-typeahead`}
                                           key={`${manufacturer}-${snakeCase(this.props.tire.location)}`}
                                />
                            </DataPair>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                            <DataPair label="Size" id={`${snakeCase(this.props.tire.location)}__tire-size-label`}>
                                <div className=" input-group align-items-center" onClick={this.handleToggleSizeSelector}>
                                    <Input
                                        type="text"
                                        id={`${snakeCase(this.props.tire.location)}__tire-size`}
                                        className="form-control tire-size-input"
                                        readOnly={true}
                                        value={size}
                                    />
                                    <div className="input-group-append">
                                        <Button color="primary" className="input-group-text pr-2 pl-2 m-0"
                                                onClick={this.handleToggleSizeSelector}>
                                            <i className={'icon icon-primary-dark prism-icon-edit'}/>
                                        </Button>
                                    </div>
                                </div>
                            </DataPair>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                            <DataPair label="Tread Depth" id={`${snakeCase(this.props.tire.location)}__tread-depth`}>
                                <Dropdown value={depth && depth.toString()}
                                          onChange={this.handleTreadDepthChange}>
                                    <option value="" disabled="disabled">{PLEASE_SELECT}</option>
                                    {TIRE_DEPTHS.map(depth_option => (
                                        <option id={depth_option.toString()} key={depth_option.toString()} value={depth_option.toString()}>{`${depth_option}/32`}</option>
                                    ))}
                                </Dropdown>
                            </DataPair>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                            <DataPair label="Construction" id={`${snakeCase(this.props.tire.location)}__construction-label`}>
                                <ButtonGroup className="m-0"
                                             id={`${snakeCase(this.props.tire.location)}__construction`}>
                                    <Button outline active={construction === 'B'}
                                            onClick={() => this.handleConstructionButtonClick('B')}>B</Button>
                                    <Button outline active={construction === 'D'}
                                            onClick={() => this.handleConstructionButtonClick('D')}>D</Button>
                                    <Button outline active={construction === 'R'}
                                            onClick={() => this.handleConstructionButtonClick('R')}>R</Button>
                                    <Button outline active={construction === 'ZR'}
                                            onClick={() => this.handleConstructionButtonClick('ZR')}>ZR</Button>
                                </ButtonGroup>
                            </DataPair>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                            <DataPair label="" id={`${snakeCase(this.props.tire.location)}__copy-location`}>
                                <Dropdown disabled={!this.props.validTires.length > 0} onChange={this.copyTire}>
                                    <option>Select tire to copy</option>
                                    {this.props.validTires.map(validTire => {
                                        return <option key={validTire.location} value={validTire.location}>{validTire.location}</option>
                                    })}
                                </Dropdown>
                            </DataPair>
                        </Col>
                </Row>}


            </div>
        )
    }
}

const matchDispatchToProps = {
    toggleDamageModal,
    deleteAutoDamage,
    addAutoDamage
};

function mapStateToProps({condition, damageRules, globalDisplay, automatedDamages}) {
    return {condition, damageRules, globalDisplay, automatedDamages};
}

export default connect(mapStateToProps, matchDispatchToProps)(Tire);