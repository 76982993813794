import React from "react";
import Col from "@prism/col";
import DataPair from "../../common/DataPair";
import Dropdown from "../../common/Dropdown";
import {addAutoDamage, deleteAutoDamage, updateServiceRequired} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {PLEASE_SELECT, SERVICE_REQUIRED_OPTIONS} from "../../../utils/constants";
import {SERVICE_REQUIRED_DAMAGE} from "../../../utils/constantsDamages";
import Api from "../../Api";
import AddDamageModal from "./AddDamageModal";

class ServiceRequired extends React.Component {

    state = {};
    getServiceRequiredDamage = () => {
        if (this.props.condition && this.props.condition.damages) {
            return this.props.condition.damages.find(damage => damage.item === SERVICE_REQUIRED_DAMAGE.item);
        }
        return null;
    };

    handleServiceRequired = async (e) => {
        let updatedServiceRequired;
        let value = e.target.value.toLowerCase()

        switch (value) {
            case 'true':
                updatedServiceRequired = true;
                break;
            case 'false':
                updatedServiceRequired = false;
                break;
            default:
                updatedServiceRequired = null;
        }
        await this.props.updateServiceRequired(updatedServiceRequired);

        if (this.props.account.isMercedes) {
            if (value === 'true') {
                this.toggleDamageModal();
            } else if (value === 'false' || !!value) {
                if (this.getServiceRequiredDamage()) {
                    await this.props.deleteAutoDamage(SERVICE_REQUIRED_DAMAGE)
                }
            }
        }
    }

    getChargeableOptions = async () => {
        return Api.getChargeableOptions({
            itemCode: SERVICE_REQUIRED_DAMAGE.itemCode,
            subItemCode: SERVICE_REQUIRED_DAMAGE.subItemCode,
            damageCode: SERVICE_REQUIRED_DAMAGE.damageCode,
            severityCode: SERVICE_REQUIRED_DAMAGE.severityCode,
            groupCode: this.props.account.groupCode,
            category: this.props.consignment.categoryCode,
            listOptions: true
        });
    }

    getServiceRequiredOptions = () => {
        return SERVICE_REQUIRED_OPTIONS.map(option => {
            return <option value={option.code} key={option.code}>{option.description}</option>;
        });
    };

    isNewClientSpecificCondition = (isBmw, isMercedes) => {
        return (isBmw || isMercedes) && this.props.condition.isNewCondition && !this.props.globalDisplay.serviceRequiredSelected
    }

    getDropdown = (serviceRequired, isBmw, isMercedes) => {
        let isNewClientSpecificCondition = this.isNewClientSpecificCondition(isBmw, isMercedes)
        if (isNewClientSpecificCondition) {
            serviceRequired = null;
        }
        return <Dropdown
            type="text"
            id="edit-service-required"
            value={serviceRequired}
            onChange={this.handleServiceRequired}>
            {isNewClientSpecificCondition && <option value="" disabled>{PLEASE_SELECT}</option>}
            {this.getServiceRequiredOptions()}
        </Dropdown>;
    }

    toggleDamageModal = () => {
        const {showDamageModal} = this.state;
        this.setState({
            showDamageModal: !showDamageModal,
        });
    };

    handleCancelClick = () => {
        this.props.updateServiceRequired(false);
        this.toggleDamageModal();
    };

    render() {
        const {isBmw, isMercedes} = this.props.account
        const serviceRequired = (this.props.condition && this.props.condition.serviceRequired !== undefined && this.props.condition.serviceRequired !== null) ? this.props.condition.serviceRequired.toString() : 'null';

        if (this.props.condition === null) {
            return <></>
        }
        return (
            <>
                <AddDamageModal showModal={this.state.showDamageModal}
                                onCancelClick={this.handleCancelClick}
                                toggle={this.toggleDamageModal}
                                damage={SERVICE_REQUIRED_DAMAGE}
                />
                <Col xs={6} sm={6} md={4} lg={3} xl={3}>
                    <DataPair label="Service Required" id="Service_Required" required={isBmw || isMercedes}>
                        {this.getDropdown(serviceRequired, isBmw, isMercedes)}
                    </DataPair>
                </Col>
            </>
        )
    }
}

const mapDispatchToProps = {
    updateServiceRequired,
    addAutoDamage,
    deleteAutoDamage
};

function mapStateToProps({condition, account, globalDisplay, consignment}) {
    return {condition, account, globalDisplay, consignment};
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequired);