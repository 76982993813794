import React from "react";

import {connect} from "react-redux";
import {handlePanelClick} from "../../../../actions/flatCarActions";

const Mechanical = (props) => {
    function handlePanelClick(panelId) {
        props.clearSelectedItem();
        props.handlePanelClick(panelId);
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -40 1350 1300">
            <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g id="mechanical" transform="translate(0.000000,1248.000000) scale(0.100000,-0.100000)" className="flat-car" stroke="#c0c0c0" strokeWidth="150" onClick={() => handlePanelClick(70)} >
                <path className={`panel-dark${props.selectedFlatCarPanel === 70 ? ' active' : ''}`} d="M1521 12322 l-81 -162 52 -30 c29 -16 406 -232 838 -479 l785 -449 -2 -128 c-3 -157 -24 -311 -63 -472 -124 -508 -428 -915 -892 -1196 l-77 -47 -808 462 c-444 254 -822 470 -840 481 l-32 19 -41 -80 -40 -81 45 -126 c171 -469 434 -893 728 -1171 469 -443 1087 -674 1877 -699 212 -7 259 -16 377 -79 229 -121 522 -350 963 -750 236 -214 353 -327 721 -693 l327 -324 642 642 643 641 -345 347 c-925 930 -1467 1555 -1666 1922 -61 113 -69 157 -76 395 -14 492 -80 830 -217 1124 -134 286 -364 533 -647 693 -432 245 -1019 370 -1854 394 l-236 7 -81 -161z"/>
                <path className={`panel-dark${props.selectedFlatCarPanel === 70 ? ' active' : ''}`} d="M10805 11524 c-434 -304 -796 -563 -803 -576 -7 -13 -46 -160 -88 -328 l-75 -304 -2320 -2318 -2319 -2318 -320 200 -320 200 -160 -160 c-156 -156 -160 -162 -160 -203 0 -61 -17 -171 -40 -259 -93 -362 -401 -708 -826 -925 -208 -107 -435 -181 -632 -205 l-103 -13 -1321 -1320 -1321 -1319 7 -86 c27 -362 234 -753 567 -1075 287 -277 601 -446 934 -500 50 -8 110 -15 134 -15 l44 0 1319 1319 c1220 1222 1318 1322 1318 1353 0 108 55 333 122 503 209 526 590 912 1015 1025 43 12 130 26 195 33 l116 11 157 156 157 157 -201 321 -201 322 2310 2309 c1271 1269 2317 2314 2325 2321 8 7 155 47 326 90 171 43 317 84 324 92 7 7 261 367 564 800 l550 787 -208 208 c-115 114 -224 222 -242 239 l-34 32 -790 -554z"/>
                <path className={`panel-dark${props.selectedFlatCarPanel === 70 ? ' active' : ''}`} d="M6957 5998 l-639 -641 435 -436 c614 -617 916 -935 1222 -1291 332 -386 540 -689 574 -840 5 -25 12 -139 16 -255 19 -728 166 -1185 488 -1522 144 -151 281 -251 477 -348 425 -212 972 -318 1752 -341 l236 -7 81 161 81 162 -52 30 c-29 16 -406 232 -838 479 l-785 449 2 123 c12 707 353 1310 945 1667 l87 53 808 -462 c444 -254 822 -470 840 -481 l32 -19 40 80 40 79 -28 84 c-70 206 -198 481 -314 673 -413 684 -1033 1083 -1872 1204 -161 23 -386 41 -528 41 -152 0 -259 42 -472 183 -339 226 -775 617 -1575 1414 -223 221 -408 403 -410 402 -3 0 -292 -289 -643 -641z"/>
            </g>
        </svg>
    )
};

const matchDispatchToProps = {
    handlePanelClick
};

function mapStateToProps({globalDisplay}){
    const {selectedFlatCarPanel} = globalDisplay;
    return {selectedFlatCarPanel};
}

export default connect(mapStateToProps, matchDispatchToProps)(Mechanical)