import React from "react";
import Icon from "@prism/icon";

const Thumbnail = (props) => {


    const getImageLabel = (key) => {
        if(key && key.length >13) {
            return `${key.substring(0, 12)}...`;
        }
        return key;
    };

    const miscLabel = (key) => {
        const miscellaneous = 'MISCELLANEOUS';
        if(key && key.length>13 && key.includes(miscellaneous) ) {
            return ('MISC'+key.substring(13, key.length));
        }
        return key;
    }

    let footer = 'MISC';
    if(props.imageKey) {
        footer = getImageLabel(props.imageKey);
    }
    if(props.image && props.image.imageTag) {
        footer = miscLabel(props.image.imageTag);
    }

    return (
        <div id={props.id}
             className="thumbnail-item"
             key={props.imageKey}
             onClick={props.onClick}>
          {!props.image && <div className="thumbnail-item-header">
            <Icon glyph="camera"/>
          </div>}

          {props.image ? <img alt="thumbnail" src={props.image.src.thumbnail} /> :
            <div className="thumbnail-placeholder"> <Icon glyph="image" /></div>}
            <div className="thumbnail-item-footer">
                {footer}
            </div>
        </div>
    );
};

export default Thumbnail;