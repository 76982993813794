import {
    LOAD_WORK_ORDER_SUCCESS,
    RESET_SUBMIT_STATUS,
    SET_TAB_INDEX,
    SHOW_DAMAGE_SUMMARY_VIEW,
    SUBMIT_WORK_ORDER_SUCCESS
} from "../actions/dispatchTypes";

const defaultState = {
    selectedConditionTabIndex: 0,
    submitSuccessful: undefined
};

export default function navigationReducer(state = defaultState, action) {

    switch (action.type) {
        case LOAD_WORK_ORDER_SUCCESS:
            return setCurrentConditionTabIndex(state, 0)
        case SET_TAB_INDEX:
            return setCurrentConditionTabIndex(state, action.selectedConditionTabIndex)
        case SUBMIT_WORK_ORDER_SUCCESS:
            return {...state, submitSuccessful: true}
        case RESET_SUBMIT_STATUS:
            return {...state, submitSuccessful: false}
        case SHOW_DAMAGE_SUMMARY_VIEW:
            return setCurrentConditionTabIndex(state, 3)
        default:
            return state;
    }

}

function setCurrentConditionTabIndex(state, selectedConditionTabIndex) {
    return {...state, selectedConditionTabIndex}
}